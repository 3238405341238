/**
 * Created by Kirill Mashchenko on 03.07.17.
 */

/**
 * @class Functions for PushLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.PushLevelModule = pm.ModuleInfo.extend(/** @lends pm.PushLevelModule# */{

	name: "Push",

	getType: function()
	{
		return pm.PushLevelModule.Type;
	},

	getBackgroundParameters: function ()
	{
		return{
			color: cc.color(102, 0, 255),

			centerImage: "Background/Levels/push_center.png"
		};
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.PushMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = PushMapElementType.Grass;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.PushLevel();

		level.name = "Новый Двигун";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.PushRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.PushLevelModule.Type] = new pm.data.PushTask(level);

		return level;
	},

	getRobotSpriteSheet: function (robotType)
	{
		switch (robotType)
		{
			case pm.PushLevelModule.RobotTypes.PushRobot:
				return "Robot/push_robot.plist";
			case pm.PushLevelModule.RobotTypes.PullRobot:
				return "Robot/pull_robot.plist";
		}
	},

	getObjectsInfo: function()
	{
		var ret = {};
		ret[pm.PushLevelModule.ObjectTypes.PushBarrel] = "Objects/PushBarrel.png";
		ret[pm.PushLevelModule.ObjectTypes.PushBox] = "Objects/PushBox.png";
		return ret;
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.PushLevelModule.RobotTypes.PushRobot] = "push_robot0.png";
		ret[pm.PushLevelModule.RobotTypes.PullRobot] = "pull_robot0.png";
		return ret;
	},

	getSettings: function (robotType, object)
	{
		return new PushRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return PushMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		switch (robotType)
		{
			case pm.PushLevelModule.RobotTypes.PushRobot:
				return LocalizedString("Push");
			case pm.PushLevelModule.RobotTypes.PullRobot:
				return LocalizedString("Pull");
		}
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/push_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return[
			"Robot Methods/robot4-methods.plist",
			"Conditions/robot4-conditions.plist",
			"Conditions/push_robot-conditions.plist",
			"Robot Methods/pull_robot-methods.plist"
		];
	},

	generateObject: function (objectType)
	{
		switch (objectType)
		{
			case pm.PushLevelModule.ObjectTypes.PushBox:
				return new pm.data.PushBox();
			case pm.PushLevelModule.ObjectTypes.PushBarrel:
				return new pm.data.PushBarrel();
		}
	},

	generateRobot: function (robotType)
	{
		switch (robotType)
		{
			case pm.PushLevelModule.RobotTypes.PushRobot:
				return new pm.data.PushRobot();
			case pm.PushLevelModule.RobotTypes.PullRobot:
				return new pm.data.PullRobot();
		}
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Push": "Push",
					"Pull": "Pull",
					"PushLevelType": "Push level",
					"PushTask": "Push objects to targets",
					"PushLevel": "Push and Pull",
					"push_pull" : "push",
					"push_mf" : "can move",
					"push_nmf" : "can not move"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Push": "Двигун",
					"Pull": "Тягун",
					"PushLevelType": "Двигун",
					"PushTask": "Расставить предметы",
					"PushLevel": "Двигун и Тягун",
					"push_pull" : "тащить",
					"push_mf" : "можно двигаться вперед",
					"push_nmf" : "нельзя двигаться вперед"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/PushLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new PushTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "PushTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.PushTask(level);
		level.taskList.addTask(pm.PushLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function()
	{
		return {
			horizontal: pm.spriteUtils.getMapTileFrame("push-depth_horizontal"),
			vertical: pm.spriteUtils.getMapTileFrame("push-depth_vertical")
		};
	},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function() {},

	getLessonMapLayer: function()
	{
		var level = new pm.data.PushLevel();

		level.name = "Push";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.PushRobot());

		level.startRobotIndex = 0;

		level.robots[0].id = 0;
		level.robots[0].groupID = 0;
		level.robots[0].parentLevel = level;

		level.robots[0]._oldPosition = cc.p(1, 0);
		level.robots[0].position = cc.p(1, 0);
		level.robots[0].direction = 0;

		level.programData[0] = new pm.data.ProgramInfo();
		level.programData[0].currentIndex = 0;
		level.programData[0].programDataArray[0] = pm.appUtils.generateProgramData();

		level.robots.push(new pm.data.PullRobot());

		level.robots[1].id = 1;
		level.robots[1].groupID = 1;
		level.robots[1].parentLevel = level;

		level.robots[1]._oldPosition = cc.p(0, 1);
		level.robots[1].position = cc.p(0, 1);
		level.robots[1].direction = 1;

		level.programData[1] = new pm.data.ProgramInfo();
		level.programData[1].currentIndex = 0;
		level.programData[1].programDataArray[0] = pm.appUtils.generateProgramData();

		level.startMapIndex = 0;

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.PushLevelModule.Type] = new pm.data.PushTask(level);

		var map = new pm.data.PushMap();

		map.parentLevel = level;
		map.setSize(2, 2);

		for(var x = 0; x < 2; ++x)
		{
			for(var y = 0; y < 2; ++y)
				map.element(cc.p(x, y)).originalType = PushMapElementType.Grass;
		}

		map.element(cc.p(1, 0)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 0)).startRobotData.direction = 0;

		map.element(cc.p(0, 1)).startForRobot = level.robots[1].id;
		map.element(cc.p(0, 1)).startRobotData.direction = 1;

		map.objects.push(new pm.data.PushBarrel());

		map.objects[0].position = cc.p(0,0);

		level.maps.push(map);
		level.load();

		map.load();
		map.generateMapLayer(false);

		map.mapLayer.placeRobots(true);
		map.mapLayer.spawnObjects();

		level.robots[0].sprite.getSprite().removeAllChildren();
		level.robots[1].sprite.getSprite().removeAllChildren();

		return map.mapLayer;
	}
});

pm.PushLevelModule.RobotTypes = {
	PushRobot: "push-robot",
	PullRobot: "pull-robot"
};
pm.PushLevelModule.Type = "push";
pm.PushLevelModule.ObjectTypes = {
	PushBox: "PushBox",
	PushBarrel: "PushBarrel"
};
