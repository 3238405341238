/**
 * Created by Kirill Mashchenko on 23.04.20.
 */

pm.appUtils = {

	_modules: {},

	load: function()
	{
		pm.robotManager = pm.appUtils.generateRobotManager();

		FunctionButton.Type = pm.appUtils.getButtonTypes();
		FunctionButton.deselect = pm.appUtils.getDeselectFunction();

		var availableRobotList = pm.appUtils.getAvailableRobots();

		for (var i = 0; i < availableRobotList.length; ++i)
			pm.moduleUtils.register(availableRobotList[i]);
	},

	register: function(module, type)
	{
		this._modules[type] = module;
	},

	getProgramLayerWidth: function()
	{
		return this._modules[pm.appConfig.appType].getProgramLayerWidth();
	},

	compileRobotProgram: function(robot, level)
	{
		return this._modules[pm.appConfig.appType].compileRobotProgram(robot, level);
	},

	generateProgramLayer: function(gameType, useMethodStack, robot, level)
	{
		return this._modules[pm.appConfig.appType].generateProgramLayer(gameType, useMethodStack, robot, level);
	},

	generateProgramEditorLayer: function(gameType, useMethodStack, robot, level)
	{
		return this._modules[pm.appConfig.appType].generateProgramEditorLayer(gameType, useMethodStack, robot, level);
	},

	generateRobotManager: function()
	{
		return this._modules[pm.appConfig.appType].generateRobotManager();
	},

	generateProgramData: function(isFilled)
	{
		return this._modules[pm.appConfig.appType].generateProgramData(isFilled);
	},

	getButtonTypes: function()
	{
		return this._modules[pm.appConfig.appType].getButtonTypes();
	},

	getDeselectFunction: function()
	{
		return this._modules[pm.appConfig.appType].getDeselectFunction();
	},

	getFunctionButtonPrototype: function()
	{
		return this._modules[pm.appConfig.appType].getFunctionButtonPrototype();
	},

	getProgramLayerPrototype: function()
	{
		return this._modules[pm.appConfig.appType].getProgramLayerPrototype();
	},

	generateFunctionButton: function(type, value, drawBackground)
	{
		return this._modules[pm.appConfig.appType].generateFunctionButton(type, value, drawBackground);
	},

	createMethodListButton: function(value)
	{
		return this._modules[pm.appConfig.appType].createMethodListButton(value);
	},

	generatePlayerRobotInterfaceLayer: function (robot)
	{
		return this._modules[pm.appConfig.appType].generatePlayerRobotInterfaceLayer(robot);
	},

	getAvailableGlobalRobots: function()
	{
		return this._modules[pm.appConfig.appType].getAvailableGlobalRobots();
	},

	getAvailableRobots: function()
	{
		return this._modules[pm.appConfig.appType].getAvailableRobots();
	},

	useExtraMethodsInKumirRobot: function()
	{
		return this._modules[pm.appConfig.appType].useExtraMethodsInKumirRobot();
	},

	getAppVersion: function()
	{
		return this._modules[pm.appConfig.appType].getAppVersion();
	},

	getSupportedLevelFormatVersion: function()
	{
		return this._modules[pm.appConfig.appType].getSupportedLevelFormatVersion();
	},

	getWorldConvertFunctions: function()
	{
		return this._modules[pm.appConfig.appType].getWorldConvertFunctions();
	},

	getAquariusTankScale: function ()
	{
		return this._modules[pm.appConfig.appType].getAquariusTankScale();
	},

	getLoadText: function ()
	{
		return this._modules[pm.appConfig.appType].getLoadText();
	},

	/**
	 * Adds localized images of all modules for language.
	 * @param {Object} localizedImages
	 * @param {String} language Localized string language
	 * @returns {String}
	 */
	extendImagesLocalization: function(localizedImages, language)
	{
		var commands = [pm.CMD_A, pm.CMD_B, pm.CMD_C, pm.CMD_D, pm.CMD_E];

		for(var i = 0; i < commands.length; ++i)
			localizedImages[commands[i]] = "{0}{1}".format(pm.LANGUAGE_PREFIXES[language], commands[i]);

		for(var module in this._modules)
		{
			var moduleLoc = this._modules[module].getImagesLocalization(language);

			for (var loc in moduleLoc)
			{
				if (localizedImages[loc])
					cc.warn("Duplicate image localization from module {0} for key: {1}!".format(module, loc));

				localizedImages[loc] = moduleLoc[loc];
			}
		}
	}

};
