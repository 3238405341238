/**
 * Created by Kirill Mashchenko on 17.05.23.
 */

/**
 * @class Functions for PressRobotLevel module.
 * @extends pm.ModuleInfo
 */

pm.PressRobotLevelModule = pm.ModuleInfo.extend(/** @lends pm.PressRobotLevelModule# */{

	name: "Press",

	_clearCondition: null,
	_canPressCondition: null,
	_boxCondition: null,

	_conditionChangedTarget: null,
	_conditionChangedCallback: null,

	getType: function()
	{
		return pm.PressRobotLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.PressRobotMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = PressRobotMapElementType.Green;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.PressRobotLevel();

		level.name = "Новый Толкун";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";

		level.robots.push(new pm.data.PressRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.PressRobotLevelModule.Type] = new pm.data.PressTask(level);
		level.taskList.tasks[pm.PressRobotLevelModule.Type].data[0] = [];

		return level;
	},

	getObjectsInfo: function()
	{
		var ret = {};
		ret[pm.PressRobotLevelModule.ObjectTypes.PressBox] = "Objects/PressBox.png";
		return ret;
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.PressRobotLevelModule.RobotType] = "press_robot.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/press_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new PressRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return PressRobotMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Press");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/physical_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/robot4-methods.plist",
			"Robot Methods/press_robot-methods.plist",
			"Conditions/press_robot-conditions.plist",
			"Robot Methods/pull_robot-methods.plist"
		];
	},

	generateObject: function (objectType)
	{
		return new pm.data.PressBox();
	},

	generateRobot: function (robotType)
	{
		return new pm.data.PressRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{

			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Press": "Press robot",
					"PressLevelType": "Press robot level",
					"PressTask": "Press objects",
					"PressRobotLevel": "Press robot",
					"press_press": "press",
					"press_load_box": "load box",
					"press_unload_box": "unload_box",
					"press_clear": "can move",
					"press_not_clear": "can not move",
					"press_can_press": "can press",
					"press_can_not_press": "can not press",
					"press_box": "is box forward",
					"press_not_box": "is not box forward"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Press": "Толкун",
					"PressLevelType": "Толкун",
					"PressTask": "Расставить грузики",
					"PressRobotLevel": "Толкун",
					"press_press": "толкать",
					"press_load_box": "загрузить",
					"press_unload_box": "разгрузить",
					"press_clear": "впереди свободно",
					"press_not_clear": "впереди не свободно",
					"press_can_press": "можно толкать",
					"press_can_not_press": "нельзя толкать",
					"press_box": "впереди есть груз",
					"press_not_box": "впереди нет груза"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/PressRobotLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new PressTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "PressTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.PressTask(level);
		level.taskList.addTask(pm.PressRobotLevelModule.Type, task);

		return task;
	},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target)
	{
		this._conditionChangedCallback = callback;
		this._conditionChangedTarget = target;

		var conditionsLayout = new ccui.Layout();
		conditionsLayout.setLayoutType(ccui.Layout.RELATIVE);

		this._clearCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Clear, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Clear, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Clear, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useClearCondition)
			this._clearCondition.setSelectedTexture();
		else
			this._clearCondition.setNormalTexture();

		if (!robot.usePressMethod)
			this._clearCondition.setEnabled(false);

		this._clearCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "clear", flag);

			if (robot.useClearCondition)
				this._clearCondition.setSelectedTexture();
			else
				this._clearCondition.setNormalTexture();
		}.bind(this));

		this._canPressCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.CanPress, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.CanPress, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.CanPress, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useCanPressCondition)
			this._canPressCondition.setSelectedTexture();
		else
			this._canPressCondition.setNormalTexture();

		if (!robot.usePressMethod)
			this._canPressCondition.setEnabled(false);

		this._canPressCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "canPress", flag);

			if (robot.useCanPressCondition)
				this._canPressCondition.setSelectedTexture();
			else
				this._canPressCondition.setNormalTexture();
		}.bind(this));

		this._boxCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Box, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Box, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.PressRobot.Condition.Box, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useBoxCondition)
			this._boxCondition.setSelectedTexture();
		else
			this._boxCondition.setNormalTexture();

		if (!robot.usePressMethod)
			this._boxCondition.setEnabled(false);

		this._boxCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "box", flag);

			if (robot.useBoxCondition)
				this._boxCondition.setSelectedTexture();
			else
				this._boxCondition.setNormalTexture();
		}.bind(this));

		var clearAlign = new ccui.RelativeLayoutParameter();
		clearAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		clearAlign.setRelativeName("clear");
		this._clearCondition.setLayoutParameter(clearAlign);

		var canPressAlign = new ccui.RelativeLayoutParameter();
		canPressAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		canPressAlign.setMargin(5, 0, 0, 0);
		canPressAlign.setRelativeName("canPress");
		canPressAlign.setRelativeToWidgetName("clear");
		this._canPressCondition.setLayoutParameter(canPressAlign);

		var boxAlign = new ccui.RelativeLayoutParameter();
		boxAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		boxAlign.setMargin(5, 0, 0, 0);
		boxAlign.setRelativeToWidgetName("canPress");
		this._boxCondition.setLayoutParameter(boxAlign);

		conditionsLayout.addChild(this._clearCondition);
		conditionsLayout.addChild(this._canPressCondition);
		conditionsLayout.addChild(this._boxCondition);

		conditionsLayout.setContentSize(LevelSettingsLayer.WIDTH, this._clearCondition.height);

		return conditionsLayout;
	},

	updateChangeableConditions: function(robot, name)
	{
		switch(name)
		{
			case "clear":
				robot.useClearCondition = !robot.useClearCondition;
				break;
			case "canPress":
				robot.useCanPressCondition = !robot.useCanPressCondition;
				break;
			case "box":
				robot.useBoxCondition = !robot.useBoxCondition;
				break;
		}
	},

	compareConditions: function(a, b)
	{
		if (a.indexOf("clear") !== -1 && b.indexOf("clear") === -1)
			return -1;
		else if (a.indexOf("clear") === -1 && b.indexOf("clear") !== -1)
			return 1;

		if (a.indexOf("can") !== -1 && b.indexOf("can") === -1)
			return -1;
		else if (a.indexOf("can") === -1 && b.indexOf("can") !== -1)
			return 1;

		if (a.indexOf("box") !== -1 && b.indexOf("box") === -1)
			return -1;
		else if (a.indexOf("box") === -1 && b.indexOf("box") !== -1)
			return 1;
	},

	getChangeableMethods: function(robot, callback, target)
	{
		var methodsLayout = new ccui.Layout();
		methodsLayout.setLayoutType(ccui.Layout.RELATIVE);

		var pressMethod = new pmui.SelectionButton(pm.spriteUtils.getRobotMethodSpriteName(pm.data.PressRobot.NativeMethod.Press, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotMethodSpriteName(pm.data.PressRobot.NativeMethod.Press, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotMethodSpriteName(pm.data.PressRobot.NativeMethod.Press, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.usePressMethod)
			pressMethod.setSelectedTexture();
		else
			pressMethod.setNormalTexture();

		pressMethod.addClickEventListener(function (flag)
		{
			callback.call(target, "press", flag);

			if (robot.usePressMethod)
				pressMethod.setSelectedTexture();
			else
				pressMethod.setNormalTexture();
		});

		var loadingBoxesMethod = new pmui.SelectionButton(pm.spriteUtils.getRobotMethodSpriteName("press_loading_setting", pm.NORMAL_STATE),
			pm.spriteUtils.getRobotMethodSpriteName("press_loading_setting", pm.SELECTED_STATE),
			pm.spriteUtils.getRobotMethodSpriteName("press_loading_setting", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useLoadingBoxes)
			loadingBoxesMethod.setSelectedTexture();
		else
			loadingBoxesMethod.setNormalTexture();

		loadingBoxesMethod.addClickEventListener(function (flag)
		{
			callback.call(target, "loadBox", flag);

			if (robot.useLoadingBoxes)
				loadingBoxesMethod.setSelectedTexture();
			else
				loadingBoxesMethod.setNormalTexture();
		});

		var pressAlign = new ccui.RelativeLayoutParameter();
		pressAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		pressAlign.setRelativeName("pressMethod");
		pressMethod.setLayoutParameter(pressAlign);

		var loadingBoxesAlign = new ccui.RelativeLayoutParameter();
		loadingBoxesAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		loadingBoxesAlign.setMargin(5, 0, 0, 0);
		loadingBoxesAlign.setRelativeToWidgetName("pressMethod");
		loadingBoxesMethod.setLayoutParameter(loadingBoxesAlign);

		methodsLayout.addChild(pressMethod);
		methodsLayout.addChild(loadingBoxesMethod);

		methodsLayout.setContentSize(LevelSettingsLayer.WIDTH, loadingBoxesMethod.height);

		return methodsLayout;
	},

	updateChangeableMethods: function(robot, name)
	{
		switch(name)
		{
			case "loadBox":
				robot.useLoadingBoxes = !robot.useLoadingBoxes;
				break;
			case "press":
				robot.usePressMethod = !robot.usePressMethod;

				if (!robot.usePressMethod)
				{
					robot.useClearCondition = false;
					robot.useCanPressCondition = false;
					robot.useBoxCondition = false;

					this._clearCondition.setEnabled(false);
					this._canPressCondition.setEnabled(false);
					this._boxCondition.setEnabled(false);

					this._conditionChangedCallback.call(this._conditionChangedTarget, "");
				}
				else
				{
					robot.useClearCondition = true;
					robot.useCanPressCondition = true;
					robot.useBoxCondition = true;

					this._clearCondition.setEnabled(true);
					this._canPressCondition.setEnabled(true);
					this._boxCondition.setEnabled(true);

					this._clearCondition.setSelectedTexture();
					this._canPressCondition.setSelectedTexture();
					this._boxCondition.setSelectedTexture();

					this._conditionChangedCallback.call(this._conditionChangedTarget, "");
				}

				break;
		}

		robot.updateSprite();
	},

	compareMethods: function(a, b)
	{
		if (a.indexOf("4") !== -1 && b.indexOf("4") === -1)
			return -1;
		else if (a.indexOf("4") === -1 && b.indexOf("4") !== -1)
			return 1;

		if (a.indexOf("press_press") !== -1 && b.indexOf("press_press") === -1)
			return -1;
		else if (a.indexOf("press_press") === -1 && b.indexOf("press_press") !== -1)
			return 1;

		if (a.indexOf("press_load_box") !== -1 && b.indexOf("press_load_box") === -1)
			return -1;
		else if (a.indexOf("press_load_box") === -1 && b.indexOf("press_load_box") !== -1)
			return 1;

		if (a.indexOf("press_unload_box") !== -1 && b.indexOf("press_unload_box") === -1)
			return -1;
		else if (a.indexOf("press_unload_box") === -1 && b.indexOf("press_unload_box") !== -1)
			return 1;
	},

	getAvailablePhysicalCommands: function()
	{
		return [
			"r4_move",
			"r4_turn_left",
			"r4_turn_right",
			"press_press",
			"press_box",
			"press_not_box",
			"indicate",
			"press_load_box",
			"press_unload_box"
		];
	},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.PressRobotLevelModule.RobotType = "press-robot";
pm.PressRobotLevelModule.Type = "press";
pm.PressRobotLevelModule.ObjectTypes = {
	PressBox: "PressBox"
};
