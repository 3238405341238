/**
 * Created by Nikita Besshaposhnikov on 23.08.18.
 */

/**
 * @class Helper class for loading world list and separating it by groups
 * @extends cc.Class
 */
var WorldList = cc.Class.extend(/** @lends WorldList# */{

	_selectGraph: {},
	_worldList: {},

	_loadCallback: null,
	_loadTarget: null,
	_loadLocalWorlds: null,

	ctor: function(callback, target, loadLocalWorlds)
	{
		this._loadCallback = callback;
		this._loadTarget = target;

		if(loadLocalWorlds === undefined)
			loadLocalWorlds = true;

		this._loadLocalWorlds = loadLocalWorlds;
	},

	getSelectGraph: function()
	{
		return this._selectGraph;
	},

	loadList: function()
	{
		this._worldList = {};
		this._selectGraph = {};

		this._selectedGroupID = pm.settings.getSelectedUserGroupID();
		this._selectedWorldID = pm.settings.getSelectedWorldID();

		var tasks =[];

		if (!pm.appConfig.useDefaultWorlds)
		{
			if (!pm.settings.isEditorMode)
			{
				tasks.push({
					apiCall: pm.apiServerUtils.getPublicWorlds,
					type: WorldList.WORLD_TYPE.PUBLIC
				});

				tasks.push({
					apiCall: pm.apiServerUtils.getPurchasedWorlds,
					type: WorldList.WORLD_TYPE.PURCHASED
				});

				if (pm.settings.userLoggedIn)
				{
					tasks.push({
						apiCall: pm.apiServerUtils.getSharedWorlds,
						type: WorldList.WORLD_TYPE.SHARED
					});
					//todo: some error may occur here with pm.apiServerUtils.getGrantedWorlds
					// (see jira [PIK-45])
					tasks.push({
						apiCall: pm.apiServerUtils.getGrantedWorlds,
						type: WorldList.WORLD_TYPE.GRANTED
					});
				}
			}
			else
			{
				tasks.push({
					apiCall: pm.apiServerUtils.getUserWorldList,
					type: WorldList.WORLD_TYPE.OWN
				});
				tasks.push({
					apiCall: pm.apiServerUtils.getSharedWorlds,
					type: WorldList.WORLD_TYPE.SHARED
				});
			}

			cc.async.map(tasks, {
				iterator: this._loadWorldList,
				iteratorTarget: this,
				cb: this._onLoadWorlds,
				cbTarget: this
			});
		}
		else
		{
			this._loadDefaultWorlds();
		}
	},

	_loadDefaultWorlds: function()
	{
		this._selectGraph[WorldList.PUBLIC_GROUP.id] = {
			name: WorldList.PUBLIC_GROUP.name,
			worlds: []
		};

		var worlds = this._selectGraph[WorldList.PUBLIC_GROUP.id].worlds;

		var foundWorld = false;

		var builtinWorlds = pm.settings.getBuiltinWorlds();

		for(var i = 0 ; i < builtinWorlds.length; ++i)
		{
			if(builtinWorlds[i].id === this._selectedWorldID)
				foundWorld = true;

			worlds.push(builtinWorlds[i]);

			this._worldList[builtinWorlds[i].id] = {
				type: WorldList.WORLD_TYPE.PUBLIC,
				name: builtinWorlds[i].name,
				description: builtinWorlds[i].description,
				owner: builtinWorlds[i].owner
			};
		}

		if(!foundWorld)
		{
			this._selectedWorldID = builtinWorlds[0].id;
			pm.settings.setSelectedWorldID(this._selectedWorldID);
		}

		if(this._loadCallback)
			this._loadCallback.call(this._loadTarget);
	},

	_onLoadWorlds: function(errors, worldLists)
	{
		if(errors || ! worldLists)
		{
			for(var i = 0 ; i < errors.length; ++i)
			{
				if(errors[i])
					cc.log("Error loading world list: " + errors[i].text);
			}
		}
		else
		{
			for (var i = 0; i < worldLists.length; ++i)
			{
				for (var w in worldLists[i])
					this._worldList[w] = worldLists[i][w];
			}
		}

		this._matchLocalAvailableWorlds();

		this._createSelectGraph();

		if(this._loadCallback)
			this._loadCallback.call(this._loadTarget);
	},

	_loadWorldList: function(params, index, cb)
	{
		params.apiCall.call(pm.apiServerUtils, function(error, response)
		{
			var worldList = {};

			if(!error && response)
			{
				for(var i = 0; i < response.worlds.length; ++i)
				{
					worldList[response.worlds[i].id] = response.worlds[i];
					worldList[response.worlds[i].id].type = params.type;
				}
			}

			cb(error, worldList);
		});
	},

	_matchLocalAvailableWorlds: function()
	{
		var availableWorlds = pm.settings.getAvailableWorlds();

		for(var worldID in this._worldList)
		{
			if(availableWorlds[worldID])
				pm.settings.setWorldMetaData(worldID, this._worldList[worldID]);
		}

		if(this._loadLocalWorlds)
		{
			for (var worldID in availableWorlds)
			{
				if (!this._worldList[worldID])
				{
					this._worldList[worldID] = availableWorlds[worldID];
					this._worldList[worldID].type = WorldList.WORLD_TYPE.PUBLIC;
				}
			}
		}
	},

	_createSelectGraph: function()
	{
		for(var worldID in this._worldList)
		{
			if(!pm.settings.isEditorMode && worldID === pm.appConfig.demoWorldID)
				continue;

			var world = this._worldList[worldID];

			if(world.type !== WorldList.WORLD_TYPE.GRANTED)
			{
				var groupData = null;

				switch(world.type)
				{
					case WorldList.WORLD_TYPE.PUBLIC:
						groupData = WorldList.PUBLIC_GROUP;
						break;
					case WorldList.WORLD_TYPE.OWN:
						groupData = WorldList.OWN_GROUP;
						break;
					case WorldList.WORLD_TYPE.PURCHASED:
						groupData = WorldList.PURCHASED_GROUP;
						break;
					case WorldList.WORLD_TYPE.SHARED:
						groupData = WorldList.SHARED_GROUP;
						break;
				}

				if(groupData)
				{
					if (!this._selectGraph[groupData.id])
					{
						this._selectGraph[groupData.id] = {
							name: LocalizedString(groupData.name),
							worlds: []
						};
					}

					var group = this._selectGraph[groupData.id];
					group.worlds.push(world);
				}
			}
			else
			{
				for(var i = 0 ; i < world.groups.length; ++i)
				{
					var groupID = world.groups[i].id;

					if(!this._selectGraph[groupID])
					{
						this._selectGraph[groupID] = {
							name: world.groups[i].name,
							worlds: []
						};
					}

					this._selectGraph[groupID].worlds.push(world);
				}
			}
		}

		for(var groupID in this._selectGraph)
		{
			this._selectGraph[groupID].worlds.sort(function(a, b)
			{
				return new Date(a.createDate) - new Date(b.createDate);
			});
		}
	}
});

/**
 * Public group data
 * @const
 * @type {{id:String, name:String}}
 * @default
 */
WorldList.PUBLIC_GROUP = {id: "public", name: "PublicWorldsGroup"};

/**
 * Own group data
 * @const
 * @type {{id:String, name:String}}
 * @default
 */
WorldList.OWN_GROUP = {id: "own", name: "OwnWorldsGroup"};

/**
 * Purchased group data
 * @const
 * @type {{id:String, name:String}}
 * @default
 */
WorldList.PURCHASED_GROUP = {id: "purchased", name: "PurchasedWorldsGroup"};
/**
 * Shared group data
 * @const
 * @type {{id:String, name:String}}
 * @default
 */
WorldList.SHARED_GROUP = {id: "purchased", name: "SharedWorldsGroup"};

/**
 * World type to select
 * @enum {String}
 */
WorldList.WORLD_TYPE = {
	PUBLIC: "public",
	OWN: "own",
	GRANTED: "granted",
	PURCHASED: "purchased",
	SHARED: "shared"
};
