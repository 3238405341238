/**
 * Created by Nikita Besshaposhnikov on 27.02.15.
 */

/**
 * @class This class provides a container for map layers.</br>
 * Maps are placed on line by {@link pm.MapsContainerLayer.LAYER_POSITION_ANGLE} to
 * X-axis with {@link pm.MapsContainerLayer.LAYER_POSITION_X_DELTA} delta.</br>
 * Maps can be dragged, if one of maps contains touch point, or scrolled in line described above.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level Level which maps will be represented.
 * @param {Boolean} previewDraw Preview draw of maps.
 */
pm.MapsContainerLayer = ccui.Layout.extend(/** @lends pm.MapsContainerLayer# */{
	_level: null,
	/**
     * Index of map tha is selected by player.
     * @type {Number}
     */
	activeMapIndex: -1,
	/**
     * @private
     * @type {cc.Node}
     */
	_parallax: null,
	/**
     * @private
     * @type {cc.Node}
     */
	_background: null,
	/**
     * @private
     * @type {Boolean}
     */
	_canScrollLayers: true,
	/**
     * @private
     * @type {Boolean}
     */
	_previewDraw: false,
	/**
     * @private
     * @type {Boolean}
     */
	_tutorialDraw: false,
	/**
     * @private
     * @type {cc.Point}
     */
	_mapScrollVector: cc.p(0, 0),
	/**
     * @private
     * @type {cc.Point}
     */
	_startPoint: cc.p(0, 0),
	/**
     * @private
     * @type {pm.MapsContainerLayer.TouchType| Number}
     */
	_touchType: 0,

	_touchCount: 0,

	_scale: 1.0,

	_centerPoint: cc.p(0, 0),

	_parallaxDelta: cc.p(0, 0),

	_enableScroll: false,

	_parallaxArray: [],

	_enableMoving: true,

	ctor: function(level, previewDraw, tutorialDraw)
	{
		this._super();

		this._parallaxArray = [];

		this._level = level;
		this.activeMapIndex = this._level.startMapIndex;
		this._previewDraw = previewDraw;
		this._tutorialDraw = tutorialDraw;

		this._touchType = pm.MapsContainerLayer.TouchType.None;

		this._enableScroll = true;

		this._enableMoving = true;

		var mapSize = pm.settings.getMapSize();

		var size = this._previewDraw ? mapSize : cc.size(pm.settings.getScreenSize().width - pm.appUtils.getProgramLayerWidth() + ProgramLayer.METHOD_STACK_WIDTH,
		pm.settings.getScreenSize().height);

		this.setContentSize(size);

		this._centerPoint = cc.p(mapSize.width / 2, mapSize.height / 2);

		this._parallax = new cc.Node();

		if(!previewDraw)
		{
			if (level.isTutorial && tutorialDraw)
			{
				this.setClippingEnabled(true);

				this._background = pm.backgroundUtils.generateLevelBackground(level.getType(), cc.size(size.width, size.height));
				this._parallax.addChild(this._background, -100);
			}
			else
			{
				var scaledMapSize = cc.size(size.width * pm.MapsContainerLayer.BACKGROUND_SCALE,
					size.height * pm.MapsContainerLayer.BACKGROUND_SCALE);

				this._background = pm.backgroundUtils.generateLevelBackground(level.getType(), scaledMapSize);

				this._background.setPosition(
					(size.width - scaledMapSize.width) / 2,
					(size.height - scaledMapSize.height) / 2
				);

				this._parallax.addChild(this._background, -100);
			}
		}

		this.addChild(this._parallax);

		this.drawMapLayers();

		this._touches = [];

		if(!this._previewDraw && !this._tutorialDraw)
		{
			var listener = cc.EventListener.create({
				event: cc.EventListener.TOUCH_ALL_AT_ONCE,
				onTouchesBegan: this._touchBegan.bind(this),
				onTouchesMoved: this._touchMoved.bind(this),
				onTouchesEnded: this._touchEnded.bind(this)
			});

			cc.eventManager.addListener(listener, this);

			pm.registerCustomEventListener(pm.MAP_CHANGED_EVENT_STR, this._mapChangedEventCallback.bind(this), this);

			pm.registerCustomEventListener(pm.LAYER_ZOOM_IN_EVENT, this._zoomIn.bind(this), this);
			pm.registerCustomEventListener(pm.LAYER_ZOOM_OUT_EVENT, this._zoomOut.bind(this), this);

			pm.registerCustomEventListener(pm.FOCUS_TO_ROBOT_IN_MAP, this._focusToRobot.bind(this), this);
		}

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function(event)
		{
			this._enableMoving = false ;

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, function(event)
		{
			this._enableMoving = true;

		}.bind(this), this);
	},

	_mapChangedEventCallback: function(event)
	{
		this.changeActiveMap(event.getUserData().index, event.getUserData().sendEvent, event.getUserData().rebaseRobots);
	},

	_focusToRobot: function(event)
	{
		var delta = event.getUserData();

		if(delta && delta.x !== undefined && delta.y !== undefined)
			this._moveParallaxLayerByDelta(delta, true, pm.settings.getAnimationSpeed());
	},

	/**
     * Cleans active map layer and moves layer to original position.
     */
	clean: function()
	{
		if(!this._previewDraw)
			this._level.maps[this.activeMapIndex].mapLayer.centerMap();

		for (var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].clean();
	},

	/**
     * Change displayed map by index.
     * @param {Number} newActiveMapIndex
     * @param {Boolean} [sendEvent = true] Send change Event
     * @param {Boolean} [rebaseRobots] rebase Robots

     */
	changeActiveMap: function(newActiveMapIndex, sendEvent, rebaseRobots)
	{
		if(sendEvent === undefined)
			sendEvent = true;

		var prevActiveMapIndex = this.activeMapIndex;

		if(prevActiveMapIndex !== newActiveMapIndex)
		{
			this.activeMapIndex = newActiveMapIndex;

			this._level.maps[prevActiveMapIndex].mapLayer.removeFocus();
			this._level.maps[newActiveMapIndex].mapLayer.focus();

			this._parallaxArray[prevActiveMapIndex] = this._getMapLayerRatio(prevActiveMapIndex);
			this._parallaxArray[newActiveMapIndex] = this._getMapLayerRatio(newActiveMapIndex);

			if(rebaseRobots)
				this._rebaseRobots(prevActiveMapIndex, newActiveMapIndex);

			if(sendEvent)
				pm.sendCustomEvent(pm.MAP_DRAGGED_EVENT_STR, {index: newActiveMapIndex});

			this._recalculateMapLayersZOrder();
		}

		this._correctMaps();
	},

	_recalculateMapLayersZOrder: function()
	{
		for (var i = 0; i < this._level.maps.length; ++i)
		{
			var depth = this.activeMapIndex - i;

			if (depth > 0)
				depth = -depth;

			this._level.maps[i].mapLayer.setLocalZOrder(depth);
		}
	},

	/**
     * Returns displayed map layer count
     * @returns {Number}
     */
	getMapCount: function()
	{
		return this._level.maps.length;
	},

	_getMapLayerRatio: function(index)
	{
		return index === this.activeMapIndex ?
			pm.MapsContainerLayer.ACTIVE_MAP_RATIO:
			pm.MapsContainerLayer.BACKGROUND_RATIO;
	},

	/**
     * Returns map layer position without parallax shift
     * @param {Number} i Index of map
     * @returns {cc.Point}
     * @private
     */
	_getOriginalMapLayerPos: function(i)
	{
		var ratio = this._parallaxArray[i];

		return cc.p(
			this._level.maps[i].mapLayer.x - ratio.x * this._parallaxDelta.x,
			this._level.maps[i].mapLayer.y - ratio.y * this._parallaxDelta.y
		);
	},

	/**
     * Draws all map layer in this container
     * @param {Boolean} [generateLayers=false] Generate layers by maps.
     */
	drawMapLayers: function(generateLayers)
	{
		if(generateLayers === undefined)
			generateLayers = true;

		if (!this._previewDraw)
		{
			for (var i = 0; i < this._level.maps.length; ++i)
			{
				if (generateLayers)
					this._level.maps[i].generateMapLayer(this._previewDraw);

				var depth = this.activeMapIndex - i;

				if (depth>0)
					depth = -depth;

				this._parallax.addChild(this._level.maps[i].mapLayer, depth);
				this._parallaxArray.push(this._getMapLayerRatio(i));

				if (i !== this.activeMapIndex)
					this._level.maps[i].mapLayer.removeFocus();
				else if (!this._previewDraw)
					this._level.maps[i].mapLayer.focus();
			}
		}
		else
		{
			if (generateLayers)
				this._level.maps[this.activeMapIndex].generateMapLayer(this._previewDraw);

			this._parallax.addChild(this._level.maps[this.activeMapIndex].mapLayer, this.activeMapIndex);
			this._parallaxArray.push(this._getMapLayerRatio(this.activeMapIndex));
		}

		if(!this._previewDraw && generateLayers)
		{
			for(var i = 0; i < this._level.maps.length; ++i)
			{
				if(i === this.activeMapIndex)
					this._level.maps[i].placeRobots(true);
			}
		}

		this._updateLayers();

		if(!this._previewDraw)
		{
			if(this._level.maps.length > 1)
				this._mapScrollVector = cc.pSub(this._level.maps[1].mapLayer.getPosition(), this._level.maps[0].mapLayer.getPosition());

			this._level.maps[this.activeMapIndex].mapLayer.centerMap();
		}
	},

	_updateLayers: function()
	{
		if (!this._previewDraw)
		{
			for (var i = 0; i < this._level.maps.length; ++i)
			{
				var layer = this._level.maps[i].mapLayer;
				var pos = this._layerPositionByIndex(i - this.activeMapIndex);
				var scale = this._getScaleByX(pos.x);
				var opacity = this._layerOpacityByX(pos.x);

				//dirty hack to make cc.Layer use anchor point for position.
				layer.ignoreAnchorPointForPosition(false);

				layer.setAnchorPoint(cc.p(0.5, 0.5));
				layer.setScale(scale);
				layer.setOpacity(opacity);
				layer.setPosition(cc.pAdd(pos, this._centerPoint));
			}
		}
		else
		{
			var layer = this._level.maps[this.activeMapIndex].mapLayer;
			var pos = this._layerPositionByIndex(0);
			var scale = this._getScaleByX(pos.x);
			var opacity = this._layerOpacityByX(pos.x);

			//dirty hack to make cc.Layer use anchor point for position.
			layer.ignoreAnchorPointForPosition(false);

			layer.setAnchorPoint(cc.p(0.5, 0.5));
			layer.setScale(scale);
			layer.setOpacity(opacity);
			layer.setPosition(cc.pAdd(pos, this._centerPoint));
		}
	},

	_isTouchMoved: function(touch)
	{
		var touchPoint = this.convertTouchToNodeSpace(touch);
		
		return cc.pLength(cc.pSub(this._startPoint, touchPoint)) > pm.MapsContainerLayer.TOUCH_CRITICAL_DISTANCE;
	},

	_touchBegan: function(touches, pEvent)
	{
		if(!this.enabled)
			return;

		var activeMapLayer = this._level.activeMap.mapLayer;

		var foundFlag = false;

		for (var i = 0 ; i < touches.length; ++i)
			if (!activeMapLayer.containsPoint(touches[0]))
				foundFlag = true;

		if (this._touchCount > 0 && foundFlag)
			return;

		this._touchCount = 0;
		this._touchType = pm.MapsContainerLayer.TouchType.None;

		this._startPoint = this.convertTouchToNodeSpace(touches[0]);

		var mapSize = pm.settings.getMapSize();

		if(this._startPoint.x > mapSize.width || this._startPoint.y > mapSize.height)
			return;

		if (activeMapLayer.containsPoint(touches[0]))
		{
			++this._touchCount;

			if(this._touchCount === 2)
				this._touchType = pm.MapsContainerLayer.TouchType.ScaleActiveMap;
			else
				this._touchType = pm.MapsContainerLayer.TouchType.TouchActiveMap;
		}
		else if(this._level.maps.length > 1)
		{
			this._touchType = pm.MapsContainerLayer.TouchType.Touch;
		}
	},

	_touchMoved: function(touches, pEvent)
	{
		if(!this.enabled)
			return;

		if (this._touchCount === 1 && touches.length === 2)
		{
			this._touchType = pm.MapsContainerLayer.TouchType.ScaleActiveMap;
			this._touchCount = 2;
		}

		switch (this._touchType)
		{
			case pm.MapsContainerLayer.TouchType.Touch:
			{
				if(this._isTouchMoved(touches[0]) && this._enableScroll && this._enableMoving)
				{
					this._touchType = pm.MapsContainerLayer.TouchType.ScrollMaps;
				}
				else if (this._isTouchMoved(touches[0]) && !this._enableMoving)
				{
					pm.sendCustomEvent(pm.ANIMATE_HIDE_BUTTON);
					this._touchType = pm.MapsContainerLayer.TouchType.None;
				}

				break;
			}
			case pm.MapsContainerLayer.TouchType.TouchActiveMap:
			{
				if(this._isTouchMoved(touches[0]) && this._enableMoving)
				{
					this._touchType = pm.MapsContainerLayer.TouchType.DragActiveMap;
				}
				else if (this._isTouchMoved(touches[0]) && !this._enableMoving)
				{
					pm.sendCustomEvent(pm.ANIMATE_HIDE_BUTTON);
					this._touchType = pm.MapsContainerLayer.TouchType.None;
				}

				break;
			}

			case pm.MapsContainerLayer.TouchType.DragActiveMap:
			{
				var diff = touches[0].getDelta();

				this._moveParallaxLayerByDelta(diff);

				break;
			}

			case pm.MapsContainerLayer.TouchType.ScaleActiveMap:
			{
				if(touches.length === 2)
				{
					var layer = this._level.activeMap.mapLayer;

					var diff1 = touches[0].getDelta();
					var diff2 = touches[1].getDelta();

					var loc1 = touches[0].getLocation();
					var loc2 = touches[1].getLocation();

					var dist = cc.pDistance(loc1, loc2);

					var oldDist = cc.pDistance(
						cc.pSub(loc1, diff1),
						cc.pSub(loc2, diff2)
					);

					var ratio = dist / oldDist;
					var newScale = this._scale - (1 - ratio) * pm.MapsContainerLayer.SCALE_VELOCITY;

					if (dist > oldDist || layer.getScale() > 1.0 ||
                        ((dist < oldDist) && (layer.width * newScale > pm.settings.getMapSize().width))
					)
					{
						this._scale = newScale;

						this._setMapsScale(this._scale);
					}
				}

				break;
			}

			case pm.MapsContainerLayer.TouchType.ScrollMaps:
			{
				var diff = touches[0].getDelta();

				var angleCos = cc.pDot(cc.pNormalize(diff), cc.pNormalize(this._mapScrollVector));
				var delta = cc.pLength(diff) * angleCos;

				this._scrollMaps(delta);

				break;
			}
		}
	},

	_touchEnded: function(touches, pEvent)
	{
		this._touchCount -= touches.length;

		if(this._touchCount > 0)
		{
			this._touchType = pm.MapsContainerLayer.TouchType.DragActiveMap;
			return;
		}

		switch(this._touchType)
		{
			case pm.MapsContainerLayer.TouchType.ScrollMaps:
				var min = pm.settings.getMapSize().width;

				var newActiveMapIndex = this.activeMapIndex;

				var activeLayerPos = this._layerPositionByIndex(0);

				//Find closest map layer to become active
				for (var i = 0; i < this._level.maps.length; ++i)
				{
					this._parallaxArray[i] = this._getMapLayerRatio(i);

					var mapPosX = this._level.maps[i].mapLayer.x;

					var shiftedX = Math.abs(mapPosX - this._centerPoint.x - activeLayerPos.x);

					if (shiftedX < min)
					{
						newActiveMapIndex = i;
						min = shiftedX;
					}
				}

				pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
				this.changeActiveMap(newActiveMapIndex, true, true);
				break;

			case pm.MapsContainerLayer.TouchType.ScaleActiveMap:
			case pm.MapsContainerLayer.TouchType.DragActiveMap:
				this._centerCurrentMap();
				break;

			case pm.MapsContainerLayer.TouchType.TouchActiveMap:
				this._level.activeMap.mapLayer.handleTouch(touches[0]);
				break;
		}

		this._touchType = pm.MapsContainerLayer.TouchType.None;
	},

	_setMapsScale: function (scale)
	{
		for (var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].mapLayer.setScale(scale * this._getScaleByIndex(Math.abs(this.activeMapIndex - i)));
	},

	_zoomIn: function ()
	{
		this._scale += pm.MapsContainerLayer.ZOOM_SPEED;
		this._setMapsScale(this._scale);
	},

	_zoomOut: function ()
	{
		var layer = this._level.activeMap.mapLayer;
		var newScale = this._scale - pm.MapsContainerLayer.ZOOM_SPEED;

		if (layer.width * newScale < pm.settings.getMapSize().width && layer.getScale() <= 1.0)
			return;

		this._scale = newScale;

		this._setMapsScale(this._scale);

		this._centerCurrentMap();
	},

	_moveParallaxLayerByDelta: function(delta, animated, speed)
	{
		//Optimization: do no move on zero delta
		if(delta.x === 0 && delta.y === 0)
		{
			this._endMove();
			return;
		}

		if(speed === undefined)
			speed = pm.SYSTEM_ANIMATION_DELAY / 2;

		if(animated === undefined)
			animated = false;

		for(var i = 0; i < this._level.maps.length; ++i)
		{
			var ratio = this._parallaxArray[i];
			var layer = this._level.maps[i].mapLayer;
			var targetPos = cc.pAdd(layer.getPosition(), cc.p(ratio.x * delta.x, ratio.y * delta.y));

			if(!animated)
				layer.setPosition(targetPos);
			else

				layer.runAction(cc.moveTo(speed, targetPos));

		}

		var backRatio = pm.MapsContainerLayer.BACKGROUND_RATIO;
		var backgroundTargetPos = cc.pAdd(this._background.getPosition(), cc.p(backRatio.x * delta.x, backRatio.y * delta.y));

		if(!animated)
		{ this._background.setPosition(backgroundTargetPos); }
		else
		{
			var moveEnd = cc.callFunc(this._endMove, this);

			this._background.runAction(cc.sequence(cc.moveTo(speed, backgroundTargetPos), moveEnd));
		}

		this._parallaxDelta = cc.pAdd(this._parallaxDelta, delta);
	},

	_endMove: function ()
	{
		this._level.activeMap.mapLayer.enabled = true;
	},

	_centerCurrentMap: function ()
	{
		var activeLayer = this._level.activeMap.mapLayer;
		var moveDelta = cc.p(0, 0);

		var mapBB = cc.rectApplyAffineTransform(activeLayer.getBoundingBox(), activeLayer.getParent().getNodeToWorldTransform());

		var minX = mapBB.x;
		var maxX = mapBB.x + mapBB.width;

		var minY = mapBB.y;
		var maxY = mapBB.y + mapBB.height;

		var activeMapPos = cc.pAdd(this._layerPositionByIndex(0), this._centerPoint);

		if (mapBB.width > pm.settings.getMapSize().width)
		{
			if (minX > 0)
				moveDelta.x = -minX;
			else if (maxX < pm.settings.getMapSize().width)
				moveDelta.x = pm.settings.getMapSize().width - maxX;
		}
		else
		{ moveDelta.x = activeMapPos.x - activeLayer.x; }

		if (mapBB.height > pm.settings.getMapSize().height)
		{
			if (minY > 0)
				moveDelta.y = -minY;
			else if (maxY < pm.settings.getMapSize().height)
				moveDelta.y = pm.settings.getMapSize().height - maxY;
		}
		else
		{ moveDelta.y = activeMapPos.y - activeLayer.y; }

		this._moveParallaxLayerByDelta(moveDelta, true);
	},

	_rebaseRobots: function(from, to)
	{
		if(from === to)
			return;

		this._level.setActiveMap(this._level.maps[to]);
	},

	_scrollMaps: function(delta)
	{
		var activeMapPos = cc.pAdd(this._layerPositionByIndex(0), this._centerPoint);
		var mapCount = this._level.maps.length;

		var lastMapPos = this._getOriginalMapLayerPos(mapCount - 1);
		var firstMapPos = this._getOriginalMapLayerPos(0);

		if((delta < 0 && lastMapPos.x <= activeMapPos.x) || (delta > 0 && firstMapPos.x >= activeMapPos.x))
			return;

		//Determine if active map need to be changed
		var newActiveMapIndex = this.activeMapIndex;

		for(var i = 0; i < mapCount; ++i)
		{
			var distanceToActiveMap = cc.pDistance(this._getOriginalMapLayerPos(i), activeMapPos);

			if(distanceToActiveMap <= Math.abs(delta))
			{
				if(this.activeMapIndex !== i)
					newActiveMapIndex = i;
			}
		}

		for(var i = 0; i < mapCount; ++i)
		{
			var mapLayer = this._level.maps[i].mapLayer;
			var realMapPos = mapLayer.getPosition();
			var realMapPosWithoutParallax = this._getOriginalMapLayerPos(i);

			var curMapIndex = i - this.activeMapIndex;

			//Find previous and next map theoretical pos on maps line (don't considering the moving direction)
			var prevMapPosIndex = null;
			var nextMapPosIndex = null;
			var nextMapPos = null;
			var prevMapPos = null;

			for(var j = curMapIndex - 2; j <= curMapIndex + 2; ++j)
			{
				var pos = this._layerPositionByIndex(j);

				if(pos.x + this._centerPoint.x < realMapPosWithoutParallax.x)
				{
					prevMapPosIndex = j;
					prevMapPos = pos;
				}

				if(pos.x + this._centerPoint.x > realMapPosWithoutParallax.x)
				{
					nextMapPosIndex = j;
					nextMapPos = pos;
					break;
				}
			}

			if(!prevMapPos || !nextMapPos)
				return;

			//Find current and target map pos (considering the moving direction)
			var curMapPos = null;
			var targetMapPos = null;

			if(delta > 0)
			{
				targetMapPos = nextMapPos;
				curMapPos = this._layerPositionByIndex(nextMapPosIndex - 1);
			}
			else
			{
				targetMapPos = prevMapPos;
				curMapPos = this._layerPositionByIndex(prevMapPosIndex + 1);
			}

			var distanceToTargetMap = cc.pDistance(realMapPosWithoutParallax, targetMapPos);
			var mapDelta = delta * Math.abs(curMapPos.x - targetMapPos.x) / pm.MapsContainerLayer.LAYER_POSITION_X_DELTA;

			if(distanceToTargetMap <= Math.abs(mapDelta))
				mapDelta = mapDelta < 0 ? -distanceToTargetMap : distanceToTargetMap;

			//Shift current map real position by delta
			var newMapPos = this._shiftPointByDelta(realMapPos, mapDelta);

			//Shift current map theoretical(without parallax effect) position by delta
			var shiftedPoint = this._shiftPointByDelta(realMapPosWithoutParallax, mapDelta);

			//Get scale && opacity by coordinates of maps line
			var scale = this._getScaleByX(shiftedPoint.x - this._centerPoint.x) * this._scale;
			var opacity = this._layerOpacityByX(shiftedPoint.x - this._centerPoint.x);

			if(opacity > 255)
				opacity = 255;

			mapLayer.setPosition(newMapPos);

			mapLayer.setOpacity(opacity);
			mapLayer.setScale(scale);
		}

		if(this.activeMapIndex !== newActiveMapIndex)
		{
			this._level.activeMap.clean();

			this._rebaseRobots(this.activeMapIndex, newActiveMapIndex);

			pm.sendCustomEvent(pm.MAP_DRAGGED_EVENT_STR, {index: newActiveMapIndex} );

			this.activeMapIndex = newActiveMapIndex;

			this._recalculateMapLayersZOrder();
		}
	},

	_correctMaps: function()
	{
		for(var i = 0; i < this._level.maps.length; ++i)
		{
			var pos = this._layerPositionByIndex(i - this.activeMapIndex);
			var scale = this._getScaleByX(pos.x) * this._scale;
			var opacity = this._layerOpacityByX(pos.x);

			var moveLayer = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY / 2, cc.pAdd(pos, this._centerPoint));
			var scaleLayer = cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY / 2, scale);
			var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY / 2, opacity);

			this._level.maps[i].mapLayer.stopAllActions();
			this._level.maps[i].mapLayer.runAction(cc.spawn(scaleLayer, moveLayer, fadeLayer));
		}

		//return parallax delta to zero, because all maps will be on it's theoretical pos.
		this._parallaxDelta = cc.p(0, 0);
	},

	/**
     * Returns index of map layer by X coordinate in MapContainerLayer
     * @private
     * @param {Number} x
     * @returns {Number}
     */
	_getIndexByX: function(x)
	{
		return x * x / (pm.MapsContainerLayer.LAYER_POSITION_X_DELTA * pm.MapsContainerLayer.LAYER_POSITION_X_DELTA);
	},

	/**
     * Returns map layer opacity by X coordinate in MapContainerLayer
     * @private
     * @param {Number} x
     * @returns {Number}
     */
	_layerOpacityByX: function(x)
	{
		return ((Math.abs(x) < 0.00000001) ? 255 : Math.atan(1.0/1.2)/Math.atan(this._getIndexByX(x)/1.2) * 150.0);
	},

	/**
     * Returns X position of map layer by it's index.
     * @private
     * @param {Number} i
     * @returns {cc.Point}
     */
	_layerPositionByIndex: function(i)
	{
		var sign = i < 0 ? -1 : 1;
		var x = sign * pm.MapsContainerLayer.LAYER_POSITION_X_DELTA * Math.sqrt(sign * i);

		return cc.p(x, x * Math.tan(pm.MapsContainerLayer.LAYER_POSITION_ANGLE));
	},
	/**
     * Shifts point by a number in layers line direction
     * @private
     * @param {cc.Point} p Original point
     * @param {Number} v Shift number
     * @returns {cc.Point}
     */
	_shiftPointByDelta: function(p, v)
	{
		return cc.p(p.x + Math.cos(pm.MapsContainerLayer.LAYER_POSITION_ANGLE) * v,
			p.y + Math.sin(pm.MapsContainerLayer.LAYER_POSITION_ANGLE) * v);
	},
	/**
     * Returns scale of map layer by X coordinate in MapContainerLayer
     * @private
     * @param {Number} x
     * @returns {Number}
     */
	_getScaleByX: function(x) { return this._getScaleByIndex(this._getIndexByX(x)); },

	/**
     * Returns scale of map layer by index in MapContainerLayer
     * @private
     * @param {Number} index
     * @returns {Number}
     */
	_getScaleByIndex: function (index){ return 1.0 - Math.atan(index/1.2)/Math.atan(1.0/1.2) * 0.35; }
});

/**
 * Enum for touch type in pm.MapsContainerLayer
 * @readonly
 * @enum {Number}
 * @protected
 */
pm.MapsContainerLayer.TouchType = {
	None: 0,
	TouchActiveMap: 1,
	Touch: 2,
	DragActiveMap: 3,
	ScrollMaps: 4,
	ScaleActiveMap: 5
};

/**
 * Scale of background layer to screen size
 * @const
 * @default
 * @type {number}
 */
pm.MapsContainerLayer.BACKGROUND_SCALE = 3;

/**
 * Active layer scale rate
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {number}
 */
pm.MapsContainerLayer.SCALE_VELOCITY = 0.75;

/**
 * Delta detection of moving layers
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {number}
 */
pm.MapsContainerLayer.TOUCH_CRITICAL_DISTANCE = 2;
/**
 * X-Delta between two layers
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {number}
 */
pm.MapsContainerLayer.LAYER_POSITION_X_DELTA = 250.0;
/**
 * Angle to X axis of line of layer.
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {number}
 */
pm.MapsContainerLayer.LAYER_POSITION_ANGLE = Math.PI / 4.0;

/**
 * Ratio of background layer in parallax array.
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {cc.Point}
 */
pm.MapsContainerLayer.BACKGROUND_RATIO = cc.p(0.4, 0.5);

/**
 * Ratio of active map layer in parallax array.
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {cc.Point}
 */
pm.MapsContainerLayer.ACTIVE_MAP_RATIO = cc.p(1.0, 1.0);

/**
 * Step of zooming map layers by event.
 * @const
 * @default
 * @see pm.MapsContainerLayer
 * @type {Number}
 */
pm.MapsContainerLayer.ZOOM_SPEED = 0.1;
