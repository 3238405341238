/**
 * Created by Kirill Mashchenko on 20.08.2018.
 */

/**
 * @class Layer for register.
 * @extends pmui.OvalLayout
 */
var CommonSettingsLayer = MovingAfterKeyboardLayout.extend(/** @extends CommonSettingsLayer# */{
	_target: null,
	_cancelCallback: null,
	physicalRobotCombobox: null,
	_nameInput: null,

	ctor: function(target, cancelCallback)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSizeWithBorder(CommonSettingsLayer.WIDTH, CommonSettingsLayer.HEIGHT);

		this.setTouchEnabled(true);

		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

		this._target = target;
		this._cancelCallback = cancelCallback;

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._close.bind(this));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height/4, -closeButton.width/4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton);

		var isRecogniseCheckbox = new pmui.CheckBox(LocalizedString("IsRecogniseOn"), this._isRecognise,
			this, 15, pm.settings.getUseProgramRecognizer());

		var isRecogniseAlign = new ccui.RelativeLayoutParameter();
		isRecogniseAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		isRecogniseAlign.setRelativeName("isRecognise");
		isRecogniseAlign.setMargin(CommonSettingsLayer.SEPARATOR + pmui.OvalLayout.STANDARD_BORDER_RADIUS, CommonSettingsLayer.SEPARATOR + pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		isRecogniseCheckbox.setLayoutParameter(isRecogniseAlign);

		this.addChild(isRecogniseCheckbox);

		this._nameInput = new pmui.TextInput(pm.settings.getUserDeviceName(), LocalizedString("Name"), 20, CommonSettingsLayer.WIDTH - CommonSettingsLayer.WIDTH_DELTA);

		var nameInputAlign = new ccui.RelativeLayoutParameter();
		nameInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		nameInputAlign.setMargin(0, CommonSettingsLayer.SEPARATOR, 0, 0);
		nameInputAlign.setRelativeName("name");
		nameInputAlign.setRelativeToWidgetName("isRecognise");
		this._nameInput.setLayoutParameter(nameInputAlign);

		this.addChild(this._nameInput);

		this.setInputAttach(this._nameInput);

		this.physicalRobotCombobox = new pmui.ComboBox(this._getComboboxValueList(), this, this._changePhysicalConnectorType, 20);

		var comboboxAlign = new ccui.RelativeLayoutParameter();
		comboboxAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		comboboxAlign.setMargin(0, CommonSettingsLayer.SEPARATOR, 0, 0);
		comboboxAlign.setRelativeName("combobox");
		comboboxAlign.setRelativeToWidgetName("name");
		this.physicalRobotCombobox.setLayoutParameter(comboboxAlign);

		this.addChild(this.physicalRobotCombobox, 1);
	},

	_isRecognise: function(flag)
	{
		pm.settings.setUseProgramRecognizer(flag);
	},

	_getComboboxValueList: function()
	{
		switch(pm.settings.getPhysicalConnectorType())
		{
			case pm.PhysicalConnectorType.Lego:
				return ["Lego", "BLE", "Sound", "Magnet","None"];
			case pm.PhysicalConnectorType.BLE:
				return ["BLE", "Lego", "Sound", "Magnet", "None"];
			case pm.PhysicalConnectorType.Sound:
				return ["Sound", "Lego", "BLE", "Magnet", "None"];
			case pm.PhysicalConnectorType.Magnet:
				return ["Magnet", "Sound", "Lego", "BLE", "None"];
			case pm.PhysicalConnectorType.None:
				return ["None", "BLE", "Lego", "Sound", "Magnet"];

		}
	},

	_changePhysicalConnectorType: function(index)
	{
		var objects = this.physicalRobotCombobox.getValueList();
		var curObject = objects[index].getString();
		var type = "";

		switch(curObject)
		{
			case "Lego":
				type = pm.PhysicalConnectorType.Lego;
				break;
			case "BLE":
				type = pm.PhysicalConnectorType.BLE;
				pm.btleManager.init();
				break;
			case "Sound":
				type = pm.PhysicalConnectorType.Sound;
				pm.btleManager.init();
				break;
			case "Magnet":
				type = pm.PhysicalConnectorType.Magnet;
				break;
			case "None":
				type = pm.PhysicalConnectorType.None;
				break;
		}

		pm.settings.setPhysicalConnectorType(type);
	},

	_close: function()
	{
		this.closeAttachKeyboard();

		pm.settings.setUserDeviceName(this._nameInput.getString());
		this._cancelCallback.call(this._target);
	}

});

CommonSettingsLayer.WIDTH = 300;
CommonSettingsLayer.HEIGHT = 165;
CommonSettingsLayer.TAG = 7142;
CommonSettingsLayer.SEPARATOR = 15.0;
CommonSettingsLayer.WIDTH_DELTA = 70.0;
CommonSettingsLayer.BORDER = 45.0;

