/**
 * Created by Kirill Mashchenko on 03.07.17.
 */

/**
 * @class Functions for PhysicalRobotLevel module.
 * @extends pm.ModuleInfo
 */

pm.PhysicalRobotLevelModule = pm.ModuleInfo.extend(/** @lends pm.PhysicalRobotLevelModule# */{

	name: "Physical",

	getType: function()
	{
		return pm.PhysicalRobotLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.PhysicalRobotMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = PhysicalRobotMapElementType.Green;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.PhysicalRobotLevel();

		level.name = "Новый Ползун";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";

		level.robots.push(new pm.data.PhysicalRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.PhysicalRobotLevelModule.Type] = new pm.data.PhysicalTask(level);
		level.taskList.tasks[pm.PhysicalRobotLevelModule.Type].data[0] = [];

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};

		switch(pm.settings.getPhysicalConnectorType())
		{
			case pm.PhysicalConnectorType.Lego:
				ret[pm.PhysicalRobotLevelModule.RobotType] = "physical_robot_lego.png";
				break;
			case pm.PhysicalConnectorType.Sound:
			case pm.PhysicalConnectorType.BLE:
			case pm.PhysicalConnectorType.None:
				ret[pm.PhysicalRobotLevelModule.RobotType] = "physical_robot_BLE.png";
				break;
		}

		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/physical_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new PhysicalRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return PhysicalRobotMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Physical");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/physical_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/robot4-methods.plist",
			"Robot Methods/physical_robot-methods.plist",
			"Conditions/robot4-conditions.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.PhysicalRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{

			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Physical": "Floor robot",
					"PhysicalLevelType": "Floor robot level",
					"PhysicalTask": "Go by numbers",
					"PhysicalRobotLevel": "Floor robot",
					"phys_push" : "push",
					"phys_pull" : "pull"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Physical": "Ползун",
					"PhysicalLevelType": "Ползун",
					"PhysicalTask": "Пройти по цифрам",
					"PhysicalRobotLevel": "Ползун",
					"phys_push" : "толкать",
					"phys_pull" : "тянуть"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/PhysicalRobotLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new PhysicalTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "PhysicalTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.PhysicalTask(level);
		level.taskList.addTask(pm.PhysicalRobotLevelModule.Type, task);

		return task;
	},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function() {},

	getAvailablePhysicalCommands: function()
	{
		return [
			"r4_move",
		    "r4_turn_left",
		    "r4_turn_right",
			"indicate"
		];
	},

	getLessonMapLayer: function()
	{
		var level = new pm.data.PhysicalRobotLevel();

		level.name = "Physical";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.PhysicalRobot());

		this._fillLevelData(level);

		level.robots[0]._oldPosition = cc.p(0, 1);
		level.robots[0].position = cc.p(0, 1);
		level.robots[0].direction = 2;

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.PhysicalRobotLevelModule.Type] = new pm.data.PhysicalTask(level);
		level.taskList.tasks[pm.PhysicalRobotLevelModule.Type].data[0] = [];

		var map = new pm.data.PhysicalRobotMap();

		map.parentLevel = level;
		map.setSize(2, 2);

		map.element(cc.p(0, 0)).originalType = PhysicalRobotMapElementType.OneElement;
		map.element(cc.p(1, 0)).originalType = PhysicalRobotMapElementType.TwoElement;
		map.element(cc.p(0, 1)).originalType = PhysicalRobotMapElementType.Green;
		map.element(cc.p(1, 1)).originalType = PhysicalRobotMapElementType.ThreeElement;

		map.element(cc.p(0, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(0, 1)).startRobotData.direction = 2;

		level.maps.push(map);
		level.load();

		map.load();
		map.generateMapLayer(false);

		map.mapLayer.placeRobots(true);

        level.robots[0].sprite.changeState(pm.PhysicalConnector.STATE.CONNECTED);

		return map.mapLayer;
	}
});

pm.PhysicalRobotLevelModule.RobotType = "physical-robot";
pm.PhysicalRobotLevelModule.Type = "physical";
pm.PhysicalRobotLevelModule.ObjectTypes = {};
