/**
 * Created by danilaeremin on 10.08.15.
 */

/**
 * @class Layer for settings of level.
 * @extends LevelSettingsLayer
 * @constructor
 * @param {pm.AbstractLevel} level Level which settings shows
 */
var LevelSettings = LevelSettingsLayer.extend(/** @lends LevelSettings# */{

	_levelName: null,
	_editTutorialButton: null,

	ctor: function(level)
	{
		this._super(level);

		var label = new ccui.Text(LocalizedString("Level"), pm.settings.fontName, pm.settings.fontSize);
		var height = label.height + LevelSettingsLayer.SEPARATOR;

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		labelAlign.setRelativeName("label");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		this._levelName = new pmui.TextInput(level.name, LocalizedString("LevelName"), 20, LevelSettingsLayer.WIDTH - 60);
		height += this._levelName.height + LevelSettingsLayer.SEPARATOR;

		var levelNameAlign = new ccui.RelativeLayoutParameter();
		levelNameAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		levelNameAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		levelNameAlign.setRelativeName("levelName");
		levelNameAlign.setRelativeToWidgetName("label");
		this._levelName.setLayoutParameter(levelNameAlign);

		this.addChild(this._levelName);

		this._levelName.setInsertTextCallback(this, this._insertText);

		var relativeName = "levelName";

		if (pm.moduleUtils.canUseMethodStack(level.getType()))
		{
			var useMethodStack = new pmui.CheckBox(LocalizedString("UseMethodStack"), this._useMethodStackChanged, this, LevelSettings.FONT_SIZE, level.useMethodStack);
			height += useMethodStack.height + LevelSettingsLayer.SEPARATOR;

			var useMethodStackAlign = new ccui.RelativeLayoutParameter();
			useMethodStackAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			useMethodStackAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
			useMethodStackAlign.setRelativeName("methodStack");
			useMethodStackAlign.setRelativeToWidgetName("levelName");
			useMethodStack.setLayoutParameter(useMethodStackAlign);

			this.addChild(useMethodStack);

			relativeName = "methodStack";
		}

		var useProgramRecognizer = new pmui.CheckBox(LocalizedString("UseProgramRecognizer"), this._useProgramRecognizerChanged, this, LevelSettings.FONT_SIZE, level.useProgramRecognizer);
		height += useProgramRecognizer.height + LevelSettingsLayer.SEPARATOR;

		var useProgramRecognizerAlign = new ccui.RelativeLayoutParameter();
		useProgramRecognizerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		useProgramRecognizerAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		useProgramRecognizerAlign.setRelativeName("programRecognizer");
		useProgramRecognizerAlign.setRelativeToWidgetName(relativeName);
		useProgramRecognizer.setLayoutParameter(useProgramRecognizerAlign);

		this.addChild(useProgramRecognizer);

		var canEditProgramData = new pmui.CheckBox(LocalizedString("CanEditProgramData"), this._canEditProgramDataChanged, this, LevelSettings.FONT_SIZE, level.isProgramDataEditable);
		height += canEditProgramData.height + LevelSettingsLayer.SEPARATOR;

		var canEditProgramDataAlign = new ccui.RelativeLayoutParameter();
		canEditProgramDataAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		canEditProgramDataAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		canEditProgramDataAlign.setRelativeName("programData");
		canEditProgramDataAlign.setRelativeToWidgetName("programRecognizer");
		canEditProgramData.setLayoutParameter(canEditProgramDataAlign);

		this.addChild(canEditProgramData);

		this._checkAllPatterns = new pmui.CheckBox(LocalizedString("CheckAllPatterns"), this._checkAllPatternsChanged, this, LevelSettings.FONT_SIZE, this._level.checkAllPatterns);
		height += this._checkAllPatterns.height + LevelSettingsLayer.SEPARATOR;

		var checkAllAlign = new ccui.RelativeLayoutParameter();
		checkAllAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		checkAllAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		checkAllAlign.setRelativeName("checkAllPatterns");
		checkAllAlign.setRelativeToWidgetName("programData");
		this._checkAllPatterns.setLayoutParameter(checkAllAlign);

		this.addChild(this._checkAllPatterns);

		if (this._level.robots.length > 1)
		{
			this._checkAllPatterns.setChecked(false);
			this._checkAllPatternsChanged(false);

			this._checkAllPatterns.setEnabled(false);
		}

		var indicateMethod = new pmui.CheckBox(LocalizedString("IndicateMethod"), this._indicationChanged, this, LevelSettings.FONT_SIZE, level.robotsCanBeIndicated);
		height += indicateMethod.height + LevelSettingsLayer.SEPARATOR;

		var indicateAlign = new ccui.RelativeLayoutParameter();
		indicateAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		indicateAlign.setRelativeName("indicate");
		indicateAlign.setRelativeToWidgetName("checkAllPatterns");
		indicateAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		indicateMethod.setLayoutParameter(indicateAlign);

		this.addChild(indicateMethod);

		var extraSettings = pm.moduleUtils.getExtraLevelSettings(level.getType(), level);
		relativeName = "indicate";

		if (extraSettings)
		{
			var extraAlign = new ccui.RelativeLayoutParameter();
			extraAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			extraAlign.setRelativeName("extra");
			extraAlign.setRelativeToWidgetName("indicate");
			extraAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
			extraSettings.setLayoutParameter(extraAlign);

			this.addChild(extraSettings);

			relativeName = "extra";
			height += extraSettings.height + LevelSettingsLayer.SEPARATOR;
		}

		var tutorialLayout = new ccui.HBox();

		var isTutorial = new pmui.CheckBox(LocalizedString("IsTutorialLevel"), this._isTutorialChanged, this, LevelSettings.FONT_SIZE, level.isTutorial);

		this._editTutorialButton = new pmui.Button(pm.spriteUtils.getIconName("edit", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("edit", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("edit", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		this._editTutorialButton.addClickEventListener(this._editTutorial.bind(this));

		this._editTutorialButton.setScale(0.5);
		this._editTutorialButton.setEnabled(level.isTutorial);

		var tutorialElementAlign = new ccui.LinearLayoutParameter();
		tutorialElementAlign.setGravity(ccui.LinearLayoutParameter.CENTER_VERTICAL);

		isTutorial.setLayoutParameter(tutorialElementAlign);
		this._editTutorialButton.setLayoutParameter(tutorialElementAlign.clone());

		tutorialLayout.addChild(isTutorial);
		tutorialLayout.addChild(this._editTutorialButton);

		tutorialLayout.setContentSize(
			isTutorial.width + this._editTutorialButton.width,
			Math.max(isTutorial.height, this._editTutorialButton.height * 0.5)
		);
		height += tutorialLayout.height + LevelSettingsLayer.SEPARATOR;

		var tutorialAlign = new ccui.RelativeLayoutParameter();
		tutorialAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		tutorialAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		tutorialAlign.setRelativeName("tutorial");
		tutorialAlign.setRelativeToWidgetName(relativeName);
		tutorialLayout.setLayoutParameter(tutorialAlign);

		this.addChild(tutorialLayout);

		var mapLayersLayout = new ccui.Layout();
		mapLayersLayout.setLayoutType(ccui.Layout.LINEAR_HORIZONTAL);

		var mapLayersLayoutAlign = new ccui.RelativeLayoutParameter();
		mapLayersLayoutAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mapLayersLayoutAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
		mapLayersLayoutAlign.setRelativeName("maps");
		mapLayersLayoutAlign.setRelativeToWidgetName("tutorial");
		mapLayersLayout.setLayoutParameter(mapLayersLayoutAlign);

		var addDownButton = new pmui.Button(pm.spriteUtils.getIconName("addDown", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addDown", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addDown", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		addDownButton.addClickEventListener(this._addDownLayer.bind(this));

		var addUpButton = new pmui.Button(pm.spriteUtils.getIconName("addUp", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("addUp", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("addUp", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		addUpButton.addClickEventListener(this._addUpLayer.bind(this));

		var deleteButton = new pmui.Button(pm.spriteUtils.getIconName("deleteMap", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("deleteMap", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("deleteMap", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		deleteButton.addClickEventListener(this._deleteLayer.bind(this));

		var addUpButtonAlign = new ccui.LinearLayoutParameter();
		addUpButtonAlign.setMargin(8, 0, 8, 0);
		addUpButton.setLayoutParameter(addUpButtonAlign);

		mapLayersLayout.addChild(addDownButton);
		mapLayersLayout.addChild(addUpButton);
		mapLayersLayout.addChild(deleteButton);

		mapLayersLayout.setContentSize(LevelSettingsLayer.WIDTH, addDownButton.height);
		height += mapLayersLayout.height + LevelSettingsLayer.SEPARATOR;

		this.addChild(mapLayersLayout);

		var robot = level.robots[level.startRobotIndex];

		if (robot.hasChangeableConditions())
		{
			var conditionsLayout = pm.moduleUtils.getChangeableConditions(level.getType(), robot, this._conditionChanged, this);
			height += conditionsLayout.height + LevelSettingsLayer.SEPARATOR;

			var conditionsAlign = new ccui.RelativeLayoutParameter();
			conditionsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			conditionsAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);
			conditionsAlign.setRelativeName("conditions")
			conditionsAlign.setRelativeToWidgetName("maps");
			conditionsLayout.setLayoutParameter(conditionsAlign);

			this.addChild(conditionsLayout);
		}

		if (robot.hasChangeableMethods())
		{
			var methodsLayout = pm.moduleUtils.getChangeableMethods(level.getType(), robot, this._methodChanged, this);
			height += methodsLayout.height + LevelSettingsLayer.SEPARATOR;

			var methodsAlign = new ccui.RelativeLayoutParameter();
			methodsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			methodsAlign.setMargin(0, LevelSettingsLayer.SEPARATOR, 0, 0);

			if (robot.hasChangeableConditions())
				methodsAlign.setRelativeToWidgetName("conditions");
			else
				methodsAlign.setRelativeToWidgetName("maps");

			methodsLayout.setLayoutParameter(methodsAlign);

			this.addChild(methodsLayout);
		}

		height += LevelSettingsLayer.SEPARATOR + LevelSettingsLayer.BORDER_RADIUS;
		this.updateSize(LevelSettingsLayer.WIDTH, height);

		pm.registerCustomEventListener(pm.TUTORIAL_SCENARIO_CLOSED, this.show.bind(this), this);

		pm.registerCustomEventListener(pme.ADD_ROBOT, function ()
		{
			this._checkAllPatterns.setChecked(false);
			this._checkAllPatternsChanged(false);

			this._checkAllPatterns.setEnabled(false);
		}.bind(this), this);

		pm.registerCustomEventListener(pme.REMOVE_ROBOT, function ()
		{
			if(this._level.robots.length === 2) // '=== 2' due to pme.REMOVE_ROBOT event is sent before(!) level.robots.splice(...) is done.
				this._checkAllPatterns.setEnabled(true);
		}.bind(this), this);
	},

	_conditionChanged: function(name)
	{
		var startRobot = this._level.robots[this._level.startRobotIndex];

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			var robot = this._level.robots[i];

			if (robot.groupID === startRobot.groupID)
			{
				pm.moduleUtils.updateChangeableConditions(this._level.getType(), robot, name);

				robot.updateConditions();
			}
		}

		pm.sendCustomEvent(pm.COMMANDS_CHANGED, name);
	},

	_methodChanged: function(name)
	{
		var startRobot = this._level.robots[this._level.startRobotIndex];

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			var robot = this._level.robots[i];

			if (robot.groupID === startRobot.groupID)
			{
				pm.moduleUtils.updateChangeableMethods(this._level.getType(), robot, name);

				robot.updateMethods();
			}
		}

		pm.sendCustomEvent(pm.COMMANDS_CHANGED, name);
	},

	_useMethodStackChanged: function (flag)
	{
		this._level.useMethodStack = flag;
	},

	_useProgramRecognizerChanged: function (flag)
	{
		this._level.useProgramRecognizer = flag;
	},

	_canEditProgramDataChanged: function (flag)
	{
		this._level.isProgramDataEditable = flag;
	},

	_checkAllPatternsChanged: function(flag) // _checkAllPatterns checkBox is disabled if there are > 1 robots on the level
	{
		this._level.checkAllPatterns = flag;

		if(flag)
		{
			pm.sendCustomEvent(pme.SHOW_START_ALL_BUTTON, flag);
		}
		else
		{
			if (this._level.maps.length === 1)
				pm.sendCustomEvent(pme.SHOW_START_ALL_BUTTON, flag);
		}
	},

	_indicationChanged: function(flag)
	{
		this._level.robotsCanBeIndicated = flag;
		pm.sendCustomEvent(pm.COMMANDS_CHANGED);
	},

	_isTutorialChanged: function (flag)
	{
		this._level.isTutorial = flag;

		if(flag)
			this._level.tutorialScenario = new pm.data.TutorialScenario();
		else
			this._level.tutorialScenario = {};

		this._editTutorialButton.setEnabled(flag);
	},

	_editTutorial: function ()
	{
		this.hide();
		pm.tutorialUtils.editTutorial(this._level.tutorialScenario);
	},

	_insertText: function ()
	{
		this._level.name = this._levelName.getString();
	},

	_addDownLayer: function ()
	{
		for(var taskType in this._level.taskList.tasks)
		{
			var task = this._level.taskList.tasks[taskType];

			if(!(task instanceof pm.Task))
				continue;

			for (var i = this._level.maps.length-1; i >= this._level.getActiveMapIndex(); --i)
			{
				task.data[i + 1] = task.data[i];
				delete task.data[i];
			}

			task.fillWithInitialData(this._level.getActiveMapIndex());
		}

		pm.sendCustomEvent(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, MapLayersEditActions.AddDown);

		if (this._level.maps.length === 2 && !this._level.checkAllPatterns)
			pm.sendCustomEvent(pme.SHOW_START_ALL_BUTTON, true);
	},

	_addUpLayer: function ()
	{
		for(var taskType in this._level.taskList.tasks)
		{
			var task = this._level.taskList.tasks[taskType];

			if(!(task instanceof pm.Task))
				continue;

			for (var i = this._level.maps.length-1; i >= this._level.getActiveMapIndex() + 1; --i)
			{
				task.data[i + 1] = task.data[i];
				delete task.data[i];
			}

			task.fillWithInitialData(this._level.getActiveMapIndex() + 1);
		}

		pm.sendCustomEvent(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, MapLayersEditActions.AddUp);

		if (this._level.maps.length === 2 && !this._level.checkAllPatterns)
			pm.sendCustomEvent(pme.SHOW_START_ALL_BUTTON, true);
	},

	_deleteLayer: function ()
	{
		for(var taskType in this._level.taskList.tasks)
		{
			var task = this._level.taskList.tasks[taskType];

			if(!(task instanceof pm.Task))
				continue;

			for (var i = this._level.getActiveMapIndex() + 1; i < this._level.maps.length; i++)
			{
				task.data[i - 1] = task.data[i];
				delete task.data[i];
			}
			delete task.data[this._level.maps.length - 1];
		}

		pm.sendCustomEvent(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, MapLayersEditActions.DeleteCurrent);

		if(this._level.maps.length === 1 && !this._level.checkAllPatterns)
			pm.sendCustomEvent(pme.SHOW_START_ALL_BUTTON, false);
	}
});

LevelSettings.FONT_SIZE = 18;
