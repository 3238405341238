/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * Grasshopper map element types.
 * @enum {Number}
 * @readonly
 */
var GrasshopperMapElementType = {
	None: -1,
	LightBlueGrass: 0,
	BlueGrass: 1,
	UnpaintedGrass: 2
};

/**
 * @class Elements for {@link pm.data.GrasshopperMap}
 * @implements pm.MapElement
 */
pm.data.GrasshopperMapElement = pm.MapElement2D.extend(/** @lends pm.data.GrasshopperMapElement# */{
	typeName: "GrasshopperMapElement",

	_elementNumber: 0,
	_paintCount: 0,

	ctor: function()
	{
		this._addNonEnumerableProps("_paintCount", "_elementNumber");
		this._super();
	},

	restore: function()
	{
		this._paintCount = 0;
		pm.MapElement.prototype.restore.call(this);
	},

	generateTerrainSprite: function(x, previewDraw, orientation)
	{
		if(!CORE_BUILD)
		{
			this.sprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(this._type));

			this._notCompletedSprite = new cc.Sprite("MapsPics/mapElement_error_selection.png");

			this._notCompletedSprite.setPosition(cc.p(this.sprite.getSprite().getContentSize().width/2, this.sprite.getSprite().getContentSize().height/2));
			this._notCompletedSprite.setAnchorPoint(cc.p(0.5, 0.5));
			this._notCompletedSprite.setVisible(false);

			this.sprite.getSprite().addChild(this._notCompletedSprite, 10);

			this._elementNumber = new ccui.Text(x, pm.settings.fontName, pm.settings.fontSize);

			this._elementNumber.setPosition(cc.p(this.sprite.getSprite().getContentSize().width/2, this.sprite.getSprite().getContentSize().height/2));
			this._elementNumber.setAnchorPoint(cc.p(0.5, 0.5));

			if (orientation === pm.MapLayer2D.Orientation.Iso)
			{
				this._elementNumber.setRotation(45);
				this._elementNumber.setScaleY(2);
			}

			if (!previewDraw)
				this.sprite.getSprite().addChild(this._elementNumber, 10);

			return this.sprite;
		}
	},

	updateElementNumber: function(number)
	{
		this._elementNumber.setString(number);
	},

	/**
     * Paints element.
     */
	paint: function()
	{
		if(this._type === GrasshopperMapElementType.UnpaintedGrass)
			this.setType(GrasshopperMapElementType.BlueGrass);
		else if(this._type === GrasshopperMapElementType.LightBlueGrass)
			this.setType(GrasshopperMapElementType.BlueGrass);

		++this._paintCount;
	},

	/**
     * Returns element to broken state.
     */
	unpaint: function()
	{
		--this._paintCount;

		if(this._paintCount === 0)
		{
			if (this._type === GrasshopperMapElementType.BlueGrass)
				this.setType(GrasshopperMapElementType.UnpaintedGrass);
			else if (this._type === GrasshopperMapElementType.BlueGrass)
				this.setType(GrasshopperMapElementType.LightBlueGrass);
		}
	},

	/**
     * Checks is element is painted.
     * @returns {Boolean}
     */
	isPainted: function()
	{
		return this.originalType === GrasshopperMapElementType.UnpaintedGrass && this._type === GrasshopperMapElementType.BlueGrass;
	},

	/**
	 * Checks is element is bad painted.
	 * @returns {Boolean}
	 */
	isBadPainted: function()
	{
		return this.originalType === GrasshopperMapElementType.LightBlueGrass && this._type === GrasshopperMapElementType.BlueGrass;
	},

	getState: function ()
	{
		return this._type;
	},

	setState: function (state)
	{
		this.setType(state);
	},

	containsPoint: function(touch)
	{
		var rect = this.sprite.getSprite().getContentSize();
		var point = this.sprite.getSprite().convertTouchToNodeSpace(touch);

		return point.x > 0 && point.y > 0 && point.x < rect.width && point.y < rect.height;
	}
});

pm.data.GrasshopperMapElement.START_ELEMENT_POS = "start_element_pos";
