/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * Returns an array with arc points.
 * @param {Number} cx X Center
 * @param {Number} cy Y Center
 * @param {Number} r Radius
 * @param {Number} start_angle
 * @param {Number} arc_angle
 * @param {Number} num_segments Number of segments.
 * @returns {Array<{x:Number,y:Number}>}
 */
pm.utils.getArcPoints = function(cx, cy, r, start_angle, arc_angle, num_segments)
{
	var theta = arc_angle / (num_segments - 1);
	var tangetial_factor = Math.tan(theta);

	var radial_factor = Math.cos(theta);

	var x = r * Math.cos(start_angle);
	var y = r * Math.sin(start_angle);

	var arc_vertices = [];

	for(var ii = 0; ii < num_segments; ii++)
	{
		arc_vertices.push( cc.p(x + cx, y + cy));

		var tx = -y;
		var ty = x;

		x += tx * tangetial_factor;
		y += ty * tangetial_factor;

		x *= radial_factor;
		y *= radial_factor;
	}
	return arc_vertices;
};

pm.utils.logError = function(error)
{
	if(error)
		cc.error(cc.isObject(error) ? JSON.stringify(error): error);
};

pm.utils.SOCIAL_TYPE = {
	FB: "fb",
	VK: "vk"
};

pm.utils.socialLogin = function(socialType, callback, state, codeChallenge)
{
	var url = pm.appConfig.authAddress + "app/authorize/" + socialType + "/" +
		"?flow=oauth" +
		"&state=" + state +
		"&code_challenge=" + codeChallenge +
		"&client_id=" + pm.appConfig.pmAppID +
		"&redirect_uri=";

	if(cc.sys.isNative)
	{
		switch(socialType)
		{
			case pm.utils.SOCIAL_TYPE.VK:
				var scheme = "piktomir";

				if(pm.appConfig.appType === 'pm-k')
					scheme = "piktomir-k";

                url += encodeURIComponent(scheme + "://piktomir.ru");

				pm.socialLogin(url, function (code, state)
				{
					if (code === "" || state === "")
					{
						callback(new Error("Social auth error"));
					}
					else
					{
						callback(null, {
							code: code,
							state: state,
							redirectUri: scheme + "://piktomir.ru"
						});
					}
				}.bind(pm.utils));
				break;
		}
	}
	else
	{
		switch(socialType)
		{
			case pm.utils.SOCIAL_TYPE.VK:

				var redirect =  window.location.href;
				var redirParts = window.location.href.split('/');
				var lastPart = redirParts[redirParts.length - 1];

				if(lastPart !== "")
					redirect = redirect.replace(lastPart, 'login.html');
				else
					redirect = redirect + 'login.html';

				url += encodeURIComponent(redirect);

				var win = window.open(url, 'Login VK', "modal,width=665,height=370");

				win.focus();

				var loginCallback = function(event){
					if(event.origin === window.location.origin)
					{
						window.removeEventListener("message", loginCallback);

						if(!event.data || cc.isString(event.data))
						{
							callback(new Error(event.data));
						}
						else
						{
							callback(null, {
								code: event.data.code,
								state: event.data.state,
								redirectUri: redirect
							});
						}
					}
				};

				window.addEventListener("message", loginCallback, false);

				break;
		}
	}
};

pm.utils.getUserAccountDataCallback = function(error, response, callback)
{
	if(!error && response)
	{
		pm.settings.userLoggedIn = true;

		pm.settings.setUserName(response.username);

		var accountType = response.permissions.pm || pm.USER_TYPE.USER;

		pm.settings.setUserData(response._id, accountType,
			response.name, response.surname);

		pm.userCache.remove(); //clean up cache from not logged in user
		pm.userCache.forceSyncCache(callback);

		return;
	}

	if(error && (error.status === -1 || error.status === 0))
	{
		pm.settings.userLoggedIn = true;
	}
	else
	{
		pm.settings.setAccessToken("");
		pm.settings.setRefreshToken("");

		pm.settings.userLoggedIn = false;
		cc.error(JSON.stringify(error));
	}

	callback();
};

pm.utils.openURL = function (url)
{
	if (cc.sys.isNative)
	{
		cc.Application.getInstance().openURL(url);
	}
	else
	{
		var win = window.open(url, '_blank');
		win.focus();
	}
};

