/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @class Functions for AquariusLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.AquariusLevelModule = pm.ModuleInfo.extend(/** @lends pm.AquariusLevelModule# */{

	name: "Aquarius",

	getType: function()
	{
		return pm.AquariusLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.AquariusMap();

		map.parentLevel = level;

		map.tankA = new pm.data.AquariusTank("A");
		map.tankA.availableCapacity = 3;
		map.tankA.startCapacity = 0;
		map.tankA.currentCapacity = 0;

		map.tankB = new pm.data.AquariusTank("B");
		map.tankB.availableCapacity = 3;
		map.tankB.startCapacity = 0;
		map.tankB.currentCapacity = 0;

		map.tankC = new pm.data.AquariusTank("C");
		map.tankC.availableCapacity = 3;
		map.tankC.startCapacity = 0;
		map.tankC.currentCapacity = 0;

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.AquariusLevel();

		level.name = "Новый Водолей";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.AquariusRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.AquariusLevelModule.Type] = new pm.data.AquariusTask(level);
		level.taskList.tasks[pm.AquariusLevelModule.Type].data[0] = 0;

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		return {};
	},

	getRobotSpriteSheet: function (robotType)
	{
		return null;
	},

	getSettings: function (robotType, object)
	{
		return new AquariusRobotSettingsLayer(object);
	},

	getExtraMapSettings: function (target)
	{
		var layout = new ccui.Layout();
		layout.setLayoutType(ccui.Layout.RELATIVE);

		var availableTankALabel = new pmui.Text(LocalizedString("AvailableTankCapacity") + "A", LevelSettings.FONT_SIZE);

		var availableTankALabelAlign = new ccui.RelativeLayoutParameter();
		availableTankALabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		availableTankALabelAlign.setRelativeName("availableTankALabel");
		availableTankALabel.setLayoutParameter(availableTankALabelAlign);

		var availableTankA = new pmui.TextInput(target.level.activeMap.tankA.availableCapacity, "1", LevelSettings.FONT_SIZE, 40);

		var availableTankAAlign = new ccui.RelativeLayoutParameter();
		availableTankAAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		availableTankAAlign.setRelativeToWidgetName("availableTankALabel");
		availableTankAAlign.setMargin(5, 0, 0, 0);
		availableTankA.setLayoutParameter(availableTankAAlign);

		availableTankA.setInsertTextCallback(this, function() {
			target.level.activeMap.tankA.setAvailableCapacity(Number(availableTankA.getString()));

			target.level.getCurrentRobot().updateNativeFunctions();
		}.bind(this));

		var currentTankALabel = new pmui.Text(LocalizedString("CurrentTankCapacity") + "A", LevelSettings.FONT_SIZE);

		var currentTankALabelAlign = new ccui.RelativeLayoutParameter();
		currentTankALabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		currentTankALabelAlign.setRelativeName("currentTankALabel");
		currentTankALabelAlign.setRelativeToWidgetName("availableTankALabel");
		currentTankALabelAlign.setMargin(0, 15, 0, 0);
		currentTankALabel.setLayoutParameter(currentTankALabelAlign);

		var currentTankA = new pmui.TextInput(target.level.activeMap.tankA.startCapacity, "0", LevelSettings.FONT_SIZE, 40);

		var currentTankAAlign = new ccui.RelativeLayoutParameter();
		currentTankAAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		currentTankAAlign.setRelativeToWidgetName("currentTankALabel");
		currentTankAAlign.setMargin(5, 0, 0, 0);
		currentTankA.setLayoutParameter(currentTankAAlign);

		currentTankA.setInsertTextCallback(this, function() {
			target.level.activeMap.tankA.setStartCapacity(Number(currentTankA.getString()));
		}.bind(this));

		var availableTankBLabel = new pmui.Text(LocalizedString("AvailableTankCapacity") + "B", LevelSettings.FONT_SIZE);

		var availableTankBLabelAlign = new ccui.RelativeLayoutParameter();
		availableTankBLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		availableTankBLabelAlign.setRelativeName("availableTankBLabel");
		availableTankBLabelAlign.setRelativeToWidgetName("currentTankALabel");
		availableTankBLabelAlign.setMargin(0, 15, 0, 0);
		availableTankBLabel.setLayoutParameter(availableTankBLabelAlign);

		var availableTankB = new pmui.TextInput(target.level.activeMap.tankB.availableCapacity, "1", LevelSettings.FONT_SIZE, 40);

		var availableTankBAlign = new ccui.RelativeLayoutParameter();
		availableTankBAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		availableTankBAlign.setRelativeToWidgetName("availableTankBLabel");
		availableTankBAlign.setMargin(5, 0, 0, 0);
		availableTankB.setLayoutParameter(availableTankBAlign);

		availableTankB.setInsertTextCallback(this, function() {
			target.level.activeMap.tankB.setAvailableCapacity(Number(availableTankB.getString()));

			target.level.getCurrentRobot().updateNativeFunctions();
		}.bind(this));

		var currentTankBLabel = new pmui.Text(LocalizedString("CurrentTankCapacity") + "B", LevelSettings.FONT_SIZE);

		var currentTankBLabelAlign = new ccui.RelativeLayoutParameter();
		currentTankBLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		currentTankBLabelAlign.setRelativeName("currentTankBLabel");
		currentTankBLabelAlign.setRelativeToWidgetName("availableTankBLabel");
		currentTankBLabelAlign.setMargin(0, 15, 0, 0);
		currentTankBLabel.setLayoutParameter(currentTankBLabelAlign);

		var currentTankB = new pmui.TextInput(target.level.activeMap.tankB.startCapacity, "0", LevelSettings.FONT_SIZE, 40);

		var currentTankBAlign = new ccui.RelativeLayoutParameter();
		currentTankBAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		currentTankBAlign.setRelativeToWidgetName("currentTankBLabel");
		currentTankBAlign.setMargin(5, 0, 0, 0);
		currentTankB.setLayoutParameter(currentTankBAlign);

		currentTankB.setInsertTextCallback(this, function() {
			target.level.activeMap.tankB.setStartCapacity(Number(currentTankB.getString()));
		}.bind(this));

		var availableTankCLabel = new pmui.Text(LocalizedString("AvailableTankCapacity") + "C", LevelSettings.FONT_SIZE);

		var availableTankCLabelAlign = new ccui.RelativeLayoutParameter();
		availableTankCLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		availableTankCLabelAlign.setRelativeName("availableTankCLabel");
		availableTankCLabelAlign.setRelativeToWidgetName("currentTankBLabel");
		availableTankCLabelAlign.setMargin(0, 15, 0, 0);
		availableTankCLabel.setLayoutParameter(availableTankCLabelAlign);

		var availableTankC = new pmui.TextInput(target.level.activeMap.tankC.availableCapacity, "1", LevelSettings.FONT_SIZE, 40);

		var availableTankCAlign = new ccui.RelativeLayoutParameter();
		availableTankCAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		availableTankCAlign.setRelativeToWidgetName("availableTankCLabel");
		availableTankCAlign.setMargin(5, 0, 0, 0);
		availableTankC.setLayoutParameter(availableTankCAlign);

		availableTankC.setInsertTextCallback(this, function() {
			target.level.activeMap.tankC.setAvailableCapacity(Number(availableTankC.getString()));

			target.level.getCurrentRobot().updateNativeFunctions();
		}.bind(this));

		var currentTankCLabel = new pmui.Text(LocalizedString("CurrentTankCapacity") + "C", LevelSettings.FONT_SIZE);

		var currentTankCLabelAlign = new ccui.RelativeLayoutParameter();
		currentTankCLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		currentTankCLabelAlign.setRelativeName("currentTankCLabel");
		currentTankCLabelAlign.setRelativeToWidgetName("availableTankCLabel");
		currentTankCLabelAlign.setMargin(0, 15, 0, 0);
		currentTankCLabel.setLayoutParameter(currentTankCLabelAlign);

		var currentTankC = new pmui.TextInput(target.level.activeMap.tankC.startCapacity, "0", LevelSettings.FONT_SIZE, 40);

		var currentTankCAlign = new ccui.RelativeLayoutParameter();
		currentTankCAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		currentTankCAlign.setRelativeToWidgetName("currentTankCLabel");
		currentTankCAlign.setMargin(5, 0, 0, 0);
		currentTankC.setLayoutParameter(currentTankCAlign);

		currentTankC.setInsertTextCallback(this, function() {
			target.level.activeMap.tankC.setStartCapacity(Number(currentTankC.getString()));
		}.bind(this));

		layout.addChild(currentTankALabel);
		layout.addChild(currentTankA);
		layout.addChild(availableTankALabel);
		layout.addChild(availableTankA);

		layout.addChild(currentTankBLabel);
		layout.addChild(currentTankB);
		layout.addChild(availableTankBLabel);
		layout.addChild(availableTankB);

		layout.addChild(currentTankCLabel);
		layout.addChild(currentTankC);
		layout.addChild(availableTankCLabel);
		layout.addChild(availableTankC);

		layout.setContentSize(Math.max(currentTankALabel.width + 5 + currentTankA.width, availableTankALabel.width + 5 + availableTankA.width),
			6 * currentTankALabel.height + 75);

		return {
			layout: layout
		}
	},

	getExtraInfo: function (level)
	{
		if (level.taskList.getTask(pm.AquariusLevelModule.Type))
		{
			var layout = new pmui.OvalLayout(null, null, pmui.OvalLayout.Type.SLIM);

			var taskLabel = new pmui.Text(LocalizedString("AquariusFullTask").format(level.taskList.getTask(pm.AquariusLevelModule.Type).data[0]), pm.settings.fontSize);

			var taskAlign = new ccui.RelativeLayoutParameter();
			taskAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			taskAlign.setMargin(pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, pmui.OvalLayout.SLIM_BORDER_RADIUS + 5, 0, 0);
			taskLabel.setLayoutParameter(taskAlign);

			layout.addChild(taskLabel);
			layout.setContentSizeWithBorder(taskLabel.width + 10, taskLabel.height + 10);

			return layout;
		}
		else
		{
			return null;
		}
	},

	getMapElementsType: function()
	{
		return {};
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Aquarius");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/aquarius_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/aquarius_robot-methods.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.AquariusRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Aquarius": "Aquarius",
					"AquariusLevelType": "Aquarius level",
					"AquariusTask": "Obtain amount of l.",
					"AquariusFullTask": "Obtain: {0} l.",
					"AquariusLevel": "Aquarius",
					"EnterAmountOfLiters": "Enter amount of liters",
					"CurrentTankCapacity": "Cur. capacity of tank ",
					"AvailableTankCapacity": "Av. capacity of tank ",
					"aquarius_AToB": "pour from A to B",
					"aquarius_BToC": "pour from B to C",
					"aquarius_AToC": "pour from A to C",
					"aquarius_fullA": "full A",
					"aquarius_fullB": "full B",
					"aquarius_fullC": "full C",
					"aquarius_BToA": "pour from B to A",
					"aquarius_CToB": "pour from C to B",
					"aquarius_CToA": "pour from C to A",
					"aquarius_emptyA": "empty A",
					"aquarius_emptyB": "empty B",
					"aquarius_emptyC": "empty C"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Aquarius": "Водолей",
					"AquariusLevelType": "Водолей",
					"AquariusTask": "Получить кол-во л.",
					"AquariusFullTask": "Получить: {0} л.",
					"AquariusLevel": "Водолей",
					"EnterAmountOfLiters": "Введите кол-во литров",
					"CurrentTankCapacity": "Тек. вм. сосуда ",
					"AvailableTankCapacity": "Макс. вм. сосуда ",
					"aquarius_AToB": "перелить из А в В",
					"aquarius_BToC": "перелить из В в С",
					"aquarius_AToC": "перелить из А в С",
					"aquarius_fullA": "наполнить А",
					"aquarius_fullB": "наполнить B",
					"aquarius_fullC": "наполнить C",
					"aquarius_BToA": "перелить из В в А",
					"aquarius_CToB": "перелить из С в В",
					"aquarius_CToA": "перелить из С в А",
					"aquarius_emptyA": "вылить из А",
					"aquarius_emptyB": "вылить из B",
					"aquarius_emptyC": "вылить из C"
				};
		}
	},

	generateTaskLayer: function (level, task)
	{
		return new AquariusTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "AquariusTask";
	},

	needToRotateRobot: function()
	{
		return false;
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.AquariusTask(level);

		for(var i = 0; i < level.maps.length;++i)
			task.fillWithInitialData(i, 0);

		level.taskList.addTask(pm.AquariusLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function(a, b) {},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.AquariusLevelModule.RobotType = "aquarius-robot";
pm.AquariusLevelModule.Type = "aquarius";
pm.AquariusLevelModule.ObjectTypes = {};
