/**
 * Created by antonded on 24.10.14.
 */

/**
 * @class Layer for choosing game.
 * @extends ccui.Layout
 * @constructor
 */
var SelectGameLayer = ccui.Layout.extend(/** @lends SelectGameLayer# */{

	_worldSelect: null,

	_menuButtonHeight: 0,
	_labelHeight: 0,

	ctor: function ()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		if(cc.sys.isNative)
        	cc.textureCache.removeAllTextures();

		this.setContentSize(pm.settings.getScreenSize());

		this._loadInterface();

		pm.registerCustomEventListener(pm.CLEAR_CACHE, this._clearCache.bind(this), this);
		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new SelectGameScene());
		}, this);

		return true;
	},

	_menu: null,

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var goToStartScreen = new pmui.Button( pm.spriteUtils.getIconName("back", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("back", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("back", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		goToStartScreen.addClickEventListener(this._startScreen.bind(this));

		var goToStartScreenAlign = new ccui.RelativeLayoutParameter();
		goToStartScreenAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		goToStartScreenAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		goToStartScreenAlign.setRelativeName("start");
		goToStartScreen.setLayoutParameter(goToStartScreenAlign);

		this.addChild(goToStartScreen, 2);

		var menuButton = new pmui.Button( pm.spriteUtils.getIconName("menu", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("menu", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("menu", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		this._menuButtonHeight = menuButton.height;

		menuButton.addClickEventListener(this._showMenu.bind(this));

		var menuButtonAlign = new ccui.RelativeLayoutParameter();
		menuButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		menuButtonAlign.setMargin(0, screenBounds.top, screenBounds.right, 0);
		menuButtonAlign.setRelativeName("menu");
		menuButton.setLayoutParameter(menuButtonAlign);

		this.addChild(menuButton);

		var downloadFromServer = new pmui.SelectionButton(pm.spriteUtils.getIconName("downloadFromServer", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("downloadFromServer", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("downloadFromServer", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		downloadFromServer.addClickEventListener(this._confirmDownloadFromServer.bind(this));

		var downloadAlign = new ccui.RelativeLayoutParameter();
		downloadAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		downloadAlign.setRelativeToWidgetName("menu");
		downloadAlign.setMargin(0, SelectGameLayer.SEPARATOR, 0, 0);
		downloadFromServer.setLayoutParameter(downloadAlign);

		this.addChild(downloadFromServer, 2);

		var label = new ccui.Text(world.name, pm.settings.fontName, 70);
		this._labelHeight = label.height;

		var labelWidth = label.width;
		var availableWidth = pm.settings.getScreenSize().width - menuButton.width - goToStartScreen.width - screenBounds.right - screenBounds.left - 2 * SelectGameLayer.SEPARATOR;
		var worldNameScale = availableWidth/labelWidth;

		if (worldNameScale < 1)
			label.setScale(worldNameScale);
		else
			worldNameScale = 1;

		var labelAlign = new ccui.RelativeLayoutParameter();

		labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		labelAlign.setMargin(0, screenBounds.top + Math.abs((menuButton.height - label.height * worldNameScale)/2), 0, 0);

		label.setLayoutParameter(labelAlign);
		this.addChild(label);

		var slidingLayout = new ccui.Layout();

		slidingLayout.setLayoutType(ccui.Layout.RELATIVE);

		slidingLayout.setContentSize(
        	this.getContentSize().width,
			this.getContentSize().height - screenBounds.top - screenBounds.bottom - Math.max(menuButton.height, label.height) - SelectGameLayer.SEPARATOR
		);

		var slidingLayoutAlign = new ccui.RelativeLayoutParameter();
		slidingLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		slidingLayoutAlign.setMargin(0, 0, 0, SelectGameLayer.SEPARATOR);
		slidingLayout.setLayoutParameter(slidingLayoutAlign);

		this._menu = new pmui.SlidingGameMenu(
			this.getContentSize().width,
			this.getContentSize().height - (screenBounds.top + screenBounds.bottom + Math.max(menuButton.height, label.height) + SelectGameLayer.SEPARATOR),
			this._selectGame,
			this
		);

		this._drawWorld();

		var scale = pm.settings.getScreenSize().height / (screenBounds.top + screenBounds.bottom + Math.max(menuButton.height, label.height) + 2 * SelectGameLayer.SEPARATOR + this._menu.height);

		if (scale > 1)
			scale = 1;

		slidingLayout.setScale(scale);

		var slidingMenuAlign = new ccui.RelativeLayoutParameter();
		slidingMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		this._menu.setLayoutParameter(slidingMenuAlign);

		slidingLayout.addChild(this._menu);

		this.addChild(slidingLayout);

		// this._worldSelect = new SelectWorldLayer(this, this._drawWorld);
		// var worldLayerAlign = new ccui.RelativeLayoutParameter();
		// worldLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		// worldLayerAlign.setMargin(0, pm.SCREEN_BOUND, pm.SCREEN_BOUND, 0);
		// this._worldSelect.setLayoutParameter(worldLayerAlign);
		//
		// this.addChild(this._worldSelect);
		//
		// var versionLabel = new ccui.Text(LocalizedString("AppVersion").format(pm.appUtils.getAppVersion()),
		// 								 pm.settings.fontName, pm.settings.fontSize);
		//
		// var versionLabelAlign = new ccui.RelativeLayoutParameter();
		// versionLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		// versionLabelAlign.setMargin(pm.SCREEN_BOUND, 0, 0, pm.SCREEN_BOUND);
		// versionLabelAlign.setRelativeName("version");
		// versionLabel.setLayoutParameter(versionLabelAlign);
		//
		// this.addChild(versionLabel);

		// var clearCache = new pmui.InterfaceButton(this._clearCache, this, LocalizedString("ClearProgress"));
		//
		// var clearCacheAlign = new ccui.RelativeLayoutParameter();
		// clearCacheAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);
		// clearCacheAlign.setMargin(0, 0, 0, pm.SCREEN_BOUND);
		// clearCacheAlign.setRelativeToWidgetName("version");
		// clearCache.setLayoutParameter(clearCacheAlign);
		//
		// this.addChild(clearCache);
	},

	_onUserChanged: function()
	{
		this._worldSelect.refresh();
	},

	_drawWorld: function()
	{
		this._menu.removeAllChildren();

		var screenBounds = pm.settings.getScreenBounds();
		var margin = screenBounds.top + screenBounds.bottom + Math.max(this._menuButtonHeight, this._labelHeight);

		for(var i = 0; i < world.getGameCount(); ++i)
		{
			var menuElement = new pmui.SlidingGameMenuElement(new ccui.ImageView("WorldPics/gameBackground.png"),
				world.getGameName(i), this._menu.getChildrenCount(), margin);

			this._menu.addMenuElement(menuElement);

			if (world.games[i].available === false)
				menuElement.setOpacity(100);
		}

		this._menu.setCurrentElement(pm.settings.getGame(), false);
	},

	_selectGame: function(game)
	{
		if (world.games[game].available === false)
			return;

		if(game !== pm.settings.getGame())
		{
			pm.settings.setGame(game);
			pm.settings.setLevel(0);
		}

		var trans = new cc.TransitionFade( 1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectLevelScene());
		cc.director.runScene(trans);
	},

	_clearCache: function(sender)
	{
		pm.userCache.cleanup();
		cc.sys.garbageCollect();
	},

	_startScreen: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_showMenu: function()
	{
		if(cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
			return;

    	var settingsLayer = new SettingsLayer(this, this._closeMenu);

		cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
	},

	_closeMenu: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	},

	_confirmDownloadFromServer: function()
	{
		if (!this.getChildByTag(MenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("DownloadWorldFromServer"));

			input.setCallback(this, this._downloadFromServer, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, MenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_downloadFromServer: function ()
	{
		console.log("downloading world from server");

		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		pm.worldUtils.downloadWorldFromServer({
			worldID: pm.settings.getSelectedWorldID(),
			callbackTarget: this,
			loadLocalOnError: false,
			reloadBuiltinOnError: true,
			callback: function (error, result)
			{
				loadingLayer.remove();

				if(!error && result)
				{
					this._drawWorld();
				}
				else
				{
					this._handleNetworkError(error, LocalizedString("DownloadFromServerError").format(error[0].text), function (){});

					cc.log(error);
				}
			}
		});
	},

	_handleNetworkError: function (error, message, callback)
	{
		var messageBox = new pmui.MessageBox(this, message, callback);

		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		messageBox.setLayoutParameter(align);

		this.addChild(messageBox, 100);
	}
});

SelectGameLayer.SEPARATOR = 25;

/**
 * @class Scene for {@link SelectGameLayer}
 * @extends cc.Scene
 * @constructor
 */
var SelectGameScene = cc.Scene.extend(/** @lends SelectGameScene# */{
	ctor: function ()
	{
		this._super();

		var layer = new SelectGameLayer();

		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);

		if (!cc.sys.isNative)
		    window.location.hash = "worlds/" + world.id + "/games";
	}
});
