/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class 2D robot for {@link pm.data.GrasshopperLevel}.</br>
 * Has a paint element native function.
 * @implements PlayerRobot4
 */
pm.data.GrasshopperRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.GrasshopperRobot# */{
	typeName: "GrasshopperRobot",

	ctor: function()
	{
		this._super();

		this.nativeFunctionMap[pm.data.GrasshopperRobot.NativeMethod.Paint] = new pm.NativeFunction(this, this._paint);

		this.nativeFunctionMap[pm.data.GrasshopperRobot.NativeMethod.MoveLeft] = new pm.NativeFunction(this, this._moveLeft);
		this.nativeFunctionMap[pm.data.GrasshopperRobot.NativeMethod.MoveRight] = new pm.NativeFunction(this, this._moveRight);

		this.nativeFunctions.push(pm.data.GrasshopperRobot.NativeMethod.MoveLeft, pm.data.GrasshopperRobot.NativeMethod.MoveRight, pm.data.GrasshopperRobot.NativeMethod.Paint);
	},

	getDirectionCount: function() { return 2; },

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new GrasshopperRobotSprite();

			return this.sprite;
		}
	},

	_paint: function()
	{
		this.playAnimation(GrasshopperRobotAnimation.Paint, this._endPaint, this.direction);
	},

	_unpaint: function()
	{
		this.playAnimation(GrasshopperRobotAnimation.Paint, this._endUnpaint, this.direction);
	},

	_endPaint: function()
	{
		var map = this.getMap();

		map.paintElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endUnpaint: function()
	{
		var map = this.getMap();

		map.unpaintElement(this.position);

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_moveLeft: function()
	{
		var target = cc.p(this.position.x - this.parentLevel.leftStepCount, this.position.y);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Left);
	},

	_moveRight: function()
	{
		var target = cc.p(this.position.x + this.parentLevel.rightStepCount, this.position.y);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false, MapDirection4.Right);
	},

	_canMoveTo: function(target, direction)
	{
		return true;
	},

	getState: function ()
	{
		return {
			position: this.position,
			animation: this.getLastAnimation()
		};
	},

	setState: function (state)
	{
		if (state.position !== this.position || this.isBroken())
			this._moveTo(state.position, true);
		else if (state.animation === GrasshopperRobotAnimation.Paint)
			this._unpaint();
	},

	getType: function() { return pm.GrasshopperLevelModule.RobotType; },

	getRobotMethodName: function (methodName)
	{
		if(methodName === pm.data.GrasshopperRobot.NativeMethod.MoveLeft)
			return "{0} {1}".format(LocalizedString(methodName) ,this.parentLevel.leftStepCount);
		else if(methodName === pm.data.GrasshopperRobot.NativeMethod.MoveRight)
			return "{0} {1}".format(LocalizedString(methodName), this.parentLevel.rightStepCount);
		else
			return LocalizedString(methodName);
	}
});

pm.data.GrasshopperRobot.NativeMethod =
{
	Paint: "kumir_paint",
	Unpaint: "kumir_unpaint",
	MoveLeft: "kumir_move_left",
	MoveRight: "kumir_move_right"
};
