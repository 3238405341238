/**
 * System animation delay.
 * @const
 * @type {number}
 * @default
 */
pm.SYSTEM_ANIMATION_DELAY = 0.4;
/**
 * A default bound for placing elements on screen.
 * @const
 * @type {number}
 * @default
 */
pm.SCREEN_BOUND = 15.0;
/**
 * Maximum animation speed.
 * @const
 * @type {number}
 * @default
 */
pm.MAX_ANIMATION_SPEED = 0.8;
/**
 * Medium animation speed.
 * @const
 * @type {number}
 * @default
 */
pm.MEDIUM_ANIMATION_SPEED = 0.4;
/**
 * Small animation speed.
 * @const
 * @type {number}
 * @default
 */
pm.SMALL_ANIMATION_SPEED = 0.2;
/**
 * Minimum animation speed
 * @const
 * @type {number}
 * @default
 */
pm.MIN_ANIMATION_SPEED = 0.07;

/**
 * DPI factor.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.MOBILE_DPI_FACTOR = 160.0;

/**
 * Tablet or phone.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.DEVICE_WIDTH_INDICATOR = 5.0;

/**
 * Target retina DPI for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.TABLET_RETINA_TARGET_DPI = 264.0;

/**
 * Target retina width for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.TABLET_RETINA_TARGET_WIDTH = 2048.0;

/**
 * Target retina height for tablets.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.TABLET_RETINA_TARGET_HEIGHT = 1536.0;

/**
 * Target retina DPI for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.PHONE_RETINA_TARGET_DPI = 458.0;

/**
 * Target retina width for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.PHONE_RETINA_TARGET_WIDTH = 2358.0;

/**
 * Target retina height for phones.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.PHONE_RETINA_TARGET_HEIGHT = 750.0;

/**
 * Minimum display width to recognize retina for desktop platform.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.DESKTOP_RETINA_DISPLAY_MIN_WIDTH = 2400;
/**
 * Minimum display width to recognize retina.
 * @see cc.game.onStart
 * @const
 * @type {number}
 * @default
 */
pm.IPHONE_BIG_DISPLAY_MIN_WIDTH = 2200;
/**
 * Minimum distance to detect drag or click.
 * @const
 * @type {Number}
 * @default
 */
pm.TOUCH_CRITICAL_DISTANCE = 5;

/**
 * Version of settings file format
 * @const
 * @type {number}
 * @default
 */
pm.SETTINGS_VERSION = 260;

/**
 * Enum for user types
 * @enum {String}
 */
pm.USER_TYPE = {
	ADMIN: "admin",
	TEACHER: "teacher",
	USER: "user"
};

/**
 * All game settings are stored here.
 * @namespace
 */
pm.settings = {
	/**
     * @private
     * @default
     */
	_cacheDir: "Cache",
	/**
     * Global font definition.
     * @type {cc.FontDefinition}
     * @default
     */
	fontDefinition: new cc.FontDefinition(),
	/**
     * Global font name.
     * @type {String}
     * @default
     */
	fontName: pm.fonts.getRealFontName("Comfortaa-Regular"),
	/**
	 * Global font name in bold style.
	 * @type {String}
	 * @default
	 */
	fontBoldName: pm.fonts.getRealFontName("Comfortaa-Bold"),
	/**
	 * Global font name in light style.
	 * @type {String}
	 * @default
	 */
	fontLightName: pm.fonts.getRealFontName("Comfortaa-Light"),
	/**
	 * Global font size.
	 * @type {Number}
	 * @default
	 */
	fontSize: 20,
	/**
     * Global transparent color.
     * @type {cc.Color}
     * @default
     */
	transparentColor: cc.color(0, 0, 0, 80),

	/**
	 * Level font transparent color.
	 * @type {cc.Color}
	 * @default
	 */
	levelFontColor: cc.color(0, 102, 102),

	/**
     * Is user logged in editor.
     * @type {Boolean}
     * @default
     */
	userLoggedIn: false,

	/**
     * If display is retina.
     * @type {Boolean}
     * @default
     */
	isRetinaEnabled: false,

	/**
     * If is in editor mode.
     * @type {Boolean}
     * @default
     */
	isEditorMode: false,

	/**
	 * If it is help pages.
	 *
	 **/
	isHelpMode: false,

	/**
	 * A flag to block exiting level,
	 * when piktomir is opened by link with search param blockExit=true.
	 * Also gives an opportunity to load the current level to server for teacher.
	 */
	blockExit: false,

	/**
	 * Screen bounds
	 * @private
     */
	_screenBounds: null,

	/**
     * Returns a screen size.
     * @returns {cc.Size}
     */
	getScreenSize: function()
	{
		return cc.director.getWinSize();
	},

	/**
     * Returns a safe area rect on screen.
     * @returns {cc.Rect}
     */
	getSafeAreaRect: function()
	{
    	if(!cc.sys.isNative)
		{
			var screenSize = this.getScreenSize();

			return {
				x: 0,
				y: 0,
				width: screenSize.width,
				height: screenSize.height
			};
		}

		return cc.director.getOpenGLView().getSafeAreaRect();
	},
	/**
	 * Returns screen bounds
     * @returns {{safeAreas: {top: boolean, left: boolean, bottom: boolean, right: boolean}, top: number, left: number, bottom: number, right: number}}
     */
	getScreenBounds: function()
	{
		if(!this._screenBounds)
		{
			var safeAreaRect = this.getSafeAreaRect();
			var screenSize = this.getScreenSize();

			var safeAreas = {
				top: false,
				left: safeAreaRect.x !== 0,
				bottom: safeAreaRect.y !== 0,
				right: safeAreaRect.width + safeAreaRect.x < screenSize.width
			};

			this._screenBounds = {
				safeAreas: safeAreas,
				top: pm.SCREEN_BOUND,
				left: safeAreas.left ? safeAreaRect.x : pm.SCREEN_BOUND,
				bottom: safeAreas.bottom ? safeAreaRect.y : pm.SCREEN_BOUND,
				right: safeAreas.right ? screenSize.width - safeAreaRect.x - safeAreaRect.width : pm.SCREEN_BOUND
			};
		}

		return this._screenBounds;
	},

	/**
     * Returns an area of map.
     * @returns {cc.Size}
     */
	getMapSize: function()
	{
		return cc.size(this.getScreenSize().width - pm.appUtils.getProgramLayerWidth() + ProgramLayer.METHOD_STACK_WIDTH, this.getScreenSize().height);
	},
	/**
     * Saves settings to file.
     */
	save: function()
	{
		pm.fileUtils.saveObject(this._getSettingsPath(), this._cache);
	},
	/**
     * Loads settings.
     */
	load: function()
	{
		var builtinWorlds = pm.settings.getBuiltinWorlds();

		if(pm.fileUtils.isFileExist(this._getSettingsPath()))
		{
			var fileContent = pm.fileUtils.readObject(this._getSettingsPath());

			if(fileContent.version < pm.SETTINGS_VERSION)
			{
				pm.fileUtils.removeFile(this._getSettingsPath());

				for(var i = 0; i < builtinWorlds.length; ++i)
				{
					this.addWorld(builtinWorlds[i].id);
					this.setWorldMetaData(builtinWorlds[i].id, builtinWorlds[i]);
				}

				this.setSelectedWorldID(builtinWorlds[0].id);
				this.save();
			}
			else
			{
				this._cache = fileContent;
			}
		}
		else
		{
			pm.fileUtils.createDirectory(pm.fileUtils.getWritablePath());

			this._loadDefaultCache();

			this.save();
		}

		this.fontDefinition.fontName = this.fontName;
		this.fontDefinition.fontSize = this.fontSize;
	},

	_cache: {},

	_loadDefaultCache: function()
	{
		var builtinWorlds = pm.settings.getBuiltinWorlds();

		this._cache = {
			version: pm.SETTINGS_VERSION,
			animationSettings: {animationSpeed: 0.5},
			languageSettings: {language: cc.sys.language},
			soundSettings: {
				backgroundVolume: 0.99,
				effectVolume: 0.99,
				soundEnabled: 1
			},
			worldSettings: {
				selectedUserGroupID: "none",
				selectedWorldID: builtinWorlds[0].id,

				worlds: {}
			},
			userSettings: {
				userAutoLogin: false,
				accessToken: "",
				refreshToken: "",

				userData: {
					id: "",
					login: "",
					accountType: "",
					username: "",

					firstName: "",
					surname: ""
				}
			},
			PhysicalConnectorType: "none",
			useProgramRecognizer: true,
			useAR: true,
			useTurboMode: false,
			appScale: null,
			useClearProgramButton: false,
			deviceName: null,
			modifyDate: null
		};

		for(var i = 0; i < builtinWorlds.length; ++i)
		{
			this.addWorld(builtinWorlds[i].id);
			this.setWorldMetaData(builtinWorlds[i].id, builtinWorlds[i]);
		}

		this.setSelectedWorldID(builtinWorlds[0].id);
	},

	/**
     * Returns a path to settings.
     * @returns {string}
     * @private
     */
	_getSettingsPath: function()
	{
		return pm.fileUtils.getWritablePath() + "/Settings.json";
	},

	/**
     * Returns a path to world of format version.
     * @param {String} [worldID] A world id.
     * @returns {string}
     */
	getWorldPath: function(worldID)
	{
		return 	this.getWorldDir(worldID) + "/world.json";
	},

	/**
     * Returns a path to world dir of format version.
     * @param {String} [worldID] A world id.
     * @returns {string}
     */
	getWorldDir: function(worldID)
	{
		worldID = ((worldID === undefined)? this.getSelectedWorldID() : worldID);

		return 	pm.fileUtils.getWritablePath() + "/" + pm.appUtils.getSupportedLevelFormatVersion() + "/" + worldID;
	},

	/**
     * Returns a path to world edit log file.
	 * @param {String} [worldID] A world name.
	 * @returns {string}
     */
	getWorldEditLogPath: function(worldID)
	{
		return 	this.getWorldDir(worldID) + "/editlog.json";
	},
	/**
     * Returns a path to world temp dir.
     * @returns {string}
     */
	getWorldTempDir: function()
	{
		return 	this.getWorldDir() + "/Temp";
	},
	/**
     * Returns a path to world cache dir.
     * @param {String} [worldID] A world name.
     * @returns {string}
     */
	getWorldCachePath: function(worldID)
	{
		return 	this.getWorldDir(worldID) + "/" + this._cacheDir;
	},

	/**
     * Returns selected world name.
     * @returns {String}
     */
	getSelectedWorldID: function()
	{
		return this._cache.worldSettings.selectedWorldID;
	},

	/**
     * Returns selected world name.
     * @returns {string}
     */
	getSelectedUserGroupID: function()
	{
		return this._cache.worldSettings.selectedUserGroupID;
	},
	/**
     * Sets selected world
     * @param {String} worldID World id to select.
     */
	setSelectedWorldID: function(worldID)
	{
		this._cache.worldSettings.selectedWorldID = worldID;

		this.save();
	},

	getWorldRecord: function(worldID)
	{
		worldID = worldID === undefined ? this.getSelectedWorldID() : worldID;

		return this._cache.worldSettings.worlds[worldID];
	},

	/**
     * Returns selected user name
     * @returns {String}
     */
	getSelectedUserID: function()
	{
		return this._cache.worldSettings.worlds[this.getSelectedWorldID()].owner.id;
	},
	/**
     * Sets selected user group
     * @param {String} groupID Group id.
     */
	setSelectedUserGroupID: function(groupID)
	{
		this._cache.worldSettings.selectedUserGroupID = groupID;

		this.save();
	},

	updateModifyDate: function()
	{
		this._cache.modifyDate = new Date();

		this.save();
	},

	clearModifyDate: function()
	{
		this._cache.modifyDate = null;

		this.save();
	},

	getModifyDate: function()
	{
		return this._cache.modifyDate;
	},

	/**
	 * Sets world metadata
     * @param {String} worldID
     * @param {Object} metadata
	 * @param {String} metadata.name
	 * @param {String} metadata.description
	 * @param {String} metadata.createDate
	 * @param {Boolean} metadata.linkedWithMirera
	 * @param {Object} [metadata.owner]
	 * @param {String} [metadata.owner.id]
	 * @param {String} [metadata.owner.name]
	 * @param {Array<String>} [metadata.groups]
     */
	setWorldMetaData: function(worldID, metadata)
	{
		if (this._cache.worldSettings.worlds[worldID])
		{
        	var world = this._cache.worldSettings.worlds[worldID];

			world.name = metadata.name || "";
			world.description = metadata.description || "";
			world.createDate = metadata.createDate || (new Date()).toISOString();
			world.linkedWithMirera = metadata.linkedWithMirera || false;

			if(metadata.owner)
			{
				if (!world.owner)
					world.owner = {};

				world.owner.id = metadata.owner.id || "";
				world.owner.name = metadata.owner.name || "";
			}

			if(metadata.groups && metadata.groups.length > 0)
			{
				world.groups = [];

				for(var i = 0 ; i < metadata.groups.length; ++i)
					world.groups.push(metadata.groups[i]);
			}

			this.save();
		}
	},

	/**
     * Is user auto login to editor.
     * @returns {boolean}
     */
	isUserAutoLogin: function()
	{
		return this._cache.userSettings.userAutoLogin;
	},

	/**
     * Sets user auto login to editor.
     * @param {boolean} status
     */
	setUserAutoLogin: function(status)
	{
		this._cache.userSettings.userAutoLogin = status;
	},

	/**
     * Returns last played game index.
     * @returns {Number}
     */
	getGame: function()
	{
		return this.getWorldRecord().game;
	},
	/**
     * Sets last played game index.
     * @param {Number} game Game Index.
     */
	setGame: function(game)
	{
		this.getWorldRecord().game = game;
		this.save();
	},
	/**
     * Returns last played level index.
     * @returns {Number}
     */
	getLevel: function()
	{
		return this.getWorldRecord().level;
	},
	/**
     * Sets last played level index.
     * @param {Number} level Level Index.
     */
	setLevel: function(level)
	{
		this.getWorldRecord().level = level;
		this.save();
	},

	/**
     * Decrements last played level index
     */
	decLevel: function()
	{
		this.setLevel(this.getLevel() - 1);
	},
	/**
     * Decrements last played game index
     */
	decGame: function()
	{
		this.setGame(this.getGame() - 1);
	},
	/**
     * Increments last played game index
     */
	incGame: function()
	{
		this.setGame(this.getGame() + 1);
	},
	/**
     * Increments last played level index
     */
	incLevel: function()
	{
		this.setLevel(this.getLevel() + 1);
	},

	/**
     * Adds world to settings.
     * @param {String} worldID ID of world.
     */
	addWorld: function (worldID)
	{
    	if(!this._cache.worldSettings.worlds[worldID])
		{
			this._cache.worldSettings.worlds[worldID] = {
            	id: worldID,
				game: 0,
				level: 0
			};
		}
	},

	/**
	 * Rename world
	 * @param {String} worldID
	 * @param {String} newName
	 */
	renameWorld: function (worldID, newName)
	{
		if (this._cache.worldSettings.worlds[worldID])
		{
			this._cache.worldSettings.worlds[worldID].name = newName;
			this.save();
		}
	},

	/**
	 * Set world linked with mirera status
	 * @param {String} worldID
	 * @param {Boolean} linkedWithMirera
	 */
	setWorldLinkedWithMirera: function (worldID, linkedWithMirera)
	{
		if (this._cache.worldSettings.worlds[worldID])
		{
			this._cache.worldSettings.worlds[worldID].linkedWithMirera = linkedWithMirera;
			this.save();
		}
	},

	/**
     * Change description of world
     * @param {String} worldID
     * @param {String} newDesc
     */
	changeWorldDescription: function (worldID, newDesc)
	{
		if (this._cache.worldSettings.worlds[worldID])
		{
			this._cache.worldSettings.worlds[worldID].description = newDesc;
			this.save();
		}
	},

	/**
	 * Delete world
	 * @param {String} worldID
	 */
	deleteWorld: function (worldID)
	{
		var worlds = this._cache.worldSettings.worlds;

		delete worlds[worldID];
		this.save();
	},

	/**
     * Returns all world data stored in settings cache.
     * @returns {Object}
     */
	getAvailableWorlds: function()
	{
		return this._cache.worldSettings.worlds;
	},

	/**
     * Returns animation speed.
     * @returns {number}
     */
	getAnimationSpeed: function()
	{
		if(this.isHelpMode)
		{
			return pm.MEDIUM_ANIMATION_SPEED;
		}
		return this._cache.animationSettings.animationSpeed;
	},
	/**
     * Returns if animation if disabled(animationSpeed < pm.MIN_ANIMATION_SPEED).
     * @returns {boolean}
     */
	isAnimationDisabled: function()
	{
		return this._cache.animationSettings.animationSpeed < pm.MIN_ANIMATION_SPEED;
	},
	/**
     * Sets animation speed.
     * @param {Number} value Speed value.
     */
	setAnimationSpeed: function(value)
	{
		this._cache.animationSettings.animationSpeed = value;
		this.save();
	},
	/**
     * Returns background music volume.
     * @returns {number}
     */
	getBackgroundVolume: function()
	{
		return this._cache.soundSettings.backgroundVolume;
	},
	/**
     * Sets background music volume.
     * @param {Number} value Background music value.
     */
	setBackgroundVolume: function(value)
	{
		this._cache.soundSettings.backgroundVolume = value;
		this.save();
	},
	/**
     * Returns effect music volume.
     * @returns {number}
     */
	getEffectVolume: function()
	{
		return this._cache.soundSettings.effectVolume;
	},
	/**
     * Sets effect music volume.
     * @param {Number} value Effect music value.
     */
	setEffectVolume: function(value)
	{
		this._cache.soundSettings.effectVolume = value;
		this.save();
	},
	/**
     * Returns language code.
     * @returns {String}
     */
	getLanguage: function()
	{
		return this._cache.languageSettings.language;
	},
	/**
     * Sets language code.
     * @param {String} value Language code.
     */
	setLanguage: function(value)
	{
		this._cache.languageSettings.language = value;
		this.save();
	},
	/**
     * Returns access token for current user.
     * @returns {String}
     */
	getAccessToken: function()
	{
		return this._cache.userSettings.accessToken;
	},
	/**
     * Sets access token for current user.
     * @param {String} token Access token.
     */
	setAccessToken: function(token)
	{
		this._cache.userSettings.accessToken = token;
		this.save();
	},
	/**
     * Returns refresh token for current user.
     * @returns {String}
     */
	getRefreshToken: function()
	{
		return this._cache.userSettings.refreshToken;
	},
	/**
     * Sets refresh token for current user.
     * @param {String} token Refresh token.
     */
	setRefreshToken: function(token)
	{
		this._cache.userSettings.refreshToken = token;
		this.save();
	},

	/**
     * Returns user data of current user.
     * @returns {Object}
     */
	getUserData: function()
	{
		return this._cache.userSettings.userData;
	},

	/**
     * Sets user name
     * @param {String} username
     */
	setUserName: function(username)
	{
		this._cache.userSettings.userData.username = username;
		this.save();
	},

	/**
	 * Sets user login
	 * @param {String} login
	 */
	setUserLogin: function(login)
	{
		this._cache.userSettings.userData.login = login;
		this.save();
	},

	/**
     * Sets user data
     * @param {String} accountType
     * @param {String} firstName
     * @param {String} surname
     */
	setUserData: function(id, accountType, firstName, surname)
	{
		this._cache.userSettings.userData.id = id;
		this._cache.userSettings.userData.accountType = accountType;
		this._cache.userSettings.userData.firstName = firstName;
		this._cache.userSettings.userData.surname = surname;

		this.save();
	},

	/**
     * Removes all user data + his tokens
     */
	clearUserData: function()
	{
		this._cache.userSettings.userData.accountType = "";
		this._cache.userSettings.userData.firstName = "";
		this._cache.userSettings.userData.surname = "";
		this._cache.userSettings.userData.username = "";

		this._cache.userSettings.accessToken = "";
		this._cache.userSettings.refreshToken = "";

		this.save();
	},

	/**
	 * Sets physical robot type.
	 * @param {pm.PhysicalConnectorType} type
     */
	setPhysicalConnectorType: function(type)
	{
		this._cache.PhysicalConnectorType = type;
		this.save();

		pm.sendCustomEvent(pm.PHYSICAL_ROBOT_TYPE_CHANGED, type);
	},

	/**
     * Returns physical robot type.
	 * @returns {pm.PhysicalConnectorType}
     */
	getPhysicalConnectorType: function()
	{
		return this._cache.PhysicalConnectorType || pm.PhysicalConnectorType.BLE;
	},

	/**
     * Sets using of program recognizer.
     * @param {Boolean} state
     */
	setUseProgramRecognizer: function(state)
	{
		this._cache.useProgramRecognizer = state;
		this.save();
	},

	/**
     * Returns using of program recognizer.
	 * @returns {Boolean}
     */
	getUseProgramRecognizer: function()
	{
		return this._cache.useProgramRecognizer === undefined ? true : this._cache.useProgramRecognizer;
	},

	/**
	 * Sets using of program recognizer.
	 * @param {Boolean} state
	 */
	setUseAR: function(state)
	{
		this._cache.useAR = state;
		this.save();
	},

	/**
	 * Returns using of program recognizer.
	 * @returns {Boolean}
	 */
	getUseAR: function()
	{
		return this._cache.useAR === undefined ? true : this._cache.useAR;
	},

	/**
     * Sets using of turbo mode.
     * @param {Boolean} state
     */
	setUseTurboMode: function(state)
	{
		this._cache.useTurboMode = state;
		this.save();
	},

	/**
     * Returns using of turbo mode.
     * @returns {Boolean}
     */
	getUseTurboMode: function()
	{
		return this._cache.useTurboMode === undefined ? false : this._cache.useTurboMode;
	},

	/**
	 * Sets app scale.
	 * @param {Float} scale
	 */
	setAppScale: function(scale)
	{
		this._cache.appScale = scale;
		this.save();
	},

	/**
	 * Returns app scale.
	 * @returns {Float}
	 */
	getAppScale: function()
	{
		return this._cache.appScale;
	},

	/**
	 * Sets using of clear program button.
	 * @param {Boolean} state
	 */
	setUseClearProgramButton: function(state)
	{
		this._cache.useClearProgramButton = state;
		this.save();
	},

	/**
	 * Returns using of clear program button.
	 * @returns {Boolean}
	 */
	getUseClearProgramButton: function()
	{
		return this._cache.useClearProgramButton === undefined ? false : this._cache.useClearProgramButton;
	},

	setUserDeviceName: function(name)
	{
		this._cache.deviceName = name;
		this.save();
	},

	getUserDeviceName: function()
	{
		return !this._cache.deviceName ? "Unnamed": this._cache.deviceName;
	},

	getBuiltinWorlds: function ()
	{
		return cc.sys.isNative ? pm.appConfig.builtinWorlds[pm.appConfig.appType] : [ pm.appConfig.builtinWorlds[pm.appConfig.appType][0] ];
	}
};
