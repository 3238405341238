/**
 * Created by Danila Eremin on 03.02.15.
 */

/**
 * @const
 * @default
 * @type {string}
 */
var pmVersion = "3.1.0";
/**
 * @const
 * @default
 * @type {string}
 */
var pmkVersion = "3.1.0";
/**
 * @const
 * @default
 * @type {string}
 */
var build = "202403281457";
/**
 * @const
 * @default
 * @type {Number}
 */
var PM_SUPPORTED_LEVEL_FORMAT_VERSION = 21;
/**
 * @const
 * @default
 * @type {Number}
 */
var PMK_SUPPORTED_LEVEL_FORMAT_VERSION = 24;
