/**
 * Created by Kirill Mashchenko on 07.07.2018.
 */

/**
 * @class 2D Level where robots can carry objects.
 * @implements pm.AbstractLevel
 */
pm.data.TrainLevel = pm.AbstractLevel.extend(/** @lends pm.data.TrainLevel# */{
	typeName: "TrainLevel",
	/**
	 * Robots can be indicated.
	 * @type {Boolean}
	 */
	robotsCanBeIndicated: false,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.TrainLevelModule.Type; }

});
