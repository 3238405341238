/**
 * Created by Kirill Mashchenko on 10.07.17.
 */

/**
 * @class Functions for LightLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.LightLevelModule = pm.ModuleInfo.extend(/** @lends pm.LightLevelModule# */{

	name: "Light",

	getType: function()
	{
		return pm.LightLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.LightMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = LightMapElementType.GreenGrass;
		}

		map.element(cc.p(1, 1)).originalType = LightMapElementType.StopPoint;
		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).originalType = LightMapElementType.StopPoint;
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.LightLevel();

		level.name = LocalizedString("Light");
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";

		level.robots.push(new pm.data.LightRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.LightLevelModule.Type] = new pm.data.LightTask(level);

		return level;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/light_robot.plist";
	},

	getObjectsInfo: function()
	{
		var ret = {};

		ret[pm.LightLevelModule.ObjectTypes.LightLamp0] = "Objects/LightLamp0.png";
		ret[pm.LightLevelModule.ObjectTypes.LightLamp1] = "Objects/LightLamp1.png";
		ret[pm.LightLevelModule.ObjectTypes.LightObject0] = "Objects/LightObject0.png";
		ret[pm.LightLevelModule.ObjectTypes.LightObject1] = "Objects/LightObject1.png";

		return ret;
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.LightLevelModule.RobotType] = "light_robot0.png";
		return ret;
	},

	getSettings: function (robotType, object)
	{
		return new LightRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return LightMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Light");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/light_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return ["Robot Methods/light_robot-methods.plist"];
	},

	generateObject: function (objectType)
	{
		var ret = null;

		switch (objectType)
		{
			case pm.LightLevelModule.ObjectTypes.LightLamp0:
			case pm.LightLevelModule.ObjectTypes.LightLamp1:
				ret = new pm.data.LightLamp();
				break;
			case pm.LightLevelModule.ObjectTypes.LightObject0:
			case pm.LightLevelModule.ObjectTypes.LightObject1:
				ret = new pm.data.LightObject();
				break;
		}

		if(ret)
			ret.type = objectType;

		return ret;
	},

	generateRobot: function (robotType)
	{
		return new pm.data.LightRobot();
	},

	stopResizingWidth: function (map, side)
	{
		switch (side)
		{
			case SquarableMapEditor_resizeSide.Left:
				for (var y = 1; y < map.height - 1; ++y)
				{
					if (map.element(cc.p(1, y)).getType() === LightMapElementType.BottomWater)
						return true;
				}

				for (var y = 0; y < map.height; ++y)
				{
					var type = map.element(cc.p(0, y)).getType();

					if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
						return true;
				}
				break;
			case SquarableMapEditor_resizeSide.Right:
				for (var y = 1; y < map.height - 1; ++y)
				{
					if (map.element(cc.p(map.width - 2, y)).getType() === LightMapElementType.BottomWater)
						return true;
				}

				for (var y = 0; y < map.height; ++y)
				{
					var type = map.element(cc.p(map.width - 1, y)).getType();

					if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
						return true;
				}
				break;
		}
	},

	stopDecreasingHeight: function (map, side)
	{
		switch (side)
		{
			case SquarableMapEditor_resizeSide.Left:
				for (var x = 1; x < map.width - 1; ++x)
				{
					if (map.element(cc.p(x, map.height - 2)).getType() === LightMapElementType.BottomWater)
						return true;
				}

				for (var x = 0; x < map.width; ++x)
				{
					var type = map.element(cc.p(x, map.height - 1)).getType();

					if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
						return true;
				}
				break;
			case SquarableMapEditor_resizeSide.Right:
				for (var x = 1; x < map.width - 1; ++x)
				{
					if (map.element(cc.p(x, 1)).getType() === LightMapElementType.BottomWater)
						return true;
				}

				for (var x = 0; x < map.width; ++x)
				{
					var type = map.element(cc.p(x, 0)).getType();

					if (type === LightMapElementType.Road || type === LightMapElementType.StopPoint)
						return true;
				}
				break;
		}
	},

	canNotDragOrAddObject: function (map, point)
	{
		var type = map.element(point).getType();

		if (type === LightMapElementType.BottomWater ||
            type === LightMapElementType.Road ||
            type === LightMapElementType.StopPoint)

			return true;

		var x = point.x;
		var y = point.y;

		for (var i = x - 1; i <= x + 1; ++i)
		{
			for (var j = y - 1; j <= y + 1; ++j)
			{
				if (map.element(cc.p(i, j)))
				{
					if (map.element(cc.p(i, j)).getType() === LightMapElementType.BottomWater)
						return true;
				}
			}
		}

		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Light": "Light",
					"LightLevelType": "Light level",
					"LightTask": "Turn on Lights",
					"LightLevel": "Light"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Light": "Зажигун",
					"LightLevelType": "Зажигун",
					"LightTask": "Зажечь Лампы",
					"LightLevel": "Зажигун"
				};
		}
	},

	generateTaskLayer: function (level, task)
	{
		return new LightTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "LightTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.LightTask(level);
		level.taskList.addTask(pm.LightLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function()
	{
		return {
			horizontal: pm.spriteUtils.getMapTileFrame("light-depth_horizontal"),
			vertical: pm.spriteUtils.getMapTileFrame("light-depth_vertical")
		};
	},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function() {},

	getLessonMapLayer: function()
	{
		var level = new pm.data.LightLevel();

		level.name = "Light";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";

		level.robots.push(new pm.data.LightRobot());

		this._fillLevelData(level);

		level.robots[0]._oldPosition = cc.p(0, 1);
		level.robots[0].position = cc.p(0, 1);
		level.robots[0].direction = 1;

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.LightLevelModule.Type] = new pm.data.LightTask(level);

		var map = new pm.data.LightMap();
		map.parentLevel = level;

		map.setSize(2, 2);

		for (var x = 0; x < 2; ++x)
		{
			for (var y = 0; y < 2; ++y)
				map.element(cc.p(x, y)).originalType = LightMapElementType.GreenGrass;
		}

		map.element(cc.p(0, 1)).originalType = LightMapElementType.StopPoint;
		map.element(cc.p(0, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(0, 1)).startRobotData.direction = 1;

        map.element(cc.p(1, 0)).originalType = LightMapElementType.Road;

        map.objects.push(new pm.data.LightLamp());

        map.objects[0].position = cc.p(0,0);
        map.objects[0].type = pm.LightLevelModule.ObjectTypes.LightLamp1;

        level.maps.push(map);
		level.load();

		map.load();
		map.generateMapLayer(false);

		map.mapLayer.placeRobots(true);

		return map.mapLayer;
	}
});

pm.LightLevelModule.RobotType = "light-robot";
pm.LightLevelModule.Type = "light";
pm.LightLevelModule.Lamp = "lamp";
pm.LightLevelModule.Object = "tree";
pm.LightLevelModule.ObjectTypes = {
	LightLamp0: "LightLamp0",
	LightLamp1: "LightLamp1",
	LightObject0: "LightObject0",
	LightObject1: "LightObject1"

};
