/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * This callback is used when changed state of game(start, restart).
 * @callback ProgramControlLayer~changeStateCallback
 */

/**
 * @class Layer which contains buttons for controlling program.
 * @extends pmui.DragAndDropLayout
 * @constructor
 * @param {Boolean} showStartAllButton
 * @param {Boolean} isNetGame Is a network game.
 * @param {Boolean} useProgramRecognizer
 */
var ProgramControlLayer = pmui.DragAndDropLayout.extend(/** @lends ProgramControlLayer# */{

	_showStartAllButton: null,
	_isNetGame: false,
	_useProgramRecognizer: null,

	_startProgramButton: null,
	_restartProgramButton: null,
	_makeStepButton: null,
	_startAllButton: null,
	_infoButton: null,
	_settingsButton: null,

	_changeSpeedButton: null,
	_turboButton: null,
	_velocity: null,

	_recognizeResultButton: null,

	ctor: function (showStartAllButton, isNetGame, useProgramRecognizer)
	{
		this._showStartAllButton = showStartAllButton;
		this._isNetGame = isNetGame;
		this._useProgramRecognizer = useProgramRecognizer;

		this._super(pmui.DragAndDropLayout.Type.DRAG_ONLY);
		this.addDropTargetName(ProgramContainerLayer.DNDNAME);
		this.addDNDEventListener(this._dndEventCallback, this);

		this.ignoreBoundingBox = true;

		var screenBounds = pm.settings.getScreenBounds();

		var width = pm.appUtils.getProgramLayerWidth();

		if(screenBounds.safeAreas.right)
			width += screenBounds.right / 2;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setBackGroundImageScale9Enabled(true);
		this.setBackGroundImage("System/PCL_Background.png");
		this.setBackGroundImageCapInsets(cc.rect(180, 85, 180, 40));
		this.setContentSize(width + 10, ProgramControlLayer.HEIGHT);

		this._loadInterface();

		pm.registerCustomEventListener(pm.ANIMATE_RESTART_BUTTON, function()
		{
			var showSelected = cc.callFunc(function()
			{
				this.setHighlighted(true);
			}, this._restartProgramButton);

			var showNormal = cc.callFunc(function()
			{
				this.setHighlighted(false);
			}, this._restartProgramButton);

			var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY);

			this._restartProgramButton.runAction(cc.repeat(cc.sequence(showSelected, delay, showNormal, delay), 2));
		}.bind(this), this);

		pm.registerCustomEventListener(pme.SHOW_START_ALL_BUTTON, function (event)
		{
			this._showStartAllButton = event.getUserData();

			this.reloadInterface();
		}.bind(this), this);
	},

	reloadInterface: function()
	{
		this.removeAllChildren(true);
		this._loadInterface();
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var turboMode = pm.settings.getUseTurboMode();
		var relativeStartName = this._showStartAllButton ? "startAll" : "start";
		var relativeSpeedName = turboMode ? "turbo" : "speed";

		var separator = 0;
		var margin = 0;

		if (!this._showStartAllButton && !turboMode)
		{
			separator = ProgramControlLayer.BUTTON_SEPARATOR1;
			margin = ProgramControlLayer.HORIZONTAL_MARGIN2;
		}
		else if (this._showStartAllButton && turboMode)
		{
			separator = ProgramControlLayer.BUTTON_SEPARATOR3;
			margin = ProgramControlLayer.HORIZONTAL_MARGIN1;
		}
		else
		{
			separator = ProgramControlLayer.BUTTON_SEPARATOR2;
			margin = ProgramControlLayer.HORIZONTAL_MARGIN2;
		}

		this._infoButton = new pmui.Button( pm.spriteUtils.getIconName("info", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("info", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("info", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		var infoAlign = new ccui.RelativeLayoutParameter();
		infoAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		infoAlign.setMargin(margin, ProgramControlLayer.VERTICAL_MARGIN, 0, 0);
		infoAlign.setRelativeName("info");
		this._infoButton.setLayoutParameter(infoAlign);

		this._infoButton.setName("info");
		this._infoButton.addClickEventListener(this._buttonPressed.bind(this));
		this.addChild(this._infoButton);

		if (this._useProgramRecognizer)
		{
			var recognizeProgramButton = new pmui.Button(pm.spriteUtils.getIconName("photo", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("photo", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("photo", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			var recognizeAlign = new ccui.RelativeLayoutParameter();
			recognizeAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
			recognizeAlign.setMargin(0, ProgramControlLayer.TOP_SEPARATOR, 0, 0);
			recognizeAlign.setRelativeName("recognize");
			recognizeAlign.setRelativeToWidgetName("info");
			recognizeProgramButton.setLayoutParameter(recognizeAlign);

			recognizeProgramButton.setName("recognize");
			recognizeProgramButton.addClickEventListener(this._buttonPressed.bind(this));
			this.addChild(recognizeProgramButton);

			this._recognizeResultButton = new RecognizeResultButton({});

			var recognizeResultAlign = new ccui.RelativeLayoutParameter();
			recognizeResultAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
			recognizeResultAlign.setMargin(0, 0, ProgramControlLayer.TOP_SEPARATOR, 0);
			recognizeResultAlign.setRelativeToWidgetName("recognize");
			this._recognizeResultButton.setLayoutParameter(recognizeResultAlign);

			this.addChild(this._recognizeResultButton);

			this._recognizeResultButton.setVisible(false);
		}

		this._startProgramButton = new pmui.Button( pm.spriteUtils.getIconName("start", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("start", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("start", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		var startAlign = new ccui.RelativeLayoutParameter();
		startAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		startAlign.setMargin(separator, 0, 0, 0);
		startAlign.setRelativeName("start");
		startAlign.setRelativeToWidgetName("info");
		this._startProgramButton.setLayoutParameter(startAlign);

		this._startProgramButton.setName("start");
		this._startProgramButton.addClickEventListener(this._buttonPressed.bind(this));
		this.addChild(this._startProgramButton);

		if(this._showStartAllButton)
		{
			this._startAllButton = new pmui.Button(pm.spriteUtils.getIconName("startAll", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("startAll", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("startAll", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			var startAllAlign = new ccui.RelativeLayoutParameter();
			startAllAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			startAllAlign.setMargin(ProgramControlLayer.BUTTON_SEPARATOR3, 0, 0, 0);
			startAllAlign.setRelativeName("startAll");
			startAllAlign.setRelativeToWidgetName("start");
			this._startAllButton.setLayoutParameter(startAllAlign);

			this._startAllButton.setName("startAll");
			this._startAllButton.addClickEventListener(this._buttonPressed.bind(this));
			this._startAllButton.setEnabled(this._showStartAllButton);
			this.addChild(this._startAllButton);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.START_ALL_PROGRAM, this._startAllButton);
		}

		this._makeStepButton = new pmui.Button( pm.spriteUtils.getIconName("makeStep", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("makeStep", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("makeStep", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		var makeStepAlign = new ccui.RelativeLayoutParameter();
		makeStepAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		makeStepAlign.setMargin(ProgramControlLayer.BUTTON_SEPARATOR3, 0, 0, 0);
		makeStepAlign.setRelativeName("makeStep");
		makeStepAlign.setRelativeToWidgetName(relativeStartName);
		this._makeStepButton.setLayoutParameter(makeStepAlign);

		this._makeStepButton.setName("makeStep");
		this._makeStepButton.addClickEventListener(this._buttonPressed.bind(this));
		this.addChild(this._makeStepButton);

		this._restartProgramButton = new pmui.Button(pm.spriteUtils.getIconName("restart", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("restart", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("restart", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var restartAlign = new ccui.RelativeLayoutParameter();
		restartAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		restartAlign.setMargin(ProgramControlLayer.BUTTON_SEPARATOR3, 0, 0, 0);
		restartAlign.setRelativeName("restart");
		restartAlign.setRelativeToWidgetName("makeStep");
		this._restartProgramButton.setLayoutParameter(restartAlign);

		this._restartProgramButton.setName("restart");
		this._restartProgramButton.addClickEventListener(this._buttonPressed.bind(this));
		this.addChild(this._restartProgramButton);

		this._velocity = this._getVelocity();

		var texture = this._getSpeedButtonTexture();

		this._changeSpeedButton = new pmui.Button(pm.spriteUtils.getIconName(texture, pm.NORMAL_STATE),
			pm.spriteUtils.getIconName(texture, pm.SELECTED_STATE),
			pm.spriteUtils.getIconName(texture, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (this._isTurboEnabled())
		{
			if (turboMode)
			{
				if(pm.settings.getAnimationSpeed() === pm.MIN_ANIMATION_SPEED)
					pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED - 0.01);

				this._changeSpeedButton.setBright(false);
			}
			else
			{
				pm.settings.setAnimationSpeed(this._getSpeed());
			}
		}

		this._changeSpeedButton.addClickEventListener(this._changeSpeed.bind(this));

		var speedAlign = new ccui.RelativeLayoutParameter();
		speedAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		speedAlign.setMargin(ProgramControlLayer.BUTTON_SEPARATOR3, 0, 0, 0);
		speedAlign.setRelativeName("speed");
		speedAlign.setRelativeToWidgetName("restart");
		this._changeSpeedButton.setLayoutParameter(speedAlign);

		this.addChild(this._changeSpeedButton);

		if (turboMode)
		{
			var state = this._getTurboButtonState();

			this._turboButton = new pmui.Button(pm.spriteUtils.getIconName("speedMax", state),
				pm.spriteUtils.getIconName("speedMax", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("speedMax", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._turboButton.addClickEventListener(this._turnMaxSpeed.bind(this));

			var turboAlign = new ccui.RelativeLayoutParameter();
			turboAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			turboAlign.setMargin(ProgramControlLayer.BUTTON_SEPARATOR3, 0, 0, 0);
			turboAlign.setRelativeName("turbo");
			turboAlign.setRelativeToWidgetName("speed");
			this._turboButton.setLayoutParameter(turboAlign);

			this.addChild(this._turboButton);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.MAX_SPEED, this._turboButton);
		}

		this._settingsButton = new pmui.Button( pm.spriteUtils.getIconName("settings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("settings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("settings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE );

		if (screenBounds.safeAreas.right)
			margin += screenBounds.right / 2;

		var optionsAlign = new ccui.RelativeLayoutParameter();
		optionsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		optionsAlign.setMargin(0, ProgramControlLayer.VERTICAL_MARGIN, margin, 0);
		optionsAlign.setRelativeToWidgetName(relativeSpeedName);
		this._settingsButton.setLayoutParameter(optionsAlign);

		this._settingsButton.setName("settings");
		this._settingsButton.addClickEventListener(this._buttonPressed.bind(this));
		this.addChild(this._settingsButton);

		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.START_PROGRAM, this._startProgramButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.RESTART_PROGRAM, this._restartProgramButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.MAKE_STEP, this._makeStepButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.GLOBAL_HINT, this._infoButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.SETTINGS, this._settingsButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.CHANGE_SPEED, this._changeSpeedButton);
	},

	_findElementByTouch: function(touch)
	{
		if(!this._recognizeResultButton)
			return null;

		var localTouch = this.convertTouchToNodeSpace(touch);
		var recognizeBB = this._recognizeResultButton.getBoundingBox();

		if(cc.rectContainsPoint(recognizeBB, localTouch))
			return this._recognizeResultButton;

		return null;
	},

	_cloneElement: function(element)
	{
		return this._recognizeResultButton.cloneImage();
	},

	_dndEventCallback: function(element, eventType, touch)
	{
		if(eventType === pmui.DragAndDropLayout.Event.CLICKED)
			element.select();
	},

	_buttonPressed: function(sender)
	{
		switch(sender.getName())
		{
			case "start":
				// if(this._isNetGame)
				//     pm.sendCustomEvent(pm.TEST_CURRENT_ROBOT_EVENT_STR);
				// else
				pm.sendCustomEvent(pm.PROGRAM_START_EVENT_STR);

				this._startProgramButton.setEnabled(false);

				if (this._startAllButton)
					this._startAllButton.setEnabled(false);

				break;
			case "restart":
				pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
				this._startProgramButton.setEnabled(true);
				break;
			case "makeStep":
				pm.sendCustomEvent(pm.PROGRAM_MAKE_STEP_EVENT_STR);
				this._startProgramButton.setEnabled(true);

				if (this._startAllButton)
					this._startAllButton.setEnabled(false);

				break;
			case "startAll":
				pm.sendCustomEvent(pm.PROGRAM_TEST_ALL_MAPS_EVENT_STR);
				this._startProgramButton.setEnabled(false);
				this._startAllButton.setEnabled(false);
				this._makeStepButton.setEnabled(false);
				break;
			case "info":
				pm.sendCustomEvent(pm.SHOW_INFO);
				break;
			case "settings":
				if (cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
					return;

				var settingsLayer = new SettingsLayer(this, this._closeOptions, false, true);
				cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
				break;
			case "recognize":
				pm.sendCustomEvent(pm.RECOGNIZE_PROGRAM);
				break;
		}
	},

	_getVelocity: function()
	{
		var animSpeed = pm.settings.getAnimationSpeed();
		if (animSpeed > pm.MIN_ANIMATION_SPEED && animSpeed <= pm.SMALL_ANIMATION_SPEED)
			return this.VELOCITY.Fast;
		else if (animSpeed > pm.SMALL_ANIMATION_SPEED && animSpeed <= pm.MEDIUM_ANIMATION_SPEED)
			return this.VELOCITY.Medium;
		else if (animSpeed <= pm.MAX_ANIMATION_SPEED)
			return this.VELOCITY.Low;
	},

	_getSpeedButtonTexture: function()
	{
		switch (this._velocity)
		{
			case this.VELOCITY.Low:
				return "speedFirst";
			case this.VELOCITY.Medium:
				return "speedSecond";
			case this.VELOCITY.Fast:
				return "speedThird";
		}
	},

	_getTurboButtonState: function()
	{
		return this._isTurboEnabled() ? pm.SELECTED_STATE : pm.NORMAL_STATE;
	},

	_getSpeed: function()
	{
		switch (this._velocity)
		{
			case this.VELOCITY.Low:
				return pm.MAX_ANIMATION_SPEED;
			case this.VELOCITY.Medium:
				return pm.MEDIUM_ANIMATION_SPEED;
			case this.VELOCITY.Fast:
				return pm.SMALL_ANIMATION_SPEED;
		}
	},

	_changeSpeed: function()
	{
		if (this._isTurboEnabled())
		{
			this._turnOffMaxSpeed();
			return;
		}

		if (this._velocity !== this.VELOCITY.Fast)
			this._velocity++;
		else
			this._velocity = this.VELOCITY.Low;

		var texture = this._getSpeedButtonTexture();

		this._changeSpeedButton.loadTextures(pm.spriteUtils.getIconName(texture, pm.NORMAL_STATE),
			pm.spriteUtils.getIconName(texture, pm.SELECTED_STATE),
			pm.spriteUtils.getIconName(texture, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		pm.settings.setAnimationSpeed(this._getSpeed());
	},

	_turnMaxSpeed: function()
	{
		if (this._isTurboEnabled())
		{
			this._turnOffMaxSpeed();
		}
		else
		{
			this._turboButton.loadTextureNormal(pm.spriteUtils.getIconName("speedMax", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);

			if (this.getParent().isControlled())
				pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED);
			else
				pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED - 0.01);

			this._changeSpeedButton.setBright(false);
		}
	},

	_turnOffMaxSpeed: function()
	{
		if (this._turboButton)
			this._turboButton.loadTextureNormal(pm.spriteUtils.getIconName("speedMax", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);

		pm.settings.setAnimationSpeed(this._getSpeed());
		this._changeSpeedButton.setBright(true);
	},

	_isTurboEnabled: function()
	{
		return pm.settings.getAnimationSpeed() <= pm.MIN_ANIMATION_SPEED;
	},

	_closeOptions: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	},

	/**
     * Sets buttons enabled.
     * @param {Boolean} flag
     */
	setButtonsEnabled: function(flag)
	{
		this._startProgramButton.setEnabled(flag);
		this._restartProgramButton.setEnabled(flag);
		this._makeStepButton.setEnabled(flag);

		if (flag === true && this._isTurboEnabled())
			this._changeSpeedButton.setBright(false);

		if(this._showStartAllButton)
			this._startAllButton.setEnabled(flag);

		if(this._recognizeResultButton)
			this._recognizeResultButton.setEnabled(flag);
	},

	showRecognizeResultButton: function(predictions)
	{
		if(this._recognizeResultButton)
		{
			this._recognizeResultButton.setVisible(true);
			this._recognizeResultButton.value = predictions;
		}
	},

	hideRecognizeResultButton: function()
	{
		if(this._recognizeResultButton)
			this._recognizeResultButton.setVisible(false);
	},

	VELOCITY: {
		Low: 0,
		Medium: 1,
		Fast: 2
	}
});

/**
 * Separators for buttons in {@link ProgramControlLayer}.
 * @const
 * @default
 * @type {Number}
 */
ProgramControlLayer.BUTTON_SEPARATOR1 = 41.8;
ProgramControlLayer.BUTTON_SEPARATOR2 = 19.0;
ProgramControlLayer.BUTTON_SEPARATOR3 = 0.5;
ProgramControlLayer.TOP_SEPARATOR = 18;
ProgramControlLayer.HORIZONTAL_MARGIN1 = 1;
ProgramControlLayer.HORIZONTAL_MARGIN2 = 5;
ProgramControlLayer.VERTICAL_MARGIN = 3;
ProgramControlLayer.BORDER_X = 76;
ProgramControlLayer.BORDER_Y = 11;
ProgramControlLayer.HEIGHT = 134;

