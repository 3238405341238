/**
 * @class This class processes and executes functions for robots.
 * @extends cc.Class
 * @property {pm.RobotManager.State} state
 */
pm.RobotManager = cc.Class.extend(/** @lends pm.RobotManager# */{

	_level: null,
	/**
     * State of robot manager.
     * @type {pm.RobotManager.State|Number}
     */
	_state: 0,
	/**
     * If manager controlled by {@link MethodStackLayer}
     * @type {Boolean}
     */
	_controlled: false,

	_testAllLevelMaps: false,
	_testAllRobotPatterns: false,
	_testCurrentRobotPattern: false,
	_testCurrentRobot: false,
	_startMapIndex: -1,
	_currentMapIndex: -1,
	_startPatternIndex: -1,

	_programs: {},

	_forcedList: [],
	_executedStepForceInstrCount: [],

	states: [],

	_revertCount: 0,

	_loopCount: 0,

	ctor: function()
	{
		this._programs = {};
		this._forcedList = [];
		this._controlled = false;

		this.states = [];

		this._revertCount = 0;

		if(!CORE_BUILD)
		{
			pm.registerCustomEventListener(pm.PROGRAM_RESTART_EVENT_STR, function ()
			{
				this._controlled = false;

				this.state = pm.RobotManager.State.Paused;
				this.clear();
			}.bind(this), 1);

			pm.registerCustomEventListener(pm.RESET_LEVEL_EVENT_STR, function ()
			{
				this._controlled = false;

				this.state = pm.RobotManager.State.Paused;
				this.clear();
			}.bind(this), 1);

			pm.registerCustomEventListener(pm.PROGRAM_START_EVENT_STR, function ()
			{
				if (this.state !== pm.RobotManager.State.PausedByStep && this.state !== pm.RobotManager.State.SteppedWorking)
				{
					if (!this._level)
						return;

					this._controlled = false;

					this._currentMapIndex = this._level.getActiveMapIndex();
					this.clear();
					this._compilePrograms();

					if (this._level.checkAllPatterns)
						this._testCurrentRobotPattern = true;
				}

				this.state = pm.RobotManager.State.Working;
			}.bind(this), 1);

			pm.registerCustomEventListener(pm.PROGRAM_MAKE_STEP_EVENT_STR, function ()
			{
				switch(this._state)
				{
					case pm.RobotManager.State.Paused:
						if (!this._level)
							return;

						this._controlled = false;

						this._currentMapIndex = this._level.getActiveMapIndex();
						this.clear();
						this._compilePrograms();
						this.state = pm.RobotManager.State.SteppedWorking;
						break;

					case pm.RobotManager.State.PausedByStep:
					case pm.RobotManager.State.Working:
						this.state = pm.RobotManager.State.RequestSteppedWorking;
						break;
				}

			}.bind(this), 1);

			pm.registerCustomEventListener(pm.PROGRAM_TEST_ALL_MAPS_EVENT_STR, function ()
			{
				if (this.state === pm.RobotManager.State.Paused)
				{
					if (!this._level)
						return;

					this._controlled = false;

					this.clear();
					this._compilePrograms();

					if (this._level.checkAllPatterns)
					{
						this._testAllRobotPatterns = true;
						this._startPatternIndex = this._level.getCurrentRobotProgramInfo().currentIndex;
					}

					this._testAllLevelMaps = true;
					this._startMapIndex = this._level.maps.indexOf(this._level.activeMap);
					this._currentMapIndex = this._startMapIndex;

					this.state = pm.RobotManager.State.Working;
				}

			}.bind(this), 1);

			pm.registerCustomEventListener(pm.TEST_CURRENT_ROBOT_EVENT_STR, function ()
			{
				if (this.state !== pm.RobotManager.State.PausedByStep && this.state !== pm.RobotManager.State.SteppedWorking)
				{
					if (!this._level)
						return;

					this.clear();
					this._compilePrograms();

					this._testCurrentRobot = true;
					this._currentMapIndex = this._level.getActiveMapIndex();
				}

				this.state = pm.RobotManager.State.Working;
			}.bind(this), 1);
		}
	},
	/**
	 * Inits robot manager.
	 */
	init: function()
	{
		if(!CORE_BUILD)
			cc.director.getScheduler().scheduleCallbackForTarget(this, this._loop, 0.001, cc.REPEAT_FOREVER, 0, true);
	},

	/**
	 * Sets state of pm.RobotManager
	 * @param {pm.RobotManager.State} state
	 */
	setState: function(state)
	{
		if(!CORE_BUILD)
		{
			if (state === pm.RobotManager.State.Paused)
				cc.director.getScheduler().pauseTarget(this);
			else if (this._state === pm.RobotManager.State.Paused)
				cc.director.getScheduler().resumeTarget(this);
		}

		this._state = state;
	},

	/**
	 * Returns state of robot manager
	 * @returns {pm.RobotManager.State}
	 */
	getState: function()
	{
		return this._state;
	},

	/**
	 * Tests all maps in syc call
	 */
	testAllMapsSync: function()
	{
		if(!this._level)
			return;

		this.clear();
		this._compilePrograms();

		this._testAllLevelMaps = true;
		this._startMapIndex = this._level.maps.indexOf(this._level.activeMap);
		this._currentMapIndex = this._startMapIndex;

		this._state = pm.RobotManager.State.Working;

		this._loopCount = 0;

		while(this._state !== pm.RobotManager.State.Paused)
		{
			++this._loopCount;

			if(this._loopCount > pm.RobotManager.MAX_SYNC_LOOP_COUNT)
				break;

			this._loop();
		}
	},

	/**
	 * Tests all maps in asyc call
	 */
	testAllMaps: function()
	{
		if(!this._level)
			return;

		this.clear();
		this._compilePrograms();

		this._testAllLevelMaps = true;
		this._startMapIndex = this._level.maps.indexOf(this._level.activeMap);
		this._currentMapIndex = this._startMapIndex;

		this.state = pm.RobotManager.State.Working;
	},

	_updateVisualState: function()
	{
		if(CORE_BUILD)
			return;

		if(pm.settings.isAnimationDisabled())
		{
			var isBrokenRobot = false;

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				if (this._level.robots[i].isBroken())
				{
					isBrokenRobot = true;
					break;
				}
			}

			pm.sendCustomEvent(pm.MAP_CHANGED_EVENT_STR, {
				index: this._level.getActiveMapIndex(),
				sendEvent: true,
				rebaseRobots: false
			});

			for (var i = 0; i < this._level.maps.length; ++i)
			{
				var map = this._level.maps[i];

				for (var j = 0; j < map.objects.length; ++j)
				{
					var object = map.objects[j];

					if (object instanceof pm.Object)
						object.updateSprite(isBrokenRobot);
				}
			}

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				var robot = this._level.robots[i];
				if (robot instanceof pm.PlayerRobot)
					robot.updateSprite(isBrokenRobot);
			}

			for (var i = 0; i < this._level.globalRobots.length; ++i)
				this._level.globalRobots[i].updateSprite();
		}
	},

	/**
     * Loop of robot manager. Processes 1 command per loop for each robot.
     */
	_loop: function()
	{
		if(this.state === pm.RobotManager.State.Paused || this.state === pm.RobotManager.State.PausedByStep)
			return;

		var baseRobot = this._level.getCurrentRobot();

		if(!this._controlled && this._level.robotsEndedWork())
		{
			var completeInfo = this._level.getTaskCompleteInfoForMap(this._currentMapIndex);

			if(completeInfo.completed)
			{
				var isWin = true;

				if (!CORE_BUILD && !this._testAllRobotPatterns && !this._testCurrentRobotPattern)
				{
					pm.userCache.setScore(this._level, this._currentMapIndex, this._level.getCurrentRobot().stepCount);
					pm.sendCustomEvent(pm.MAP_COMPLETED_STR);
				}

				var programInfo = this._level.getCurrentRobotProgramInfo();

				if (this._testCurrentRobotPattern)
				{
					if(!CORE_BUILD)
						pm.sendCustomEvent(pm.PATTERN_COMPLETED_STR);

					var stepCount = 0;

					for (var i = 0; i < programInfo.programDataArray.length; ++i)
					{
						if (this._level.maps[this._currentMapIndex].patternsCompleted.hasOwnProperty(i))
						{
							stepCount += this._level.maps[this._currentMapIndex].patternsCompleted[i];
						}
						else
						{
							isWin = false;
							break;
						}
					}

					if (!isWin)
					{
						this._updateVisualState();
						this.state = pm.RobotManager.State.Paused;
					}

					if (!CORE_BUILD && isWin)
					{
						pm.userCache.setScore(this._level, this._currentMapIndex, stepCount);
						pm.sendCustomEvent(pm.MAP_COMPLETED_STR);
					}
				}

				if (this._testAllRobotPatterns)
				{
					var stepCount = baseRobot.stepCount;

					if ((programInfo.currentIndex + 1) % programInfo.programDataArray.length !== this._startPatternIndex
						|| (this._currentMapIndex + 1) % this._level.maps.length !== this._startMapIndex)
					{
						programInfo.currentIndex = (programInfo.currentIndex + 1) % programInfo.programDataArray.length;

						if (!CORE_BUILD)
							pm.sendCustomEvent(pm.PATTERN_CHANGED_EVENT_STR, {index: programInfo.currentIndex});

						if (programInfo.currentIndex !== this._startPatternIndex)
							isWin = false;

						this._level.maps[this._currentMapIndex].clean();

						baseRobot.reset();

						for(var i = 0; i < this._level.globalRobots.length; ++i)
							this._level.globalRobots[i].reset();

						if (this._programs[baseRobot.id])
						{
							this._programs[baseRobot.id].paused = false;
							this._programs[baseRobot.id].ended = false;
						}

						pm.variableList.clearList();

						this._compilePrograms();
					}

					baseRobot.stepCount = stepCount;

					if (!CORE_BUILD && isWin)
					{
						pm.userCache.setScore(this._level, this._currentMapIndex, baseRobot.stepCount);
						pm.sendCustomEvent(pm.MAP_COMPLETED_STR);
					}
				}

				if (this._testAllLevelMaps
					&& (!this._testAllRobotPatterns || programInfo.currentIndex === this._startPatternIndex))
				{
					++this._currentMapIndex;
					this._currentMapIndex = this._currentMapIndex % this._level.maps.length;

					if (this._currentMapIndex !== this._startMapIndex)
					{
						for (var i in this._programs)
						{
							this._programs[i].instrPosition = this._programs[i].labels[pm.CMD_MAIN];
							this._programs[i].ended = false;
						}

						for (var i = 0; i < this._level.robots.length; ++i)
							this._level.robots[i].reset();

						for (var i = 0; i < this._level.globalRobots.length; ++i)
							this._level.globalRobots[i].reset();

						if (!CORE_BUILD && !pm.settings.isAnimationDisabled())
						{
							pm.sendCustomEvent(pm.MAP_CHANGED_EVENT_STR, {
								index: this._currentMapIndex,
								sendEvent: true,
								rebaseRobots: true
							});
						}
						else
						{
							this._level.setActiveMap(this._level.maps[this._currentMapIndex]);
						}

						isWin = false;
					}
				}

				if (isWin)
				{
					this._updateVisualState();
					this._level.getCurrentRobot().win();

					if (!CORE_BUILD)
					{
						pm.sendCustomEvent(pm.ROBOT_WIN_EVENT_STR);
						pm.networkUtils.isRobotWin = true;
					}
					else
					{
						this.isWin = true;
					}

					this.state = pm.RobotManager.State.Paused;
				}
			}
			else
			{
				var notCompletedObjects = completeInfo.notCompletedObjects;

				for (var i = 0; i < notCompletedObjects.length; ++i)
					notCompletedObjects[i].setNotCompleted(true);

				this._updateVisualState();

				if(!CORE_BUILD)
				{
					pm.sendCustomEvent(pm.ROBOT_FAILURE_EVENT_STR);
					pm.networkUtils.isRobotWin = false;
				}
				else
				{
					console.log("Robot Failure");
					console.log(JSON.stringify(notCompletedObjects));
				}

				this.state = pm.RobotManager.State.Paused;
				return;
			}

			return;
		}

		if(this._testCurrentRobot && this._level.getCurrentRobot().isEndedWork())
		{
			if(!CORE_BUILD)
            	pm.sendCustomEvent(pm.CURRENT_ROBOT_TESTED_EVENT_STR);

			this.state = pm.RobotManager.State.Paused;
			return;
		}

		if(!this._controlled)
		{
			var programPaused = true;

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				var program = this._programs[this._level.robots[i].id];

				if (!program.ended && !program.paused)
				{
					programPaused = false;
					break;
				}
			}

			if (programPaused)
			{
				if(!this._level.robotsPlayingAnimation() && !this._level.objectsPlayingAnimation())
				{
					if(this._state === pm.RobotManager.State.SteppedWorking)
					{
						this._state = pm.RobotManager.State.PausedByStep;
						return;
					}

					if(this._state === pm.RobotManager.State.RequestSteppedWorking)
						this._state = pm.RobotManager.State.SteppedWorking;

					for (var i = 0; i < this._level.robots.length; ++i)
						this._programs[this._level.robots[i].id].paused = false;
				}
				else
				{
					return;
				}
			}

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				var robot = this._level.robots[i];

				if(this._testCurrentRobot && robot.id !== this._level.getCurrentRobot().id)
                	continue;

				if (this._programs[robot.id])
				{
					if (!this._programs[robot.id].paused)
						this._processRobotProgram(robot, this._programs[robot.id]);

					if (!robot.isEndedWork() && this._programs[robot.id].ended)
					{
						robot.setStateFlag(pm.RobotState.EndedWork);
						continue;
					}
				}
			}
		}
		else
		if (!this._level.robotsPlayingAnimation() && !this._level.objectsPlayingAnimation())
		{
			if(this._state !== pm.RobotManager.State.RevertingForceInstructions)
			{
				this._processForceCommands(baseRobot, this._programs[baseRobot.id]);

				if (this._state === pm.RobotManager.State.ProcessingForceFunction)
					this._processRobotProgram(baseRobot, this._programs[baseRobot.id]);
			}
			else
			{
				if(this._revertCount === 0)
				{
					this._state = pm.RobotManager.State.Paused;
					return;
				}

				if (this.states.length > 0)
				{
					this._level.setState(this.states[this.states.length-1]);
					this.states.pop();
				}

				--this._revertCount;
			}
		}

		if(this._state !== pm.RobotManager.State.RevertingForceInstructions && this._level.robotsLoose())
		{
			this._updateVisualState();

			for (var r = 0; r < this._level.robots.length; ++r)
			{
				var curRobot = this._level.robots[r];

				if (curRobot.isBroken())
				{
					for (var u = 0; u < this._level.robots.length; ++u)
						this._level.robots[u].destroy();

					for (var u = 0; u < this._level.activeMap.objects.length; ++u)
						this._level.activeMap.objects[u].stopAnimation();

					break;
				}
			}

			if(!CORE_BUILD)
			{
				if(!this._controlled)
					pm.sendCustomEvent(pm.ROBOT_LOOSE_EVENT_STR);
			}
			else
			{
				console.log("Robot Broken");
			}

			this.state = pm.RobotManager.State.Paused;
		}
	},

	_processForceCommands: function(baseRobot, program)
	{
		if(this._state === pm.RobotManager.State.ProcessingForceFunction)
		{
			if(!program.ended)
				return;
			else
				this.state = pm.RobotManager.State.Working;
		}

		if(this._forcedList.length === 0)
		{
			this.state = pm.RobotManager.State.Paused;
			pm.sendCustomEvent(pm.FORCED_COMMANDS_ENDED_EVENTS_STR);

			return;
		}

		var forcedInstr = this._forcedList[0];

		if(forcedInstr.command !== pm.Instruction.EXECUTE)
		{
			this._forcedList.shift();
			return;
		}

		if(!forcedInstr.data.isNative)
		{
			program.setForceInstruction(forcedInstr.data.methodID);
			this.state = pm.RobotManager.State.ProcessingForceFunction;

			this._executedStepForceInstrCount.push(0);
		}
		else
		{
			if(this._level.robotsPlayingAnimation())
				return;

			this.states.push(this._level.getState());

			forcedInstr.data.robot.executeNativeFunction(forcedInstr.data.methodID, forcedInstr.data.funcArgs);

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				var robot = this._level.robots[i];
				this.states[this.states.length - 1].robotStates[i].animation = robot.getLastAnimation();
			}

			this._executedStepForceInstrCount.push(1);
		}

		this._forcedList.shift();
	},

	_compilePrograms: function()
	{
		for(var i = 0; i < this._level.robots.length; ++i)
		{
			var robot = this._level.robots[i];

			this._programs[robot.id] = pm.programUtils.compileRobotProgram(robot, this._level);
		}
	},

	/**
	 * Sets level of robot manager
	 * @param {pm.AbstractLevel} level
	 */
	setLevel: function(level)
	{
		this._level = level;

		if (!this._level)
			return;

		this._programs = {};
		this._forcedList.length = 0;
		this._executedStepForceInstrCount.length = 0;

		this.state = pm.RobotManager.State.Paused;
	},

	/**
     * Add method to be forced processed by base robot of level.
     * @param {String} methodID Method id to be executed.
     * @param {Boolean} ignoreRobotState Ignore robot broken state for processing.Robot state will be changed to normal.
     * @param {Number} [n=1] Number of method executing.
     */
	addForceCommand: function(methodID, n)
	{
		if(!this.canAddForceCommand())
			return;

		//this._level.getCurrentRobot().clearStateFlag(pm.RobotState.Broken);

		if(n === undefined)
			n = 1;

		var nativeFunc = pm.programUtils.searchNativeRobotFunction(this._level.getCurrentRobot(), methodID);

		if(!nativeFunc)
		{
			nativeFunc = pm.programUtils.searchGlobalNativeFunction(this._level, methodID);
		}

		for(var i = 0; i < n; ++i)
		{
			var instr = new pm.Instruction(pm.Instruction.EXECUTE);

			instr.data.isNative = false;

			if(nativeFunc)
			{
				instr.data.robot = nativeFunc.robot;
				instr.data.isNative = true;
			}

			instr.data.methodID = methodID;
			this._forcedList.push(instr);
		}

		if(n > 0 && this.state !== pm.RobotManager.State.ProcessingForceFunction)
			this.state = pm.RobotManager.State.Working;
	},

	/**
	 * Reverts last n executed instructions. <br/> If something is executing then nothing happens.
	 * @param {String} [preMethodID] Method to be executed before revert
	 * @param {Number} [n=1] Number of instructions to revert
     */
	revertForceCommands: function(preMethodID, n)
	{
		if (this._state !== pm.RobotManager.State.Paused || this._revertCount > 0)
			return;

		if (n === undefined)
			n = 1;

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			var preMethod = pm.programUtils.searchNativeRobotFunction(this._level.robots[i], preMethodID);

			if (preMethod)
			{
				var preInstr = new pm.Instruction(pm.Instruction.EXECUTE);

				preInstr.data.robot = preMethod.robot;
				preInstr.data.isNative = true;

				preInstr.data.methodID = preMethodID;
				preInstr.data.robot.executeNativeFunction(preInstr.data.methodID, preInstr.data.funcArgs, true);
			}
		}

		for (var i = 0 ; i < n; ++i)
		{
			this._revertCount += this._executedStepForceInstrCount[this._executedStepForceInstrCount.length - 1];
			this._executedStepForceInstrCount.pop();
		}

		var baseRobot = this._level.getCurrentRobot();

		if (this._programs[baseRobot.id].stack.length !== 0)
			this._programs[baseRobot.id].stack = [];

		if(!CORE_BUILD)
		{
			pm.sendCustomEvent(pm.REM_LAST_HIGHLIGHT_EVENT_STR, {robotIndex: baseRobot.id});
			pm.sendCustomEvent(pm.RELOAD_REPEATERS_EVENT_STR, {robotIndex: baseRobot.id});
		}

		this.state = pm.RobotManager.State.RevertingForceInstructions;
	},

	/**
     * Sets controlled mode of manager.
     * @param {Boolean} flag
     */
	setControlledMode: function(flag)
	{
		if(flag)
		{
			var robot = this._level.getCurrentRobot();
			this._programs[robot.id] = pm.programUtils.compileRobotProgram(robot, this._level);
		}
		else
		{
			this.state = pm.RobotManager.State.Paused;
		}

		this._controlled = flag;
	},

	/**
     * Returns 1 if manager can process force program.
     * @returns {Boolean}
     */
	canAddForceCommand: function()
	{
		return this._controlled && !this._level.getCurrentRobot().isBroken();
	},

	/**
     * Clears internal data of robot manager.
     */
	clear: function()
	{
		pm.variableList.clearList();
		this._forcedList.length = 0;
		this.length = 0;
		this._testAllLevelMaps = false;
		this._testAllRobotPatterns = false;
		this._testCurrentRobotPattern = false;
		this._testCurrentRobot = false;

		for(var i = 0; i < this._level.robots.length; ++i)
		{
			var robot = this._level.robots[i];
			robot.reset();
		}

		for(var i = 0; i < this._level.globalRobots.length; ++i)
		{
			var robot = this._level.globalRobots[i];
			robot.reset();
		}

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			if (this._programs[this._level.robots[i].id])
			{
				this._programs[this._level.robots[i].id].paused = false;
				this._programs[this._level.robots[i].id].ended = false;
			}
		}

		this.states = [];
		this._revertCount = 0;
	},

	clearPausedFlags: function ()
	{
		var programPaused = true;

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			var program = this._programs[this._level.robots[i].id];

			if (!program.ended && !program.paused)
			{
				programPaused = false;
				break;
			}
		}

		if (programPaused)
		{
			for (var i = 0; i < this._level.robots.length; ++i)
			{
				if (this._programs[this._level.robots[i].id])
					this._programs[this._level.robots[i].id].paused = false;
			}
		}
	}
});

var _p = pm.RobotManager.prototype;

/** @expose */
_p.state;
cc.defineGetterSetter(_p, "state", _p.getState, _p.setState);

/**
 * Maximum loop count in sync working
 * @type {number}
 */
pm.RobotManager.MAX_SYNC_LOOP_COUNT = 1000;

/**
 * States of {@link pm.RobotManager}.
 * @readonly
 * @enum {Number}
 */
pm.RobotManager.State = {
	Paused: 0,
	ProcessingForceFunction: 1,
	RevertingForceInstructions: 2,
	RequestSteppedWorking: 3,
	SteppedWorking: 4,
	PausedByStep: 5,
	Working: 6
};
