/**
 * Created by Antony Orlovsky on 29.04.18.
 */

/**
 * @class Layer for changing settings.
 * @extends pmui.OvalLayout
 */
var SettingsLayer = pmui.OvalLayout.extend(/** @lends SettingsLayer# */{
	_target: null,
	_cancelCallback: null,

	_turboModeButton: null,
	_clearProgramButton: null,
	_showCommonSettings: null,
	_showLevelSettings: null,

	ctor: function(target, cancelCallback, showCommonSettings, showLevelSettings)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		if (showCommonSettings === undefined)
			showCommonSettings = true;

		if (showLevelSettings === undefined)
			showLevelSettings = false;

		this._showCommonSettings = showCommonSettings;
		this._showLevelSettings = showLevelSettings;

		this.setAnchorPoint(cc.p(0.5, 0.5));
		this.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2);

		this.setTouchEnabled(true);

		this._target = target;
		this._cancelCallback = cancelCallback;

		this._loadInterface();
	},

	_loadInterface: function()
	{
		var closeButton = new pmui.Button(pm.spriteUtils.getIconName("close", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("close", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("close", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		closeButton.addClickEventListener(this._cancelCallback.bind(this._target));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -closeButton.height / 4, -closeButton.width / 4, 0);
		closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(closeButton);

		var musicLabel = new ccui.Text(LocalizedString("BackgroundVolume"), pm.settings.fontBoldName, 50);
		var height = musicLabel.height + SettingsLayer.SEPARATOR;

		var musicLabelAlign = new ccui.RelativeLayoutParameter();
		musicLabelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		musicLabelAlign.setMargin(SettingsLayer.LEFT_BORDER, SettingsLayer.TOP_BORDER + pmui.OvalLayout.STANDARD_BORDER_RADIUS, 0, 0);
		musicLabelAlign.setRelativeName("music");
		musicLabel.setLayoutParameter(musicLabelAlign);

		this.addChild(musicLabel);

		var effectsLabel = new ccui.Text(LocalizedString("EffectVolume"), pm.settings.fontBoldName, 50);
		height += effectsLabel.height + SettingsLayer.SEPARATOR;

		var effectsLabelAlign = new ccui.RelativeLayoutParameter();
		effectsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		effectsLabelAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
		effectsLabelAlign.setRelativeToWidgetName("music");
		effectsLabelAlign.setRelativeName("effects");
		effectsLabel.setLayoutParameter(effectsLabelAlign);

		this.addChild(effectsLabel);

		var musicSlider = new ccui.Slider();
		musicSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		musicSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		musicSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);

		musicSlider.addEventListener(this._volumeMusicChanged.bind(this));
		musicSlider.setPercent(100 * pm.settings.getBackgroundVolume());

		var musicSliderAlign = new ccui.RelativeLayoutParameter();
		musicSliderAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
		musicSliderAlign.setMargin(effectsLabel.width - musicLabel.width + SettingsLayer.LEFT_BORDER, 0, 0, musicLabel.height / 2);
		musicSliderAlign.setRelativeToWidgetName("music");
		musicSliderAlign.setRelativeName("musicSlider");
		musicSlider.setLayoutParameter(musicSliderAlign);

		this.addChild(musicSlider);

		var effectsSlider = new ccui.Slider();
		effectsSlider.loadBarTexture(pm.spriteUtils.getInterfaceElementName("sliderTrack"), ccui.Widget.PLIST_TEXTURE);
		effectsSlider.loadProgressBarTexture(pm.spriteUtils.getInterfaceElementName("sliderProgress"), ccui.Widget.PLIST_TEXTURE);
		effectsSlider.loadSlidBallTextureNormal(pm.spriteUtils.getInterfaceElementName("sliderThumb"), ccui.Widget.PLIST_TEXTURE);

		effectsSlider.addEventListener(this._volumeEffectsChanged.bind(this));

		effectsSlider.setPercent(100 * pm.settings.getEffectVolume());

		var effectsSliderAlign = new ccui.RelativeLayoutParameter();
		effectsSliderAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
		effectsSliderAlign.setMargin(SettingsLayer.LEFT_BORDER, 0, 0, effectsLabel.height / 2);
		effectsSliderAlign.setRelativeToWidgetName("effects");
		effectsSlider.setLayoutParameter(effectsSliderAlign);

		this.addChild(effectsSlider);

		var scaleLabel = new ccui.Text(LocalizedString("SCALE"), pm.settings.fontBoldName, 50);
		height += scaleLabel.height + SettingsLayer.SEPARATOR;

		var scaleLabelAlign = new ccui.RelativeLayoutParameter();
		scaleLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		scaleLabelAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
		scaleLabelAlign.setRelativeName("scale");
		scaleLabelAlign.setRelativeToWidgetName("effects");
		scaleLabel.setLayoutParameter(scaleLabelAlign);

		this.addChild(scaleLabel);

		pm.spriteUtils.initRobotTextures(pm.RepairLevelModule.Type);

		var scaleOutButton = new pmui.Button(pm.spriteUtils.getIconName("delete", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("delete", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("delete", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		scaleOutButton.addClickEventListener(this._scaleOut.bind(this));

		var scaleOutButtonAlign = new ccui.RelativeLayoutParameter();
		scaleOutButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		scaleOutButtonAlign.setRelativeToWidgetName("scale");
		scaleOutButtonAlign.setRelativeName("scaleOut");
		scaleOutButtonAlign.setMargin(SettingsLayer.LEFT_BORDER, 0, 0, 0);

		scaleOutButton.setLayoutParameter(scaleOutButtonAlign);

		this.addChild(scaleOutButton);

		var scaleText = pm.settings.getAppScale();
		var label = new ccui.Text(scaleText.toFixed(1), pm.settings.fontName, 20);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		labelAlign.setRelativeToWidgetName("scaleOut");
		labelAlign.setRelativeName("label");
		labelAlign.setMargin(5, 0, 0, 0);

		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		var scaleInButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);
		scaleInButton.addClickEventListener(this._scaleIn.bind(this));

		var scaleInButtonAlign = new ccui.RelativeLayoutParameter();
		scaleInButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		scaleInButtonAlign.setRelativeToWidgetName("label");
		scaleInButtonAlign.setRelativeName("scaleIn");
		scaleInButtonAlign.setMargin(5, 0, 0, 0);

		scaleInButton.setLayoutParameter(scaleInButtonAlign);

		this.addChild(scaleInButton);

		var relativeToWidgetName = "scale";

		if (this._showLevelSettings)
		{
			var turboLabel = new ccui.Text(LocalizedString("TURBO"), pm.settings.fontBoldName, 50);
			height += turboLabel.height + SettingsLayer.SEPARATOR;

			var turboLabelAlign = new ccui.RelativeLayoutParameter();
			turboLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			turboLabelAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
			turboLabelAlign.setRelativeName("turbo");
			turboLabelAlign.setRelativeToWidgetName(relativeToWidgetName);
			turboLabel.setLayoutParameter(turboLabelAlign);

			this.addChild(turboLabel);

			var state = this._getTurboButtonState();

			this._turboModeButton = new pmui.Button(pm.spriteUtils.getIconName("speedMax", state),
				pm.spriteUtils.getIconName("speedMax", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("speedMax", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._turboModeButton.addClickEventListener(this._changeTurboMode.bind(this));

			var turboButtonAlign = new ccui.RelativeLayoutParameter();
			turboButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
			turboButtonAlign.setMargin(SettingsLayer.LEFT_BORDER, 0, 0, 0);
			turboButtonAlign.setRelativeToWidgetName("turbo");
			this._turboModeButton.setLayoutParameter(turboButtonAlign);

			this.addChild(this._turboModeButton);

			var clearLabel = new ccui.Text(LocalizedString("CLEAR PROGRAM"), pm.settings.fontBoldName, 50);
			height += clearLabel.height + SettingsLayer.SEPARATOR;

			var clearLabelAlign = new ccui.RelativeLayoutParameter();
			clearLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			clearLabelAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
			clearLabelAlign.setRelativeName("clear");
			clearLabelAlign.setRelativeToWidgetName("turbo");
			clearLabel.setLayoutParameter(clearLabelAlign);

			this.addChild(clearLabel);

			relativeToWidgetName = "clear";

			state = this._getClearProgramButtonState();

			this._clearProgramButton = new pmui.Button(pm.spriteUtils.getIconName("clearSettings", state),
				pm.spriteUtils.getIconName("clearSettings", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("clearSettings", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._clearProgramButton.addClickEventListener(this._changeClearProgramButton.bind(this));

			var clearProgramButtonAlign = new ccui.RelativeLayoutParameter();
			clearProgramButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
			clearProgramButtonAlign.setMargin(SettingsLayer.LEFT_BORDER, 0, 0, 0);
			clearProgramButtonAlign.setRelativeToWidgetName("clear");
			this._clearProgramButton.setLayoutParameter(clearProgramButtonAlign);

			this.addChild(this._clearProgramButton);
		}

		var languageLabel = new ccui.Text(LocalizedString("LANGUAGE"), pm.settings.fontBoldName, 50);
		height += languageLabel.height + SettingsLayer.SEPARATOR;

		var languageLabelAlign = new ccui.RelativeLayoutParameter();
		languageLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		languageLabelAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
		languageLabelAlign.setRelativeName("language");
		languageLabelAlign.setRelativeToWidgetName(relativeToWidgetName);
		languageLabel.setLayoutParameter(languageLabelAlign);

		this.addChild(languageLabel);

		var languageLayer = new LanguageLayer();

		var languageLayerAlign = new ccui.RelativeLayoutParameter();
		languageLayerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		languageLayerAlign.setMargin(SettingsLayer.LEFT_BORDER, 0, 0, 0);
		languageLayerAlign.setRelativeToWidgetName("language");
		languageLayer.setLayoutParameter(languageLayerAlign);

		this.addChild(languageLayer);

		if(cc.sys.isNative && (pm.appConfig.useNetworkFeatures || pm.appConfig.useRobotFeature || pm.appConfig.useRecognizeFeature || pm.appConfig.useARFeature))
		{
			var commonSettingsButton = new ccui.Text(LocalizedString("SETTINGS"), pm.settings.fontBoldName, 50);
			commonSettingsButton.addClickEventListener(this._onCommonSettings.bind(this));
			commonSettingsButton.setTouchEnabled(true);
			height += commonSettingsButton.height + SettingsLayer.SEPARATOR;

			if (!this._showCommonSettings)
			{
				commonSettingsButton.setEnabled(false);
				commonSettingsButton.setTextColor(cc.color(174, 174, 174));
			}

			var commonSettingsButtonAlign = new ccui.RelativeLayoutParameter();
			commonSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			commonSettingsButtonAlign.setMargin(0, SettingsLayer.SEPARATOR, 0, 0);
			commonSettingsButtonAlign.setRelativeName("settings");
			commonSettingsButtonAlign.setRelativeToWidgetName("language");

			commonSettingsButton.setLayoutParameter(commonSettingsButtonAlign);

			this.addChild(commonSettingsButton);
		}

		height += SettingsLayer.SEPARATOR;

		this.setContentSizeWithBorder(SettingsLayer.WIDTH, height);

		var loadingLayer = new LoadingLayer(false);
		loadingLayer.setPosition((this.width - pm.settings.getScreenSize().width) / 2,
			(this.height - pm.settings.getScreenSize().height) / 2);

		this.addChild(loadingLayer, -100);
	},

	_getTurboButtonState: function()
	{
		return pm.settings.getUseTurboMode() ? pm.SELECTED_STATE : pm.NORMAL_STATE;
	},

	_changeTurboMode: function(flag)
	{
		if (pm.settings.getUseTurboMode())
		{
			this._turboModeButton.loadTextureNormal(pm.spriteUtils.getIconName("speedMax", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
			pm.settings.setUseTurboMode(false);
		}
		else
		{
			this._turboModeButton.loadTextureNormal(pm.spriteUtils.getIconName("speedMax", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
			pm.settings.setUseTurboMode(true);
		}

		pm.sendCustomEvent(pm.TURN_TURBO_MODE);
	},

	_getClearProgramButtonState: function()
	{
		return pm.settings.getUseClearProgramButton() ? pm.SELECTED_STATE : pm.NORMAL_STATE;
	},

	_changeClearProgramButton: function(flag)
	{
		if (pm.settings.getUseClearProgramButton())
		{
			this._clearProgramButton.loadTextureNormal(pm.spriteUtils.getIconName("clearSettings", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
			pm.settings.setUseClearProgramButton(false);
		}
		else
		{
			this._clearProgramButton.loadTextureNormal(pm.spriteUtils.getIconName("clearSettings", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
			pm.settings.setUseClearProgramButton(true);
		}

		pm.sendCustomEvent(pm.UPDATE_CLEAR_PROGRAM_BUTTON);
	},

	_onCommonSettings: function()
	{
		if(cc.director.getRunningScene().getChildByTag(CommonSettingsLayer.TAG))
			return;

		var settingsLayer = new CommonSettingsLayer(this, this._closeSettings);

		cc.director.getRunningScene().addChild(settingsLayer, 100, CommonSettingsLayer.TAG);
	},

	_scaleIn: function()
	{
		var scale = pm.settings.getAppScale();
		scale += 0.1;
		
		pm.settings.setAppScale(scale);

		cc.view.setDesignResolutionSize(
			cc.view.getFrameSize().width / pm.settings.getAppScale(),
			cc.view.getFrameSize().height / pm.settings.getAppScale(),
			cc.ResolutionPolicy.SHOW_ALL);

		pm.sendCustomEvent(pm.UPDATE_SCENE);
	},

	_scaleOut: function()
	{
		var scale = pm.settings.getAppScale();
		scale -= 0.1;

		if (scale < 0.3)
			return;

		pm.settings.setAppScale(scale);

		cc.view.setDesignResolutionSize(
			cc.view.getFrameSize().width / pm.settings.getAppScale(),
			cc.view.getFrameSize().height / pm.settings.getAppScale(),
			cc.ResolutionPolicy.SHOW_ALL);

		pm.sendCustomEvent(pm.UPDATE_SCENE);
	},

	_closeSettings: function()
	{
		cc.director.getRunningScene().removeChildByTag(CommonSettingsLayer.TAG);
	},

	_volumeMusicChanged: function(target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			pm.settings.setBackgroundVolume(target.getPercent() / 100);
			pm.audioUtils.setBackgroundMusicVolume(target.getPercent() / 100);
		}
	},

	_volumeEffectsChanged: function(target, eventType)
	{
		if(eventType === ccui.Slider.EVENT_PERCENT_CHANGED)
		{
			pm.settings.setEffectVolume(target.getPercent() / 100);
			pm.audioUtils.setEffectVolume(target.getPercent() / 100);
		}
	}
});

SettingsLayer.WIDTH = 500;
SettingsLayer.TAG = 1001;
SettingsLayer.SEPARATOR = 10;
SettingsLayer.LEFT_BORDER = 30;
SettingsLayer.TOP_BORDER = 18;
