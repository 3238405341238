/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * Painr map element types.
 * @enum {Number}
 * @readonly
 */
var DraftsmanMapElementType = {
	None: -1,
	Green: 0
};

/**
 * @class Elements for {@link pm.data.DraftsmanMap}
 * @implements pm.MapElement
 */
pm.data.DraftsmanMapElement = pm.MapElement2D.extend(/** @lends pm.data.DraftsmanMapElement# */{
	typeName: "DraftsmanMapElement",
	_elementNumber: null,

	ctor: function()
	{
		this._addNonEnumerableProps("_elementNumber");
		this._super();
	},

	updateElementNumber: function(pos)
	{
		if (this.sprite.getSprite().getChildByTag(pm.data.DraftsmanMapElement.ELEMENT_NUMBER_TAG))
		{
			this.sprite.getSprite().removeChildByTag(pm.data.DraftsmanMapElement.ELEMENT_NUMBER_TAG);
			this._elementNumber = null;
		}

		if (pos.x !== 0 && pos.y !== 0)
			return;

		this._elementNumber = new ccui.Text(pos.x + pos.y, pm.settings.fontName, pm.settings.fontSize);

		this._elementNumber.setPosition(cc.p(this.sprite.getSprite().getContentSize().width / 2, this.sprite.getSprite().getContentSize().height / 2));
		this._elementNumber.setAnchorPoint(cc.p(0.5, 0));

		this.sprite.getSprite().addChild(this._elementNumber, 10, pm.data.DraftsmanMapElement.ELEMENT_NUMBER_TAG);
	},

	getState: function ()
	{
		return this._type;
	},

	setState: function (state)
	{
		this.setType(state);
	}
});

pm.data.DraftsmanMapElement.START_ELEMENT_POS = "start_element_pos";
pm.data.DraftsmanMapElement.ELEMENT_NUMBER_TAG = 1552;
