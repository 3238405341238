/**
 * Created by Antony Orlovsky on 19.07.19.
 */
/**
 * Animation type for paint robot.
 * @enum {String}
 * @readonly
 */
var GrasshopperRobotAnimation = {Paint: "paint"};

/**
 * @class 2D Sprite for {@link pm.data.GrasshopperRobot}
 * @implements pm.IsoRobotSprite
 */
var GrasshopperRobotSprite = pm.IsoRobotSprite.extend(/** @lends GrasshopperRobotSprite# */{
	ctor: function()
	{
		cc.animationCache.addAnimations("Robot/kumir_robot-animations.plist");

		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir-indicate.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot-broken.plist");
		pm.spriteUtils.registerTempSpriteFrames("Robot/kumir_robot-paint.plist");

		this._super(cc.spriteFrameCache.getSpriteFrame(this._getFrameName(0)));

		this._minPoint = cc.p(25, 26);
		this._maxPoint = cc.p(25, 26);

		this._commandsSprite.setOpacity(0);
	},

	/**
	 * Set orientation of sprite
	 * @param {pm.MapLayer2D.Orientation | Number} orientation
	 */
	setOrientation: function(orientation)
	{
		this._orientation = orientation;
		this.sprite.setRotation(0);
		this.sprite.setScale(1);

		if(this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			this.sprite.setRotation(90);
			this._commandsSprite.setScaleY(2);
		}
		else
		{
			// this.sprite.setRotation(90);
			this._commandsSprite.setScaleY(1);
		}

		if(pm.MapLayer2D.ENABLE_DEBUG_DRAW_BB)
			this._debugDrawBB();
	},

	_getActiveSpritePosition: function()
	{
		return cc.p(24, 57);
	},

	_getCommandsSpritePosition: function()
	{
		return cc.p(24, 25);
	},

	_getFrameName: function(direction)
	{
		return "kumir_robot0.png";
	},

	_getDestroyAnimationName: function(direction)
	{
		return "kumir_robot_d_0";
	},

	_getIndicateAnimationName: function(direction)
	{
		return "kumir_robot_i_0";
	},

	playAnimation: function(callback, target, type, args)
	{
		switch(type)
		{
			case GrasshopperRobotAnimation.Paint: this._paint(args, callback, target); break;
			default: pm.IsoRobotSprite.prototype.playAnimation.call(this, callback, target, type, args); break;
		}
	},

	_move: function(pos, endCallback, target)
	{
		var targetPos = this.getRealPosition(pos);
		var callback = cc.callFunc(endCallback, target);
		var moveEnd = cc.callFunc(function()
		{
			this._needToUpdateZOrder = false;
		}, this);

		var oldPos = this.getSprite().getPosition();
		var mapElementSize = target.parentLevel.activeMap.mapLayer.getMapElementSize();

		var diffPosX = (targetPos.x - oldPos.x) / mapElementSize.width;
		var diffPosY = (targetPos.y - oldPos.y) / mapElementSize.height;

		var speedRatio = Math.sqrt(diffPosX * diffPosX + diffPosY * diffPosY);
		var height = this._orientation === pm.MapLayer2D.Orientation.Iso ? 50.0 : 110.0;
		var moveRobot = cc.jumpTo(1.5 * speedRatio * pm.settings.getAnimationSpeed(), targetPos, height, 1);

		this._needToUpdateZOrder = true;
		this.getSprite().runAction(cc.sequence(moveRobot, moveEnd, callback));
	},

	_paint: function(direction, endCallback, target)
	{
		var animation = cc.animationCache.getAnimation("kumir_robot_p_0");
		animation.setDelayPerUnit(pm.settings.getAnimationSpeed()/animation.getTotalDelayUnits());
		var animate = new cc.Animate(animation);

		var turnEnd = new cc.CallFunc(endCallback, target);
		this.getSprite().runAction( new cc.Sequence(animate, turnEnd));
	},

	setActive: function(flag)
	{
		this._activeCircleSprite.setVisible(flag);
		this._activeSprite.setVisible(flag);
	},

	showCommands: function(flag, count)
	{
		if (flag && MethodStackLayer.opened)
			return;

		if (flag)
			this._commandsCount.setString(count);

		this._commandsSprite.setVisible(flag);
	}
});
