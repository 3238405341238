/**
 * Created by Nikita Besshaposhnikov on 18.11.14.
 */

/**
 * @class This layer showing on start of PiktoMir, while loading world data.
 * @extends cc.Layer
 */
var LoadLayer = cc.Layer.extend(/** @extends LoadLayer# */{

	_data: {},

	ctor: function(data)
	{
		this._super();
		this._data = data;

		var colorLayer = new cc.LayerColor(cc.color(0, 94, 163), pm.settings.getScreenSize().width, pm.settings.getScreenSize().height);
		colorLayer.setAnchorPoint( cc.p() );
		colorLayer.setPosition( cc.p() );
		this.addChild(colorLayer, -1);

		var backGround = new cc.Sprite("Background/LoadLayer/LoadBackground.png");
		var loadSprite = new cc.Sprite("Background/LoadLayer/LoadBar.png");
		var loadBarBack = new cc.Sprite("Background/LoadLayer/LoadBarBackground.png");
		var text = pm.appUtils.getLoadText();

		var screenSize = pm.settings.getScreenSize();

		var maxWidth = Math.max(text.width, backGround.width);
		var totalHeight = text.height + backGround.height;

		var layer = new cc.Layer();
		layer.setContentSize(maxWidth, totalHeight);
		var scale = Math.min(screenSize.width / maxWidth, screenSize.height / totalHeight);

		if(scale > 1)
			scale = 1;

		backGround.setAnchorPoint(cc.p(0.5, 0));
		backGround.setPosition(maxWidth/ 2, 0);
		layer.addChild(backGround, 1);

		text.setAnchorPoint(cc.p(0.5, 0));
		text.setPosition( cc.p(maxWidth/ 2, backGround.height - 15));

		layer.addChild(text, 2);

		layer.setPosition((screenSize.width - layer.width) / 2, (screenSize.height - layer.height ) / 2);
		layer.setScale(scale);
		this.addChild(layer);

		var loadBarLayer = new cc.ClippingNode();
		loadBarLayer.setContentSize(647, 127);
		loadBarLayer.setAnchorPoint(cc.p(0, 0));

		var shape = new cc.DrawNode();
		var rect = [
			cc.p(0, 0), cc.p(0, loadBarLayer.height),
			cc.p(loadBarLayer.width, loadBarLayer.height), cc.p(loadBarLayer.width, 0)
		];

		var color = cc.color(0, 255, 0, 255);
		shape.drawPoly(rect, color, 0, color);

		loadBarLayer.setPosition(cc.p((layer.width - loadBarLayer.width) / 2 - 9, 125));
		loadBarLayer.setStencil(shape);

		loadBarBack.setAnchorPoint(cc.p(0, 0));

		loadBarLayer.addChild(loadBarBack);
		loadBarLayer.addChild(loadSprite);

		loadSprite.setAnchorPoint(cc.p(0, 0));
		loadSprite.setPosition(-loadSprite.width, 0);

		layer.addChild(loadBarLayer, 0);

		var move = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 4, loadBarLayer.width + loadSprite.width, 0);
		var returnPos = cc.callFunc(function()
		{
			this.setPosition(cc.p(-this.width, 0));
		}, loadSprite);

		loadSprite.runAction(cc.repeatForever(cc.sequence(move, returnPos)));

		world = new pm.data.World();

		if (pm.settings.isRetinaEnabled)
			cc.loader.loadAliases("res/resources-hd.plist", this._endLoadAliases.bind(this));
		else
			cc.loader.loadAliases("res/resources.plist", this._endLoadAliases.bind(this));
	},

	_endLoadAliases: function()
	{
		pm.validationUtils.load();

		if (this._data.mireraAuthID)
		{
			pm.worldUtils.downloadWorldFromServer({
				worldID: pm.settings.getSelectedWorldID(),
				callback: this._endLoadWorlds,
				callbackTarget: this,
				reloadBuiltinOnError: true
			});
		}
		else
		{
			pm.worldUtils.loadWorld({
				worldID: pm.settings.getSelectedWorldID(),
				callback: this._endLoadWorlds,
				callbackTarget: this,
				reloadBuiltinOnError: true
			});
		}
	},

	_endLoadWorlds: function(error, loadedWorldID)
	{
		pm.spriteUtils.init();
		cc.sys.garbageCollect();

		if(!error && loadedWorldID.length > 0 && loadedWorldID[0])
		{
			pm.audioUtils.load();
			pm.audioUtils.playBackgroundMusic();
			pm.audioUtils.setBackgroundMusicVolume(pm.settings.getBackgroundVolume());
			pm.audioUtils.setEffectVolume(pm.settings.getEffectVolume());

			pm.robotManager.init();
			pm.tutorialUtils.init();

			pm.userCache.init();

			pm.networkUtils.init();

			if(loadedWorldID[0] !== pm.settings.getSelectedWorldID())
				pm.settings.setSelectedWorldID(loadedWorldID[0]);

			if(cc.sys.isNative)
			{
				var supportsBLE = cc.sys.os === cc.sys.OS_IOS || cc.sys.os === cc.sys.OS_ANDROID ||
					cc.sys.os === cc.sys.OS_OSX;

				var connectorType = pm.settings.getPhysicalConnectorType();
				var supportedConnectorType = connectorType === pm.PhysicalConnectorType.BLE ||
					connectorType === pm.PhysicalConnectorType.Sound;

				if(supportsBLE && supportedConnectorType)
					pm.btleManager.init();

				pm.programRecognizer.setStatsURL(pm.appConfig.mlStatsURL);
				pm.matsRecognizer.setServerURL(pm.appConfig.matsRecognitionURL);
			}

			if(!pm.settings.userLoggedIn && pm.settings.isUserAutoLogin() && pm.settings.getAccessToken() !== "")
			{
				pm.apiServerUtils.getUserAccountData(this._getUserAccountDataCallback.bind(this));
			}
			else if(pm.settings.getUserData() && !pm.settings.userLoggedIn)
			{
				pm.settings.clearUserData();
				this._runNextScene();
			}
		}
		else
		{
			var message = new pmui.MessageBox(this, error.text, function() { cc.director.end(); });
			var x = pm.settings.getScreenSize().width / 2 - message.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - message.getContentSize().height / 2;

			message.setPosition(x, y);

			cc.director.getRunningScene().addChild(message, 10000);
		}
	},

	_runNextScene: function ()
	{
		var worldID = pm.settings.getSelectedWorldID();

		if (this._data.start || (this._data.editor && this._data.worldID !== worldID))
		{
			cc.director.runScene(new StartMenuScene());
		}
		else if (this._data.editor && pm.settings.userLoggedIn &&
			(pm.settings.getUserData().accountType === pm.USER_TYPE.TEACHER || pm.settings.getUserData().accountType === pm.USER_TYPE.ADMIN))
		{
			pm.settings.isEditorMode = true;

			if (this._data.game === undefined || !world.games[this._data.game])
			{
				cc.director.runScene(new MenuEditorScene());
			}
			else if (this._data.level === undefined || !world.games[this._data.game].levels[this._data.level])
			{
				pm.settings.setGame(this._data.game);
				cc.director.runScene(new MenuEditorScene(this._data.game));
			}
			else
			{
				pm.settings.setGame(this._data.game);
				pm.settings.setLevel(this._data.level);
				cc.director.runScene(new LevelEditorScene(this._data.game, this._data.level));
			}
		}
		else
		{
			if (this._data.worldID === undefined || this._data.worldID !== worldID)
			{
				cc.director.runScene(new SelectWorldScene());
			}
			else if (this._data.game === undefined || !world.games[this._data.game])
			{
				cc.director.runScene(new SelectGameScene());
			}
			else if (this._data.level === undefined || !world.games[this._data.game].levels[this._data.level])
			{
				pm.settings.setGame(this._data.game);
				cc.director.runScene(new SelectLevelScene());
			}
			else
			{
				pm.settings.setGame(this._data.game);
				pm.settings.setLevel(this._data.level);
				cc.director.runScene(new LevelScene(GameType.Local, null, this._data.game, this._data.level));
			}
		}
	},

	_getUserAccountDataCallback: function(error, response)
	{
		pm.utils.getUserAccountDataCallback(error, response, this._runNextScene.bind(this));
	}
});

/**
 * @class This scene showing on start of PiktoMir, while loading world data.
 * @see LoadLayer
 * @extends cc.Scene
 */
var LoadScene = cc.Scene.extend(/** @lends LoadScene# */{

	_data: null,

	ctor: function (data)
	{
		this._super();
		this._data = data;
	},

	onEnter: function ()
	{
		var layer = new LoadLayer(this._data);
		this.addChild(layer);
	}
});