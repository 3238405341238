/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

/**
 * @class Layer for {@link pm.data.AquariusMap}.
 * @implements MapLayer
 */
var AquariusMapLayer = pm.MapLayer.extend(/** @lends AquariusMapLayer#*/{

	ctor: function(map)
	{
		this._super(map);

		pm.registerCustomEventListener(pm.COMMANDS_CHANGED, this.updateTankPosition.bind(this), this);
	},

	drawMap: function(previewDraw)
	{
		this._previewDraw = previewDraw;

		var tankA = this._map.tankA;
		var tankB = this._map.tankB;
		var tankC = this._map.tankC;

		tankA.mapLayer = this;
		tankB.mapLayer = this;
		tankC.mapLayer = this;

		var tankSpriteA = tankA.generateSprite(previewDraw);
		var tankSpriteB = tankB.generateSprite(previewDraw);
		var tankSpriteC = tankC.generateSprite(previewDraw);

		this.updateTankPosition();

		this.addChild(tankSpriteA);
		this.addChild(tankSpriteB);
		this.addChild(tankSpriteC);
	},

	updateTankPosition: function()
	{
		var visibleTanks = [];

		if (this._map.tankA.sprite.isVisible())
			visibleTanks.push(this._map.tankA.sprite);

		if (this._map.tankB.sprite.isVisible())
			visibleTanks.push(this._map.tankB.sprite);

		if (this._map.tankC.sprite.isVisible())
			visibleTanks.push(this._map.tankC.sprite);

		var realWidth = this.width - MethodStackLayer.INNER_WIDTH - AquariusMapLayer.FRONT_SHIFT;

		var firstTank = visibleTanks[0];
		var secondTank = visibleTanks[1];
		var thirdTank = visibleTanks[2];

		if (visibleTanks.length === 1)
		{
			firstTank.setPosition(cc.p(AquariusMapLayer.FRONT_SHIFT + (realWidth - firstTank.width * firstTank.scale) / 2, (this.height - firstTank.height) / 2));
		}

		if (visibleTanks.length === 2)
		{
			var maxHeight = Math.max(firstTank.height, secondTank.height);
			firstTank.setPosition(cc.p(AquariusMapLayer.FRONT_SHIFT - firstTank.width * firstTank.scale / 2 + (realWidth + AquariusMapLayer.BORDER) / 3, (this.height - maxHeight) / 2));
			secondTank.setPosition(cc.p(firstTank.getPosition().x + (realWidth - AquariusMapLayer.BORDER) / 3 * secondTank.scale, firstTank.getPosition().y));
		}

		if (visibleTanks.length === 3)
		{
			var maxHeight = Math.max(firstTank.height, secondTank.height, thirdTank.height);
			secondTank.setPosition(cc.p(AquariusMapLayer.FRONT_SHIFT + (realWidth - secondTank.width * secondTank.scale) / 2, (this.height - maxHeight) / 2));
			firstTank.setPosition(cc.p(secondTank.getPosition().x - (realWidth - AquariusMapLayer.BORDER) * firstTank.scale / 4, secondTank.getPosition().y));
			thirdTank.setPosition(cc.p(secondTank.getPosition().x + (realWidth - AquariusMapLayer.BORDER) * thirdTank.scale / 4, secondTank.getPosition().y));
		}
	},

	clean: function()
	{
		this._map.tankA.stopAnimation();
		this._map.tankB.stopAnimation();
		this._map.tankC.stopAnimation();

		this._map.tankA.setCurrentCapacity(this._map.tankA.startCapacity, true);
		this._map.tankB.setCurrentCapacity(this._map.tankB.startCapacity, true);
		this._map.tankC.setCurrentCapacity(this._map.tankC.startCapacity, true);
	},

	containsPoint: function(point)
	{
		return !pm.settings.isEditorMode;
	},

	hasChangeOrientationOption: function()
	{
		return false;
	}
});

AquariusMapLayer.BORDER = 150;
AquariusMapLayer.FRONT_SHIFT = 75;
