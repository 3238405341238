/**
 * Created by Antony Orlovsky on 09.08.19.
 */

var TurtleMapEditorLayer = TurtleMapLayer.extend(SquarableMapEditorLayerHelper).extend({

	createMapElement: function () {},

	resizeContainer: function()
	{
		SquarableMapEditorLayerHelper.resizeContainer.call(this);

		this.taskDrawLayer.clear();
		this.drawLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.backgroundLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
	},

	moveElementsUp: function () {},

	moveElementsDown: function () {},

	moveElementsLeft: function () {},

	moveElementsRight: function () {},

	_resizeHeightRight: function (sgn)
	{
		if (pm.moduleUtils.stopResizingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
			return;

		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.y > (this._map.height - 1) * this._map.mapLayer.getMapElementSize().height)
					return;

				if (task && task.data[mapIndex][robots[i].id].y > (this._map.height - 1) * this._map.mapLayer.getMapElementSize().height)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.y > (this._map.height - 1) * this._map.mapLayer.getMapElementSize().height)
					return;
			}

			if (pm.moduleUtils.stopDecreasingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
				return;
		}

		if (sgn === -1)
		{
			if (this._map.height === 1)
				return;

			this._map.height--;
		}
		else if (sgn === 1)
		{
			this._map.height++;
		}

		this._update();
	},

	_resizeHeightLeft: function (sgn)
	{
		if (pm.moduleUtils.stopResizingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
			return;

		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.y < 2 * this._map.mapLayer.getMapElementSize().height)
					return;

				if (task && task.data[mapIndex][robots[i].id].y < 2 * this._map.mapLayer.getMapElementSize().height)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.y < 2 * this._map.mapLayer.getMapElementSize().height)
					return;
			}

			if (pm.moduleUtils.stopDecreasingHeight(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
				return;
		}

		if (sgn === -1)
		{
			var map = this._map;

			if (map.height === 1)
				return;

			this.moveElementsUp();

			this._map.height--;

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.y -= this._map.mapLayer.getMapElementSize().height;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					task.data[mapIndex][robots[i].id].y -= this._map.mapLayer.getMapElementSize().height;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(task.data[mapIndex][robots[i].id]);
				}
			}

			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].startPosition.y -= this._map.mapLayer.getMapElementSize().height;
				objects[i].setPosition(objects[i].startPosition);
				objects[i].updateSprite();
			}
		}
		else if (sgn === 1)
		{
			this._map.height++;

			this.moveElementsDown();

			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.y += this._map.mapLayer.getMapElementSize().height;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].sprite.setRealPosition(robots[i].position);

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					task.data[mapIndex][robots[i].id].y += this._map.mapLayer.getMapElementSize().height;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].startPosition.y += this._map.mapLayer.getMapElementSize().height;
				objects[i].setPosition(objects[i].startPosition);
				objects[i].updateSprite();
			}
		}

		this._update();
	},

	_resizeWidthRight: function (sgn)
	{
		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.x > (this._map.width - 2) * this._map.mapLayer.getMapElementSize().width)
					return;

				if (task && task.data[mapIndex][robots[i].id].x > (this._map.width - 2) * this._map.mapLayer.getMapElementSize().width)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.x > (this._map.width - 2) * this._map.mapLayer.getMapElementSize().width)
					return;
			}

			if (pm.moduleUtils.stopResizingWidth(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Right))
				return;
		}

		if (sgn === -1)
		{
			if (this._map.width === 1)
				return;

			this._map.width--;
		}
		else if (sgn === 1)
		{
			this._map.width++;
		}

		this._update();
	},

	_resizeWidthLeft: function (sgn)
	{
		if (sgn === -1)
		{
			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				if (robots[i].position.x < this._map.mapLayer.getMapElementSize().width)
					return;

				if (task && task.data[mapIndex][robots[i].id].x < this._map.mapLayer.getMapElementSize().width)
					return;
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				if (objects[i].position && objects[i].position.x < this._map.mapLayer.getMapElementSize().width)
					return;
			}

			if (pm.moduleUtils.stopResizingWidth(this._map.getType(), this._map, SquarableMapEditor_resizeSide.Left))
				return;
		}

		if (sgn === -1)
		{
			if (this._map.width === 1)
				return;

			this.moveElementsRight();

			this._map.width--;

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.x -= this._map.mapLayer.getMapElementSize().width;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					task.data[mapIndex][robots[i].id].x -= this._map.mapLayer.getMapElementSize().width;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].position.x -= this._map.mapLayer.getMapElementSize().width;
				objects[i].setPosition(objects[i].startPosition);
				objects[i].updateSprite();
			}
		}
		else if (sgn === 1)
		{
			var map = this._map;

			map.width++;

			this.moveElementsLeft();

			var robots = this._map.parentLevel.robots;

			var level = this._map.parentLevel;
			var task = level.taskList.getTask(pm.GlobalTaskType.Position4);
			var mapIndex = level.getActiveMapIndex();

			for (var i = 0; i < robots.length; ++i)
			{
				robots[i].position.x += this._map.mapLayer.getMapElementSize().width;
				robots[i].setPosition(robots[i].position, robots[i].direction);
				robots[i].updateSprite();

				var newPos = cc.p(robots[i].position.x, robots[i].position.y);

				this._setRobotStartPositionMarkPosition(robots[i].id, newPos);

				if (task)
				{
					task.data[mapIndex][robots[i].id].x += this._map.mapLayer.getMapElementSize().width;

					var pos = cc.p(task.data[mapIndex][robots[i].id].x, task.data[mapIndex][robots[i].id].y);

					this._removePositionMark(robots[i].id);
					this._drawPositionMark(robots[i].id, pos);

					if (robots[i] === this._activeRobot)
						this._setMarkPosition(robots[i].id, pos);
				}
			}

			var objects = this._map.objects;
			for (var i = 0; i < objects.length; ++i)
			{
				objects[i].position.x += this._map.mapLayer.getMapElementSize().width;
				objects[i].updateSprite();
			}
		}

		this._update();
	},

	_touchBegan: function (touch, pEvent)
	{
		if (this._map.parentLevel.activeMap !== this._map || this._disableEditing)
			return false;

		var level = this._map.parentLevel;

		// resize

		var p = this._resizeSprite.convertTouchToNodeSpace(touch);
		this._startPoint = touch.getLocation();

		var l = this._resizeLeftSprite.convertTouchToNodeSpace(touch);

		var rect = this._resizeSprite.getContentSize();
		var rectL = this._resizeLeftSprite.getContentSize();

		if (p.x > -20 && p.y > -20 && p.x < rect.width + 20 && p.y < rect.height + 20)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].isPlayingAnimation() || level.robots[i].isEndedWork())
					return;
			}

			this._touchType = SquarableMapEditor_touchType.Resize;
			this._startPoint = this.convertTouchToNodeSpace(touch);

			return true;
		}

		if (l.x > -20 && l.y > -20 && l.x < rectL.width + 20 && l.y < rectL.height + 20)
		{
			for(var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].isPlayingAnimation() || level.robots[i].isEndedWork())
					return;
			}

			this._touchType = SquarableMapEditor_touchType.ResizeLeft;
			this._startPoint = this.convertTouchToNodeSpace(touch);

			return true;
		}

		if (!this.containsPoint(touch))
			return false;

		// drag robot

		if (!level.robotsPlayingAnimation())
		{
			for (var i = 0; i < level.robots.length; ++i)
			{
				if (level.robots[i].sprite.containsPoint(touch) && level.robots[i].getType() !== pm.PlayerRobotType.Counter)
				{
					if (level.robots[i].position === level.robots[i].startPosition &&
						level.robots[i].direction === level.robots[i].startDirection)
					{
						this.dragedRobot = i;
						this._touchType = SquarableMapEditor_touchType.DragRobot;
						this._startPoint = this.convertTouchToNodeSpace(touch);
						this._startDragPoint = this.objectLayer.convertTouchToNodeSpace(touch);

						return true;
					}
					else
					{
						pm.sendCustomEvent(pm.ANIMATE_RESTART_BUTTON);
					}
				}
			}
		}

		//drag objects
		for(var i = 0; i < level.activeMap.objects.length; ++i)
		{
			if (level.activeMap.objects[i].sprite.containsPoint(touch))
			{
				this._dragedObject = i;
				this._touchType = SquarableMapEditor_touchType.DragObject;
				this._startPoint = this.convertTouchToNodeSpace(touch);
				this._startDragPoint = this.objectLayer.convertTouchToNodeSpace(touch);

				return true;
			}
		}

		// drag startPoint
		var startPoint = this.terrainLayer.getChildByName(pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(this._activeRobot.id));

		if (startPoint !== null)
		{
			p = startPoint.convertTouchToNodeSpace(touch);
			rect = startPoint.getContentSize();

			if (p.x > 0 && p.y > 0 && p.x < rect.width && p.y < rect.height)
			{
				this._dragedObject = this._activeRobot;
				this._touchType = SquarableMapEditor_touchType.DragStartPoint;
				this._startPoint = this.convertTouchToNodeSpace(touch);

				return true;
			}
		}

		this._startPoint = this.convertTouchToNodeSpace(touch);
		this._touchType = SquarableMapEditor_touchType.EditMap;
		return true;
	},

	_dragRobotMoved: function (touch, pEvent)
	{
		var level = this._map.parentLevel;
		var robot = level.robots[this.dragedRobot];

		if (level.robotsEndedWork())
			return;

		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);

		if (!(robot.direction >= 0 && robot.direction < robot.getDirectionCount()))
		{
			cc.log("Incorrect robot4 direction");
			robot.direction = 0;
		}

		robot.startPosition = p_normal;
		robot.setPosition(p_normal, robot.direction);
		robot.sprite.setRealPosition(robot.position);

		this._setRobotStartPositionMarkPosition(robot.id, robot.position);

		var opacity = 255;

		if (this._positionOutOfMap(p))
			opacity = 50;

		robot.sprite.getSprite().setOpacity(opacity);
	},

	_touchEnded: function (touch, pEvent)
	{
		var p = this.objectLayer.convertTouchToNodeSpace(touch);
		var p_normal = this.positionFromReal(p);
		var level = this._map.parentLevel;

		if(this._touchType === SquarableMapEditor_touchType.DragRobot)
		{
			var robot = level.robots[this.dragedRobot];

			if (!level.robotsEndedWork())
			{
				robot.startPosition = robot.position;
				this._setRobotStartPositionMarkPosition(robot.id, robot.position);
			}

			var start = this._startPoint;
			var end = this.convertTouchToNodeSpace(touch);

			var dif = cc.pSub(start, end);
			dif = cc.pCompOp(dif, Math.abs);

			if (dif.x < 10 && dif.y < 10)
			{
				if (this._activeRobot.id === robot.id && !level.robotsEndedWork())
				{
					if (!(robot.direction >= 0 && robot.direction < robot.getDirectionCount()))
					{
						cc.log("Incorrect robot4 direction");
						robot.direction = 0;
					}

					if (pm.moduleUtils.needToRotateRobot(this._map.getType()))
					{
						var newDirection = (robot.direction + 1) % robot.getDirectionCount();

						robot.setPosition(robot.position, newDirection);
						robot.startDirection = robot.direction;
						robot.sprite.setDirection(newDirection);
						robot.sprite.setRealPosition(robot.position);
					}
				}
				else
				{
					this._setActiveRobot(robot);
				}
			}
			else if (this._positionOutOfMap(p) && level.robots.length > 1)
			{
				if(robot.id === this._activeRobot.id)
				{
					var activeIndex = level.robots.indexOf(robot);
					for (var t = 0; t < level.robots.length; ++t)
					{
						if (t !== activeIndex)
						{
							this._setActiveRobot(level.robots[t]);
							break;
						}
					}
				}

				this.removeObject(robot.sprite, false);
				this._removeRobotStartPositionMark(robot.id);
				this._removePositionMark(robot.id);

				pm.sendCustomEvent(pme.REMOVE_ROBOT, robot);

				level.robots.splice(this.dragedRobot, 1);

				for (var r = this.dragedRobot; r < level.robots.length; ++r)
				{
					var markSprite = this.terrainLayer.getChildByName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(level.robots[r].id));

					level.robots[r].id--;

					markSprite.setName(pm.MapLayer2D.START_MARK_NAME_PATTERN.format(level.robots[r].id));
				}

				this._setActiveRobot(this._activeRobot);

				if (this._map.parentLevel.robots.length === 1)
					this._activeRobot.sprite.setActive(false);

				if (this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.forceUpdate();

			}

			if (this._map.parentLevel.robots.length > 1 && this._positionOutOfMap(p) && this.dragedRobot === this._map.parentLevel.startRobotIndex)
				robot.sprite.setActive(robot.id === this._activeRobot.id);
		}

		if(this._touchType === SquarableMapEditor_touchType.DragObject)
		{
			if (this._positionOutOfMap(p))
			{
				var object = level.activeMap.objects[this._dragedObject];

				this._removeObject(object);

				if (this._orientation === pm.MapLayer2D.Orientation.Iso)
					this.objectLayer.forceUpdate();
			}
		}

		if(this._touchType === SquarableMapEditor_touchType.EditMap &&
			!this._changeWallsForTouch(touch) &&
			this._selectedObjectForTapType !== undefined &&
			this._selectedObjectForTapType !== null)
		{
			this._mapElementClicked(p_normal);
		}
	},

	_mapElementClicked: function (pos)
	{
		if (this._tapType === EditorTapsType.AddRobot)
		{
			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._selectedObjectForTapType);
			this._addRobot(pos, robot);
			pm.sendCustomEvent(pme.ADD_ROBOT, robot);

			this._setActiveRobot(robot);
		}
		else if (this._tapType === EditorTapsType.AddClone)
		{
			var robot = pm.moduleUtils.generateRobot(this._map.getType(), this._activeRobot.getType());
			this._addClone(pos, robot);
			pm.sendCustomEvent(pme.ADD_ROBOT, robot);

			this._setActiveRobot(robot);
		}
	},

	_addRobot: function (pos, robot)
	{
		var groupID = 0;
		var isFound = false;
		var indexes = [];
		var robots = this._map.parentLevel.robots;

		for (var i = 0; i < robots.length; ++i)
			indexes[i] = robots[i].groupID;

		indexes.sort();

		for (var i = 1; i < indexes.length; ++i)
		{
			if (indexes[i] - indexes[i-1] > 1)
			{
				groupID = indexes[i-1] + 1;
				isFound = true;
				break;
			}
		}

		if (robots.length > 0 && !isFound)
			groupID = indexes[indexes.length-1] + 1;

		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = groupID;

		robot.parentLevel.programData[robot.groupID] = new pm.data.ProgramInfo();
		robot.parentLevel.programData[robot.groupID].currentIndex = 0;
		robot.parentLevel.programData[robot.groupID].programDataArray[0] = pm.appUtils.generateProgramData();

		robot.setPosition(pos, MapDirection4.Down);
		robot.startPosition = pos;
		robot.startDirection = 0;

		robot.generateRobotSprite();

		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	},

	_addClone: function(pos, robot)
	{
		robot.childRobot = null;
		robot.childRobotID = -1;
		robot.parentLevel = this._map.parentLevel;

		this._map.parentLevel.robots.push(robot);
		robot.id = this._map.parentLevel.robots.indexOf(robot);

		robot.groupID = this._activeRobot.groupID;

		robot.setPosition(pos, this._activeRobot.direction);
		robot.startPosition = pos;
		robot.startDirection = robot.direction;

		robot.generateRobotSprite();

		robot.sprite.mapLayer = this._map.mapLayer;
		robot.sprite.setDirection(robot.direction);
		robot.sprite.setOrientation(this._orientation);

		this.addObject(robot.sprite);
		robot.sprite.setRealPosition(robot.position);

		this.drawRobotStartPositionMark(robot.id, robot.position);

		robot.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
	}
});
