/**
 * Created by Nikita Besshaposhnikov on 12.11.14.
 */
/**
 * @class This layer contains view of whole level.
 * @extends ccui.Layout
 * @constructor
 * @param {Number} game Index of game for level.
 * @param {Number} level Index of level to load.
 * @param {GameType} gameType
 * @param {Number} [robotIndex] Index of base robot to override in level.
 */
var LevelLayer = ccui.Layout.extend(/** @lends LevelLayer# */{
	_programLayer: null,
	_mapsContainerLayer: null,
	_levelMenuLayer: null,
	_globalRobotsLayer: null,
	_tutorialData: {},
	_timerID: null,

	_gameIndex: 0,
	_levelIndex: 0,
	_robotIndex: null,

	_level: null,
	_gameType: GameType.Local,

	_programStarted: false,
	_testAllMaps: false,

	_wasMethodStackOpened: false,

	_completedMaps: {},

	ctor: function(gameType, robotIndex, game, level, levelObject, tutorialData)
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._gameIndex = game;
		this._levelIndex = level;
		this._gameType = gameType;
		this._robotIndex = robotIndex;
		this._tutorialData = tutorialData;

		if(levelObject !== undefined)
			this._level = levelObject;

		if (tutorialData === undefined)
		{
			this._tutorialData = {
				show: false,
				scale: 1
			};
		}

		if (tutorialData && tutorialData.show)
			this.setScale(tutorialData.scale);

		pm.tutorialUtils.clearTutorialObjects();

		pm.registerCustomEventListener(pm.ROBOT_WIN_EVENT_STR, this._onWin.bind(this), this);
		pm.registerCustomEventListener(pm.ROBOT_LOOSE_EVENT_STR, this._onLoose.bind(this), this);
		pm.registerCustomEventListener(pm.ROBOT_FAILURE_EVENT_STR, this._onFailure.bind(this), this);
		pm.registerCustomEventListener(pm.CURRENT_ROBOT_TESTED_EVENT_STR, this._onRobotTested.bind(this), this);
		pm.registerCustomEventListener(pm.PROGRAM_RESTART_EVENT_STR, this._restartLevel.bind(this), this);
		pm.registerCustomEventListener(pm.PROGRAM_START_EVENT_STR, this._startLevel.bind(this), this);
		pm.registerCustomEventListener(pm.PROGRAM_MAKE_STEP_EVENT_STR, this._makeStep.bind(this), this);
		pm.registerCustomEventListener(pm.PROGRAM_TEST_ALL_MAPS_EVENT_STR, this._testWholeLevel.bind(this), this);
		pm.registerCustomEventListener(pm.RESET_LEVEL_EVENT_STR, this._resetLevel.bind(this), this);
		pm.registerCustomEventListener(pm.START_NETWORK_GAME_EVENT_STR, this._startNetworkGame.bind(this), this);
		pm.registerCustomEventListener(pm.MAP_COMPLETED_STR, this._onMapCompleted.bind(this), this);
		pm.registerCustomEventListener(pm.PATTERN_COMPLETED_STR, this._onPatternCompleted.bind(this), this);

		pm.registerCustomEventListener(pm.SET_ROBOT_PROGRAM_EVEN_STR, function(event)
		{
			var data = event.getUserData();
			if(data !== undefined)
				this._setRobotProgram(data.robotIndex, data.program, data.programDataIndex);
		}.bind(this), this);

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function(event)
		{
			this._wasMethodStackOpened = true;

		}.bind(this), this);

		this._registerUpdateSceneEvent();
	},

	_registerUpdateSceneEvent: function()
	{
		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new LevelScene(this._gameType, this._robotIndex, this._gameIndex, this._levelIndex, this._level));
		}.bind(this), this);
	},

	_loadLevel: function(robotIndex)
	{
		var screenBounds = pm.settings.getScreenBounds();

		this.setContentSize(pm.settings.getScreenSize());

		if(!this._level)
		{
			this._level = world.loadLevel(false, this._gameIndex, this._levelIndex, robotIndex);
		}
		else
		{
			world.runningLevel = this._level;
			this._level.load(false, robotIndex);
		}

		if(this._level)
		{
			this._levelMenuLayer = new LevelMenuLayer(this._level, this.isNetGame(), !this._tutorialData.show);

			var levelMenuAlign = new ccui.RelativeLayoutParameter();
			levelMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			this._levelMenuLayer.setLayoutParameter(levelMenuAlign);

			this.addChild(this._levelMenuLayer, 2);

			var baseRobot = this._level.getCurrentRobot();

			var useMethodStack = pm.moduleUtils.canUseMethodStack(this._level.getType()) && this._level.useMethodStack;

			this._programLayer = pm.appUtils.generateProgramLayer(this._gameType, useMethodStack, baseRobot, this._level);

			var programLayerAlign = new ccui.RelativeLayoutParameter();
			programLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			programLayerAlign.setRelativeName("programLayer");
			this._programLayer.setLayoutParameter(programLayerAlign);

			this.addChild(this._programLayer, 2);

			this._mapsContainerLayer = new pm.MapsContainerLayer(this._level, false, this._tutorialData.show);

			var mapsContainerLayerAlign = new ccui.RelativeLayoutParameter();
			mapsContainerLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			this._mapsContainerLayer.setLayoutParameter(mapsContainerLayerAlign);

			this.addChild(this._mapsContainerLayer);

			if (this.isNetGame())
			{
				for (var i = 0; i < this._level.maps.length; ++i)
				{
					var mapLayer = this._level.maps[i].mapLayer;

					mapLayer.enableClicks(false);
				}
			}

			if (this._level.activeMap.mapLayer instanceof pm.MapLayer2D && this._level.activeMap.mapLayer.hasChangeOrientationOption() && this._level.activeMap.mapLayer.needToDrawOrientationOption())
				this._levelMenuLayer.drawChangeOrientationButton();

			this._globalRobotsLayer = new GlobalRobotsLayer(this._level);

			var rightMargin = screenBounds.safeAreas.right ? screenBounds.right / 2 : 0;

			var globalRobotsLayerAlign = new ccui.RelativeLayoutParameter();
			globalRobotsLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			globalRobotsLayerAlign.setMargin(-rightMargin, 0, 0, 0);
			this._globalRobotsLayer.setLayoutParameter(globalRobotsLayerAlign);

			this.addChild(this._globalRobotsLayer, 1);
		}
	},

	_setRobotProgram: function(robotIndex, programData, programDataIndex)
	{
		if(robotIndex < 0 || robotIndex > this._level.robots.length)
			return;

		//TODO validate program data
		var data = pm.appUtils.generateProgramData(true);
		data.deserialize(programData, false);

		this._level.robots[robotIndex].setProgramData(data, programDataIndex);
	},

	_startLevel: function()
	{
		if(!this._programStarted || this._wasMethodStackOpened)
		{
			this._levelMenuLayer.onStartGame();
			this._programLayer.reset();
			this._mapsContainerLayer.setEnabled(false);
			this._mapsContainerLayer.clean();
			this._programStarted = true;
			this._wasMethodStackOpened = false;
			FunctionButton.deselect();
			this._completedMaps = {};
		}
		this._programLayer.setEnabled(false);
	},

	_startNetworkGame: function()
	{
		pm.sendCustomEvent(pm.PROGRAM_START_EVENT_STR);
	},

	_makeStep: function()
	{
		if(!this._programStarted || this._wasMethodStackOpened)
		{
			this._levelMenuLayer.onStartGame();
			this._programLayer.reset();
			this._mapsContainerLayer.setEnabled(false);
			this._mapsContainerLayer.clean();
			this._programStarted = true;
			this._wasMethodStackOpened = false;
			FunctionButton.deselect();
			this._completedMaps = {};
		}
		this._programLayer.setEnabled(false, true);
	},

	_testWholeLevel: function()
	{
		this._levelMenuLayer.onStartGame();
		this._programLayer.reset();
		this._programLayer.setEnabled(false);
		this._mapsContainerLayer.setEnabled(false);
		this._mapsContainerLayer.clean();
		this._programStarted = true;
		this._testAllMaps = true;
		this._completedMaps = {};

		for (var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].patternsCompleted = {};
	},

	_restartLevel: function(object)
	{
		this._levelMenuLayer.onRestartGame();
		this._programLayer.reset();
		this._mapsContainerLayer.clean();
		this._mapsContainerLayer.setEnabled(true);
		this._programStarted = false;
		this._testAllMaps = false;
		this._completedMaps = {};

		for (var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].patternsCompleted = {};
	},

	_resetLevel: function(object)
	{
		this._mapsContainerLayer.clean();
		this._programStarted = false;
		this._testAllMaps = false;
		this._completedMaps = {};

		for (var i = 0; i < this._level.maps.length; ++i)
			this._level.maps[i].patternsCompleted = {};
	},

	_onWin: function()
	{
		if(!this.isNetGame())
		{
			if(!this._level.checkAllPatterns)
			{
				pm.userCache.setScore(this._level, this._mapsContainerLayer.activeMapIndex, this._level.getCurrentRobot().stepCount);
			}
			else // в теории этот кусок можно закомментировать
			{
				var stepCount = 0;
				for (var i = 0; i < this._level.getCurrentRobotProgramInfo().programDataArray.length; ++i)
					stepCount += this._level.maps[this._mapsContainerLayer.activeMapIndex].patternsCompleted[i];

				pm.userCache.setScore(this._level, this._mapsContainerLayer.activeMapIndex, stepCount);
			}

			if(pm.settings.userLoggedIn && pm.settings.getUserData().accountType === pm.USER_TYPE.USER)
				this._uploadResult();
		}

		this._completedMaps[this._mapsContainerLayer.activeMapIndex] = this._level.getCurrentRobot().stepCount;

		this._levelMenuLayer.onWin();
		this._programLayer.setEnabled(true);
		this._programLayer.setProgramControlEnabled(true);
		this._mapsContainerLayer.setEnabled(true);
		this._programStarted = false;
		this._testAllMaps = false;
	},

	_onLoose: function()
	{
		this._levelMenuLayer.onLoose();
		this._programLayer.setEnabled(true);
		this._programLayer.setProgramControlEnabled(true);
		this._mapsContainerLayer.setEnabled(true);
		this._programStarted = false;
		this._testAllMaps = false;
	},

	_onFailure: function()
	{
		this._levelMenuLayer.onFailure();
		this._programLayer.setEnabled(true);
		this._programLayer.setProgramControlEnabled(true);
		this._mapsContainerLayer.setEnabled(true);
		this._programStarted = false;
		this._testAllMaps = false;
	},

	_onRobotTested: function()
	{
		this._levelMenuLayer.onRobotTested();
		this._programLayer.setEnabled(true);
		this._programLayer.setProgramControlEnabled(true);
		this._mapsContainerLayer.setEnabled(true);
		this._programStarted = false;
		this._testAllMaps = false;
	},

	_onMapCompleted: function ()
	{
		this._completedMaps[this._level.getActiveMapIndex()] = this._level.getCurrentRobot().stepCount;

		if (!this._testAllMaps)
			this._levelMenuLayer.onMapCompleted();
	},

	_onPatternCompleted: function ()
	{
		this._level.maps[this._level.getActiveMapIndex()].patternsCompleted[this._level.getCurrentRobotProgramInfo().currentIndex] = this._level.getCurrentRobot().stepCount;

		if (!this._testAllMaps)
		{
			this._levelMenuLayer.onPatternCompleted();

			this._programLayer.setEnabled(true);
			this._programLayer.setProgramControlEnabled(true);
			this._mapsContainerLayer.setEnabled(true);
			this._programStarted = false;
			this._testAllMaps = false;
		}
	},

	_uploadResult: function ()
	{
		var solution = {
			levelId: this._level.id,
			version: this._level.version,
			revision: this._level.revision,
			data: this._level.programDataCache
		};

		pm.apiServerUtils.uploadSolution(function(error, res)
		{
			if(error)
				cc.log(error);

		}.bind(this), solution);

	},

	/**
     * Return if game is Network
     * @returns {Boolean}
     */
	isNetGame: function() { return this._gameType === GameType.Net; },

	onExit: function ()
	{
		this._super();
	},

	onExitTransitionDidStart: function()
	{
		if (this._timerID)
			clearTimeout(this._timerID);

		if(this.isNetGame())
		{
			for(var i = 0; i < this._level.robots.length; ++i)
			{
				if (this._level.programDataCache[i])
				{
					for (var j = 0; j < this._level.programDataCache[i].programDataArray.length; ++j)
						this._level.robots[i].setProgramData(null, j);
				}
			}
		}

		world.runningLevel = null;
		this._level.destroy();

		FunctionButton.deselect();

		this._super();
	},

	onEnter: function()
	{
		this._super();

		this._loadLevel(this._robotIndex);
	},

	onEnterTransitionDidFinish: function()
	{
		this._super();

		if(this._level.isTutorial)
		{
			pm.tutorialUtils.setTutorialScenario(this._level.tutorialScenario);

			this._timerID = setTimeout(function()
			{
				if (this.getParent())
					pm.tutorialUtils.startTutorial(this.getParent(), this._tutorialData);
			}.bind(this), LevelLayer.TUTORIAL_TIMEOUT);
		}

		if (this.isNetGame())
			pm.sendCustomEvent(pm.LEVEL_SCENE_ENTERED);
	},

	getCompletedMaps: function ()
	{
		return this._completedMaps;
	}
});

LevelLayer.TUTORIAL_TIMEOUT = 500;

var LevelScene = cc.Scene.extend({
	ctor: function(gameType, robotIndex, game, level, levelObject)
	{
		this._super();

		var layer = new LevelLayer(gameType, robotIndex, game, level, levelObject);
		this.addChild(layer);

		if (!cc.sys.isNative)
			window.location.hash = "worlds/" + world.id + "/games/" + game + "/levels/" + level;
	},

	onEnter: function ()
	{
		this._super();
	}
});
