/**
 * @class Layer for selecting level.</br>
 * Contains {@link LevelPreview}'s.
 * @extends ccui.Layout
 */
var SelectLevelLayer = ccui.Layout.extend({
	_refreshInterval: null,
	_levelsMenu: null,

	ctor: function ()
	{
		var screenBounds = pm.settings.getScreenBounds();

		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getScreenSize());

		var game = pm.settings.getGame();

		this._levelsMenu = new pmui.SlidingLevelMenu(this.getContentSize().width - screenBounds.left - screenBounds.right, this._selectLevel, this);

		this._levelsMenu.addMenuElement(new pmui.SlidingLevelMenuElement(new ccui.ImageView("WorldPics/game_{0}.png".format(game % 3)),
			world.getGameName(game), 0, this._levelsMenu.getChildrenCount() - 1));

		for (var i = 0; i < world.getLevelCount(game); ++i)
		{
			var needToDrawDots = true;

			if ((i + 1) === world.getLevelCount(pm.settings.getGame()))
				needToDrawDots = false;

			this._levelsMenu.addMenuElement(new pmui.SlidingLevelMenuElement(new LevelPreview(game, i),
				world.getLevelName(game, i), i + 1, this._levelsMenu.getChildrenCount() - 1));
		}
		this._levelsMenu.setCurrentElement(pm.settings.getLevel(), false);

		var slidingMenuAlign = new ccui.RelativeLayoutParameter();
		slidingMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
		slidingMenuAlign.setRelativeName("scroll");
		slidingMenuAlign.setMargin(screenBounds.left, 0, 0, 0);
		this._levelsMenu.setLayoutParameter(slidingMenuAlign);

		this.addChild(this._levelsMenu);

		// var levelCount = new ccui.Text(LocalizedString('LevelCount').format(world.getLevelCount(game)),
		//     pm.settings.fontName, pm.settings.fontSize);
		//
		// var worldLabel = new ccui.Text(LocalizedString("WorldPattern").format(pm.settings.getWorldRecord().name),
		//     pm.settings.fontName, pm.settings.fontSize);
		//
		// var gameNameLabel = new ccui.Text(world.getGameName(game), pm.settings.fontName, pm.settings.fontSize);
		//
		// var levelSprite = new ccui.ImageView("WorldPics/game{0}.png".format(game % 4));
		//
		// var levelCountAlign = new ccui.RelativeLayoutParameter();
		// levelCountAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
		// levelCountAlign.setRelativeName("levelCount");
		// levelCountAlign.setRelativeToWidgetName("scroll");
		// levelCountAlign.setMargin(0, 0, 0, 50);
		// levelCount.setLayoutParameter(levelCountAlign);
		//
		// var worldLabelAlign = new ccui.RelativeLayoutParameter();
		// worldLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
		// worldLabelAlign.setRelativeName("world");
		// worldLabelAlign.setRelativeToWidgetName("levelCount");
		// worldLabel.setLayoutParameter(worldLabelAlign);
		//
		// var gameNameLabelAlign = new ccui.RelativeLayoutParameter();
		// gameNameLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
		// gameNameLabelAlign.setRelativeName("game");
		// gameNameLabelAlign.setRelativeToWidgetName("world");
		// gameNameLabel.setLayoutParameter(gameNameLabelAlign);
		//
		// var levelSpriteAlign = new ccui.RelativeLayoutParameter();
		// levelSpriteAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_CENTER);
		// levelSpriteAlign.setRelativeToWidgetName("game");
		// levelSprite.setLayoutParameter(levelSpriteAlign);
		// levelSprite.setScale(0.7);
		//
		// this.addChild(levelCount);
		// this.addChild(gameNameLabel);
		// this.addChild(worldLabel);
		// this.addChild(levelSprite);

		var goToSelectGame = new pmui.Button( pm.spriteUtils.getIconName("back", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("back", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("back", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		goToSelectGame.addClickEventListener(this._gamesMenu.bind(this));

		var selectGameAlign = new ccui.RelativeLayoutParameter();
		selectGameAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		selectGameAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		goToSelectGame.setLayoutParameter(selectGameAlign);

		this.addChild(goToSelectGame);

		var menuButton = new pmui.Button( pm.spriteUtils.getIconName("menu", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("menu", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("menu", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		menuButton.addClickEventListener(this._showMenu.bind(this));

		var menuButtonAlign = new ccui.RelativeLayoutParameter();
		menuButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		menuButtonAlign.setMargin(0, screenBounds.top, screenBounds.right, 0);
		menuButton.setLayoutParameter(menuButtonAlign);

		this.addChild(menuButton);

		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new SelectLevelScene());
		}, this);


		var isUser = pm.settings.userLoggedIn && pm.settings.getUserData().accountType === pm.USER_TYPE.USER;

		if(isUser && pm.settings.getWorldRecord().linkedWithMirera) {
			this._requestGameMireraResults();

			this._refreshInterval = setInterval(
				this._requestGameMireraResults.bind(this),
				SelectLevelLayer.REFRESH_STATUS_INTERVAL
			);
		}
	},

	_requestGameMireraResults: function() {
		var game = pm.settings.getGame();
		var id = world.getGames()[game].id;

		pm.apiServerUtils.getGameSolutionsResult(function(error, response) {
			if(!error)
			{
				var childCount = this._levelsMenu.getChildrenCount();
				var children = this._levelsMenu.getChildren();

				for(var i = 0; i < childCount; ++i)
				{
					var levelId = children[i].levelID;

					if(levelId)
					{
						var level = world.getLevelById(levelId);
						if (response.levels[levelId]) // если задание выполнено в мирере, считаем его выполненым в пиктомире
						{
							for(var j = 0; j < level.maps.length; ++j)
								pm.userCache.setScore(level, j, 0);
						}

                        children[i].setMireraCompleted(response.levels[levelId]);
					}
				}
			}
			else
			{
				console.log(JSON.stringify(error));
			}
		}.bind(this), id);
	},

	_gamesMenu: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectGameScene());
		cc.director.runScene(trans);
	},

	_selectLevel: function(level)
	{
		pm.settings.setLevel(level);
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelScene(GameType.Local, null, pm.settings.getGame(), level));
		cc.director.runScene(trans);
	},

	_showMenu: function()
	{
		if(cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
			return;

		var settingsLayer = new SettingsLayer(this, this._closeMenu);

		cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
	},

	_closeMenu: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	},

	onExitTransitionDidStart: function()
	{
		if(this._refreshInterval)
			clearInterval(this._refreshInterval);
	}
});

/**
 * @class Scene for {@link SelectLevelLayer}
 * @extends cc.Scene
 */
var SelectLevelScene = cc.Scene.extend({
	onEnter: function ()
	{
		this._super();

		var layer = new SelectLevelLayer();

		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);

		if (!cc.sys.isNative)
		    window.location.hash = "worlds/" + world.id + "/games/" + pm.settings.getGame() + "/levels";
	}
});

SelectLevelLayer.REFRESH_STATUS_INTERVAL = 10000;
