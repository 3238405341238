/**
 * Created by Nikita Besshaposhnikov on 17.11.14.
 */

/**
 * Callback called on select button clicked.
 * @callback pm.ControlledModeData~addMethodCallback
 * @param {FunctionButton} button
 */

/**
 * @class This class contains data for controlled mode start event by {@link MethodStackLayer}
 * @extends cc.Class
 * @constructor
 * @param {pm.ControlledModeData~addMethodCallback} addMethodCallback
 * @param {pm.ControlledModeData~getMethodListFunction} getMethodListFunction Function for getting method list.
 * @param {Object} callbackTarget
 */
pm.ControlledModeData = cc.Class.extend({

	/**
     * Callback to call when need to add method to method stack
     * @type {pm.ControlledModeData~addMethodCallback}
     */
	addMethodCallback: null,
	/**
     * Target for callbacks in this class
     * @type {Object}
     */
	callbackTarget: null,

	ctor: function(addMethodCallback, callbackTarget)
	{
		this.addMethodCallback = addMethodCallback;
		this.callbackTarget = callbackTarget;
	}
});

/**
 * @class This layer is used for make stepped robot actions. </br>
 * Buttons menu from program layer is used for make actions.
 * @extends ccui.Layout
 * @constructor
 * @param {ProgramLayer} programLayer
 * @param {Number} height
 */
var MethodStackLayer = ccui.Layout.extend(/** @lends MethodStackLayer# */{
	_innerLayer: null,
	_showButton: null,
	_hideButton: null,
	_clearButton: null,
	hidden: true,

	_topPoint: cc.p(),
	_curPoint: cc.p(),

	_functionButtonStacks: {},
	_robotIdStack: [],
	_activeButtonsCount: 0,

	_scroll: null,

	_stackCapacity: 0,

	_methodListButton: null,

	_level: null,

	_recognizeMode: false,
	_recognizeArray: [],
	_recognizePhotoButton: null,

	ctor: function(height, level)
	{
		this._super();

		MethodStackLayer.opened = false;

		this._level = level;

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._stackCapacity = Math.floor(height / ProgramContainerLayer.BUTTON_SIZE);
		this._stackCapacity = this._stackCapacity > 0 ? this._stackCapacity : 2;

		this._innerLayer = new pmui.DragAndDropLayout(pmui.DragAndDropLayout.Type.DRAG_ONLY);
		this._innerLayer.addDropTargetName(ProgramContainerLayer.DNDNAME);
		this._innerLayer.setLayoutType(ccui.Layout.RELATIVE);
		this._innerLayer.setCascadeOpacityEnabled(true);
		this._innerLayer.setBackGroundImageScale9Enabled(true);
		this._innerLayer.setBackGroundImage("System/MSL_Background.png");
		this._innerLayer.setBackGroundImageCapInsets(cc.rect(23, 73, 32, 35));
		this._innerLayer.setContentSize(MethodStackLayer.INNER_WIDTH, (this._stackCapacity) * ProgramContainerLayer.BUTTON_SIZE);

		this._showButton = new pmui.Button(pm.spriteUtils.getIconName("methodStackShow", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("methodStackShow", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("methodStackShow", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._hideButton = new pmui.Button(pm.spriteUtils.getIconName("methodStackHide", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("methodStackHide", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("methodStackHide", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._clearButton = new pmui.Button(pm.spriteUtils.getIconName("clear", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clear", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clear", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._clearButton.setEnabled(false);
		this._clearButton.setOpacity(0);
		this._hideButton.setVisible(false);

		var showButtonAlign = new ccui.RelativeLayoutParameter();
		showButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		showButtonAlign.setMargin(1, MethodStackLayer.CONTROL_BUTTONS_MARGIN, 0, 0);
		showButtonAlign.setRelativeName("showButton");
		this._showButton.setLayoutParameter(showButtonAlign);

		this._hideButton.setLayoutParameter(showButtonAlign.clone());

		var clearButtonAlign = new ccui.RelativeLayoutParameter();
		clearButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_RIGHTALIGN);
		clearButtonAlign.setRelativeToWidgetName("showButton");
		clearButtonAlign.setMargin(0, -10, 2, 0);
		this._clearButton.setLayoutParameter(clearButtonAlign);

		this._showButton.addClickEventListener(this._show.bind(this));
		this._hideButton.addClickEventListener(this._hide.bind(this));
		this._clearButton.addClickEventListener(this._clearCallback.bind(this));

		this.addChild(this._hideButton);
		this.addChild(this._showButton);
		this.addChild(this._clearButton);

		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.SHOW_MSL, this._showButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.HIDE_MSL, this._hideButton);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.CLEAR_MSL, this._clearButton);

		var buttonWidth = Math.max(this._hideButton.width, this._clearButton.width);

		this.setContentSize(MethodStackLayer.INNER_WIDTH + buttonWidth, (this._stackCapacity) * ProgramContainerLayer.BUTTON_SIZE);

		this._scroll = new MethodStackScrollLayer(cc.size(ProgramContainerLayer.BUTTON_SIZE,
			this.height - ProgramContainerLayer.BUTTON_SIZE));

		this._scroll.setMinimalPlaceholderHeight(1.5 * ProgramContainerLayer.BUTTON_SIZE);
		this._scroll.setItemsPopCallback(this._removeMethods, this);

		var scrollAlign = new ccui.RelativeLayoutParameter();
		scrollAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		scrollAlign.setMargin(MethodStackLayer.SCROLL_LEFT_MARGIN, 0, 0, MethodStackLayer.SCROLL_BOTTOM_MARGIN);
		this._scroll.setLayoutParameter(scrollAlign);

		var innerAlign = new ccui.RelativeLayoutParameter();
		innerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		innerAlign.setMargin(buttonWidth - MethodStackLayer.SCROLL_CORRECTION_X, 0, 0, 0);
		this._innerLayer.setLayoutParameter(innerAlign);

		this._innerLayer.addChild(this._scroll);
		this.addChild(this._innerLayer);

		var x = MethodStackLayer.SCROLL_LEFT_MARGIN + buttonWidth - MethodStackLayer.SCROLL_CORRECTION_X;

		this._topPoint = cc.p(x, this.getContentSize().height - ProgramContainerLayer.BUTTON_SIZE);
		this._curPoint = cc.p(x, MethodStackLayer.SCROLL_BOTTOM_MARGIN);

		this._functionButtonStacks = {};

		for (var i = 0; i < this._level.robots.length; ++i)
			this._functionButtonStacks[this._level.robots[i].groupID] = [];

		this._robotIdStack = [];
		this._activeButtonsCount = 0;

		this._methodListButton = pm.appUtils.createMethodListButton(0);

		var dragStackButtonAlign = new ccui.RelativeLayoutParameter();
		dragStackButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		dragStackButtonAlign.setMargin(0, 0, 0, 0);
		this._methodListButton.setLayoutParameter(dragStackButtonAlign);
		this._methodListButton.getMethodList = this._getMethodList.bind(this);

		this._innerLayer.addChild(this._methodListButton, 0);
		pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.DRAG_STACK, this._methodListButton, true);

		this._innerLayer.setOpacity(0);

		this._innerLayer._findElementByTouch = function(touch)
		{
			var currentRobotGroupID = this._level.getCurrentRobot().groupID;

			if(this.hidden || !this.enabled || this._functionButtonStacks[currentRobotGroupID].length === 0)
				return null;

			if(cc.rectContainsPoint(this._methodListButton.getBoundingBox(), this._innerLayer.convertTouchToNodeSpace(touch)))
				return this._methodListButton;

			return null;
		}.bind(this);

		this._innerLayer._cloneElement = function()
		{
			var image = this._methodListButton.cloneImage();
			var y = image.height;

			var currentRobotGroupID = this._level.getCurrentRobot().groupID;

			for(var i = 0; i < this._functionButtonStacks[currentRobotGroupID].length; ++i)
			{
				var clone = this._functionButtonStacks[currentRobotGroupID][i].cloneImage();
				clone.setAnchorPoint(0, 0.5);
				clone.setPosition(0, y);
				clone.setOpacity(0.8 * 255);

				image.addChild(clone, -(i + 1));
				y += clone.height / 2;

				var action = cc.moveTo(pm.SYSTEM_ANIMATION_DELAY * 2, 0, clone.height / 2);
				var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY * 2);

				clone.runAction(cc.sequence(delay, action));
			}

			return image;

		}.bind(this);

		this._innerLayer.addDNDEventListener(function(element, eventType, touch)
		{
			if (eventType === pmui.DragAndDropLayout.Event.CLICKED)
				element.select();
		}, this._innerLayer);

		pm.registerCustomEventListener(pm.ANIMATE_HIDE_BUTTON, this.animateHideButton.bind(this), this);
	},

	setEnabled: function(flag)
	{
		ccui.Layout.prototype.setEnabled.call(this, flag);

		this._showButton.setEnabled(flag);
		this._hideButton.setEnabled(flag);
		this._clearButton.setEnabled(this.hidden || flag);

		this._scroll.setEnabled(flag);

		if(flag)
            pm.sendCustomEvent(pm.METHOD_STACK_LAYER_ACTIVE_EVENT_STR);
    },

	_toggleIcons: function()
	{
		this._showButton.setVisible( !this._showButton.isVisible() );
		this._hideButton.setVisible( !this._hideButton.isVisible() );
	},

	processRecognizeArray: function(recognizeArray, recognizePhotoButton)
	{
		this._recognizeMode = true;
		this._recognizeArray = recognizeArray;
		this._recognizePhotoButton = recognizePhotoButton;

		if (this._recognizeArray.length === 0)
			return;

		this._recognizePhotoButton.loadTextureNormal(pm.spriteUtils.getIconName("portal", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
		this._recognizePhotoButton.loadTexturePressed(pm.spriteUtils.getIconName("portal", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
		this._recognizePhotoButton.loadTextureDisabled(pm.spriteUtils.getIconName("portal", pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);

		if (MethodStackLayer.opened)
			this._processNextRecognizeButton();
		else
			this._show();
	},

	_processNextRecognizeButton: function()
	{
		var button = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, this._recognizeArray[0]);
		this._addMethod(button);
	},

	_addMethod: function(button)
	{
		if(!button.isMethod() || !pm.robotManager.canAddForceCommand())
		{
			if (this._recognizeMode)
			{
				this._recognizeArray = [];
				this._recognizeMode = false;

				this._recognizePhotoButton.loadTextureNormal(pm.spriteUtils.getIconName("photo", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
				this._recognizePhotoButton.loadTexturePressed(pm.spriteUtils.getIconName("photo", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
				this._recognizePhotoButton.loadTextureDisabled(pm.spriteUtils.getIconName("photo", pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);
			}

			return;
		}

		if (this._recognizeMode)
			this.animatePhotoButton();

		this.setEnabled(false);

		this._processMethod(button);

		var sprite = button.cloneImage();

		var draggedElementBB = button.getBoundingBoxToWorld();

		var buttonWorldPosition;

		var finalScale = draggedElementBB.width / sprite.width;

		if (this._recognizeMode)
		{
			sprite.setScale(finalScale/5);
			buttonWorldPosition = this._recognizePhotoButton.convertToWorldSpace(cc.p(20, 20));
		}
		else
		{
			sprite.setScale(finalScale);
			buttonWorldPosition = button.convertToWorldSpace(cc.p(0, 0));
		}

		var topWorldPoint = this.convertToWorldSpace(this._topPoint);

		sprite.setPosition(buttonWorldPosition);

		pm.runningSceneUtils.addChild(sprite);

		sprite.setAnchorPoint(cc.p(0, 0));

		var moveSpriteParabolic;

		if (this._recognizeMode)
			moveSpriteParabolic = cc.jumpBy(3.0 * pm.SYSTEM_ANIMATION_DELAY, cc.pSub(topWorldPoint, sprite.getPosition()), 80.0, 1);
		else
			moveSpriteParabolic = cc.jumpBy(3.0 * pm.SYSTEM_ANIMATION_DELAY, cc.pSub(topWorldPoint, sprite.getPosition()), 110.0, 1);

		var moveToPoint = cc.p(this._curPoint);
		var maximumYPoint = this.height - 1.5 * ProgramContainerLayer.BUTTON_SIZE;
		moveToPoint.y = moveToPoint.y > maximumYPoint ? maximumYPoint : moveToPoint.y;

		var curWorldPoint = this.convertToWorldSpace(moveToPoint);

		var moveDownRatio = (this.height - moveToPoint.y) / this.height;

		var moveSpriteDown = cc.moveTo(3.0 * pm.SYSTEM_ANIMATION_DELAY * moveDownRatio, curWorldPoint.x, curWorldPoint.y);

		var reorder = cc.callFunc(function(obj)
		{
			obj.retain();
            pm.runningSceneUtils.removeChild(sprite, false);
			obj.setScale(1);
			this._scroll.pushItem(obj);
			obj.release();
		}, this, sprite);

		var moveEnd = cc.callFunc(this._endAddMethod, this);

		this._curPoint.y += ProgramContainerLayer.BUTTON_SIZE;

		if (this._recognizeMode)
		{
			var scaleAnimation = new cc.ScaleTo(pm.SYSTEM_ANIMATION_DELAY * 2.2, finalScale)
			sprite.runAction(scaleAnimation);
		}

		sprite.runAction(cc.sequence(moveSpriteParabolic, moveSpriteDown, reorder, moveEnd));
	},

	_endAddMethod: function()
	{
		var currentRobotGroupID = this._level.getCurrentRobot().groupID;

		this._methodListButton.value = this._functionButtonStacks[currentRobotGroupID].length;
		this.setEnabled(true);

		if (this._recognizeMode)
		{
			this._recognizeArray.splice(0, 1);

			if (this._recognizeArray.length > 0)
			{
				this._processNextRecognizeButton();
			}
			else
			{
				this._recognizeMode = false;

				this._recognizePhotoButton.loadTextureNormal(pm.spriteUtils.getIconName("photo", pm.NORMAL_STATE), ccui.Widget.PLIST_TEXTURE);
				this._recognizePhotoButton.loadTexturePressed(pm.spriteUtils.getIconName("photo", pm.SELECTED_STATE), ccui.Widget.PLIST_TEXTURE);
				this._recognizePhotoButton.loadTextureDisabled(pm.spriteUtils.getIconName("photo", pm.DISABLED_STATE), ccui.Widget.PLIST_TEXTURE);
			}
		}
	},

	_removeMethods: function(removeCount)
	{
		if(pm.robotManager.state !== pm.RobotManager.State.Paused)
			return false;

		if (removeCount > this._activeButtonsCount)
			return false;

		var currentRobotGroupID = this._level.getCurrentRobot().groupID;

		var count = 0;

		for(var i = 0 ; i < removeCount; ++i)
		{
			var button = this._functionButtonStacks[currentRobotGroupID].pop();

			if (!button)
				return false;

			if (button.isMethod())
				++count;

			this._curPoint.y -= ProgramContainerLayer.BUTTON_SIZE;

			--this._activeButtonsCount;
			this._robotIdStack.pop();
		}

		pm.robotManager.revertForceCommands(pm.CMD_INDICATE, count);

		this._methodListButton.value = this._functionButtonStacks[currentRobotGroupID].length;

		if(this._functionButtonStacks[currentRobotGroupID].length === 0)
			FunctionButton.deselect();

		return true;
	},

	_processMethod: function(button)
	{
		if(button.isMethod())
			pm.robotManager.addForceCommand(button.value);

		var currentRobotGroupID = this._level.getCurrentRobot().groupID;

		this._functionButtonStacks[currentRobotGroupID].push(button);
		this._robotIdStack.push(currentRobotGroupID);
		++this._activeButtonsCount;
	},

	_clearCallback: function()
	{
		if(pm.robotManager.state !== pm.RobotManager.State.Paused)
			return;

		this._clear();
	},

	_clear: function()
	{
		this._scroll.clear();

		for (var o in this._functionButtonStacks)
			this._functionButtonStacks[o].length = 0;

		this._activeButtonsCount = 0;
		this._robotIdStack = [];

		this._clearButton.setEnabled(false);

		this._curPoint.y = MethodStackLayer.SCROLL_BOTTOM_MARGIN;

		this._methodListButton.value = 0;
		FunctionButton.deselect();

		pm.robotManager.state = pm.RobotManager.State.Paused;
	},

	_show: function(sender)
	{
		this._functionButtonStacks = {};

		for (var i = 0; i < this._level.robots.length; ++i)
			this._functionButtonStacks[this._level.robots[i].groupID] = [];

		MethodStackLayer.opened = true;

		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, -this._innerLayer.width - 5, 0);

		this.setEnabled(false);

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 255);

		var fadeColorLayer = cc.targetedAction(this._innerLayer, fadeLayer);
		var fadeColorButton = cc.targetedAction(this._clearButton, fadeLayer.clone());

		var moveEnd = cc.callFunc(this._showEnded, this);

		this.runAction(cc.sequence(cc.spawn(moveLayer, fadeColorLayer, fadeColorButton), moveEnd));

		FunctionButton.deselect();
	},

	_hide: function(sender)
	{
		if (pm.robotManager.state !== pm.RobotManager.State.Paused && pm.robotManager.state !== pm.RobotManager.State.PausedByStep)
			return;

		MethodStackLayer.opened = false;

		FunctionButton.deselect();

		var moveLayer = cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, this._innerLayer.width + 5, 0);

		this.setEnabled(false);

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);

		var fadeColorLayer = cc.targetedAction(this._innerLayer, fadeLayer);
		var fadeColorButton = cc.targetedAction(this._clearButton, fadeLayer.clone());

		var moveEnd = cc.callFunc(this._hideEnded, this);

		this.runAction(cc.sequence(cc.spawn(moveLayer, fadeColorLayer, fadeColorButton), moveEnd) );

		pm.sendCustomEvent(pm.DISABLE_CONTROLLED_MODE_STR);

		pm.robotManager.setControlledMode(false);
	},

	_showEnded: function()
	{
		this.hidden = false;
		this.setEnabled(true);
		this._clearButton.setEnabled(false);
		this._toggleIcons();

		pm.sendCustomEvent(pm.ENABLE_CONTROLLED_MODE_STR,
			new pm.ControlledModeData(this._addMethod, this));

		if(pm.settings.isAnimationDisabled())
			pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED);

		pm.robotManager.setControlledMode(true);
		pm.sendCustomEvent(pm.METHOD_STACK_LAYER_OPENED_EVENT_STR);

		if (this._recognizeMode)
			this._processNextRecognizeButton();
	},

	_hideEnded: function()
	{
		this.hidden = true;
		this._toggleIcons();
		this.setEnabled(true);
		this._clearButton.setEnabled(false);
		this._clear();
		this._recognizeMode = false;
		this._recognizeArray = [];

		if(pm.settings.getAnimationSpeed() === pm.MIN_ANIMATION_SPEED)
			pm.settings.setAnimationSpeed(pm.MIN_ANIMATION_SPEED - 0.01);

		pm.sendCustomEvent(pm.METHOD_STACK_LAYER_CLOSED_EVENT_STR);
	},

	_getMethodList: function()
	{
		var currentRobotGroupID = this._level.getCurrentRobot().groupID;

		var ret = [];

		for(var i = 0; i < this._functionButtonStacks[currentRobotGroupID].length; ++i)
		{
			var button = this._functionButtonStacks[currentRobotGroupID][i];

			ret.push(button.value);
		}

		return ret;
	},

	drawRobotMethodList: function(robotGroupId)
	{
		this._scroll.clear();

		this._curPoint.y = MethodStackLayer.SCROLL_BOTTOM_MARGIN;

		this._methodListButton.value = 0;
		FunctionButton.deselect();

		pm.robotManager.state = pm.RobotManager.State.Paused;

		this._activeButtonsCount = 0;

		for (i = this._robotIdStack.length - 1; i >= 0; --i)
		{
			if (robotGroupId === this._robotIdStack[i])
				++this._activeButtonsCount;
			else
				break;
		}

		for (var i = 0; i < this._functionButtonStacks[robotGroupId].length; ++i)
		{
			var button = this._functionButtonStacks[robotGroupId][i];

			var sprite = null;

			if (i < this._functionButtonStacks[robotGroupId].length - this._activeButtonsCount)
				sprite = button.cloneDisabledImage();
			else
				sprite = button.cloneImage();

			this._scroll.pushItem(sprite);

			this._curPoint.y += ProgramContainerLayer.BUTTON_SIZE;
		}

		this._methodListButton.value = this._functionButtonStacks[robotGroupId].length;
	},

	animateHideButton: function ()
	{
		var showSelected = cc.callFunc(function()
		{
			this.setHighlighted(true);
		}, this._hideButton);

		var showNormal = cc.callFunc(function()
		{
			this.setHighlighted(false);
		}, this._hideButton);

		var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY);

		this._hideButton.runAction(cc.repeat(cc.sequence(showSelected, delay, showNormal, delay), 2));
	},

	animatePhotoButton: function ()
	{
		var showSelected = cc.callFunc(function()
		{
			this.setHighlighted(true);
		}, this._recognizePhotoButton);

		var scaleToBig = new cc.ScaleTo(pm.SYSTEM_ANIMATION_DELAY / 2, 1.2)
		var scaleToSmall = new cc.ScaleTo(pm.SYSTEM_ANIMATION_DELAY / 2, 1.0)

		var showNormal = cc.callFunc(function()
		{
			this.setHighlighted(false);
		}, this._recognizePhotoButton);
		
		this._recognizePhotoButton.runAction(cc.sequence(showSelected, scaleToBig, scaleToSmall, showNormal));
	}

});

/**
 * Outer right margin when showed.
 * @const
 * @default
 * @type {Number}
 */
MethodStackLayer.OUTER_RIGHT_MARGIN = 5;
MethodStackLayer.INNER_WIDTH = 83;
MethodStackLayer.CONTROL_BUTTONS_MARGIN = 40;
MethodStackLayer.SCROLL_BOTTOM_MARGIN = 45;
MethodStackLayer.SCROLL_LEFT_MARGIN = 22;
MethodStackLayer.SCROLL_CORRECTION_X = 2;

/**
 * Current state of method stack (opened or closed).
 */
MethodStackLayer.opened = false;
