/**
 * Created by Nikita Besshaposhnikov on 16.07.15.
 */

/**
 * This namespace contains functions for working with backgrounds.
 * @namespace
 */
pm.backgroundUtils = {

	_levelBackgroundParameters: {},

	generateBackground: function()
	{
		var layer = new cc.LayerColor(pm.backgroundUtils.BACKGROUND_COLOR);

		var screenSize = pm.settings.getScreenSize();
		var centerX = screenSize.width / 2;
		var centerY = screenSize.height / 2;

		layer.setContentSize(screenSize);

		var centerSpr = new cc.Sprite("Background/main_background.png");

		var mist1 = new cc.Sprite("#stars.png");
		var mist2 = new cc.Sprite("#stars.png");
		var galaxy1 = new cc.Sprite("#galaxy.png");
		var galaxy2 = new cc.Sprite("#galaxy.png");
		var light1 = new cc.Sprite("#light.png");
		var light2 = new cc.Sprite("#light.png");

		centerSpr.setAnchorPoint(cc.p(0.5, 0.5));
		centerSpr.setPosition(centerX, centerY);
		centerSpr.setScale(4);

		layer.addChild(centerSpr);

		var i = 0;
		var skipCount = 0;

		var excludedAreas = [
			cc.rect(screenSize.width/3 - mist1.width/2, 3*screenSize.height/4 - mist1.height/2, mist1.width, mist1.height),
			cc.rect(5*screenSize.width/6 - mist2.width/2, screenSize.height/2 - mist2.height/2, mist2.width, mist2.height),
			cc.rect(screenSize.width/4 - galaxy1.width/2, screenSize.height/5 - galaxy1.height/2, galaxy1.width, galaxy1.height),
			cc.rect(2*screenSize.width/3 - galaxy2.width/2, 7*screenSize.height/9 - galaxy2.height/2, galaxy2.width, galaxy2.height)
		];

		while (i < pm.backgroundUtils.STARS_INTENSITY)
		{
			var x = cc.random0To1() * screenSize.width;
			var y = cc.random0To1() * screenSize.height;

			var exclude = false;

			for(var j = 0; j < excludedAreas.length; ++j)
			{
				if(cc.rectContainsPoint(excludedAreas[j], cc.p(x, y)))
				{
					exclude = true;
					break;
				}
			}

			if (!exclude && skipCount > pm.backgroundUtils.MAX_SKIP_COUNT)
			{
				var star = new cc.Sprite("#Star{0}.png".format(i % 4));

				star.setPosition(cc.p(x, y));

				star.setScale(cc.random0To1() / 2);
				star.setOpacity(255 * (cc.random0To1() / 2 + 0.4));

				layer.addChild(star);

				++i;
				skipCount = 0;
			}
			else
			{
				++skipCount;
			}
		}

		light1.setAnchorPoint(cc.p(0.5, 0));
		light2.setAnchorPoint(cc.p(0.5, 0));

		mist1.setPosition(cc.p(screenSize.width/3, 3*screenSize.height/4));
		mist2.setPosition(cc.p(5*screenSize.width/6, screenSize.height/2));
		galaxy1.setPosition(cc.p(screenSize.width/4, screenSize.height/5));
		galaxy2.setPosition(cc.p(2*screenSize.width/3, 7*screenSize.height/9));
		light1.setPosition(cc.p(screenSize.width/2, -80));
		light2.setPosition(cc.p(screenSize.width/2, screenSize.height + 80));

		galaxy1.setRotation(50);
		galaxy1.setScale(0.8);
		light2.setRotation(180);

		layer.addChild(mist1);
		layer.addChild(mist2);
		layer.addChild(galaxy1);
		layer.addChild(galaxy2);
		layer.addChild(light1);
		layer.addChild(light2);

		return layer;
	},

	/**
     * Generates background layer by level type.
     * @param {String} levelType
     * @param {cc.Size} areaSize
     */
	generateLevelBackground: function(levelType, areaSize)
	{
		var parameters = pm.moduleUtils.getBackgroundParameters(levelType);

		return this._generateLayerByParams(parameters, areaSize);
	},

	_generateLayerByParams: function(parameters, areaSize)
	{
		var layer = new cc.LayerColor(parameters.color, areaSize.width, areaSize.height);

		var centerX = areaSize.width / 2;
		var centerY = areaSize.height / 2;

		if(parameters.centerImage)
		{
			var centerSpr = new cc.Sprite(parameters.centerImage);

			if (areaSize.height < centerSpr.height)
				centerSpr.setScale(4 * areaSize.height/centerSpr.height);
			else
				centerSpr.setScale(4);

			centerSpr.setAnchorPoint(cc.p(0.5, 0.5));
			centerSpr.setPosition(centerX, centerY);

			layer.addChild(centerSpr);
		}

		var intensityMultiplier = areaSize.width / pm.settings.getScreenSize().width;

		for(var i = 0 ; i < pm.backgroundUtils.STARS_INTENSITY * intensityMultiplier; ++i)
		{
			var angle = cc.random0To1() * Math.PI * 2;
			var radiusX = cc.rand() % (areaSize.width/2);
			var radiusY = cc.rand() % (areaSize.height/2);

			var star = new cc.Sprite("#Star{0}.png".format(i%4));

			star.setPosition(cc.p(centerX + Math.cos(angle) * radiusX, centerY + Math.sin(angle) * radiusY));

			star.setScale(cc.random0To1()/2);
			star.setOpacity(255 * (cc.random0To1()/2+0.4));

			layer.addChild(star);
		}

		return layer;
	}
};

pm.backgroundUtils.BACKGROUND_COLOR = cc.color(61, 61, 154);
pm.backgroundUtils.STARS_INTENSITY = 150;
pm.backgroundUtils.MAX_SKIP_COUNT = 3;
pm.backgroundUtils.ITEMS_INTENSITY = 4;
pm.backgroundUtils.WIDTH_DELTA = 20;
