/**
 * Created by Antony Orlovsky on 04.03.18.
 */

/**
 * @class Layer for choosing world.
 * @extends cc.Layer
 */
var SelectWorldLayer = ccui.Layout.extend(/** @extends SelectWorldLayer# */{

	_menu: null,
	_groupSelect: null,

	_selectedGroupID: "",
	_selectedWorldID: "",

	_worldList: null,

	_loadingLayer: null,

	_comboBoxMoving: 0,
	_innerContainerOldPosition: cc.p(),

	_startInnerPosition: null,

	_scale: 1,
	_menuButtonWidth: 0,

	ctor: function ()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._selectedGroupID = pm.settings.getSelectedUserGroupID();
		this._selectedWorldID = pm.settings.getSelectedWorldID();

		this._worldList = new WorldList(this._onLoadWorldList, this);

		this.setContentSize(pm.settings.getScreenSize());

		this._loadInterface();

		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new SelectWorldScene());
		}, this);
	},

	_loadInterface: function()
	{
		var screenBounds = pm.settings.getScreenBounds();

		var goToStartScreen = new pmui.Button( pm.spriteUtils.getIconName("back", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("back", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("back", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		goToStartScreen.addClickEventListener(this._startScreen.bind(this));

		var goToStartScreenAlign = new ccui.RelativeLayoutParameter();
		goToStartScreenAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		goToStartScreenAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);
		goToStartScreen.setLayoutParameter(goToStartScreenAlign);

		this.addChild(goToStartScreen, 1);

		var menuButton = new pmui.Button( pm.spriteUtils.getIconName("menu", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("menu", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("menu", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		this._menuButtonWidth = menuButton.width;

		menuButton.addClickEventListener(this._showMenu.bind(this));

		var menuButtonAlign = new ccui.RelativeLayoutParameter();
		menuButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		menuButtonAlign.setMargin(0, screenBounds.top, screenBounds.right, 0);
		menuButtonAlign.setRelativeName("menu");
		menuButton.setLayoutParameter(menuButtonAlign);

		this.addChild(menuButton, 1);

		var updateWorldList = new pmui.Button(pm.spriteUtils.getIconName("updateWorld", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("updateWorld", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
		updateWorldList.addClickEventListener(this.refresh.bind(this)); //troubles with cache

		var updateWorldListAlign = new ccui.RelativeLayoutParameter();
		updateWorldListAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		updateWorldListAlign.setRelativeToWidgetName("menu");
		updateWorldListAlign.setMargin(0, 0, SelectWorldLayer.SEPARATOR, 0);
		updateWorldList.setLayoutParameter(updateWorldListAlign);

		this.addChild(updateWorldList);

		this._scale = (this.getContentSize().width - screenBounds.left - screenBounds.right) / (SelectWorldLayer.BUTTON_SIZE + SelectWorldLayer.TEXT_WIDTH);
		this._scale -= 0.02;

		if (this._scale > 1)
			this._scale = 1;

		this._menu = new pmui.SlidingMenu(Math.min(this.getContentSize().width, SelectWorldLayer.ELEMENT_WIDTH) - screenBounds.left - screenBounds.right,
			this.getContentSize().height - (menuButton.height + screenBounds.top),
			this._selectWorld, this, ccui.ScrollView.DIR_VERTICAL);

		if (cc.sys.isNative)
			ccui.ScrollView.prototype._addEventListener.call(this._menu, this._moveComboBox.bind(this));
		else
			ccui.ScrollView.prototype.addEventListener.call(this._menu, this._moveComboBox.bind(this));

		this._innerContainerOldPosition = this._menu.getInnerContainerPosition() - 100;

		var slidingMenuAlign = new ccui.RelativeLayoutParameter();
		slidingMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		slidingMenuAlign.setMargin(0, menuButton.height + screenBounds.top, 0, 0);
		this._menu.setLayoutParameter(slidingMenuAlign);

		this.addChild(this._menu);

		this._groupSelect = new pmui.ComboBox([], this, this._selectGroupOnSelect, 35, 200, this._selectGroupOnShow, this._selectGroupOnClose);

		var groupAlign = new ccui.RelativeLayoutParameter();
		groupAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_CENTER_HORIZONTAL);
		groupAlign.setMargin(0, screenBounds.top, 0, 0);
		this._groupSelect.setLayoutParameter(groupAlign);

		this._comboBoxMoving = 0;

		this.addChild(this._groupSelect);

		this._groupSelect.enabled = false;

		this.refresh();
	},

	_moveComboBox: function (target, event)
	{
		if (!this._startInnerPosition)
			this._startInnerPosition = this._menu.getInnerContainerPosition();

		if (event === ccui.ScrollView.EVENT_CONTAINER_MOVED)
		{
			if (this._innerContainerOldPosition.y < this._startInnerPosition.y || this._menu.getInnerContainerPosition().y >= 0)
				return;

			if (this._menu.getInnerContainerPosition().y > this._innerContainerOldPosition.y)
			{
				if (this._comboBoxMoving === 0)
				{
					this.runAction(cc.targetedAction(this._groupSelect, cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, 0, 110)));
					this._comboBoxMoving = 1;
				}
			}
			else if (this._menu.getInnerContainerPosition().y < this._innerContainerOldPosition.y)
			{
				if (this._comboBoxMoving === 1)
				{
					this.runAction(cc.targetedAction(this._groupSelect, cc.moveBy(pm.SYSTEM_ANIMATION_DELAY, 0, -110)));
					this._comboBoxMoving = 0;
				}
			}
		}
		this._innerContainerOldPosition = this._menu.getInnerContainerPosition();
	},

	_drawGroup: function()
	{
		var selectedGroup = this._getSelectGraph()[this._selectedGroupID];
		var worldList = selectedGroup.worlds;
		var selectedIndex = -1;

		for (var i = 0; i < worldList.length; ++i)
		{
			if (worldList[i].id === this._selectedWorldID)
				selectedIndex = i;
		}

		if(selectedIndex !== -1)
		{
			var selectedWorldID = worldList[selectedIndex].id;

			if (this._selectedWorldID !== selectedWorldID)
				this._selectedWorldID = selectedWorldID;
		}

		this._menu.removeAllChildren();

		var worldNumber = 0;

		var worldsLength = selectedGroup.worlds.length;

		var screenBounds = pm.settings.getScreenBounds();
		var width = Math.min(this.getContentSize().width, SelectWorldLayer.ELEMENT_WIDTH) - screenBounds.left - screenBounds.right;

		for(var i = 0; i < worldsLength; ++i)
		{
			var curWorld = selectedGroup.worlds[i];

			var galaxyNode = new ccui.ImageView("WorldPics/galaxy{0}.png".format(worldNumber % 3));
			galaxyNode.setContentSize(galaxyNode.width*this._scale, galaxyNode.height*this._scale);

			var highlight = false;

			if (this._selectedWorldID === curWorld.id)
				highlight = true;

			var element = {
				node: galaxyNode,
				label: curWorld.name,
				number: worldNumber % 2,
				description: curWorld.description || ""
			};

			this._menu.addMenuElement(new pmui.SlidingWorldMenuElement(width, element, highlight));
			worldNumber++;
		}

		for (var i = 0; i < this._menu.getChildren().length; i++)
		{
			var child = this._menu.getChildren()[i];
			for (var j = 0; j < child.getChildren().length; j++)
			{
				var ch = child.getChildren()[j];
				ch.setScale(this._scale);
			}
		}

		this.forceDoLayout();
	},

	_startScreen: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_showMenu: function()
	{
		if(cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
			return;

		var settingsLayer = new SettingsLayer(this, this._closeMenu);

		cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
	},

	_closeMenu: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	},

	_removeLoadingLayer: function()
	{
		if (this._loadingLayer)
		{
			this._loadingLayer.remove();
			this._loadingLayer = null;
		}
	},

	_addLoadingLayer: function()
	{
		if(this._loadingLayer)
			this._removeLoadingLayer();

		this._loadingLayer = new LoadingLayer();
		this._loadingLayer.show();
	},

	refresh: function()
	{
		this._addLoadingLayer();
		this._worldList.loadList();
	},

	_onLoadWorldList: function()
	{
		this._removeLoadingLayer();

		this._fillGroupsCombobox();
		this._drawGroup();
	},

	_getSelectGraph: function()
	{
		return this._worldList.getSelectGraph();
	},

	_selectGroupOnSelect: function(index)
	{
		this._menu.enabled = true;

		var id = Object.keys(this._getSelectGraph())[index];

		if (this._selectedGroupID !== id)
		{
			this._selectedGroupID = id;

			this._drawGroup();
		}
	},

	_selectGroupOnShow: function()
	{
		this._menu.enabled = false;
	},

	_selectGroupOnClose: function()
	{
		this._menu.enabled = true;
	},

	_fillGroupsCombobox: function()
	{
		var selectedGroupID = this._setComboboxValues(this._groupSelect, this._getSelectGraph(), this._selectedGroupID);

		if(this._selectedGroupID !== selectedGroupID)
			this._selectedGroupID = selectedGroupID;

		this._groupSelect.enabled = true;
	},

	_setComboboxValues: function(combobox, valueList, selectValueID)
	{
		var comboboxValues = [];
		var selectedIndex = 0;
		var i = 0;

		for(var id in valueList)
		{
			comboboxValues.push(cc.isObject(valueList[id]) ? valueList[id].name : valueList[id]);

			if(id === selectValueID)
				selectedIndex = i;

			++i;
		}

		combobox.setValueList(comboboxValues);

		combobox.selectObject(selectedIndex);

		return Object.keys(valueList)[selectedIndex];
	},

	_selectWorld: function(index)
	{
		var selectedGroup = this._getSelectGraph()[this._selectedGroupID];
		var worldList = selectedGroup.worlds;
		var id = worldList[index].id;

		if(this._selectedWorldID !== id)
		{
			this._selectedWorldID = id;

			this._downloadWorld();
		}
		else
		{
			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectGameScene());
			cc.director.runScene(trans);
			this._removeLoadingLayer();
		}
	},

	_downloadWorld: function()
	{
		this._addLoadingLayer();

		pm.worldUtils.loadWorld({
			worldID: this._selectedWorldID,
			callback: this._endDownLoadWorld,
			callbackTarget: this,
			reloadBuiltinOnError: true
		});
	},

	_endDownLoadWorld: function(error, loadedWorldID)
	{
		if(!error && loadedWorldID.length > 0 && loadedWorldID[0])
		{
			pm.settings.setSelectedWorldID(loadedWorldID[0]);
			pm.settings.setSelectedUserGroupID(this._selectedGroupID);

			// Loaded built-in, so load public group
			if(loadedWorldID[0] !== this._selectedWorldID)
			{
				this._selectedWorldID = loadedWorldID[0];

				if(!pm.settings.isEditorMode)
				{
					this._selectedGroupID = SelectWorldLayer.PUBLIC_GROUP.id;
					pm.settings.setSelectedUserGroupID(this._selectedGroupID);
				}
				else
				{
					this._selectedGroupID = SelectWorldLayer.OWN_GROUP.id;
				}
			}

			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectGameScene());
			cc.director.runScene(trans);
		}

		this._removeLoadingLayer();
	}
});

SelectWorldLayer.SEPARATOR = 25;
SelectWorldLayer.BUTTON_SIZE = 256;
SelectWorldLayer.TEXT_WIDTH = 500;
SelectWorldLayer.TEXT_HEIGHT = 56;
SelectWorldLayer.ELEMENT_WIDTH = 1300;

/**
 * @class Scene for {@link StartMenuLayer}
 * @extends cc.Scene
 * @constructor
 */
var SelectWorldScene = cc.Scene.extend(/** @lends SelectWorldScene# */{
	onEnterTransitionDidFinish: function ()
	{
		this._super();

		var layer = new SelectWorldLayer();

		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);

		if (!cc.sys.isNative)
		    window.location.hash = "worlds";
	}
});
