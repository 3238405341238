/**
 * @class This a common interface for all levels.
 * @interface
 * @extends pm.Class
 */
pm.AbstractLevel = pm.Class.extend(/** @lends pm.AbstractLevel#*/{
	/**
     * id of level.
     * @type {String}
     */
	id: "",
	/**
     * Version format level.
     * @type {Number}
     */
	version: -1,
	/**
     * Revision of level.
     * @type {Number}
     */
	revision: 1,
	/**
     * Order of level.
     * @type {Number}
     */
	order: 0,
	/**
     * Displayed name of level.
     * @type {String}
     */
	name: "",
	/**
	 * Is level a tutorial.
	 * @type {Boolean}
	 */
	isTutorial: false,
	/**
	 * Scenario of a tutorial in level. If level is not a tutorial it is an empty object.
	 * @type {pm.data.TutorialScenario|{}}
	 */
	tutorialScenario: {},
	/**
     * Index of start robot in robots array.</br>
     * @see pm.AbstractLevel#robots
     * @type {Number}
     */
	startRobotIndex: 0,

	/**
     * Current robot for which player is writing program.
     * @private
     * @type {pm.AbstractRobot}
     */
	_currentRobot: {},

	/**
     * Array of robots.
     * @type {Array<pm.AbstractRobot>}
     */
	robots: [],

	/**
	 * Map of original program data to robot's groups. <br/>
	 * Contains groupID -> pm.data.ProgramData
	 * @see pm.data.ProgramData
	 * @type {pm.data.MapClass}
     */
	programData: new pm.data.MapClass(),

	/**
     * Map of program data cache to robot's groups. <br/>
     * Contains groupID -> pm.data.ProgramData
     * @see pm.data.ProgramData
     * @type {pm.data.MapClass}
     */
	programDataCache: new pm.data.MapClass(),

	/**
	 * Data of global robots.
	 * @type {pm.data.MapClass}
	 */
	globalRobotConfig: new pm.data.MapClass(),

	/**
	 * Array of global robots.
	 * @type {Array<pm.GlobalRobot>}
	 */
	globalRobots: [],

	/**
     * Array of maps.
     * @type {Array<pm.AbstractMap>}
     */
	maps: [],
	/**
     * Index of map which will be first displayed for player.
     * @type {Number}
     */
	startMapIndex: 0,
	/**
     * Map object which is now selected by player.
     * @type {pm.AbstractMap}
     */
	activeMap: {},
	/**
	 * Map index is selected.
	 * @private
	 * @type {Number}
	 */
	_activeMapIndex: 0,
	/**
	 * Task list of level
	 * @type {pm.data.TaskList}
	 */
	taskList: {},
	/**
     * Global hint for a level.
     * @type {pm.data.GlobalHint}
     */
	hint: null,
	/**
     * Using of method stack interface for this level.
     * @type {Boolean}
     */
	useMethodStack: true,
	/**
     * Using program recognizer for this level.
     * @type {Boolean}
     */
	useProgramRecognizer: true,
	/**
     * Program data can be edited.
     * @type {Boolean}
     */
	isProgramDataEditable: true,
	/**
	 * Flag to check that all patterns are correct.
	 * @type {Boolean}
	 */
	checkAllPatterns: false,
	/**
	 * Physical robot
	 * @type {pm.PhysicalConnector}
	 * @private
	 */
	_physicalConnector: null,

	ctor: function()
	{
		this._addNonEnumerableProps("activeMap");
		this._addNonEnumerableProps("globalRobots");
		this._addNonEnumerableProps("_currentRobot");
		this._addNonEnumerableProps("_activeMapIndex");
		this._addNonEnumerableProps("programDataCache");
		this._addNonEnumerableProps("_physicalConnector");

		this._super();
	},

	/**
     * Loads level temporary data.
     * @function
     * @param {Boolean} preview Load maps of level in preview mode
     * @param {Number} [robotIndex] Index of robot for override base robot index.
     */
	load: function(preview, robotIndex)
	{
		if(robotIndex === undefined || robotIndex === null)
			robotIndex = this.startRobotIndex;

		this._currentRobot = this.robots[robotIndex];

		if(!CORE_BUILD)
			pm.spriteUtils.clear();

		for(var i = 0; i < this.maps.length; ++i)
		{
			this.maps[i].mapLayer = null;
			this.maps[i].parentLevel = this;
			this.maps[i].load();

			if(!CORE_BUILD)
				pm.spriteUtils.initMapTexture(this.getType(), this.maps[i].tileset);
		}

		this._activeMapIndex = this.startMapIndex;
		this.activeMap = this.maps[this.startMapIndex];

		if (!preview)
		{
			this.taskList.initWithLevel(this);

			if(!CORE_BUILD)
				pm.robotManager.setLevel(this);

			for (var i = 0; i < this.robots.length; ++i)
			{
				this.robots[i].parentLevel = this;
				this.robots[i].reset();

				if (this.robots[i].childRobotID !== -1)
				{
					this.robots[i].childRobot = this.robots[this.robots[i].childRobotID];
					this.robots[this.robots[i].childRobotID].parentRobot = this.robots[i];
				}
			}

			for (var gRobot in this.globalRobotConfig)
			{
				if (this.globalRobotConfig[gRobot] && cc.isObject(this.globalRobotConfig[gRobot]))
				{
					var robot = pm.globalRobotHelper.createRobot(
						gRobot,
						this.globalRobotConfig[gRobot]
					);

					this.globalRobots.push(robot);
				}
			}

			if(!CORE_BUILD)
				pm.spriteUtils.initLevelRobotsTextures(this);

			for(var i = 0; i < this.globalRobots.length; ++i)
			{
				this.globalRobots[i].parentLevel = this;
				this.globalRobots[i].reset();
			}

			if(!CORE_BUILD)
				if(!pm.settings.isEditorMode && this.hasPhysicalConnector())
					this.connectToPhysicalEnvironment();
		}
	},

	/**
     * Removes temporary data of level.
     * @function
     */
	destroy: function()
	{
		for(var i = 0; i < this.maps.length; ++i)
			this.maps[i].mapLayer = null;

		for(var i = 0; i < this.robots.length; ++i)
		{
			if(this.robots[i] instanceof pm.PlayerRobot)
				this.robots[i].sprite = null;

			if(this.isTutorial)
				this.programDataCache[this.robots[i].groupID] = null;
		}

		this.globalRobots = [];

		if(!pm.settings.isEditorMode && this.hasPhysicalConnector())
			this.disconnectFromPhysicalEnvironment();

		if(!CORE_BUILD)
		{
			pm.spriteUtils.clear();
			cc.sys.garbageCollect();
		}
	},

	/**
     * Returns number of robots in level.
     * @returns {Number}
     */
	getRobotCount: function()
	{
		return this.robots.length;
	},

	/**
     * Returns current robot object.
     * @returns {pm.AbstractRobot}
     */
	getCurrentRobot: function()
	{
		return this._currentRobot;
	},

	/**
     * Sets current robot object.
     * @param {pm.AbstractRobot} robot
     */
	setCurrentRobot: function(robot)
	{
		this._currentRobot = robot;
	},

	/**
	 * Returns program info of robot.
	 * @returns {pm.data.ProgramInfo}
	 */
	getCurrentRobotProgramInfo: function()
	{
		var baseRobot = this.getCurrentRobot();

		if(CORE_BUILD)
		{
			if(!this.programDataCache[baseRobot.groupID])
				return this.programData[baseRobot.groupID];

			return this.programDataCache[baseRobot.groupID];
		}
		else if (!pm.settings.isEditorMode)
		{
			if (!this.programDataCache[baseRobot.groupID] ||
				!(this.programDataCache[baseRobot.groupID] instanceof pm.data.ProgramInfo))
			{
				var originalProgram = this.programData[baseRobot.groupID];
				var newCache = new pm.data.ProgramInfo();

				newCache.currentIndex = originalProgram.currentIndex;
				newCache.programDataArray = cc.clone(originalProgram.programDataArray);

				this.programDataCache[baseRobot.groupID] = newCache;
			}

			return this.programDataCache[baseRobot.groupID];
		}

		return this.programData[baseRobot.groupID];
	},

	/**
	 * Sets active map
	 * @param {pm.AbstractMap} activeMap
     */
	setActiveMap: function(activeMap)
	{
		this._activeMapIndex = this.maps.indexOf(activeMap);

		this.activeMap.removeRobots();
		this.activeMap = activeMap;
		activeMap.placeRobots(false);
	},

	/**
	 * Sets active map index
	 * @param {Number} index
     */
	setActiveMapIndex: function(index)
	{
		this._activeMapIndex = index;

		for(var i =0; i < this.robots.length; ++i)
		{
			var robot = this.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			robot.sprite.getSprite().retain();
		}

		this.activeMap.removeRobots();
		this.activeMap = this.maps[index];
		this.activeMap.placeRobots();

		for(var i = 0; i < this.robots.length; ++i)
		{
			var robot = this.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			robot.sprite.getSprite().release();
		}
	},

	getActiveMapIndex: function()
	{
		return this._activeMapIndex;
	},
	/**
     * Returns type of level.
     * @function
     * @name pm.AbstractLevel#getType
     */
	getType: function()
	{
	},

	getTaskCompleteInfoForMap: function(mapIndex)
	{
		var notCompletedObjects = this.taskList.getNotCompletedObjects(mapIndex);

		return {
			completed: notCompletedObjects.length === 0,
			notCompletedObjects: notCompletedObjects
		};
	},

	/**
     * Returns true if one of robots is broken.
     * @returns {boolean}
     */
	robotsLoose: function()
	{
		var flag = false;

		for(var i = 0; i < this.robots.length; ++i)
		{
			if(this.robots[i].isBroken())
				flag = true;
		}

		return flag;
	},

	/**
     * Returns true if all robots ended work.
     * @returns {boolean}
     */
	robotsEndedWork: function()
	{
		var flag = true;

		for(var i = 0; i < this.robots.length; ++i)
		{
			if(!this.robots[i].isEndedWork())
				flag = false;
		}

		return flag;
	},

	/**
     * Returns true if all robots except "exceptRobot" are playing animation.
     * @param {pm.AbstractRobot} [exceptRobot] A robot to except
     * @returns {boolean}
     */
	robotsPlayingAnimation: function(exceptRobot)
	{
		for (var i = 0; i < this.robots.length; ++i)
		{
			if (this.robots[i] !== exceptRobot && this.robots[i].isPlayingAnimation())
				return true;
		}

		for (var i = 0; i < this.globalRobots.length; ++i)
		{
			if (this.globalRobots[i].isPlayingAnimation())
				return true;
		}

		return false;
	},

	objectsPlayingAnimation: function ()
	{
		for (var i = 0; i < this.activeMap.objects.length; ++i)
		{
			var object = this.activeMap.objects[i];

			if (object.isPlayingAnimation())
				return true;
		}

		return false;
	},

	/**
	 * Clears robot's program data cache
	 */
	clearCache: function()
	{
		for(var i = 0; i < this.robots.length; ++i)
			this.programDataCache[this.robots[i].groupID] = null;
	},

	/**
     * Returns state of whole level (maps, objects and robots) for reverting in MethodStack
     */
	getState: function ()
	{
		var state = {
			mapStates: {},
			robotStates: {},
			globalRobotsStates: {}
		};

		for (var i = 0; i < this.maps.length; ++i)
		{
			var map = this.maps[i];

			state.mapStates[i] = map.getState();
		}

		for (var i = 0; i < this.robots.length; ++i)
		{
			var robot = this.robots[i];

			state.robotStates[i] = robot.getState();
		}

		for (var i = 0; i < this.globalRobots.length; ++i)
		{
			var globalRobot = this.globalRobots[i];

			state.globalRobotsStates[i] = globalRobot.getState();
		}

		return state;
	},

	/**
     * Sets state of level (maps, objects and robots) while reverting in MethodStack
	 * Plays animation of reverting
     */
	setState: function (state)
	{
		for (var i = 0; i < this.maps.length; ++i)
			this.maps[i].setState(state.mapStates[i]);

		for (var i = 0; i < this.robots.length; ++i)
			this.robots[i].setState(state.robotStates[i]);

		for (var i = 0; i < this.globalRobots.length; ++i)
			this.globalRobots[i].setState(state.globalRobotsStates[i]);
	},

	/**
	 * Returns if robot has physical device.
	 * @returns {Boolean}
	 */
	hasPhysicalConnector: function() { return false; },
	/**
	 * Callback for state changes of physical robot
	 * @param {pm.PhysicalConnector.STATE} state
	 * @private
	 */
	_physicalStateChanged: function(state) {},
	/**
	 * Connects to physical device.
	 */
	connectToPhysicalEnvironment: function()
	{
		var type = pm.settings.getPhysicalConnectorType();

		if(this._supportsPhysicalConnector(type))
		{
			this._physicalConnector = pm.PhysicalConnector.getConnectorForType(type, this);

			if (this._physicalConnector)
			{
				this._physicalConnector.setStateCallback(this._physicalStateChanged.bind(this));
				this._physicalConnector.connect();
			}
		}
	},
	/**
	 * Disconnects from physical device.
	 */
	disconnectFromPhysicalEnvironment: function()
	{
		if(!this._physicalConnector)
			return;

		this._physicalConnector.setStateCallback(null);
		this._physicalConnector.disconnect();
		this._physicalConnector = null;
	},

	/**
	 * Gets physical connector
	 * @returns {pm.PhysicalConnector}
	 */
	getPhysicalConnector: function ()
	{
		return this._physicalConnector;
	},

	/**
	 * Get if support physical connector
	 * @param {pm.PhysicalConnectorType} type
	 * @returns {Boolean}
	 * @private
	 */
	_supportsPhysicalConnector: function(type) { return false; }
});
