/**
 * Created by Nikita Besshaposhnikov on 20.11.14.
 */

/**
 * This callback is used when load of world is completed
 * @callback pm.worldUtils~loadCallback
 * @param {?Error} error If error occurs it will be stored here or null if no errors.
 * @param {Array<String>} loadedWorlds Worlds was loaded.
 */

/**
 * This callback is used when upload of world to server is completed
 * @callback pm.worldUtils~uploadCallback
 * @param {?Error} error If error occurs it will be stored here or null if no errors.
 */

/**
 * This callback is used when download of world from server is completed
 * @callback pm.worldUtils~downloadCallback
 * @param {?Error} error If error occurs it will be stored here or null if no errors.
 */

/**
 * Functions from this namespace are used to work with worlds.
 * @namespace
 */
pm.worldUtils =
{
	/**
     * Loads world.
     * @param {Object} params Params for world loading
     * @param {String} params.worldID
     * @param {pm.worldUtils~loadCallback} params.callback
     * @param {*} params.callbackTarget
     * @param {Boolean} [params.reloadBuiltinOnError]
     * @param {Boolean} [params.loadLocalOnError=true]
     */
	loadWorld: function(params)
	{
		if(params.loadLocalOnError === undefined)
			params.loadLocalOnError = true;

		cc.async.map([params], {
			iterator: this._loadWorldFromNetwork,
			iteratorTarget: this,
			cb: params.callback.bind(params.callbackTarget),
			cbTarget: params.callbackTarget
		});
	},

	/**
	 * Checks if world's games' orders aren't negative or duplicated; same for games' levels.
	 * Prints games and levels with such problems.
	 * @world {Object} world
	 */
	checkOrders: function (world)
	{
		if (!world)
			return;

		var isWellOrdered = true;

		for (var i = 0; i < world.games.length; ++i)
		{
			if (world.games[i].order !== i)
			{
				isWellOrdered = false;
				console.log("Bad orders in world: {0}".format(world.name));

				console.log("*** Bad games order");
				for (var k = 0; k < world.games.length; ++k)
					console.log("\t game.name = {0}, game.order = {1}, game real index = {2}".format(world.games[k].name, world.games[k].order, k));

				break;
			}
		}

		for (var i = 0; i < world.games.length; ++i)
		{
			for (var j = 0; j < world.games[i].levels.length; ++j)
			{
				if (world.games[i].levels[j].order !== j)
				{
					if (isWellOrdered)
						console.log("Bad orders in world: {0}".format(world.name));

					isWellOrdered = false;
					console.log("*** Bad levels order in game {0}, game.order = {1}, game real index = {2}".format(world.games[i].name, world.games[i].order, i ));

					for (var k = 0; k < world.games[i].levels.length; ++k)
						console.log("\t level.name = {0}, level.order = {1}, level real index = {2}".format(world.games[i].levels[k].name, world.games[i].levels[k].order, k));

					break;
				}
			}
		}

		if(isWellOrdered)
			console.log("All orders are OK");
	},

	/**
	 * Downloads world from server.
	 * @param {Object} params Params for world download
	 * @param {String} params.worldID
	 * @param {pm.worldUtils~downloadCallback} params.callback
	 * @param {*} params.callbackTarget
	 * @param {Boolean} [params.loadLocalOnError=true]
	 * @param {Boolean} [params.reloadBuiltinOnError]
	 */
	downloadWorldFromServer: function(params)
	{
		if(params.loadLocalOnError === undefined)
			params.loadLocalOnError = true;

		cc.async.map([params], {
			iterator: this._downloadWorldFromServer,
			iteratorTarget: this,
			cb: params.callback.bind(params.callbackTarget),
			cbTarget: params.callbackTarget
		});
	},

	/**
	 * Uploads world to server.
	 * @param {Object} params Params for world upload
	 * @param {String} params.worldID
	 * @param {pm.worldUtils~uploadCallback} params.callback
	 * @param {*} params.callbackTarget
	 * @param {Boolean} [params.loadLocalOnError]
	 * @param {Boolean} [params.reloadBuiltinOnError]
	 */
	uploadWorldToServer: function (params)
	{
		if(params.loadLocalOnError === undefined)
			params.loadLocalOnError = true;

		cc.async.map([params], {
			iterator: this._uploadWorldToServer,
			iteratorTarget: this,
			cb: params.callback.bind(params.callbackTarget),
			cbTarget: params.callbackTarget
		});
	},

	/**
	 * Download world from server
	 * @private
	 */
	_downloadWorldFromServer: function (params, index, cb)
	{
		pm.apiServerUtils.getWorld(
			this._worldCallback.bind(this, params, cb),
			params.worldID,
			pm.appUtils.getSupportedLevelFormatVersion()
		);
	},

	/**
	 * Upload world to server
	 * @private
	 */
	_uploadWorldToServer: function(params, index, cb)
	{
		pm.apiServerUtils.getWorld(
			this._worldMetaUploadCallback.bind(this, params, cb),
			params.worldID,
			pm.appUtils.getSupportedLevelFormatVersion()
		);
	},

	_worldMetaUploadCallback: function(params, cb, error, response)
	{
		if(!error && response)
		{
			var world = pm.fileUtils.readObject(pm.settings.getWorldPath(params.worldID));

			this._worldUploadProtocol(
				this._worldUploadResult.bind(this, params, cb, world),
				world,
				response
			);
		}
		else
		{
			this._handleNetworkError(params, cb, error);
		}
	},

	_worldUploadResult: function(params, cb, world, protocol)
	{
		pm.apiServerUtils.updateWorld(
			this._updateAfterUploadCallback.bind(this, params, cb, protocol, world),
			params.worldID,
			{ actions: protocol.actions },
			pm.appUtils.getSupportedLevelFormatVersion()
		);
	},

	_updateAfterUploadCallback: function(params, cb, protocol, world, error, response)
	{
		if(!error && response)
		{
			var gameIterator = 0;

			for(var i = 0 ; i < protocol.actions.length; ++i)
			{
				var actionResult = response.results[i];
				var action = protocol.actions[i];

				switch(action.type)
				{
					case "add_levels":

						for(var l = 0; l < action.levels.length; ++l)
							action.levels[l].id = actionResult.levels[l].id;
						break;
					case "update_levels":

						for(var l = 0; l < action.levels.length; ++l)
							action.levels[l].version = actionResult.levels[l].version;
						break;
					case "add_game":

						var game = protocol.addedGames[gameIterator++];

						game.id = actionResult.id;

						for(var l = 0; l < game.levels.length; ++l)
							game.levels[l].id = actionResult.levels[l];

						break;
				}
			}

			this._succeededLoadWorld({
				id: params.worldID,
				worldObject: world
			}, params, cb, true);
		}
		else
		{
			this._handleNetworkError(params, cb, error);
		}
	},

	_gameUploadProtocol: function(game, gameMeta, protocol)
	{
		var deleteLevels = [];
		var addLevels = [];

		var levelFound = false;

		for(var i = 0 ; i < gameMeta.levels.length; ++i)
		{
			levelFound = false;

			for(var j = 0 ; j < game.levels.length; ++j)
			{
				if(game.levels[j].id === gameMeta.levels[i].id)
				{
					levelFound = true;
					protocol.updateLevels.push(game.levels[j]);
					break;
				}
			}

			if(!levelFound)
				deleteLevels.push(gameMeta.levels[i].id);
		}

		for(var i = 0 ; i < game.levels.length; ++i)
		{
			if (game.levels[i].id === "")
			{
				addLevels.push(game.levels[i]);
			}
			else
			{
				levelFound = false;

				for (var j = 0; j < gameMeta.levels.length; ++j)
				{
					if (game.levels[i].id === gameMeta.levels[j].id)
					{
						levelFound = true;
						break;
					}
				}

				// if there's a local level, which was deleted from server, we treat it as a newly created level
				// this logic may be changed in future
				if (!levelFound)
					addLevels.push(game.levels[i]);
			}
		}

		if(addLevels.length > 0)
		{
			protocol.actions.push({
				type: "add_levels",
				game: game.id,
				levels: addLevels
			});
		}

		if(deleteLevels.length > 0)
		{
			protocol.actions.push({
				type: "delete_levels",
				game: game.id,
				levels: deleteLevels
			});
		}
	},

	_worldUploadProtocol: function(cb, world, worldMeta)
	{
		var protocol = {
			actions: [],
			updateLevels: [],
			addedGames: []
		};

		protocol.actions.push({
			type: "update_world_info",
			name: world.name,
			description: world.description,
			public: world.public
		});

		var gameFound = false;

		for(var i = 0 ; i < worldMeta.games.length; ++i)
		{
			gameFound = false;

			for(var j = 0 ; j < world.games.length; ++j)
			{
				if(world.games[j].id === worldMeta.games[i].id)
				{
					gameFound = true;

					protocol.actions.push({
						type: "update_game",
						game: world.games[j].id,
						name: world.games[j].name,
						order: world.games[j].order
					});

					this._gameUploadProtocol(world.games[j], worldMeta.games[i], protocol);

					break;
				}
			}

			if(!gameFound)
			{
				protocol.actions.push({
					type: "delete_game",
					game: worldMeta.games[i].id
				});
			}
		}

		for(var i = 0 ; i < world.games.length; ++i)
		{
			gameFound = false;

			if (world.games[i].id === "")
			{
				protocol.addedGames.push(world.games[i]);

				protocol.actions.push({
					type: "add_game",
					name: world.games[i].name,
					order: world.games[i].order,
					levels: world.games[i].levels
				});
			}
			else
			{
				for (var j = 0; j < worldMeta.games.length; ++j)
				{
					if (world.games[i].id === worldMeta.games[j].id)
					{
						gameFound = true;
						break;
					}
				}

				// if there's a local game, which was deleted from server, we treat it as a newly created game
				// this logic may be changed in future
				if (!gameFound)
				{
					protocol.addedGames.push(world.games[i]);

					protocol.actions.push({
						type: "add_game",
						name: world.games[i].name,
						order: world.games[i].order,
						levels: world.games[i].levels
					});
				}
			}
		}

		if(protocol.updateLevels.length > 0)
		{
			protocol.actions.push({
				type: "update_levels",
				levels: protocol.updateLevels
			});
		}

		delete protocol.updateLevels;

		cb(protocol);
	},

	/**
     * Load world from network
     * @private
     */
	_loadWorldFromNetwork: function(params, index, cb)
	{
		var worldRecord = pm.settings.getWorldRecord(params.worldID);

		if(worldRecord && pm.fileUtils.isFileExist(pm.settings.getWorldPath(params.worldID)))
		{
			//Do not sync updated world with server

			this._loadLocalWorld(params, cb);
		}
		else
		{
			pm.apiServerUtils.getWorld(
				this._worldCallback.bind(this, params, cb),
				params.worldID,
				pm.appUtils.getSupportedLevelFormatVersion()
			);
		}
	},

	/**
     * Loads world from local cache.</br>
     * Called if some errors in network loading of world
     * @private
     */
	_loadLocalWorld: function(params, cb)
	{
		var worldPath = pm.settings.getWorldPath(params.worldID);

		if(pm.fileUtils.isFileExist(worldPath))
		{
			var world = pm.fileUtils.readObject(worldPath);

			this._succeededLoadWorld({
				id: params.worldID,
				worldObject: world
			}, params, cb, true);
		}
		else
		{
			var sourceName = "";

			var builtinWorlds = pm.settings.getBuiltinWorlds();

			for (var i = 0; i < builtinWorlds.length; ++i)
			{
				if (builtinWorlds[i].id === params.worldID)
					sourceName = builtinWorlds[i].sourceName;
			}
			//Found built-in world
			if (sourceName !== "")
			{
				var sourcePath = pm.fileUtils.fullPathForFileName(sourceName + ".json");

				pm.fileUtils.createDirectory(pm.settings.getWorldDir(params.worldID));
				pm.fileUtils.copyFileFromRes(sourcePath, worldPath);

				this._succeededLoadWorld({
					id: params.worldID,
					worldObject: pm.fileUtils.readObject(worldPath)
				}, params, cb, true);
			}
			else
			{
				this._failedLoadWorld(params, cb, "No world data for world {0}".format(params.worldID));
			}
		}
	},

	/**
     * Called on successful loading of world
     * @param {Object} worldData
     * @param {String} worldData.id
     * @param {Object} worldData.worldObject
     * @param {Object} params
     * @param cb
     * @param {Boolean} updated
     * @private
     */
	_succeededLoadWorld: function(worldData, params, cb, updated)
	{
		var worldObject = worldData.worldObject;

		var worldConvertFunctions = pm.appUtils.getWorldConvertFunctions();

		worldObject = worldConvertFunctions.convert(worldObject, pm.appUtils.getSupportedLevelFormatVersion());

		if(!worldObject)
		{
			this._failedLoadWorld(params, cb, "World {0} can not be converted".format(params.worldID));
			return;
		}

		if(updated)
		{
			var compareFn = function (a, b)
			{
				return a.order - b.order;
			};

			worldObject.games.sort(compareFn);

			for (var i = 0; i < worldObject.games.length; ++i)
				worldObject.games[i].levels.sort(compareFn);
		}

		var worldRecord = pm.settings.getWorldRecord(worldData.id);

		if(!worldRecord)
			pm.settings.addWorld(worldData.id);

		pm.settings.setWorldMetaData(worldData.id, {
			name: worldObject.name,
			description: worldObject.description,
			createDate: worldObject.createDate,
			linkedWithMirera: worldObject.linkedWithMirera
		});

		// console.log(JSON.stringify(worldObject));

		pm.fileUtils.createDirectory(pm.settings.getWorldDir(params.worldID));
		pm.fileUtils.saveObject(pm.settings.getWorldPath(worldData.id), worldObject);

		world.load(worldData.id, worldObject, function ()
		{
			if(updated)
				pm.userCache.clearUnusedData(worldData.id);

			cb(null, worldData.id);
		});

		this.checkOrders(worldObject);
	},

	_failedLoadWorld: function(params, cb, errorMessage)
	{
		var isBuiltinWorld = false;

		var builtinWorlds = pm.settings.getBuiltinWorlds();

		for(var i = 0 ; i < builtinWorlds.length; ++i)
		{
			if (builtinWorlds[i].id === params.worldID)
				isBuiltinWorld = true;
		}

		if(isBuiltinWorld)
		{
			pm.utils.logError("Fatal loading built-in world error: "+ errorMessage);
			cb(errorMessage);
		}
		else if(params.reloadBuiltinOnError)
		{
			pm.utils.logError("Load world error: \"" + errorMessage + "\". Loading built-in world.");

			this._loadWorldFromNetwork({
				worldID: builtinWorlds[0].id,
				callback: params.callback,
				callbackTarget: params.callbackTarget,
				reloadBuiltinOnError: params.reloadBuiltinOnError
			}, 0, cb);
		}
		else
		{
			cb(errorMessage);
		}
	},

	_clearPreviousWorldVersions: function(worldID)
	{
		var versions = pm.settings.getWorldVersions(worldID);

		for(var i =0 ; i < versions.length; ++i)
		{
			if(versions[i] === pm.appUtils.getSupportedLevelFormatVersion())
				continue;

			var fileList = pm.fileUtils.getFileList(pm.settings.getWorldDir(worldID, versions[i]));

			for(var j = 0; j < fileList.length; ++j)
				pm.fileUtils.removeFile(fileList[j]);

			var cacheFileList = pm.fileUtils.getFileList(pm.settings.getWorldCachePath(worldID, versions[i]));

			for(var j = 0; j < cacheFileList.length; ++j)
				pm.fileUtils.removeFile(cacheFileList[j]);
		}
	},

	_handleNetworkError: function(params, cb, error)
	{
		pm.utils.logError(error || "Empty response from network");

		if(params.loadLocalOnError)
			this._loadLocalWorld(params, cb);
		else
			cb(error);
	},

	/**
     *
     * @param {Error} error
     * @param {Object | Number} response
     * @private
     */
	_worldCallback: function(params, cb, error, response)
	{
		if(!error && response)
		{
			var world = new pm.data.World();
			world.name = response.name;
			world.description = response.description;
			world.public = response.public;
			world.linkedWithMirera = response.linkedWithMirera;
			world.createDate = response.createDate;
			world.id = response.id;

			for(var i = 0 ; i < response.games.length; ++i)
			{
				var game = new pm.data.Game(response.games[i].name, response.games[i].order);

				game.id = response.games[i].id;
				game.available = response.games[i].available;
				game.levels = response.games[i].levels;

				world.games.push(game);
			}

			this._succeededLoadWorld({
				id: params.worldID,
				worldObject: world
			}, params, cb, true);
		}
		else
		{
			this._handleNetworkError(params, cb, error);
		}
	},

	/**
	 * returns world object for saving
	 *
	 */

	downloadWorld: function(params) {
		var worldPath = pm.settings.getWorldPath(params.worldID);

		if (pm.fileUtils.isFileExist(worldPath)) {
			return pm.fileUtils.readObject(worldPath);
		} else {
			var sourceName = "";

			var builtinWorlds = pm.settings.getBuiltinWorlds();

			for (var i = 0; i < builtinWorlds.length; ++i) {
				if (builtinWorlds[i].id === params.worldID)
					sourceName = builtinWorlds[i].sourceName;
			}
			//Found built-in world
			if (sourceName !== "") {
				var sourcePath = pm.fileUtils.fullPathForFileName(sourceName + ".json");

				pm.fileUtils.createDirectory(pm.settings.getWorldDir(params.worldID));
				pm.fileUtils.copyFileFromRes(sourcePath, worldPath);
				return pm.fileUtils.readObject(worldPath);
			}
		}
	}

};
