/**
 * Created by antonded on 10.11.14.
 */

/**
 * @class This is a common interface button.
 * @extends pmui.Button
 * @constructor
 * @param {cocosClickCallback} callback Click Callback.
 * @param {cc.Node} target An object that is callback "this" argument.
 * @param {String} text Text on a button.
 * @param {Number} [textFontSize] Font size of text.
 */
pmui.InterfaceButton = pmui.Button.extend(/** @lends pmui.InterfaceButton#*/{

	ctor: function (callback, target, text, textFontSize)
	{
		this._super(pm.spriteUtils.getInterfaceElementName("buttonScaleSprite"),
			pm.spriteUtils.getInterfaceElementName("buttonScaleSprite-selected"),
			pm.spriteUtils.getInterfaceElementName("buttonScaleSprite-disabled"),
			ccui.Widget.PLIST_TEXTURE);

		if(textFontSize === undefined)
			textFontSize = pm.settings.fontSize;

		this.setScale9Enabled(true);
		this.setCapInsets(cc.rect(14, 14, 14, 14));
		this.setTitleText(text);
		this.setTitleFontName(pm.settings.fontLightName);
		this.setTitleFontSize(textFontSize);

		var titleSize = this.getTitleRenderer().getContentSize();
		titleSize.width += 2 * pmui.InterfaceButton.TEXT_PADDING_X;
		titleSize.height += 2 * pmui.InterfaceButton.TEXT_PADDING_Y;

		this.setContentSize(titleSize);

		this.addClickEventListener(callback.bind(target));
	}
});

/**
 * Inner x-padding of text in MenuButton
 * @see MenuButton
 * @const
 * @default
 * @type {number}
 */
pmui.InterfaceButton.TEXT_PADDING_X = 13.0;
/**
 * Inner y-padding of text in MenuButton
 * @see MenuButton
 * @const
 * @default
 * @type {number}
 */
pmui.InterfaceButton.TEXT_PADDING_Y = 9.0;
