/**
 * Created by Nikita Besshaposhnikov on 05.12.14.
 */

/**
 * @class Layer which contains all needed for level menus in editor.
 * @extends LevelMenuLayer
 * @constructor
 * @param {pm.AbstractLevel} level For what level construct.
 * @param {Boolean} isNetGame Is game by network.
 */
var LevelMenuEditorLayer = LevelMenuLayer.extend(/** @lends LevelMenuEditorLayer# */{
	_level: null,
	_layersListener: null,
	hidden: true,
	_currentPageName: "",
	_currentButton: null,

	_levelSettings: null,
	_mapElements: null,
	_taskSettings: null,
	_objectsSettings: null,
	_activeObjectSettings: null,
	_globalRobotsSettings: null,

	ctor: function(level, isNetGame)
	{
		this._super(level, isNetGame);

		this._level = level;

		var screenBounds = pm.settings.getScreenBounds();

		var relativeToWidgetName = "back";

		if (pm.settings.blockExit)
		{
			var uploadToServer = new pmui.SelectionButton(pm.spriteUtils.getIconName("uploadToServer", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("uploadToServer", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("uploadToServer", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			uploadToServer.addClickEventListener(this._confirmUploadToServer.bind(this));

			var uploadAlign = new ccui.RelativeLayoutParameter();
			uploadAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			uploadAlign.setRelativeToWidgetName(relativeToWidgetName);
			uploadAlign.setRelativeName("uploadToServer");
			uploadAlign.setMargin(0, 2 * LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);
			uploadToServer.setLayoutParameter(uploadAlign);

			this.addChild(uploadToServer);

			relativeToWidgetName = "uploadToServer";
		}

		var levelSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("levelSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("levelSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("levelSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		levelSettingsButton.setName(LevelMenuEditorLayer.PageName.Level);
		levelSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var levelSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		levelSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		levelSettingsButtonAlign.setRelativeName("levelSettings");
		levelSettingsButtonAlign.setRelativeToWidgetName(relativeToWidgetName);
		levelSettingsButtonAlign.setMargin(0, 2 * LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		levelSettingsButton.setLayoutParameter(levelSettingsButtonAlign);

		this.addChild(levelSettingsButton);

		var mapSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("mapSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("mapSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("mapSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		mapSettingsButton.setName(LevelMenuEditorLayer.PageName.Map);
		mapSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var mapSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		mapSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		mapSettingsButtonAlign.setRelativeName("mapSettings");
		mapSettingsButtonAlign.setRelativeToWidgetName("levelSettings");
		mapSettingsButtonAlign.setMargin(0, LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		mapSettingsButton.setLayoutParameter(mapSettingsButtonAlign);

		this.addChild(mapSettingsButton);

		var taskSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("taskSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("taskSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("taskSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		taskSettingsButton.setName(LevelMenuEditorLayer.PageName.Task);
		taskSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var taskSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		taskSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		taskSettingsButtonAlign.setRelativeName("taskSettings");
		taskSettingsButtonAlign.setRelativeToWidgetName("mapSettings");
		taskSettingsButtonAlign.setMargin(0, LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		taskSettingsButton.setLayoutParameter(taskSettingsButtonAlign);

		this.addChild(taskSettingsButton);

		var objectSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("objectSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("objectSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("objectSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		objectSettingsButton.setName(LevelMenuEditorLayer.PageName.Objects);
		objectSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var objectSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		objectSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		objectSettingsButtonAlign.setRelativeName("objectSettings");
		objectSettingsButtonAlign.setRelativeToWidgetName("taskSettings");
		objectSettingsButtonAlign.setMargin(0, LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		objectSettingsButton.setLayoutParameter(objectSettingsButtonAlign);

		this.addChild(objectSettingsButton);

		var robotSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("robotSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("robotSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("robotSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		robotSettingsButton.setName(LevelMenuEditorLayer.PageName.Robot);
		robotSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var robotSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		robotSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		robotSettingsButtonAlign.setRelativeName("robotSettings");
		robotSettingsButtonAlign.setRelativeToWidgetName("objectSettings");
		robotSettingsButtonAlign.setMargin(0, LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		robotSettingsButton.setLayoutParameter(robotSettingsButtonAlign);

		this.addChild(robotSettingsButton);

		var globalSettingsButton = new pmui.SelectionButton( pm.spriteUtils.getIconName("globalSettings", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("globalSettings", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("globalSettings", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		globalSettingsButton.setName(LevelMenuEditorLayer.PageName.Global);
		globalSettingsButton.addClickEventListener(this._showSettings.bind(this));

		var globalSettingsButtonAlign = new ccui.RelativeLayoutParameter();
		globalSettingsButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		globalSettingsButtonAlign.setRelativeName("globalSettings");
		globalSettingsButtonAlign.setRelativeToWidgetName("robotSettings");
		globalSettingsButtonAlign.setMargin(0, LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR, 0, 0);

		globalSettingsButton.setLayoutParameter(globalSettingsButtonAlign);

		this.addChild(globalSettingsButton);

		this._levelSettings = new LevelSettings(level);
		this._mapElements = new MapElementsLayer(level);
		this._taskSettings = new TaskSettingsLayer(level);
		this._objectsSettings = new ObjectsSettingsLayer(level);
		this._activeObjectSettings = new ActiveObjectSettingsLayer(level);
		this._globalRobotsSettings = new GlobalRobotsSettingsLayer(level);

		this.addChild(this._levelSettings);
		this.addChild(this._mapElements);
		this.addChild(this._taskSettings);
		this.addChild(this._objectsSettings);
		this.addChild(this._activeObjectSettings);
		this.addChild(this._globalRobotsSettings);

		HintEditorLayer._lastY = 0;
		HintEditorLayer._hints = [];

		this._layersListener = pm.registerCustomEventListener(pme.MAP_LAYERS_COUNT_CHANGED_EVENT_STR, function()
		{
			this._selectMapMenu.updateMaps();
		}.bind(this), 2);
	},

	_registerInfoEventListener: function(isNetGame)
	{
		pm.registerCustomEventListener(pm.SHOW_INFO, function()
		{
			if (this._hintLayer && this._hintLayer.isVisible())
			{
				this._hintLayer.removeLayer();
				return;
			}

			this._hintLayer = new HintEditorLayer(this._level.hint);
			this._hintLayer.show();
		}.bind(this), this);
	},

	_showSettings: function(sender)
	{
		var name = sender.getName();
		var page = this._getPageByName(name);

		if (!this.hidden && this._currentPageName === name)
		{
			this.hidden = true;

			page.hide();
			this._currentPageName = "";

			sender.setNormalTexture();
			this._currentButton = null;
		}
		else
		{
			if (!this.hidden)
			{
				var curPage = this._getPageByName(this._currentPageName);
				curPage.hide();

				this._currentButton.loadTextureNormal(pm.spriteUtils.getIconName(this._currentPageName, pm.NORMAL_STATE),
					ccui.Widget.PLIST_TEXTURE);
			}

			this.hidden = false;

			page.show();
			this._currentPageName = name;

			sender.setSelectedTexture();
			this._currentButton = sender;
		}
	},

	_getPageByName: function(name)
	{
		switch (name)
		{
			case LevelMenuEditorLayer.PageName.Level:
				return this._levelSettings;
			case LevelMenuEditorLayer.PageName.Map:
				return this._mapElements;
			case LevelMenuEditorLayer.PageName.Task:
				return this._taskSettings;
			case LevelMenuEditorLayer.PageName.Objects:
				return this._objectsSettings;
			case LevelMenuEditorLayer.PageName.Robot:
				return this._activeObjectSettings;
			case LevelMenuEditorLayer.PageName.Global:
				return this._globalRobotsSettings;
		}
	},

	_drawTransButton: function ()
	{
		if(pm.settings.getUserData().accountType === pm.USER_TYPE.TEACHER)
		{
			var screenBounds = pm.settings.getScreenBounds();

			var toPiktomirButton = new pmui.Button( pm.spriteUtils.getIconName("toPiktomir", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("toPiktomir", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("toPiktomir", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			toPiktomirButton.addClickEventListener(this._piktomir.bind(this));

			var toPiktomirAlign = new ccui.RelativeLayoutParameter();
			toPiktomirAlign.setRelativeName("trans");

			if(!this._isNetGame)
			{
				toPiktomirAlign.setMargin(0, 0, 0, LevelMenuLayer.SEPARATOR );
				toPiktomirAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);
				toPiktomirAlign.setRelativeToWidgetName("changeLevel");
			}
			else
			{
				toPiktomirAlign.setMargin(0, screenBounds.top, 0, LevelMenuLayer.SEPARATOR);
				toPiktomirAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			}

			toPiktomirButton.setLayoutParameter(toPiktomirAlign);

			this.addChild(toPiktomirButton);
		}
	},

	_saveLevel: function ()
	{
		this._level.clearCache();

		world.setLevelUpdated(pm.settings.getGame(), pm.settings.getLevel());
		world.save();
		pm.userCache.clearLevelData(this._level.id);
	},

	_piktomir: function()
	{
		pm.settings.isEditorMode = false;

		this._saveLevel();

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelScene(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel()));
		cc.director.runScene(trans);
	},

	_drawExtraInfo: function() {},

	drawScaleButtons: function () {},

	onExit: function ()
	{
		this._super();

		cc.eventManager.removeListener(this._layersListener);
	},

	_drawHint: function()
	{
		// var showHintButton = new pmui.Button(pm.spriteUtils.getIconName("hint", pm.NORMAL_STATE),
		//     pm.spriteUtils.getIconName("hint", pm.SELECTED_STATE),
		//     pm.spriteUtils.getIconName("hint", pm.DISABLED_STATE),
		//     ccui.Widget.PLIST_TEXTURE);
		//
		// var align = new ccui.RelativeLayoutParameter();
		// align.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		// align.setMargin(10, 0, 0, 0);
		// align.setRelativeToWidgetName(SoundButton.WIDGET_NAME);
		// showHintButton.setLayoutParameter(align);
		//
		// showHintButton.addClickEventListener(this._showHint.bind(this));
		//
		// this.addChild(showHintButton, 0);
	},

	_enableMenus: function(flag)
	{
		LevelMenuLayer.prototype._enableMenus.call(this, flag);
	},

	_backToMenu: function(object)
	{
		if (pm.tutorialUtils.isProcessingTutorial())
			pm.tutorialUtils.stop();

		this._saveLevel();

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new MenuEditorScene(pm.settings.getGame()));
		cc.director.runScene(trans);
	},

	_showHint: function(sender)
	{
		var hintLayer = new HintEditorLayer(this._level.hint);
		hintLayer.show();
	},

	_confirmUploadToServer: function()
	{
		if (!this.getChildByTag(LevelMenuEditorLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("UploadWorldToServer"));

			input.setCallback(this, this._uploadToServer, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, LevelMenuEditorLayer.CONFIRM_LAYER_TAG);
		}
	},

	_uploadToServer: function ()
	{
		this._saveLevel();

		console.log("uploading world to server");

		var loadingLayer = new LoadingLayer();
		loadingLayer.show();

		pm.worldUtils.uploadWorldToServer({
			worldID: pm.settings.getSelectedWorldID(),
			loadLocalOnError: false,
			reloadBuiltinOnError: true,
			callbackTarget: this,
			callback: function (error, result)
			{
				loadingLayer.remove();

				if(!error)
				{
					world.getEditLog().clear();
				}
				else
				{
					this._handleNetworkError(error, LocalizedString("UploadToServerError").format(error[0].text), this._syncError);
					cc.log(error);
				}
			}
		});
	}
});

LevelMenuEditorLayer.PageName = {
	Level: "levelSettings",
	Map: "mapSettings",
	Task: "taskSettings",
	Objects: "objectSettings",
	Robot: "robotSettings",
	Global: "globalSettings"
};

LevelMenuEditorLayer.EDITOR_BUTTONS_SEPARATOR = 15;
LevelMenuEditorLayer.CONFIRM_LAYER_TAG = 15234;
