/**
 * Created by antonded on 13.11.14.
 */

/**
 * @class Layer which contains all needed for level menus.
 * @extends ccui.Layout
 * @constructor
 * @param {pm.AbstractLevel} level For what level construct.
 * @param {Boolean} isNetGame Is game by network.
 * @param {Boolean} showTutorialControl Is tutorial control needed to be shown.
 */
var LevelMenuLayer = ccui.Layout.extend(/** @lends LevelMenuLayer# */{

	_level: null,
	_isNetGame: false,
	_tutorialControlLayer: null,
	_changeLevelMenu: null,
	_selectMapMenu: null,
	_hintLayer: null,

	_winLayer: null,
	_looseLayer: null,
	_failureLayer: null,
	_mapCompleteLayer: null,
	_patternCompleteLayer: null,

	_netDiscoverLayer: null,
	_netControlLayer: null,

	_backButton: null,

	_orientationButton: null,

	ctor: function (level, isNetGame, showTutorialControl)
	{
		this._super();

		var screenBounds = pm.settings.getScreenBounds();

		this._level = level;
		this._isNetGame = isNetGame;

		pm.registerCustomEventListener(pm.BACK_TO_MENU, this._backToMainMenu.bind(this), this);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this.setContentSize(pm.settings.getMapSize());

		if (!isNetGame)
		{
			if (pm.settings.isEditorMode)
				this._changeLevelMenu = new ChangeLevelMenuEditor();
			else
				this._changeLevelMenu = new ChangeLevelMenu();

			var changeLevelMenuAlign = new ccui.RelativeLayoutParameter();
			changeLevelMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
			changeLevelMenuAlign.setMargin(screenBounds.left, 0, 0, LevelMenuLayer.SEPARATOR/2);
			changeLevelMenuAlign.setRelativeName("changeLevel");

			this._changeLevelMenu.setLayoutParameter(changeLevelMenuAlign);

			this.addChild(this._changeLevelMenu);
		}

		this._backButton = new pmui.Button( pm.spriteUtils.getIconName("backLevel", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("backLevel", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		this._backButton.addClickEventListener(this._backToMenu.bind(this));

		var backButtonAlign = new ccui.RelativeLayoutParameter();
		backButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		backButtonAlign.setRelativeName("back");
		backButtonAlign.setMargin(screenBounds.left, screenBounds.top, 0, 0);

		this._backButton.setLayoutParameter(backButtonAlign);

		this.addChild(this._backButton);

		this._selectMapMenu = new SelectMapMenu(level);

		var selectMapMenuAlign = new ccui.RelativeLayoutParameter();
		selectMapMenuAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		selectMapMenuAlign.setRelativeToWidgetName("back");
		selectMapMenuAlign.setMargin(SelectMapMenu.LEFT_MARGIN, -screenBounds.top, 0, 0);
		this._selectMapMenu.setLayoutParameter(selectMapMenuAlign);

		this.addChild(this._selectMapMenu);

		this._drawTransButton();

		if (this._isNetGame || pm.settings.blockExit)
			this._backButton.setEnabled(false);

		//pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAMES.BACK, this._backButton);

		HintLayer._hints = [];
		HintLayer._lastY = 0;

		if(level.isTutorial && !pm.settings.isEditorMode && showTutorialControl)
		{
			this._tutorialControlLayer = new TutorialControlLayer();

			var tutorialMenuAlign = new ccui.RelativeLayoutParameter();
			tutorialMenuAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			tutorialMenuAlign.setRelativeToWidgetName("changeLevel");
			tutorialMenuAlign.setMargin(screenBounds.left, 0, 0, 0);

			this._tutorialControlLayer.setLayoutParameter(tutorialMenuAlign);

			this.addChild(this._tutorialControlLayer);
		}

		this.drawScaleButtons();

		this._winLayer = new WinLayer(this._level, this._isNetGame);
		this._winLayer.setScale(0.7);
		this._winLayer.setAnchorPoint(0.5, 1);

		var shift = 0;
		if (cc.sys.isNative)
			shift = 40;

		var winLayerAlign = new ccui.RelativeLayoutParameter();
		winLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		winLayerAlign.setMargin(0, LevelMenuLayer.INFORM_MARGIN_Y, LevelMenuLayer.INFORM_MARGIN_X + screenBounds.right + shift, 0);
		this._winLayer.setLayoutParameter(winLayerAlign);

		this.addChild(this._winLayer);

		this._looseLayer = new StateInformLayer(LocalizedString("RobotLooseEvent"), StateInformLayer.State.FAIL);
		this._failureLayer = new StateInformLayer(LocalizedString("RobotFailureEvent"), StateInformLayer.State.FAIL);
		this._mapCompleteLayer = new StateInformLayer(LocalizedString("MapCompleteEvent"), StateInformLayer.State.WIN);
		this._patternCompleteLayer = new StateInformLayer(LocalizedString("PatternCompleteEvent"), StateInformLayer.State.WIN);

		if (cc.sys.isNative)
			shift = 20;

		var informAlign = new ccui.RelativeLayoutParameter();
		informAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		informAlign.setMargin(0, screenBounds.top, 45 + screenBounds.right + shift, 0);

		this._looseLayer.setLayoutParameter(informAlign);
		this._failureLayer.setLayoutParameter(informAlign.clone());
		this._mapCompleteLayer.setLayoutParameter(informAlign.clone());
		this._patternCompleteLayer.setLayoutParameter(informAlign.clone());

		this.addChild(this._looseLayer);
		this.addChild(this._failureLayer);
		this.addChild(this._mapCompleteLayer);
		this.addChild(this._patternCompleteLayer);

		this._drawHint();

		this._drawExtraInfo();

		if(isNetGame)
		{
			this._netControlLayer = new NetControlLayer();

			var netControlLayerAlign = new ccui.RelativeLayoutParameter();
			netControlLayerAlign.setMargin(0, screenBounds.top, 0, 0);
			netControlLayerAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			netControlLayerAlign.setRelativeToWidgetName("back");

			this._netControlLayer.setLayoutParameter(netControlLayerAlign);

			this.addChild(this._netControlLayer);
		}

		this._registerInfoEventListener(isNetGame);

		pm.registerCustomEventListener(pm.READY_TO_CHANGE_LEVEL_STR, this._onReadyToChangeLevel.bind(this), 10);

		pm.registerCustomEventListener(pm.MAP_CHANGED_EVENT_STR, this._hideInforms.bind(this), this);

		pm.registerCustomEventListener(pm.MAP_DRAGGED_EVENT_STR, this._hideInforms.bind(this), this);

		pm.registerCustomEventListener(pm.METHOD_STACK_LAYER_OPENED_EVENT_STR, function(){
			this._hideInforms();

			for(var i = 0; i < this._level.robots.length; ++i)
			{
				if(this._level.robots[i].sprite instanceof pm.RobotSprite2D)
					this._level.robots[i].sprite.showCommands(false);
			}

		}.bind(this), this);
	},

	_registerInfoEventListener: function(isNetGame)
	{
		pm.registerCustomEventListener(pm.SHOW_INFO, function()
		{
			if (this._hintLayer && this._hintLayer.isVisible())
			{
				this._hintLayer.removeLayer();
				return;
			}

			var hint = this._level.hint;

			this._hintLayer = new HintLayer(hint.text, isNetGame);
			this._hintLayer.show();
		}.bind(this), this);
	},

	_drawTransButton: function ()
	{
		if (pm.settings.getUserData().accountType === pm.USER_TYPE.TEACHER && pm.settings.userLoggedIn)
		{
			var screenBounds = pm.settings.getScreenBounds();

			var toEditorButton = new pmui.Button( pm.spriteUtils.getIconName("toEditor", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("toEditor", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("toEditor", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			toEditorButton.addClickEventListener(this._editor.bind(this));

			var toEditorAlign = new ccui.RelativeLayoutParameter();
			toEditorAlign.setRelativeName("trans");

			if(!this._isNetGame)
			{
				toEditorAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);
				toEditorAlign.setMargin(0, 0, 0, LevelMenuLayer.SEPARATOR);
				toEditorAlign.setRelativeToWidgetName("changeLevel");
			}
			else
			{
				toEditorAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
				toEditorAlign.setMargin(screenBounds.left, 0, 0, LevelMenuLayer.SEPARATOR);
			}

			toEditorButton.setLayoutParameter(toEditorAlign);

			this.addChild(toEditorButton);
		}
	},

	_editor: function()
	{
		if (pm.tutorialUtils.isProcessingTutorial())
			pm.tutorialUtils.stop();

		pm.settings.isEditorMode = true;

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new LevelEditorScene(pm.settings.getGame(), pm.settings.getLevel()));
		cc.director.runScene(trans);
	},

	drawChangeOrientationButton: function()
	{
		var normal, selected, disabled;

		normal = pm.spriteUtils.getIconName("toOrtho", pm.NORMAL_STATE);
		selected = pm.spriteUtils.getIconName("toOrtho", pm.SELECTED_STATE);
		disabled = pm.spriteUtils.getIconName("toOrtho", pm.DISABLED_STATE);

		this._orientationButton = new pmui.Button(normal, selected, disabled, ccui.Widget.PLIST_TEXTURE);

		this._orientationButton.addClickEventListener(this._changeMapOrientation.bind(this));

		var orientationButtonAlign = new ccui.RelativeLayoutParameter();

		if (pm.settings.getUserData().accountType === pm.USER_TYPE.TEACHER)
		{
			orientationButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
			orientationButtonAlign.setRelativeName("orientation");
			orientationButtonAlign.setRelativeToWidgetName("trans");
			orientationButtonAlign.setMargin(LevelMenuLayer.SEPARATOR, 0, 0, 0);
		}
		else
		{
			orientationButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_ABOVE_LEFTALIGN);
			orientationButtonAlign.setMargin(0, 0, 0, LevelMenuLayer.SEPARATOR);
			orientationButtonAlign.setRelativeToWidgetName("changeLevel");
		}

		this._orientationButton.setLayoutParameter(orientationButtonAlign);

		this.addChild(this._orientationButton);
	},

	_changeMapOrientation: function()
	{
		for (var i = 0; i < this._level.maps.length; ++i)
		{
			var map = this._level.maps[i];

			if (map.mapLayer.getOrientation() === pm.MapLayer2D.Orientation.Iso)
			{
				map.mapLayer.setOrientation(pm.MapLayer2D.Orientation.Ortho);

				map.mapLayer.removeAllChildren();
				map.mapLayer.drawMap(false);

				if (i === this._level.getActiveMapIndex())
					map.mapLayer.placeRobots(true);
			}
			else
			{
				map.mapLayer.setOrientation(pm.MapLayer2D.Orientation.Iso);

				map.mapLayer.removeAllChildren();
				map.mapLayer.drawMap(false);

				if (i === this._level.getActiveMapIndex())
					map.mapLayer.placeRobots(true);
			}
		}

		var normal, selected, disabled;

		if (this._level.activeMap.mapLayer.getOrientation() === pm.MapLayer2D.Orientation.Iso)
		{
			normal = pm.spriteUtils.getIconName("toOrtho", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("toOrtho", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("toOrtho", pm.DISABLED_STATE);
		}
		else
		{
			normal = pm.spriteUtils.getIconName("toIso", pm.NORMAL_STATE);
			selected = pm.spriteUtils.getIconName("toIso", pm.SELECTED_STATE);
			disabled = pm.spriteUtils.getIconName("toIso", pm.DISABLED_STATE);
		}

		this._orientationButton.loadTextureNormal(normal, ccui.Widget.PLIST_TEXTURE);
		this._orientationButton.loadTexturePressed(selected, ccui.Widget.PLIST_TEXTURE);
		this._orientationButton.loadTextureDisabled(disabled, ccui.Widget.PLIST_TEXTURE);
	},

	_drawExtraInfo: function()
	{
		var extraInfo = pm.moduleUtils.getExtraInfo(this._level.getType(), this._level);

		if (extraInfo)
		{
			var screenBounds = pm.settings.getScreenBounds();
			var extraAlign = new ccui.RelativeLayoutParameter();

			if (this._isNetGame)
			{
				if (pm.settings.getUserData().accountType === pm.USER_TYPE.TEACHER)
				{
					extraAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
					extraAlign.setRelativeToWidgetName("toEditor");
					extraAlign.setMargin(2 * LevelMenuLayer.SEPARATOR, 0, 0, 0);
				}
				else
				{
					extraAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
					extraAlign.setMargin(screenBounds.left, 0, 0, LevelMenuLayer.SEPARATOR / 2);
				}
			}
			else
			{
				extraAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
				extraAlign.setRelativeToWidgetName("changeLevel");
				extraAlign.setMargin(2 * LevelMenuLayer.SEPARATOR, 0, 0, 0);
			}

			extraInfo.setLayoutParameter(extraAlign);

			this.addChild(extraInfo);
		}
	},

	drawScaleButtons: function ()
	{
		if (!cc.sys.isMobile)
		{
			var screenBounds = pm.settings.getScreenBounds();

			var zoomInButton = new pmui.Button(pm.spriteUtils.getIconName("add", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("add", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("add", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			zoomInButton.addClickEventListener(this._zoomIn.bind(this));

			var zoomInButtonAlign = new ccui.RelativeLayoutParameter();
			zoomInButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			zoomInButtonAlign.setRelativeToWidgetName("back");
			zoomInButtonAlign.setRelativeName("zoomIn");
			zoomInButtonAlign.setMargin(0, pm.settings.getScreenSize().height/2 - this._backButton.height
                                                     - zoomInButton.height - 2.5 - screenBounds.top, 0, 0);

			zoomInButton.setLayoutParameter(zoomInButtonAlign);

			this.addChild(zoomInButton);

			var zoomOutButton = new pmui.Button(pm.spriteUtils.getIconName("delete", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("delete", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("delete", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);
			zoomOutButton.addClickEventListener(this._zoomOut.bind(this));

			var zoomOutButtonAlign = new ccui.RelativeLayoutParameter();
			zoomOutButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
			zoomOutButtonAlign.setRelativeToWidgetName("zoomIn");
			zoomOutButtonAlign.setMargin(0, 5, 0, 0);

			zoomOutButton.setLayoutParameter(zoomOutButtonAlign);

			this.addChild(zoomOutButton);
		}
	},

	_zoomIn: function ()
	{
		pm.sendCustomEvent(pm.LAYER_ZOOM_IN_EVENT);
	},

	_zoomOut: function ()
	{
		pm.sendCustomEvent(pm.LAYER_ZOOM_OUT_EVENT);
	},

	_drawHint: function()
	{
		var level = this._level;

		if(level.hint.empty())
			return;
	},

	_showHint: function(sender)
	{
		var hint = this._level.hint;

		var hintLayer = new HintLayer(hint.text);
		hintLayer.show();
	},

	_discoverClicked: function()
	{
		if(this._netDiscoverLayer.isHidden())
			this._netDiscoverLayer.show();
		else
			this._netDiscoverLayer.hide();
	},

	/**
     * Called on start game.
     */
	onStartGame: function()
	{
		this._enableMenus(false);
		this._hideInforms();

		if(this._changeLevelMenu)
			this._changeLevelMenu.stopAnimateNextLevelButton();
	},

	/**
     * Called on restart game.
     */
	onRestartGame: function()
	{
		this._enableMenus(true);
		if(this._changeLevelMenu)
			this._changeLevelMenu.stopAnimateNextLevelButton();
		this._hideInforms();
	},

	/**
     * Called on robot win.
     */
	onWin: function()
	{
		if(Object.keys(this.getParent().getCompletedMaps()).length === this._level.maps.length)
		{
			if(this._changeLevelMenu)
				this._changeLevelMenu.animateNextLevelButton();

			this._winLayer.show();

			for (var i = 0; i < this._level.robots.length; ++i)
			{
				if (this._level.robots[i].sprite instanceof pm.RobotSprite2D)
					this._level.robots[i].sprite.indicateCommands(this._level.robots[i].stepCount);
			}
			for (var i = 0; i < this._level.globalRobots.length; ++i)
			{
				if (this._level.globalRobots[i].sprite instanceof pm.ObjectSprite2D)
					this._level.globalRobots[i].sprite.indicateCommands(this._level.globalRobots[i].stepCount);
			}

			pm.audioUtils.playWinSound();
		}

		this._enableMenus(true);
	},

	/**
	 * Called on map complete.
	 */
	onMapCompleted: function()
	{
		if (this._level.maps.length === 1)
			return;

		this._mapCompleteLayer.show();

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			if (this._level.robots[i].sprite instanceof pm.RobotSprite2D)
				this._level.robots[i].sprite.indicateCommands(this._level.robots[i].stepCount);
		}

		for (var i = 0; i < this._level.globalRobots.length; ++i)
		{
			if (this._level.globalRobots[i].sprite instanceof pm.ObjectSprite2D)
				this._level.globalRobots[i].sprite.indicateCommands(this._level.globalRobots[i].stepCount);
		}
	},

	/**
	 * Called on pattern complete.
	 */
	onPatternCompleted: function()
	{
		if (this._level.getCurrentRobotProgramInfo().programDataArray.length === Object.keys(this._level.activeMap.patternsCompleted).length)
			return;

		this._patternCompleteLayer.show();

		for (var i = 0; i < this._level.robots.length; ++i)
		{
			if (this._level.robots[i].sprite instanceof pm.RobotSprite2D)
				this._level.robots[i].sprite.indicateCommands(this._level.robots[i].stepCount);
		}
	},

	_onReadyToChangeLevel: function()
	{
		if (this._isNetGame)
		{
			var loadingLayer = new LoadingLayer(pm.settings.getScreenSize());
			loadingLayer.show();

			var label = new cc.LabelTTF(LocalizedString("WaitForChangeLevel"), pm.settings.fontBoldName, 30);
			label.setPosition(pm.settings.getScreenSize().width / 2, pm.settings.getScreenSize().height / 2 - 100);

			loadingLayer.addChild(label);
		}
	},

	/**
     * Called on robot loose.
     */
	onLoose: function()
	{
		for (var i = 0; i < this._level.robots.length; ++i)
		{
			if (this._level.robots[i].sprite instanceof pm.RobotSprite2D)
				this._level.robots[i].sprite.indicateCommands(this._level.robots[i].stepCount);
		}

		for (var i = 0; i < this._level.globalRobots.length; ++i)
		{
			if (this._level.globalRobots[i].sprite instanceof pm.ObjectSprite2D)
				this._level.globalRobots[i].sprite.indicateCommands(this._level.globalRobots[i].stepCount);
		}

		this._looseLayer.show();
		pm.audioUtils.playLooseSound();

		this._enableMenus(true);
	},

	/**
     * Called on robot failure.
     */
	onFailure: function()
	{
		for (var i = 0; i < this._level.robots.length; ++i)
		{
			if (this._level.robots[i].sprite instanceof pm.RobotSprite2D)
				this._level.robots[i].sprite.indicateCommands(this._level.robots[i].stepCount);
		}

		for (var i = 0; i < this._level.globalRobots.length; ++i)
		{
			if (this._level.globalRobots[i].sprite instanceof pm.ObjectSprite2D)
				this._level.globalRobots[i].sprite.indicateCommands(this._level.globalRobots[i].stepCount);
		}

		this._failureLayer.show();

		this._enableMenus(true);
	},

	/**
     * Called on robot tested.
     */
	onRobotTested: function()
	{
		this._enableMenus(true);
	},

	_enableMenus: function(flag)
	{
		if(this._changeLevelMenu)
			this._changeLevelMenu.setEnabled(flag);

		if(this._selectMapMenu)
			this._selectMapMenu.setEnabled(flag);

		if(this._netControlLayer)
			this._netControlLayer.setEnabled(flag);

		if (!this._isNetGame && !pm.settings.blockExit)
			this._backButton.setEnabled(flag);
	},

	_hideInforms: function()
	{
		if (!this._winLayer.isHidden())
			this._winLayer.hide();

		if(!this._looseLayer.isHidden())
			this._looseLayer.hide();

		if(!this._failureLayer.isHidden())
			this._failureLayer.hide();

		if(!this._mapCompleteLayer.isHidden())
			this._mapCompleteLayer.hide();

		if(!this._patternCompleteLayer.isHidden())
			this._patternCompleteLayer.hide();
	},

	_backToMenu: function(object)
	{
		if (pm.tutorialUtils.isProcessingTutorial())
			pm.tutorialUtils.stop();

		if(this._isNetGame)
		{
			pm.networkUtils.stopGameClient();
			pm.networkUtils.stopGameServer();
		}

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectLevelScene());
		cc.director.runScene(trans);
	},

	_backToMainMenu: function(object)
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectGameScene());
		cc.director.runScene(trans);
	}
});

LevelMenuLayer.SEPARATOR = 10.0;
LevelMenuLayer.INFORM_MARGIN_X = 25.0;
LevelMenuLayer.INFORM_MARGIN_Y = 25.0;
