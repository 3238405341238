/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Functions for DraftsmanLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.DraftsmanLevelModule = pm.ModuleInfo.extend(/** @lends pm.DraftsmanLevelModule# */{

	name: "Draftsman",

	getType: function()
	{
		return pm.DraftsmanLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.DraftsmanMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);
		map.setStartElementPos(cc.p(0, map.height - 1));

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = DraftsmanMapElementType.Green;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.DraftsmanLevel();

		level.name = "Новый Чертежник";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.DraftsmanRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.DraftsmanLevelModule.RobotType] = "kumir_robot0.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/kumir_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new DraftsmanRobotSettingsLayer(object);
	},

	getExtraMapSettings: function (target)
	{
		var separator = cc.size(5, 5);
		var cellSize = cc.size(64, 64);

		var startElementPos = new pmui.SelectTableView(cellSize, separator, 1, 1);
		startElementPos.addSelectEventListener(function(selectedMapElement)
		{
			target.mapElementsTable.deselectAll();

			if (selectedMapElement)
				pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, pm.data.DraftsmanMapElement.START_ELEMENT_POS);
			else
				pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, undefined);
		}.bind(this), this);

		startElementPos.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
		startElementPos.setFitObjectsSizes(true);

		var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(0), ccui.Widget.PLIST_TEXTURE);

		startElementPos.setCell(0, 0, element);
		startElementPos.setContentSize(cellSize.width + 2 * separator.width, cellSize.height + 2 * separator.height);

		return {
			layout: startElementPos,
			callback: function() {
				startElementPos.deselectAll();
			}
		}
	},

	getMapElementsType: function()
	{
		return DraftsmanMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Draftsman");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/draftsman_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/draftsman_robot-methods.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.DraftsmanRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	canUseMethodStack: function()
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Draftsman": "Draftsman",
					"DraftsmanLevelType": "Draftsman level",
					"DraftsmanTask": "Draw figure",
					"DraftsmanLevel": "Draftsman",
					"draftsman_move_to_point": "move to point",
					"draftsman_move_to_vector": "move to vector",
					"draftsman_pen_down": "pen down",
					"draftsman_pen_up": "pen up"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Draftsman": "Чертежник",
					"DraftsmanLevelType": "Чертежник",
					"DraftsmanTask": "Нарисовать фигуру",
					"DraftsmanLevel": "Чертежник",
					"draftsman_move_to_point": "сместиться в точку",
					"draftsman_move_to_vector": "сместиться на вектор",
					"draftsman_pen_down": "опустить перо",
					"draftsman_pen_up": "поднять перо"
				};
		}
	},

	generateTaskLayer: function (level, task)
	{
		return new DraftsmanTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "DraftsmanTask";
	},

	needToRotateRobot: function()
	{
		return false;
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.DraftsmanTask(level);

		for (var i = 0; i < level.maps.length; ++i)
		{
			var lines = level.maps[i].lines;
			task.fillWithInitialData(i, lines);
		}

		level.taskList.addTask(pm.DraftsmanLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function(a, b) {},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.DraftsmanLevelModule.RobotType = "draftsman-robot";
pm.DraftsmanLevelModule.Type = "draftsman";
pm.DraftsmanLevelModule.ObjectTypes = {};
