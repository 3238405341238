/**
 * Created by Antony Orlovsky on 30.07.18.
 */

/**
 * @class Layer to be shown on robot win.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {pm.AbstractLevel} level Current level.
 */
var WinLayer = pmui.OvalLayout.extend(/** @lends WinLayer# */{
	_level: null,
	_hidden: true,
	_isNetGame: false,

	_leftStar: null,
	_rightStar: null,
	_centralStar: null,
	_completedLevelsLabel: null,

	_nextLevelButton: null,
	_startScreenButton: null,
	_restartButton: null,
	_closeButton: null,

	ctor: function(level, isNetGame)
	{
		this._super(cc.size(WinLayer.WIDTH, WinLayer.HEIGHT));
		this.setCascadeOpacityEnabled(true);

		this.setLayoutType(ccui.Layout.RELATIVE);

		this._level = level;
		this._isNetGame = isNetGame;

		this._loadInterface();
	},

	_loadInterface: function()
	{
		this._closeButton = new pmui.Button(pm.spriteUtils.getIconName("closeMessage", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("closeMessage", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("closeMessage", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE
		);

		this._closeButton.addClickEventListener(this.hide.bind(this));

		var closeButtonAlign = new ccui.RelativeLayoutParameter();
		closeButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		closeButtonAlign.setMargin(0, -this._closeButton.height/4, -this._closeButton.width/4, 0);
		this._closeButton.setLayoutParameter(closeButtonAlign);

		this.addChild(this._closeButton);

		if (this._isNetGame)
			this._closeButton.setEnabled(false);

		var winSprite = new ccui.ImageView("System/winRobotState.png");

		var winSpriteAlign = new ccui.RelativeLayoutParameter();
		winSpriteAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		winSpriteAlign.setMargin(2*WinLayer.SEPARATOR, WinLayer.ROBOT_BORDER_Y, 0, 0);
		winSpriteAlign.setRelativeName("winSprite");
		winSprite.setLayoutParameter(winSpriteAlign);

		this.addChild(winSprite);

		var starSprite = "starIncomplete";

		this._leftStar = new ccui.ImageView(pm.spriteUtils.getIconName(starSprite), ccui.Widget.PLIST_TEXTURE);

		var starAlign = new ccui.RelativeLayoutParameter();
		starAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		starAlign.setMargin(-WinLayer.STAR_SHIFT_X, -WinLayer.STAR_SHIFT_Y, 0, 0);
		starAlign.setRelativeToWidgetName("winSprite");
		this._leftStar.setLayoutParameter(starAlign);

		this.addChild(this._leftStar);

		var label = new ccui.Text(LocalizedString("Well done"), pm.settings.fontName, 30);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
		labelAlign.setMargin(WinLayer.SEPARATOR, WinLayer.BORDER_Y, 0, 0);
		labelAlign.setRelativeName("label");
		labelAlign.setRelativeToWidgetName("winSprite");
		label.setLayoutParameter(labelAlign);

		this.addChild(label);

		this._completedLevelsLabel = new ccui.Text("placeHolder", pm.settings.fontName, 20);

		var completedLevelsLabelAlign = new ccui.RelativeLayoutParameter();
		completedLevelsLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_CENTER);
		completedLevelsLabelAlign.setMargin(0, WinLayer.TEXT_SEPARATOR, 0, 0);
		completedLevelsLabelAlign.setRelativeName("completedLevelsLabel");
		completedLevelsLabelAlign.setRelativeToWidgetName("label");
		this._completedLevelsLabel.setLayoutParameter(completedLevelsLabelAlign);

		this.addChild(this._completedLevelsLabel);

		this._nextLevelButton = new pmui.Button(pm.spriteUtils.getIconName("nextWin", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("nextWin", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("nextWin", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var nextLevelAlign = new ccui.RelativeLayoutParameter();
		nextLevelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		nextLevelAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS + WinLayer.BUTTON_SEPARATOR);
		nextLevelAlign.setRelativeName("nextLevel");
		this._nextLevelButton.setLayoutParameter(nextLevelAlign);

		if (!this._isNetGame)
			this._nextLevelButton.addClickEventListener(this._nextLevel.bind(this));
		else
			this._nextLevelButton.addClickEventListener(this._readyToChangeLevel.bind(this));

		this.addChild(this._nextLevelButton);

		this._startScreenButton = new pmui.Button(pm.spriteUtils.getIconName("menuWin", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("menuWin", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("menuWin", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var startScreenButtonAlign = new ccui.RelativeLayoutParameter();
		startScreenButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_LEFT_OF_CENTER);
		startScreenButtonAlign.setMargin(0, 0, WinLayer.BUTTON_SEPARATOR, 0);
		startScreenButtonAlign.setRelativeToWidgetName("nextLevel");
		this._startScreenButton.setLayoutParameter(startScreenButtonAlign);

		this._startScreenButton.addClickEventListener(this._startScreen.bind(this));

		this.addChild(this._startScreenButton);

		if (pm.settings.blockExit)
		{
			this._nextLevelButton.setEnabled(false);
			this._startScreenButton.setEnabled(false);
		}

		this._restartButton = new pmui.Button(pm.spriteUtils.getIconName("again", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("again", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("again", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		var returnButtonAlign = new ccui.RelativeLayoutParameter();
		returnButtonAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		returnButtonAlign.setMargin(WinLayer.BUTTON_SEPARATOR, 0, 0, 0);
		returnButtonAlign.setRelativeToWidgetName("nextLevel");
		this._restartButton.setLayoutParameter(returnButtonAlign);

		this._restartButton.addClickEventListener(this._restart.bind(this));

		this.addChild(this._restartButton);

		this.setOpacity(0);

		this._enableButtons(false);
	},

	_enableButtons: function(flag)
	{
		if (!pm.settings.blockExit)
		{
			this._nextLevelButton.setEnabled(flag);
			this._startScreenButton.setEnabled(flag);
		}

		this._restartButton.setEnabled(flag);
	},

	_startScreen: function()
	{
		pm.settings.isEditorMode = false;

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new StartMenuScene());
		cc.director.runScene(trans);
	},

	_nextLevel: function(sender)
	{
		var level = pm.settings.getLevel();
		var game = pm.settings.getGame();

		++level;

		if (level === world.getLevelCount(game))
		{
			++game;

			while(world.getLevelCount(game) === 0 && game < world.getGameCount())
				++game;

			if(game === world.getGameCount())
			{
				game = pm.settings.getGame();
				level = pm.settings.getLevel();
			}
			else
			{
				level = 0;
			}
		}

		pm.settings.setGame(game);
		pm.settings.setLevel(level);

		this._transLevel();
	},

	_readyToChangeLevel: function(sender)
	{
		pm.sendCustomEvent(pm.READY_TO_CHANGE_LEVEL_STR);
	},

	_transLevel: function()
	{
		if (pm.settings.isEditorMode)
		{
			this.getParent()._level.clearCache();

			world.setLevelUpdated(pm.settings.getGame(), pm.settings.getLevel());
			world.save();
			pm.userCache.clearLevelData(this.getParent()._level.id);

			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
				new LevelEditorScene(pm.settings.getGame(), pm.settings.getLevel()));
			cc.director.runScene(trans);
		}
		else
		{
			var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
				new LevelScene(GameType.Local, null, pm.settings.getGame(), pm.settings.getLevel()));
			cc.director.runScene(trans);
		}
	},

	_restart: function()
	{
		pm.sendCustomEvent(pm.PROGRAM_RESTART_EVENT_STR);
	},

	/**
     * Shows layer.
     */
	show: function()
	{
		this._recalculateCompletedInformation();

		this._hidden = false;

		if (!this._isNetGame)
		{
			this._enableButtons(true);
		}
		else
		{
			this._nextLevelButton.setEnabled(true);

			var showSelected = cc.callFunc(function()
			{
				this.setHighlighted(true);
			}, this._nextLevelButton);

			var showNormal = cc.callFunc(function()
			{
				this.setHighlighted(false);
			}, this._nextLevelButton);

			var delay = cc.delayTime(pm.SYSTEM_ANIMATION_DELAY);

			this._nextLevelButton.runAction(cc.repeatForever(cc.sequence(showSelected, delay, showNormal, delay)));
		}

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 255);
		this.runAction(fadeLayer);

		this._leftStar.runAction(cc.sequence(cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 2, 1.4), cc.scaleTo(pm.SYSTEM_ANIMATION_DELAY * 2, 1)));
	},

	_recalculateCompletedInformation: function()
	{
		var notCompletedFlag = false;

		for (var i = 0; i < this._level.maps.length; ++i)
		{
			if (pm.userCache.getScore(this._level, i) === -1)
				notCompletedFlag = true;
		}

		var starSprite = (notCompletedFlag === false) ? "star" : "starIncomplete";

		this._leftStar.loadTexture(pm.spriteUtils.getIconName(starSprite), ccui.Widget.PLIST_TEXTURE);

		var count = 0;
		var completedCount = 0;

		var game = world.games[pm.settings.getGame()];

		for (var i = 0; i < game.levels.length; ++i)
		{
			var level = game.levels[i];

			// completedCount += pm.userCache.getCompleteMapCount(level);
			var notCompletedFlag = false;

			for (var j = 0; j < level.maps.length; ++j)
			{
				if (pm.userCache.getScore(level, j) === -1)
					notCompletedFlag = true;
			}

			if (!notCompletedFlag)
				completedCount++;

			count++;
		}

		this._completedLevelsLabel.setString(LocalizedString("Completed") + " " + completedCount + "/" + count);
	},

	/**
     * Hides layer.
     */
	hide: function()
	{
		this._hidden = true;

		if (!this._isNetGame)
		{
			this._enableButtons(false);
		}
		else
		{
			this._nextLevelButton.setEnabled(false);
			this._nextLevelButton.stopAllActions(); 
		}

		var fadeLayer = cc.fadeTo(pm.SYSTEM_ANIMATION_DELAY, 0);
		this.runAction(fadeLayer);
	},

	/**
     * Returns if layer if hidden.
     * @returns {boolean}
     */
	isHidden: function()
	{
		return this._hidden;
	}
});

WinLayer.WIDTH = 350;
WinLayer.HEIGHT = 190;
WinLayer.STAR_SHIFT_X = 15;
WinLayer.STAR_SHIFT_Y = 25;
WinLayer.BORDER_Y = 30;
WinLayer.SEPARATOR = 20;
WinLayer.ROBOT_BORDER_Y = 25;
WinLayer.TEXT_SEPARATOR = 5;
WinLayer.BUTTON_SEPARATOR = 15;
