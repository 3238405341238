/**
 * Created by Kirill Mashchenko on 03.07.17.
 */

/**
 * @class Functions for any module.
 * @interface
 * @extends cc.Class
 */

pm.ModuleInfo = cc.Class.extend(/** @lends pm.ModuleInfo# */{

	/**
     * Returns type of Module
     * @returns {String}
     */
	getType: function () {},

	getBackgroundParameters: function ()
	{
		return{
			color: cc.color(46, 84, 167),

			centerImage: "Background/Levels/repair_center.png"
		};
	},

	/**
     * Generates empty map for level with robot.
     * @param {pm.data.AbstractLevel} level
     * @returns {pm.data.AbstractMap}
     */
	generateEmptyMap: function (level) {},

	_fillLevelData: function(level)
	{
		level.startRobotIndex = 0;

		level.robots[0].id = 0;
		level.robots[0].groupID = 0;
		level.robots[0].parentLevel = level;

		level.programData[0] = new pm.data.ProgramInfo();
		level.programData[0].currentIndex = 0;

		level.programData[0].programDataArray[0] = pm.appUtils.generateProgramData();

		this._fillRobotProgramData(level.robots[0]);

		level.startMapIndex = 0;
		level.maps.push(this.generateEmptyMap(level));
	},

	_fillRobotProgramData: function(robot) {},

	/**
     * Generates empty level.
     * @returns {pm.data.AbstractLevel}
     */
	generateEmptyLevel: function () {},

	/**
     * Returns Map ObjectType->Object sprite filename
     * @returns {Object}
     */
	getObjectsInfo: function() {},

	/**
     * Returns RobotType->Robot sprite filename
     * @returns {Object}
     */
	getRobotsInfo: function () {},

	/**
     * Returns robot mathod name
     * @param {String} methodName
     * @returns {String}
     */

	getRobotMethodName: function (methodName)
	{
		return LocalizedString(methodName);
	},
	/**
     * Returns robot mathod name
     * @param {String} methodName
     * @returns {String}
     */

	getRobotConditionName: function (condName)
	{
		return LocalizedString(condName);
	},

	/**
	 * Returns robot sprite sheet
	 * @param {String} robotType type of Robot
	 * @returns {String}
	 */

	getRobotSpriteSheet: function (robotType) {},

	/**
     * Returns setting layer for robot in editor
     * @param {String} robotType type of Robot
     * @param {Object} object Robot
     * @returns {PlayerRobotSettingsLayer}
     */
	getSettings: function (robotType, object) {},

	/**
	 * Returns extra settings for level in editor
	 * @param {String} type Type of this level
	 * @param {String} level Level
	 */
	getExtraLevelSettings: function (level)
	{
		return null;
	},

	/**
	 * Returns extra settings for map in editor
	 * @param {String} type Type of this level
	 * @param {*} target Target
	 */
	getExtraMapSettings: function (target)
	{
		return null;
	},

	/**
	 * Returns extra info for level
	 * @param {String} type Type of this level
	 * @param {String} level Level
	 */
	getExtraInfo: function (level)
	{
		return null;
	},

	/**
     * Returns dictionary with map elements types
     * @returns {Object}
     */
	getMapElementsType: function() {},

	/**
     * Returns Localized name of robot
     * @param {String} robotType type of robot
     * @returns {String}
     */
	getRobotDisplayedName: function (robotType) {},

	/**
     * Returns Localized name of level
     * @returns {String}
     */
	getDisplayedNames: function() {},

	/**
     * Returns path to tileset
     * @param {String} tileset
     * @returns {String}
     */
	getMapTileset: function (tileset) {},

	/**
     * Returns array of Methods and conditions of robot
     * @param {String} robotType Type of robot
     * @returns {String}
     */
	getResources: function () {},

	/**
     * Generates Object for type
     * @param {String} objectType Type of Object
     * @returns {Object}
     */
	generateObject: function (objectType) {},

	/**
     * Generates Robot for type
     * @param {String} robotType Type of robot
     * @returns {Robot}
     */
	generateRobot: function (robotType) {},

	stopResizingWidth: function (map, side)
	{
		return false;
	},

	stopDecreasingHeight: function (map, side)
	{
		return false;
	},

	stopResizingHeight: function (map, side)
	{
		return false;
	},

	canUseMethodStack: function()
	{
		return true;
	},

	getAffineVector: function(dif)
	{
		return cc.p(dif.x / Math.sqrt(2) - dif.y / (Math.sqrt(2) * 2), dif.x / Math.sqrt(2) + dif.y / (Math.sqrt(2) * 2));
	},

	/**
     * Adds localized strings of module for language.
     * @param {String} language Localized string language
     * @returns {String}
     */
	getLocalization: function (language) {},

	/**
	 * Returns name of a plist-file with resources for a specified level.
	 * @returns {String}
	 */
	getResourcesPlist: function () {},

	generateTaskLayer: function (level, task) {},

	getTaskLabel: function () {},

	needToRotateRobot: function()
	{
		return true;
	},

	addLevelTask: function (level) {},

	addExtraMethods: function(robot, functions) {},

	/**
     * Returns sprite frame of depth lines
     */
	getDepthLineSprites: function() {},

	/**
     * Returns layout with changeable conditions for level settings
     * @param {pm.PlayerRobot} robot Current robot
     * @param callback
     * @param target
     * @returns {ccui.Layout}
     */
	getChangeableConditions: function(robot, callback, target) {},

	updateChangeableConditions: function(robot, name) {},

	/**
     * Returns function for comparing conditions in array
     */
	compareConditions: function() {},

	getChangeableMethods: function(robot, callback, target) {},

	updateChangeableMethods: function(robot, name) {},

	compareMethods: function() {},

	getAvailablePhysicalCommands: function() { return []; },

	getLessonMapLayer: function() {}
});
