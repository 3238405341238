/**
 * Created by Kirill Mashchenko on 03.07.2018.
 */

/**
 * @class Represents program of one robot.
 */
var ProgramContainerLayerP = ProgramContainerLayer.extend(/** @lends ProgramContainerLayer# */{

	_symbolTable: null,

	_backgroundLayer: null,
	_arrowsLayer: null,

	_parsedProgram: [],

	_resizing: false,
	_resizeSprite: null,

	_realWidth: 0,
	_realHeight: 0,

	_brokenSprite: null,

	canAddRemoveRowsState: null,

	ctor: function (programLayer, program)
	{
		this._super(programLayer, program);

		this._startedRepeatersStack = [];

		this._drawLayout();

		this.canAddRemoveRowsState = ProgramContainerLayer.NORMAL_STATE;

		pm.registerCustomEventListener(pm.REMOVE_ALL_ARROWS, function()
		{
			this._removeAllArrows();
		}.bind(this), this);

	},

	_getProgramHeight: function ()
	{
		var lastNotEmptyRow = this._program.height;

		if (!this._isEditable())
		{
			for (var i = 0; i < this._program.height; ++i)
			{
				var isEmptyRow = true;
				for (var j = 0; j < this._program.width; ++j)
				{
					var symbol = this._program.symbols[i][j];

					if (symbol.type !== pm.SymbolType.Empty)
					{
						isEmptyRow = false;
						break;
					}
				}
				if (!isEmptyRow)
					lastNotEmptyRow = i+1;
			}
		}

		return lastNotEmptyRow;
	},

	_drawProgram: function ()
	{
		for (var i = 0; i < this._getProgramHeight(); ++i)
		{
			var isEmptyRow = true;
			for (var j = 0; j < this._program.width; ++j)
			{
				var symbol = this._program.symbols[i][j];

				if (symbol.type !== pm.SymbolType.Empty)
				{
					this._drawFunctionButton(i, j, symbol.type, symbol.value);
					isEmptyRow = false;
				}
				else if (this._isEditable())
				{
					this._drawFunctionButton(i, j, symbol.type, symbol.value);
				}
			}
			if (isEmptyRow && !this._isEditable())
				this._symbolTable.setPlaceholderRow(i, ProgramContainerLayer.EMPTY_ROW_SEPARATOR_HEIGHT);
		}

		this._updateFunctionInfo();
		this._parseProgram();
		this._drawBackgroundObjects();
		this._drawResizeImage();
	},

	_parseProgram: function ()
	{
		this._parsedProgram = pm.programUtilsP.parseProgram(this._program);
	},

	updateProgram: function(program, name)
	{
		this.removeAllChildren();
		this._program = program;
		this._drawLayout(name);
	},

	_drawBackgroundObjects: function()
	{
		this._backgroundLayer.removeAllChildren(true);

		this._brokenSprite = new cc.Sprite("#empty-failed.png");
		this._brokenSprite.setAnchorPoint(0, 1);
		this._brokenSprite.setVisible(false);
		this._brokenSprite.setPosition(cc.p(0, 0));

		this._backgroundLayer.addChild(this._brokenSprite, 10);

		this._drawRectangles(this._parsedProgram, 0);
		this._drawBackSprites();
	},

	_drawRectangles: function(curChild, parity)
	{
		if (curChild.type !== pm.FunctionBlockType.Root && curChild.type !== pm.FunctionBlockType.Simple && curChild.depth === 0 &&
			curChild.rightDown.x >= curChild.leftUp.x &&  curChild.rightDown.y >= curChild.leftUp.y)
		{
			var rectangle = new cc.Scale9Sprite("System/algorithmBack.png");
			rectangle.setCapInsets(cc.rect(20, 20, 20, 20));

			rectangle.setAnchorPoint(0, 0);

			var cellSize = ProgramContainerLayer.BUTTON_SIZE + ProgramContainerLayer.BUTTON_SEPARATOR;
			var height = (curChild.rightDown.x - curChild.leftUp.x) * cellSize;
			var width = (-curChild.leftUp.y + curChild.rightDown.y) * cellSize;

			var button = null;

			for (var x = 0; x < this._program.width; ++x)
			{
				var symbol = this._program.symbols[curChild.rightDown.x][x];

				if (symbol.type !== pm.SymbolType.Empty)
				{
					button = this._symbolTable.getCell(curChild.rightDown.x, x);
					break;
				}
			}

			var rightX = ProgramContainerLayer.BUTTON_SEPARATOR / (Math.pow(2, curChild.depth));
			var leftX, bottomY, topY;

			leftX = bottomY = topY = rightX;

			if (curChild.value !== pm.CMD_MAIN && curChild.type === pm.FunctionBlockType.Function)
				topY *= 0.7;

			// if (curChild.depth === 0)
			// {
			//     leftX = 2 * ProgramContainerLayer.BUTTON_SEPARATOR / 5;
			//     bottomY = 2 * ProgramContainerLayer.BUTTON_SEPARATOR / 5;
			// }

			rectangle.setContentSize(
				width + ProgramContainerLayer.BUTTON_SIZE + rightX + leftX,
				height + ProgramContainerLayer.BUTTON_SIZE + bottomY + topY
			);

			rectangle.setPosition(curChild.leftUp.y * cellSize + ProgramContainerLayer.BUTTON_SEPARATOR - leftX,
				button.getPosition().y - ProgramContainerLayer.BUTTON_SIZE - bottomY);

			this._backgroundLayer.addChild(rectangle, curChild.depth);
		}

		for (var i = 0; i < curChild.children.length; ++i)
		{
			this._drawRectangles(curChild.children[i], parity);

			if (curChild.type === pm.FunctionBlockType.Root)
				++parity;
		}
	},

	_drawBackSprites: function()
	{
		if(!this._isEditable())
			return;

		for (var i = 0; i < this._program.height; ++i)
		{
			for (var j = 0; j < this._program.width; ++j)
			{
				var backSprite = pm.spriteUtils.getRobotMethodSprite(pm.EMPTY_METHOD, "back");

				var pos = this._symbolTable.getCellPosition(i, j);

				pos.x += ProgramContainerLayer.BUTTON_SIZE - backSprite.width;
				pos.y += ProgramContainerLayer.BUTTON_SIZE - backSprite.height;

				backSprite.setPosition(pos);
				backSprite.setAnchorPoint(0.0, 1.0);

				this._backgroundLayer.addChild(backSprite, -1);
			}
		}
	},

	_drawFunctionButton: function(row, col, type, value)
	{
		var symbolButton = null;
		var tutorialPattern = "";

		switch (type)
		{
			case pm.SymbolType.Method:
				symbolButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, value, true);
				tutorialPattern = pm.tutorialUtils.OBJECT_NAME_PATTERNS.METHOD;
				break;
			case pm.SymbolType.Condition:
				symbolButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.Condition, value, true);
				tutorialPattern = pm.tutorialUtils.OBJECT_NAME_PATTERNS.CONDITION;
				break;
			case pm.SymbolType.Repeater:
				if (value === pm.Counter.Repeater.Value || value === pm.ExtendedCounter.Repeater.Memory)
					symbolButton = new CounterRepeaterButton(value, true);
				else
					symbolButton = new RepeaterButton(value, true);
				tutorialPattern = pm.tutorialUtils.OBJECT_NAME_PATTERNS.REPEATER;
				break;
			case pm.SymbolType.CondRepeater:
				symbolButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.CondRepeater, value, true);
				tutorialPattern = pm.tutorialUtils.OBJECT_NAME_PATTERNS.REPEATER;
				break;
			case pm.SymbolType.Function:
				symbolButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.Method, value);
				tutorialPattern = pm.tutorialUtils.OBJECT_NAME_PATTERNS.METHOD;
				break;
			case pm.SymbolType.Empty:
				symbolButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.Empty, value, true);
				break;
		}

		this._symbolTable.setCell(row, col, symbolButton);

		if (tutorialPattern !== "")
		{
			pm.tutorialUtils.registerTutorialObject(
				tutorialPattern.format(row, col),
				symbolButton, true, true
			);
		}

		return symbolButton;
	},

	_drawLayout: function(name)
	{
		this._realHeight = 0;
		this._realWidth = 0;

		var cellSize = cc.size(ProgramContainerLayer.BUTTON_SIZE, ProgramContainerLayer.BUTTON_SIZE);
		var separator = cc.size(ProgramContainerLayer.BUTTON_SEPARATOR, ProgramContainerLayer.BUTTON_SEPARATOR);

		this._symbolTable = new pmui.TableView(cellSize, separator, this._getProgramHeight(), this._program.width);

		var symbolTableAlign = new ccui.RelativeLayoutParameter();
		symbolTableAlign.setRelativeName("functionLayout");
		symbolTableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		symbolTableAlign.setMargin(ProgramContainerLayer.SYMBOL_TABLE_MARGIN, 0, 0, 0);
		this._symbolTable.setLayoutParameter(symbolTableAlign);

		this.addChild(this._symbolTable);

		this._backgroundLayer = new ccui.Layout();
		this.addChild(this._backgroundLayer, -1);

		this._arrowsLayer = new ccui.Layout();
		this.addChild(this._arrowsLayer, 10);

		this._drawProgram();
		this._recalculateSizes();
		pm.sendCustomEvent(pm.UPDATE_CLEAR_PROGRAM_BUTTON, name);
	},

	_recalculateSizes: function()
	{
		var oldHeight = this._realHeight;

		this._realWidth = Math.max(this._realWidth, this._symbolTable.getContentSize().width);
		this._realHeight = this._symbolTable.getContentSize().height + ProgramContainerLayer.BOTTOM_BORDER;

		this.setContentSize(this._realWidth, this._realHeight);
		this._backgroundLayer.setContentSize(this._symbolTable.getContentSize());
		this._arrowsLayer.setContentSize(this._symbolTable.getContentSize());

		if(this.canAddRemoveRowsState === ProgramContainerLayer.ADD_REMOVE_ROWS_STATE)
		{
			this._createAddRemoveRowsButton(ProgramContainerLayerP.AddArrowIconName, 0.0, this._program.height + 1);
			this._createAddRemoveRowsButton(ProgramContainerLayerP.RemoveArrowIconName, 0.5, this._program.height);
		}
		this.forceDoLayout();

		pm.sendCustomEvent(pm.UPDATE_INNER_PROGRAM_LAYER_CONTAINER, this._realHeight - oldHeight);
	},

	_findElementByTouch: function(touch)
	{
		var elementPos = this._symbolTable.cellAtPosition(this._symbolTable.convertTouchToNodeSpace(touch));

		if (!elementPos)
			return null;

		var element = this._symbolTable.getCell(elementPos);

		if(element instanceof FunctionButton)
			return element;

		return null;
	},

	_isElementDraggable: function(element)
	{
		return (this._programLayer.getRobot().canEditProgramSymbol(this._symbolTable.cellAtPosition(element.getPosition())) &&
			(!this._isEmptyButton(element)||(this._isEditable() && element.type !== FunctionButton.Type.Empty)));
	},

	_isElementClickable: function(element)
	{
		return (this._programLayer.getRobot().canEditProgramSymbol(this._symbolTable.cellAtPosition(element.getPosition())) &&
			(!this._isEditable() || element.type !== FunctionButton.Type.Empty || FunctionButton.selectedButton !== null));
	},

	_cloneElement: function(element)
	{
		if (this._isEditable() && this._isEmptyButton(element))
		{
			var spriteName = null;

			switch (element.type)
			{
				case FunctionButton.Type.Method:
					spriteName = pm.spriteUtils.getRobotMethodSpriteName("shadow");
					break;
				case FunctionButton.Type.Condition:
					spriteName = pm.spriteUtils.getRobotConditionSpriteName("shadow");
					break;
				case FunctionButton.Type.Repeater:
					spriteName = pm.spriteUtils.getRepeaterSpriteName("shadow");
					break;
				case FunctionButton.Type.CondRepeater:
					spriteName = pm.spriteUtils.getRepeaterSpriteName("cond");
					break;
			}

			return new ccui.ImageView(spriteName, ccui.Widget.PLIST_TEXTURE);
		}
		return element.cloneImage();
	},

	_isEmptyButton: function(button)
	{
		switch(button.type)
		{
			case FunctionButton.Type.Method: return button.value === pm.EMPTY_METHOD;
			case FunctionButton.Type.Repeater: return button.value === pm.REPEATER_EMPTY || button.value === 1;
			case FunctionButton.Type.Condition:
			case FunctionButton.Type.CondRepeater:
				return button.value === pm.CONDITION_EMPTY;
		}

		return true;
	},

	_dragCallback: function(element, eventType, touch)
	{
		switch (eventType)
		{
			case pmui.DragAndDropLayout.Event.CLICKED:
				this._onClick(element, touch);
				break;
			case pmui.DragAndDropLayout.Event.DRAG_CANCELLED:
				if(pm.tutorialUtils.onDragCancelled(touch))
					this._onDragCancel(element, touch);
				break;
			case pmui.DragAndDropLayout.Event.DROP:
				this._onDropButton(element, touch);
				break;
		}
	},

	_onClick: function(button, touch)
	{
		var elementPos = this._symbolTable.cellAtPosition(button.getPosition());

		if (this._buttonClickCallback)
		{
			if (this._program.symbols[elementPos.x][elementPos.y].type === pm.SymbolType.Function)
			{
				if(pm.robotManager.state !== pm.RobotManager.State.Paused)
					return false;

				this._buttonClickCallback.call(this._buttonClickCallbackTarget, button);
				return;
			}
		}

		var selectedButton = FunctionButton.selectedButton;

		if (this._program.symbols[elementPos.x][elementPos.y].type === pm.SymbolType.Function)
		{
			button.select();
			return;
		}

		if(selectedButton)
		{
			FunctionButton.deselect();

			if(button.type === FunctionButton.Type.Method && selectedButton.type === FunctionButton.Type.MethodList)
			{
				var buttonPosition = this._symbolTable.cellAtPosition(button.getPosition());
				this._pasteMethodList(buttonPosition, selectedButton.getMethodList());
				return;
			}

			if(button.type === FunctionButton.Type.RecognizeResult)
			{
				this._pasteRecognizeResult(button, touch);
				return;
			}

			if (this._isEditable() && !(!this._isEmptyButton(selectedButton) &&
				selectedButton.type === FunctionButton.Type.Condition && button.type === FunctionButton.Type.CondRepeater))
			{
				this._replaceButton(selectedButton, touch);
				return;
			}

			if(selectedButton.type === button.type ||
				(button.type === FunctionButton.Type.CondRepeater &&
					selectedButton.type === FunctionButton.Type.Condition))
				this.setSymbol(button, button.type, selectedButton.value);
		}
	},

	_getSymbolTypeForButton: function(type)
	{
		switch (type)
		{
			case FunctionButton.Type.Method:
				return pm.SymbolType.Method;

			case FunctionButton.Type.Repeater:
				return pm.SymbolType.Repeater;

			case FunctionButton.Type.Condition:
				return pm.SymbolType.Condition;

			case FunctionButton.Type.CondRepeater:
				return pm.SymbolType.CondRepeater;
		}
	},

	_onDragCancel: function(button, touch)
	{
		var elementPos = this._symbolTable.cellAtPosition(button.getPosition());

		var isFunctionSymbol = false;

		if (elementPos)
		{
			if (this._program.symbols[elementPos.x][elementPos.y].type === pm.SymbolType.Function)
				isFunctionSymbol = true;
		}

		if (!this._isEmptyButton(button) && !isFunctionSymbol)
		{
			switch (button.type)
			{
				case FunctionButton.Type.Method:
					this.setSymbol(button, button.type, pm.EMPTY_METHOD);
					break;
				case FunctionButton.Type.Repeater:
					this.setSymbol(button, button.type, 1);
					break;
				case FunctionButton.Type.Condition:
					this.setSymbol(button, button.type, pm.CONDITION_EMPTY);
					break;
				case FunctionButton.Type.CondRepeater:
					this.setSymbol(button, button.type, pm.CONDITION_EMPTY);
					break;
			}
		}
		else if (this._isEditable())
		{
			var notEmptySymbolsCount = 0;
			var notEmptySymbolX = -1;
			var notEmptySymbolY = -1;

			for (var i = 0; i < this._program.height; ++i)
			{
				for (var j = 0; j < this._program.width; ++j)
				{
					if (this._program.symbols[i][j].type !== pm.SymbolType.Empty)
					{
						notEmptySymbolX = i;
						notEmptySymbolY = j;
						++notEmptySymbolsCount;
					}
				}
			}

			if (notEmptySymbolsCount === 1)
			{
				if (!(notEmptySymbolX === 0 && notEmptySymbolY === 0))
				{
					this._program.symbols[0][0].value = pm.EMPTY_METHOD;
					this._program.symbols[0][0].type = pm.SymbolType.Method;

					var button = this._symbolTable.getCell(0, 0);

					button._drawBackground = true;
					button.setType(FunctionButton.Type.Method, pm.EMPTY_METHOD)
				}
				else
				{
					return;
				}
			}

			this._program.symbols[elementPos.x][elementPos.y].type = pm.SymbolType.Empty;
			this._program.symbols[elementPos.x][elementPos.y].value = -1;

			var newButton = pm.appUtils.generateFunctionButton(FunctionButton.Type.Empty, -1, false);

			this._symbolTable.setCell(elementPos.x, elementPos.y, newButton);

			this._updateFunctionInfo();
			this._parseProgram();
			this._drawBackgroundObjects();
		}
	},

	_checkIfCanAddBlock: function(newElementPos, newType)
	{
		if (newType === pm.SymbolType.Method)
			return true;

		var depth = this._program.maxDepthConstructCount === 0 ? 6 : this._program.maxDepthConstructCount;

		return newElementPos.y < depth;
	},

	_checkNextBlock: function(pos, type)
	{
		if (type === FunctionButton.Type.Repeater || type === FunctionButton.Type.Condition || type === FunctionButton.Type.CondRepeater)
		{
			return this._symbolTable.getCell(pos.x, pos.y + 1);
		}
		return true;
	},

	_checkIfCanAddButton: function(type, notUpdated)
	{
		if (notUpdated === undefined)
			notUpdated = false;

		var countSymbols = notUpdated ? 1 : 0;

		for (var i = 0; i < this._program.height; ++i)
		{
			for (var j = 0; j < this._program.width; ++j)
			{
				if (this._program.symbols[i][j].type === type)
					++countSymbols;
			}
		}

		switch (type)
		{
			case pm.SymbolType.Method:
				return countSymbols <= this._program.maxMethodCount || this._program.maxMethodCount === 0;
			case pm.SymbolType.Condition:
				return countSymbols <= this._program.maxConditionCount || this._program.maxConditionCount === 0;
			case pm.SymbolType.Repeater:
				return countSymbols <= this._program.maxRepeaterCount || this._program.maxRepeaterCount === 0;
			case pm.SymbolType.CondRepeater:
				return countSymbols <= this._program.maxCondRepeaterCount || this._program.maxCondRepeaterCount === 0;
			case pm.SymbolType.Function:
				return countSymbols < this._program.maxFunctionCount - 1 || this._program.maxFunctionCount === 0;
		}
	},

	_updateFunctionInfo: function()
	{
		var functionMap = {};

		for (var i = 0; i < 2; ++i)
		{
			for (var j = 0; j < this._program.width; ++j)
			{
				if (this._program.symbols[i][j].value >= pm.CMD_A && this._program.symbols[i][j].value <= pm.CMD_E)
				{
					var button = this._symbolTable.getCell(i, j);

					this._program.symbols[i][j].type = pm.SymbolType.Method;
					button._drawBackground = true;
				}
			}
		}

		for (var i = 2; i < this._program.height; ++i)
		{
			for (var j = 0; j < this._program.width; ++j)
			{
				if (this._program.symbols[i][j].value >= pm.CMD_A && this._program.symbols[i][j].value <= pm.CMD_E)
				{
					var isFunction = true;

					for (var y = 0; y < this._program.width; ++y)
					{
						var symbol = this._program.symbols[i-1][y];

						if (symbol.type !== pm.SymbolType.Empty)
							isFunction = false;
					}

					for (var y = 1; y < this._program.width; ++y)
					{
						var symbol = this._program.symbols[i][y];

						if (symbol.type !== pm.SymbolType.Empty)
							isFunction = false;
					}

					if (j !== 0)
						isFunction = false;

					var button = this._symbolTable.getCell(i, j);

					if (isFunction && !functionMap[this._program.symbols[i][j].value])
					{
						functionMap[this._program.symbols[i][j].value] = true;
						this._program.symbols[i][j].type = pm.SymbolType.Function;
						button._drawBackground = false;
					}
					else
					{
						this._program.symbols[i][j].type = pm.SymbolType.Method;
						button._drawBackground = true;
						button.setType(FunctionButton.Type.Method, button.value);
					}
				}
			}
		}
	},

	getAvailableRecognizeFunctions: function()
	{
		var availableFunctions = [];

		for (var i = 0; i < this._program.height; ++i)
		{
			for (var j = 0; j < 1; ++j)
			{
				if (this._program.symbols[i][j].value >= pm.CMD_A && this._program.symbols[i][j].value <= pm.CMD_E
				    && this._program.symbols[i][j].type === pm.SymbolType.Function)
				{
					availableFunctions.push(this._program.symbols[i][j].value);
				}
			}
		}

		return availableFunctions;
	},

	_replaceButton: function(button, touch)
	{
		var newElementPos = this._symbolTable.cellAtPosition(this._symbolTable.convertTouchToNodeSpace(touch));

		if (newElementPos)
		{
			var oldType = this._program.symbols[newElementPos.x][newElementPos.y].type;
			var oldValue = this._program.symbols[newElementPos.x][newElementPos.y].value;

			var newType = this._getSymbolTypeForButton(button.type);

			this._program.symbols[newElementPos.x][newElementPos.y].type = newType;
			this._program.symbols[newElementPos.x][newElementPos.y].value = button.value;

			this._parseProgram();

			if (!this._checkIfCanAddBlock(newElementPos, newType) || !this._checkNextBlock(newElementPos, button.type)
				|| (newType !== oldType && !this._checkIfCanAddButton(newType)))
			{
				this._program.symbols[newElementPos.x][newElementPos.y].type = oldType;
				this._program.symbols[newElementPos.x][newElementPos.y].value = oldValue;

				this._parseProgram();
				this._drawBackgroundObjects();

				return;
			}

			this._updateFunctionInfo();

			if (this._symbolTable.getCell(newElementPos.x, newElementPos.y).type === FunctionButton.Type.Repeater)
				this._symbolTable.setCell(newElementPos.x, newElementPos.y, pm.appUtils.generateFunctionButton(button.type, button.value));

			var newButton = this._symbolTable.getCell(newElementPos.x, newElementPos.y);

			if (this._program.symbols[newElementPos.x][newElementPos.y].type === pm.SymbolType.Function)
				newButton._drawBackground = false;
			else
				newButton._drawBackground = true;

			newButton.setType(button.type, button.value);

			if (button.type === FunctionButton.Type.Repeater)
			{
				if (button.value === pm.Counter.Repeater.Value || button.value === pm.ExtendedCounter.Repeater.Memory)
					this._symbolTable.setCell(newElementPos.x, newElementPos.y, new CounterRepeaterButton(button.value));
				else
					this._symbolTable.setCell(newElementPos.x, newElementPos.y, new RepeaterButton(button.value));
			}

			this._parseProgram();
			this._drawBackgroundObjects();
		}
	},

	_onDropButton: function(button, touch)
	{
		if(button.type === FunctionButton.Type.MethodList)
		{
			var suitableButton = this._findSuitableDropButton(touch, FunctionButton.Type.Method);

			if (suitableButton && this._checkCanDropInTutorial(suitableButton.button, suitableButton.position))
				this._pasteMethodList(suitableButton.position, button.getMethodList());

			return;
		}

		if(button.type === FunctionButton.Type.RecognizeResult)
		{
			this._pasteRecognizeResult(button, touch);
			return;
		}

		var suitableButton = this._findSuitableDropButton(touch, button.type);

		if (this._isEditable() && !(!this._isEmptyButton(button) && suitableButton &&
			suitableButton.button.type === FunctionButton.Type.CondRepeater && button.type === FunctionButton.Type.Condition))
		{
			this._replaceButton(button, touch);
			return;
		}

		if (!suitableButton)
			return;

		if(!this._checkCanDropInTutorial(suitableButton.button, suitableButton.position))
			return;

		if (this._program.symbols[suitableButton.position.x][suitableButton.position.y].type === pm.SymbolType.Function)
			return;

		if (this._programLayer.getRobot().canEditProgramSymbol(this._symbolTable.cellAtTouch(touch)))
			this.setSymbol(suitableButton.button, suitableButton.button.type, button.value);
	},

	_pasteMethodList: function(fromPosition, methodList)
	{
		var row = fromPosition.x;
		var col = fromPosition.y;

		var i = 0;

		for (var y = col; y < this._program.width; ++y)
		{
			if (this._program.symbols[row][y].type !== pm.SymbolType.Empty)
			{
				if (this._program.symbols[row][y].type !== pm.SymbolType.Method)
				{
					pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
					return;
				}

				if (i >= methodList.length)
				{
					pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
					return;
				}

				this._setSymbol(cc.p(row, y), FunctionButton.Type.Method, methodList[i]);
				++i;
			}
		}

		for (var x = row + 1; x < this._program.height; ++x)
		{
			for (var y = 0; y < this._program.width; ++y)
			{
				if (this._program.symbols[x][y].type !== pm.SymbolType.Empty)
				{
					if (this._program.symbols[x][y].type !== pm.SymbolType.Method)
					{
						pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
						return;
					}

					if (i >= methodList.length)
					{
						pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
						return;
					}

					this._setSymbol(cc.p(x, y), FunctionButton.Type.Method, methodList[i]);
					++i;
				}
			}
		}

		pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
	},

	/**
	 * Setts symbol.
	 * @param {cc.Point|FunctionButton} symbolPosOrButton
	 * @param {FunctionButton.Type} type
	 * @param {String} value
	 */
	setSymbol: function(symbolPosOrButton, type, value)
	{
		if(this._setSymbol(symbolPosOrButton, type, value))
			pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
	},

	_setSymbol: function(symbolPosOrButton, type, value)
	{
		var symbolPos = symbolPosOrButton;
		var button = symbolPosOrButton;

		if(symbolPosOrButton instanceof FunctionButton)
		{
			if(type !== null && type !== undefined && symbolPosOrButton.type !== type)
				return;

			symbolPos = this._symbolTable.cellAtPosition(button.getPosition());
		}
		else
		{
			button = this._symbolTable.getCell(symbolPos);
		}

		if ((value === pm.Counter.Repeater.Value || value === pm.ExtendedCounter.Repeater.Memory) && (button.value >= 1 && button.value <= 6))
		{
			var newButton = new CounterRepeaterButton(value, true)
			this._symbolTable.setCell(symbolPos.x, symbolPos.y, newButton);
		}
		else if ((button.value === pm.Counter.Repeater.Value || button.value === pm.ExtendedCounter.Repeater.Memory) && (value >= 1 && value <= 6))
		{
			var newButton = new RepeaterButton(value, true);
			this._symbolTable.setCell(symbolPos.x, symbolPos.y, newButton);
		}
		else
		{
			button.value = value;
		}

		if(this._program.symbols[symbolPos.x][symbolPos.y].value !== value)
		{
			this._program.symbols[symbolPos.x][symbolPos.y].value = value;

			return true;
		}

		return false;
	},

	_findSuitableDropButton: function(touch, type)
	{
		var elementPos = this._symbolTable.cellAtTouch(touch);

		if (!elementPos)
			return null;

		var element = this._symbolTable.getCell(elementPos);

		if (!element)
			return null;

		if (element.type === type ||
			(type === FunctionButton.Type.Condition && element.type === FunctionButton.Type.CondRepeater) ||
			(element.type === FunctionButton.Type.Condition && type === FunctionButton.Type.CondRepeater))
		{
			return {
				position: elementPos,
				button: element
			};
		}

		return null;
	},

	_checkCanDropInTutorial: function(button, buttonPos)
	{
		var objectName = "";

		switch(button.type)
		{
			case FunctionButton.Type.Method:
				objectName = pm.tutorialUtils.OBJECT_NAME_PATTERNS.METHOD.format(buttonPos.x, buttonPos.y);
				break;
			case FunctionButton.Type.CondRepeater:
			case FunctionButton.Type.Repeater:
				objectName = pm.tutorialUtils.OBJECT_NAME_PATTERNS.REPEATER.format(buttonPos.x, buttonPos.y);
				break;
			case FunctionButton.Type.Condition:
				objectName = pm.tutorialUtils.OBJECT_NAME_PATTERNS.CONDITION.format(buttonPos.x, buttonPos.y);
				break;
		}

		return pm.tutorialUtils.onDropToObject(objectName);
	},

	_drawResizeImage: function()
	{
		if(!this._isEditable())
			return;

		this._resizeSprite = new ccui.ImageView(pm.spriteUtils.getIconName("resize"), ccui.Widget.PLIST_TEXTURE);

		var align = new ccui.RelativeLayoutParameter();
		align.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_BOTTOMALIGN);
		align.setRelativeToWidgetName("functionLayout");
		align.setMargin(-this._resizeSprite.getContentSize().width / 2, 0, 0, -this._resizeSprite.getContentSize().height / 2);

		this._resizeSprite.setLayoutParameter(align);

		this.addChild(this._resizeSprite, 1, ProgramContainerLayer.RESIZE_SPRITE_NAME);

		// pm.tutorialUtils.registerTutorialObject(
		//     pm.tutorialUtils.OBJECT_NAME_PATTERNS.RESIZE_BLOCK.format(this._id),
		//     resizeImage
		// );
	},

	_interceptTouchBegan: function(touch, event)
	{
		if (this._resizeSprite && cc.rectContainsPoint(this._resizeSprite.getBoundingBox(), this.convertTouchToNodeSpace(touch)))
		{
			this._resizeSprite.loadTexture(
				pm.spriteUtils.getIconName("resize", pm.SELECTED_STATE),
				ccui.Widget.PLIST_TEXTURE
			);

			this._startTouchPoint = touch.getLocation();
			this._resizing = true;
			return true;
		}

		return false;
	},

	_interceptTouchMoved: function(touch, event)
	{
		if(!this._resizing)
			return;

		var touchPoint = touch.getLocation();

		var dif = cc.pSub(touchPoint, this._startTouchPoint);

		if(dif.y >= ProgramContainerLayer.BUTTON_SIZE)
		{
			if(this._program.height < 2)
				return;

			this._resizeHeight(-1);
			this._startTouchPoint.y = touchPoint.y;
		}
		else if(dif.y <= -ProgramContainerLayer.BUTTON_SIZE)
		{
			this._resizeHeight(1);
			this._startTouchPoint.y = touchPoint.y;
		}

		if(dif.x >= ProgramContainerLayer.BUTTON_SIZE)
		{
			this._resizeWidth(1);
			this._startTouchPoint.x = touchPoint.x;
		}
		else if(dif.x <= -ProgramContainerLayer.BUTTON_SIZE)
		{
			if(this._program.width < 2)
				return;

			this._resizeWidth(-1);
			this._startTouchPoint.x = touchPoint.x;
		}
	},

	_interceptTouchEnded: function(touch, event)
	{
		if(!this._resizing)
			return;

		this._resizing = false;

		this._resizeSprite.loadTexture(
			pm.spriteUtils.getIconName("resize", pm.NORMAL_STATE),
			ccui.Widget.PLIST_TEXTURE
		);
	},

	_resizeWidth: function(sgn)
	{
		var oldWidth = this._program.width;

		if(sgn === 1)
		{
			if (oldWidth === pm.MAX_PROGRAM_WIDTH)
				return;

			this._program.width++;
			this._symbolTable.setColumnCount(this._program.width);

			for(var i = 0; i < this._program.height; ++i)
			{
				this._program.symbols[i].push({
					type: pm.SymbolType.Empty,
					value: -1
				});

				this._drawFunctionButton(i, this._program.width-1, pm.SymbolType.Empty, -1);
			}
		}
		else
		{
			if (oldWidth === 1)
				return;

			this._program.width--;

			for(var i = 0; i < this._program.height; ++i)

				this._program.symbols[i].pop();

			this._symbolTable.setColumnCount(this._program.width);
		}

		if(oldWidth !== this._program.width)
		{
			// this._recalculateMethodTags();
			this._recalculateSizes();
			this._parseProgram();
			this._drawBackgroundObjects();

			pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
			pm.sendCustomEvent(pm.UPDATE_CLEAR_PROGRAM_BUTTON);

		}
	},

	_resizeHeight: function(sgn)
	{
		var oldHeight = this._program.height;

		if(sgn === 1)
		{
			this._program.height++;
			this._symbolTable.setRowCount(this._program.height);

			var array = [];

			for(var i = 0; i < this._program.width; ++i)
			{
				array.push({
					type: pm.SymbolType.Empty,
					value: -1
				});
			}

			this._program.symbols.push(array);

			for(var i = 0; i < this._program.width; ++i)

				this._drawFunctionButton(this._program.height-1, i, pm.SymbolType.Empty, -1);
		}
		else
		{
			if(oldHeight === 1)
				return;

			this._program.height--;

			this._program.symbols.pop();

			this._symbolTable.setRowCount(this._program.height);
		}

		if(oldHeight !== this._program.height)
		{
			this._updateFunctionInfo();

			this._recalculateSizes();
			this._parseProgram();
			this._drawBackgroundObjects();

			pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
			pm.sendCustomEvent(pm.UPDATE_CLEAR_PROGRAM_BUTTON);
		}
	},

	highlightMethodBroken: function(methodPlace)
	{
		this._brokenSprite.setVisible(true);

		var pos = this._symbolTable.getCell(methodPlace.x, methodPlace.y).getPosition();

		var delta = (this._brokenSprite.getContentSize().height - ProgramContainerLayer.BUTTON_SIZE)/2;

		this._brokenSprite.setPosition(pos.x - delta, pos.y + delta);
	},

	highlightMethod: function(methodPlace)
	{
		this._symbolTable.getCell(methodPlace.x, methodPlace.y).setHighlighted(true);

		this._highlightedMethodsStack.push(methodPlace);
	},

	switchPointsInRepeater: function(repPlace, num, rep)
	{
		var index = (num === rep + 1) ? 0 : num;

		if (num === 1)
			this._startedRepeatersStack.push(repPlace);
		else if (num === rep + 1)
			repPlace = this._startedRepeatersStack.pop();
		if (this._program.symbols[repPlace.x][repPlace.y].value !== pm.Counter.Repeater.Value && this._program.symbols[repPlace.x][repPlace.y].value !== pm.ExtendedCounter.Repeater.Memory)
			this._symbolTable.getCell(repPlace.x, repPlace.y).switchPointsNumber(index);
		else
			this._symbolTable.getCell(repPlace.x, repPlace.y).switchPointsNumber(rep,index);
	},

	reloadRepeaters: function()
	{
		while(this._startedRepeatersStack.length > 0)
		{
			var repPlace = this._startedRepeatersStack.pop();

			this._symbolTable.getCell(repPlace.x, repPlace.y).switchPointsNumber(0);
		}
	},

	clearLastHighlight: function()
	{
		var methodStackLength = this._highlightedMethodsStack.length;

		if (methodStackLength !== 0)
		{
			var lastElem = this._highlightedMethodsStack[methodStackLength - 1];

			var found = false;
			for (var i = 0; i < this._highlightedMethodsStack.length - 1; ++i)
			{
				if (this._highlightedMethodsStack[i].x === lastElem.x && this._highlightedMethodsStack[i].y === lastElem.y)
				{
					found = true;
					break;
				}
			}
			if (!found)
				this._symbolTable.getCell(lastElem.x, lastElem.y).setHighlighted(false);

			this._highlightedMethodsStack.pop();
		}

		this._brokenSprite.setVisible(false);
	},

	clearAllHighlights: function()
	{
		for (var i = 0; i < this._highlightedMethodsStack.length; ++i)
		{
			var curElem = this._highlightedMethodsStack[i];

			this._symbolTable.getCell(curElem.x, curElem.y).setHighlighted(false);
		}

		this._highlightedMethodsStack = [];

		this._brokenSprite.setVisible(false);
	},

	_isEditable: function()
	{
		return this._programLayer.isProgramPatternEditable();
	},

	_pasteRecognizeResult: function(button, touch)
	{
		var elementPos = this._symbolTable.cellAtTouch(touch);

		if (!elementPos)
			return;

		var element = this._symbolTable.getCell(elementPos);

		if (!element)
			return;

		var predictions = button.value;
		var program = this._program;
		var functions = this._parsedProgram.children;
		var recSignIndex = 0;

		for (var i = elementPos.x; i < program.height; ++i)
		{
			var startIndex = i === elementPos.x ? elementPos.y : 0;

			for (var j = startIndex; j < program.width; ++j)
			{
				if(recSignIndex >= predictions.length)
					break;

				var symbol = this._program.symbols[i][j];

				var sign = predictions[recSignIndex].getLabel();

				if(sign === pm.EMPTY_METHOD)
					continue;

				var isRepeater = sign.indexOf("repeater_") !== -1;
				var isFunctionID = false;
				var robotHasMethod = false;

				if(!isRepeater)
				{
					robotHasMethod = (this._programLayer.robot.nativeFunctions.indexOf(sign) !== -1);

					for (var f = 0; f < functions.length; ++f)
					{
						if (functions[f].value === sign)
							isFunctionID = true;
					}
				}
				else
				{
					sign = Math.min(Math.max(1, Number(sign.replace("repeater_", ""))), 6);
				}

				switch (symbol.type)
				{
					case pm.SymbolType.Repeater:

						if(!isRepeater)
							continue;

						++recSignIndex;
						break;
					case pm.SymbolType.Method:

						if(isRepeater)
							continue;

						++recSignIndex;

						if(!robotHasMethod && !isFunctionID)
							continue;

						break;
					case pm.SymbolType.Function:
						if(!isFunctionID)
							continue;

						++recSignIndex;
						break;
					default:
						continue;
				}

				if(isRepeater || robotHasMethod || isFunctionID)
					this.setSymbol(cc.p(i, j), null, sign);
			}
		}
	},

	_isEmptyTailRow: function(index)
	{
		var isEmptyTailNextRow = true;

		for (var j = 1; j < this._program.width; ++j)
		{
			var symbol = this._program.symbols[index][j];
			if (symbol.type !== pm.SymbolType.Empty)
				isEmptyTailNextRow = false;
		}
		return isEmptyTailNextRow;
	},
	
	_createAddRemoveRowsButton: function(iconName, offsetY, arrowsCount)
	{
		for (var i = 0; i < arrowsCount; ++i)
		{
			if(iconName === ProgramContainerLayerP.AddArrowIconName)
			{
				var pos = this._symbolTable.getCellPosition(i, this._program.width - 1);
			}
			else
				var pos = this._symbolTable.getCellPosition(i, 0);

			var button = new pmui.Button(pm.spriteUtils.getIconName(iconName, pm.NORMAL_STATE),
				pm.spriteUtils.getIconName(iconName, pm.SELECTED_STATE),
				pm.spriteUtils.getIconName(iconName, pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			if(iconName === ProgramContainerLayerP.AddArrowIconName)
				pos.x += 0.6 * ProgramContainerLayer.BUTTON_SIZE;
			else
				pos.x += 0.4 * ProgramContainerLayer.BUTTON_SIZE;

			pos.y -= ProgramContainerLayer.BUTTON_SIZE * offsetY + ProgramContainerLayer.BUTTON_SEPARATOR / 2;

			button.setPosition(pos);

			if (iconName === ProgramContainerLayerP.AddArrowName)
				button.setName(ProgramContainerLayerP.AddArrowName + i);
			else
				button.setName(ProgramContainerLayerP.RemoveArrowName + i);

			button.addClickEventListener(this._rowsButtonClick.bind(this));

			if(iconName === ProgramContainerLayerP.RemoveArrowIconName)
				button.setRotation(180);

			button.setScale(0.85);
			button.setAnchorPoint(0.0, 0.5);

			this._arrowsLayer.addChild(button, 10);
		}
	},

	_rowsButtonClick: function(sender)
	{
		var name = sender.getName();
		var index = parseInt(name.substring(8));
		if(name.substring(0,8) === ProgramContainerLayerP.AddArrowName)
			this._addEmptyRowAtPosition(index);
		else
			this._removeRowFromPosition(index);
	},

	_addEmptyRowAtPosition: function(index)
	{
		this._program.height++;
		this._symbolTable.setRowCount(this._program.height);

		var array = [];

		for(var i = 0; i < this._program.width; ++i)
		{
			array.push({
				type: pm.SymbolType.Empty,
				value: -1
			});
		}

		if (index !== 0)
		{
			for (var i = 0; i< this._program.width; ++i)
			{
				var symbol = this._program.symbols[index-1][i];

				if (symbol.type === pm.SymbolType.Method)
				{
					array[i].type = symbol.type
					array[i].value = pm.EMPTY_METHOD;
				}
			}
		}

		this._program.symbols.splice(
			index,
			0,
			array
		);

		this._recalculateSizes();
		this._drawProgram();

		pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
	},

	_removeRowFromPosition: function(index)
	{
		if (this._program.height === 1)
			return;

		this._program.height--;
		this._program.symbols.splice(index, 1);
		this._symbolTable.setRowCount(this._program.height);

		var prevInd = index;
		--prevInd;
		var nextInd = index;
		++nextInd;

		var notEmptySymbolsCount = 0;
		var notEmptySymbolX = -1;
		var notEmptySymbolY = -1;

		for (var i = 0; i < this._program.height; ++i)
		{
			for (var j = 0; j < this._program.width; ++j)
			{
				if (this._program.symbols[i][j].type !== pm.SymbolType.Empty)
				{
					notEmptySymbolX = i;
					notEmptySymbolY = j;
					++notEmptySymbolsCount;
				}
			}
		}

		if (notEmptySymbolsCount === 0)
		{
			if (!(notEmptySymbolX === 0 && notEmptySymbolY === 0))
			{
				this._program.symbols[0][0].value = pm.EMPTY_METHOD;
				this._program.symbols[0][0].type = pm.SymbolType.Method;

				var button = this._symbolTable.getCell(0, 0);

				button._drawBackground = true;
				button.setType(FunctionButton.Type.Method, pm.EMPTY_METHOD)
			}
			else
			{
				return;
			}
		}

		this._recalculateSizes();
		this._drawProgram();

		pm.sendCustomEvent(pm.USER_PROGRAM_UPDATED_STR);
	},

	loadAddRemoveRowTexture: function(button)
	{
		var normal, selected, disabled;

		switch (this.canAddRemoveRowsState)
		{
			case ProgramContainerLayer.NORMAL_STATE:
				normal = pm.spriteUtils.getIconName("rowsChangeNew", pm.SELECTED_STATE);
				selected = pm.spriteUtils.getIconName("rowsChangeNew", pm.NORMAL_STATE);
				disabled = pm.spriteUtils.getIconName("rowsChangeNew", pm.DISABLED_STATE);

				this.canAddRemoveRowsState = ProgramContainerLayer.ADD_REMOVE_ROWS_STATE;
				this._createAddRemoveRowsButton(ProgramContainerLayerP.AddArrowIconName, 0.0, this._program.height + 1);
				this._createAddRemoveRowsButton(ProgramContainerLayerP.RemoveArrowIconName, 0.5, this._program.height);

				break;
			case ProgramContainerLayer.ADD_REMOVE_ROWS_STATE:
				normal = pm.spriteUtils.getIconName("rowsChangeNew", pm.NORMAL_STATE);
				selected = pm.spriteUtils.getIconName("rowsChangeNew", pm.SELECTED_STATE);
				disabled = pm.spriteUtils.getIconName("rowsChangeNew", pm.DISABLED_STATE);

				this.canAddRemoveRowsState = ProgramContainerLayer.NORMAL_STATE;
				this._arrowsLayer.removeAllChildren(false);
				break;
		}

		button.loadTextureNormal(normal, ccui.Widget.PLIST_TEXTURE);
		button.loadTexturePressed(selected, ccui.Widget.PLIST_TEXTURE);
		button.loadTextureDisabled(disabled, ccui.Widget.PLIST_TEXTURE);
	},

	_removeAllArrows: function ()
	{
		this._arrowsLayer.removeAllChildren(false);
		this.canAddRemoveRowsState = ProgramContainerLayer.NORMAL_STATE;
	}

});

ProgramContainerLayerP.AddArrowIconName = "addArrow"
ProgramContainerLayerP.RemoveArrowIconName = "removeArrow"
ProgramContainerLayerP.AddArrowName = "addArrow"
ProgramContainerLayerP.RemoveArrowName = "remArrow"