/**
 * Created by Kirill Mashchenko on 23.04.20.
 */

pm.PModule = {

	getProgramLayerWidth: function()
	{
		return 355;
	},

	compileRobotProgram: function(robot, level)
	{
		return pm.programUtilsP.compileRobotProgram(robot, level);
	},

	generateProgramLayer: function(gameType, useMethodStack, robot, level)
	{
		return new ProgramLayerP(gameType, useMethodStack, robot, level);
	},

	generateProgramEditorLayer: function(gameType, useMethodStack, robot, level)
	{
		return new ProgramEditorLayerP(gameType, useMethodStack, robot, level);
	},

	generateRobotManager: function()
	{
		return new pm.RobotManagerP();
	},

	generateProgramData: function(isFilled)
	{
		return new pm.data.ProgramDataP(isFilled);
	},

	getButtonTypes: function()
	{
		return {
			Empty: -1,
			Repeater: 0,
			Condition: 1,
			CondRepeater: 2,
			Method: 3,
			MethodList: 4,
			RecognizeResult: 5
		}
	},

	getDeselectFunction: function()
	{
		return function()
		{
			if(FunctionButton.selectedButton)
			{
				FunctionButton.selectedButton.stopAnimation();
				FunctionButton.selectedButton = null;
			}
		};
	},

	getFunctionButtonPrototype: function()
	{
		return FunctionButtonP.prototype;
	},

	getProgramLayerPrototype: function()
	{
		return ProgramLayerP.prototype;
	},

	generateFunctionButton: function(type, value, drawBackground)
	{
		return new FunctionButtonP(type, value, drawBackground);
	},

	createMethodListButton: function(value)
	{
		return new MethodListButtonP(value);
	},

	generatePlayerRobotInterfaceLayer: function (robot)
	{
		return new PlayerRobotInterfaceLayerP(robot);
	},

	getAvailableGlobalRobots: function()
	{
		return {
			labels: [
				LocalizedString("AddSimpleCounter"),
				LocalizedString("AddDoubleCounter"),
				LocalizedString("AddGreenFlag"),
				LocalizedString("AddOrangeFlag")
			],
			globalRobots: [
				pm.GlobalRobotType.Counter,
				pm.GlobalRobotType.ExtendedCounter,
				pm.GlobalRobotType.GreenFlag,
				pm.GlobalRobotType.OrangeFlag
			]
		};
	},

	getAvailableRobots: function()
	{
		return [
			new pm.RepairLevelModule,
			new pm.PushLevelModule,
			new pm.TrainLevelModule,
			new pm.PhysicalRobotLevelModule,
			new pm.PressRobotLevelModule,
			new pm.KumirLevelModule,
			new pm.AquariusLevelModule,
			new pm.GrasshopperLevelModule,
			new pm.LightLevelModule
		]
	},

	useExtraMethodsInKumirRobot: function()
	{
		return false;
	},

	getAppVersion: function()
	{
		return pmVersion + '.' + build;
	},

	getSupportedLevelFormatVersion: function()
	{
		return PM_SUPPORTED_LEVEL_FORMAT_VERSION;
	},

	getWorldConvertFunctions: function()
	{
		return pm.worldConvertFunctionsP;
	},

	getAquariusTankScale: function ()
	{
		return 1;
	},

	getLoadText: function ()
	{
		return new cc.Sprite("Background/LoadLayer/LoadText.png");
	},

	getImagesLocalization: function (language)
	{
		return {};
	}
};

pm.appUtils.register(pm.PModule, "pm");
