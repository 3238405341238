/**
 * A brief explanation for "project.json":
 * Here is the content of project.json file, this is the global configuration for your game, you can modify it to customize some behavior.
 * The detail of each field is under it.
 {
    "project_type": "javascript",
    // "project_type" indicate the program language of your project, you can ignore this field

    "debugMode"     : 1,
    // "debugMode" possible values :
    //      0 - No message will be printed.
    //      1 - cc.error, cc.assert, cc.warn, cc.log will print in console.
    //      2 - cc.error, cc.assert, cc.warn will print in console.
    //      3 - cc.error, cc.assert will print in console.
    //      4 - cc.error, cc.assert, cc.warn, cc.log will print on canvas, available only on web.
    //      5 - cc.error, cc.assert, cc.warn will print on canvas, available only on web.
    //      6 - cc.error, cc.assert will print on canvas, available only on web.

    "showFPS"       : true,
    // Left bottom corner fps information will show when "showFPS" equals true, otherwise it will be hide.

    "frameRate"     : 60,
    // "frameRate" set the wanted frame rate for your game, but the real fps depends on your game implementation and the running environment.

    "id"            : "gameCanvas",
    // "gameCanvas" sets the id of your canvas element on the web page, it's useful only on web.

    "renderMode"    : 0,
    // "renderMode" sets the renderer type, only useful on web :
    //      0 - Automatically chosen by engine
    //      1 - Forced to use canvas renderer
    //      2 - Forced to use WebGL renderer, but this will be ignored on mobile browsers

    "engineDir"     : "frameworks/cocos2d-html5/",
    // In debug mode, if you use the whole engine to develop your game, you should specify its relative path with "engineDir",
    // but if you are using a single engine file, you can ignore it.

    "modules"       : ["cocos2d"],
    // "modules" defines which modules you will need in your game, it's useful only on web,
    // using this can greatly reduce your game's resource size, and the cocos console tool can package your game with only the modules you set.
    // For details about modules definitions, you can refer to "../../frameworks/cocos2d-html5/modulesConfig.json".

    "jsList"        : [
    ]
    // "jsList" sets the list of js files in your game.
 }
 *
 */

function parseInputData(data)
{
	var params = new URLSearchParams(window.location.search);

	data.blockExit = (params.get("blockExit") === "true");
	data.mireraAuthKey = params.get("mireraAuth");

	var hash = window.location.hash;

	if (hash.length > 1)
	{
		var hashParams = (hash.slice(1)).split('/');

		if (hashParams[0] === "editor")
		{
			data.editor = true;
			hashParams.splice(0, 1);
		}

		if (hashParams[0] === "worlds" && /[a-zA-Z0-9]+/.test(hashParams[1]))
			data.worldID = hashParams[1];

		if (hashParams[2] === "games" && /[1-9][0-9]*|0/.test(hashParams[3]))
			data.game = Number.parseInt(hashParams[3]);

		if (hashParams[4] === "levels" && /[1-9][0-9]*|0/.test(hashParams[5]))
			data.level = Number.parseInt(hashParams[5]);

		if ((!data.editor && hashParams[0] === "worlds") || (data.editor && data.worldID !== undefined))
			data.start = false;
	}
}

function loadApp(appType)
{
	// data parameters may be applied only in web version, in native data is default
	var data = {
		editor: false,
		start: true,
		worldID: undefined,
		game: undefined,
		level: undefined,
		blockExit: false,
		mireraAuthKey: undefined, // authentication key from mirera.
	};

	if (!cc.sys.isNative)
		parseInputData(data);

	cc.game.onStart = function ()
	{
		cc.log("App type: " + appType);

		pm.appConfig.appType = appType;

		pm.appUtils.load();

		cc.director.setProjection(cc.Director.PROJECTION_2D);

		if (cc.sys.isMobile &&
			cc.sys.browserType !== cc.sys.BROWSER_TYPE_BAIDU &&
			cc.sys.browserType !== cc.sys.BROWSER_TYPE_WECHAT)

			cc.view.enableAutoFullScreen(true);

		pm.settings.isRetinaEnabled = false;
		var scale = 1;

		if (cc.sys.platform === cc.sys.DESKTOP_BROWSER)
		{
			pm.settings.isRetinaEnabled = (window.devicePixelRatio > 1 ||
				(window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches)
			);
		}
		else if (cc.sys.platform === cc.sys.MOBILE_BROWSER)
		{
			pm.settings.isRetinaEnabled = true;
		}

		if (cc.sys.isNative)
			cc.log("ScaleInfo: " + cc.view.getFrameSize().width + ' ' + cc.view.getFrameSize().height + ' ' + cc.Device.getDPI());

		if (cc.sys.os !== cc.sys.OS_IOS && cc.sys.os !== cc.sys.OS_OSX)
		{
			if (cc.sys.isMobile && cc.sys.isNative)
			{
				if (cc.Device.getDPI() / pm.MOBILE_DPI_FACTOR > 1.0)
					pm.settings.isRetinaEnabled = true;

				if (cc.view.getFrameSize().width / cc.Device.getDPI() >= pm.DEVICE_WIDTH_INDICATOR)
				{
					cc.log("Tablet");

					if (pm.settings.isRetinaEnabled)
					{
						var sq = pm.TABLET_RETINA_TARGET_WIDTH * pm.TABLET_RETINA_TARGET_HEIGHT / pm.TABLET_RETINA_TARGET_DPI;
						var sq1 = cc.view.getFrameSize().width * cc.view.getFrameSize().height / cc.Device.getDPI();

						var density = cc.Device.getDPI() / pm.MOBILE_DPI_FACTOR;

						if (density <= 2)
							density = 2.0 / density;
						else
							density *= 2.0;

						scale = density * sq / sq1;

						if (scale > 2.0)
							scale = 2.0;

						if (scale < 1.1)
							scale = 1.1;

					}
					else
					{
						scale = 1.1;
					}
					cc.log("scale =" + scale);
				}
				else
				{
					cc.log("Phone");

					if (pm.settings.isRetinaEnabled)
					{
						var sq = pm.PHONE_RETINA_TARGET_WIDTH * pm.PHONE_RETINA_TARGET_HEIGHT / pm.PHONE_RETINA_TARGET_DPI;
						var sq1 = cc.view.getFrameSize().width * cc.view.getFrameSize().height / cc.Device.getDPI();

						var density = cc.Device.getDPI() / pm.MOBILE_DPI_FACTOR;

						if (density <= 2)
							density = 2.0 / density;
						else
							density *= 2.0;

						scale = density * sq / sq1;

						if (scale > 2.0)
							scale = 2.0;

						if (scale < 1.1)
							scale = 1.1;
					}
					else
					{
						scale = 1.1;
					}
				}
			}
			else if (cc.view.getFrameSize().width >= pm.DESKTOP_RETINA_DISPLAY_MIN_WIDTH)
			{
				pm.settings.isRetinaEnabled = true;
				scale = 2.0;
			}
		}
		else if (cc.sys.isNative)
		{
			pm.settings.isRetinaEnabled = cc.view.getRetinaFactor() >= 2 || cc.view.getContentScaleFactor() >= 2;

			if (pm.settings.isRetinaEnabled)
			{
				if (cc.sys.platform === cc.sys.IPHONE)
				{
					if (cc.view.getFrameSize().width < pm.IPHONE_BIG_DISPLAY_MIN_WIDTH)
						scale = 1.3;
					else
						scale = 2.0;
				}
				else if (cc.sys.platform === cc.sys.IPAD)
				{
					scale = 2.0;
				}
			}
			else if (cc.sys.platform === cc.sys.IPHONE)
			{
				scale = 0.7;
			}
		}

		cc.view.enableRetina(pm.settings.isRetinaEnabled);

		if (pm.settings.isRetinaEnabled)
			cc.director.setContentScaleFactor(2.0);

		console.log("Is retina:" + pm.settings.isRetinaEnabled + ", scale:" + scale);

		pm.loadClassMapping();

		pm.settings.load();

		cc.log("ScreenSize : " + JSON.stringify(pm.settings.getScreenSize()));

		if (!pm.settings.getAppScale())
			pm.settings.setAppScale(scale);

		cc.view.setDesignResolutionSize(cc.view.getFrameSize().width / pm.settings.getAppScale(), cc.view.getFrameSize().height / pm.settings.getAppScale(), cc.ResolutionPolicy.SHOW_ALL);
		cc.view.adjustViewPort(true);
		cc.view.resizeWithBrowserSize(true);

		var resFile = pm.settings.isRetinaEnabled ? "res/pre-resources-hd.plist" : "res/pre-resources.plist";

		if (data.worldID)
			pm.settings.setSelectedWorldID(data.worldID);

		pm.settings.blockExit = data.blockExit;

		if (data.mireraAuthKey)
		{
			pm.apiServerUtils.getMireraAuthorizationTokens(function (error, response)
			{
				if (!error)
				{
					if (response.accessToken)
						pm.settings.setAccessToken(response.accessToken);

					if (response.refreshToken)
						pm.settings.setRefreshToken(response.refreshToken);

					pm.settings.setUserAutoLogin(true);
				}
				else
				{
					cc.error(JSON.stringify(error));
				}

				runLoadScene.call(this, resFile, data);
			}.bind(this), data.mireraAuthKey);
		}
		else
		{
			runLoadScene.call(this, resFile, data);
		}
	};

	cc.game.run();
}

/**
 * Function for pre-launching piktomir
 */
function runLoadScene(resFile, data)
{
	if (!cc.sys.isNative)
	{
		var loadRes = [];

		for (var key in pm.fonts)
		{
			if (cc.isObject(pm.fonts[key]))
				loadRes.push(pm.fonts[key]);
		}

		cc.loader.load(loadRes, function ()
		{
			if (!data.blockExit)
			{
				cc.loader.loadAliases(resFile, function ()
				{
					if (document.getElementById("cocosLoading")) //If referenced loading.js, please remove it
						document.body.removeChild(document.getElementById("cocosLoading"));

					cc.director.runScene(new LoadScene(data));
				});
			}
			else
			{
				pm.utils.loadAliasesFromFiles(pm.utils.getResFiles(), function ()
				{
					if (document.getElementById("cocosLoading")) //If referenced loading.js, please remove it
						document.body.removeChild(document.getElementById("cocosLoading"));

					world = new pm.data.World();

					pm.validationUtils.load();

					pm.worldUtils.downloadWorldFromServer({
						worldID: data.worldID,
						callback: runPiktomir.bind(this, data),
						callbackTarget: this,
						loadLocalOnError: false
					});
				}.bind(this));
			}
		});
	}
	else
	{
		cc.loader.loadAliases(resFile, function ()
		{
			cc.director.runScene(new LoadScene(data));
		});
	}
}
