/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class 2D robot for {@link pm.data.DraftsmanLevel}.</br>
 * Has a paint element native function.
 * @implements PlayerRobot4
 */
pm.data.DraftsmanRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.DraftsmanRobot# */{
	typeName: "DraftsmanRobot",
	_penFlag: false,

	ctor: function()
	{
		this._addNonEnumerableProps("_penFlag");

		this._super();

		this.nativeFunctionMap[pm.data.DraftsmanRobot.NativeMethod.MoveToPoint] = new pm.NativeFunction(this, this._moveToPoint, 2);
		this.nativeFunctionMap[pm.data.DraftsmanRobot.NativeMethod.MoveToVector] = new pm.NativeFunction(this, this._moveToVector, 2);

		this.nativeFunctionMap[pm.data.DraftsmanRobot.NativeMethod.PenDown] = new pm.NativeFunction(this, this._penDown);
		this.nativeFunctionMap[pm.data.DraftsmanRobot.NativeMethod.PenUp] = new pm.NativeFunction(this, this._penUp);

		this.nativeFunctions.push(pm.data.DraftsmanRobot.NativeMethod.MoveToPoint, pm.data.DraftsmanRobot.NativeMethod.MoveToVector,
			pm.data.DraftsmanRobot.NativeMethod.PenDown, pm.data.DraftsmanRobot.NativeMethod.PenUp);
	},

	getDirectionCount: function() { return 4; },

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new DraftsmanRobotSprite();

			return this.sprite;
		}
	},

	_penDown: function()
	{
		this.setPenFlag(true);
		// this.setStateFlag(pm.RobotState.PlayingAnimation);

		// this.playAnimation(KumirRobotAnimation.Paint, this._endPaint, 0);
	},

	_endPenDown: function()
	{
		// this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_penUp: function()
	{
		this.setPenFlag(false);
		// this.setStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endPenUp: function()
	{
		// this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	setPenFlag: function(flag)
	{
		this._penFlag = flag;
	},

	getPenFlag: function()
	{
		return this._penFlag;
	},

	_moveToPoint: function(x1, x2)
	{
		var startElementPos = this.parentLevel.activeMap.getStartElementPos();
		var target = cc.p(startElementPos.x + x1, startElementPos.y - x2);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false);
	},

	_moveToVector: function(x1, x2)
	{
		var target = cc.p(this.position.x + x1, this.position.y - x2);

		if (target.x < 0 || target.y < 0 || target.x >= this.getMap().width || target.y >= this.getMap().height)
			target = null;

		this._moveTo(target, false);
	},

	_endMove: function()
	{
		if (this._penFlag)
		{
			this.parentLevel.activeMap.lines.push({
				oldPos: this._oldPosition,
				newPos: this.position
			});
		}

		if (!CORE_BUILD && !this.needToPlayAnimation() && this._penFlag)
		{
			var oldPos = this.sprite.getRealPosition(this._oldPosition);
			var targetPos = this.sprite.getRealPosition(this.position);

			var length = Math.sqrt((targetPos.x - oldPos.x) * (targetPos.x - oldPos.x) + (targetPos.y - oldPos.y) * (targetPos.y - oldPos.y));
			var rotationAngle = cc.radiansToDegrees(Math.acos((targetPos.x - oldPos.x) / length));

			if (targetPos.y - oldPos.y > 0)
				rotationAngle *= -1;

			var line = new cc.Scale9Sprite("System/Line.png");

			line.setAnchorPoint(cc.p(0, 0.5));
			line.setRotation(rotationAngle);
			line.setPosition(cc.p(oldPos.x - (targetPos.x - oldPos.x) / length,oldPos.y - (targetPos.y - oldPos.y) / length));
			line.setScale((length + 2) / line.width, 1);
			this.parentLevel.activeMap.mapLayer.drawLayer.addChild(line);

			this.updateSprite();
		}

		pm.PlayerRobot2D.prototype._endMove.call(this);
	},

	reset: function()
	{
		this.setPenFlag(false);
		PlayerRobot4.prototype.reset.call(this);
	},

	getState: function ()
	{
		// return {
		// 	position: this.position,
		// 	direction: this.direction,
		// 	animation: this.getLastAnimation()
		// };
	},

	setState: function (state)
	{
		// if (state.direction !== this.direction)
		// 	this.direction = state.direction;
		//
		// if (state.position !== this.position || this.isBroken())
		// 	this._moveTo(state.position, true);
		// else if (state.animation === DraftsmanRobotAnimation.Paint)
		// 	this._unpaint();
	},

	getType: function() { return pm.DraftsmanLevelModule.RobotType; }
});

pm.data.DraftsmanRobot.NativeMethod =
{
	MoveToPoint: "draftsman_move_to_point",
	MoveToVector: "draftsman_move_to_vector",
	PenDown: "draftsman_pen_down",
	PenUp: "draftsman_pen_up"
};
