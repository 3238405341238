/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class Layer for {@link pm.data.GrasshopperMap}.
 * @implements SquarableMapLayerWithWalls
 */
var GrasshopperMapLayer = SquarableMapLayer.extend(/** @lends GrasshopperMapLayer#*/{
	ctor: function(map)
	{
		this._super(map);
		this._orientation = pm.MapLayer2D.Orientation.Ortho;
		this._mapElementSize = cc.size(64, 64);
	},

	needToDrawOrientationOption: function()
	{
		return true;
	},

	containsPoint: function(point)
	{
		var mapPoint = this.terrainLayer.convertTouchToNodeSpace(point);
		var mapSize = this.terrainLayer.getContentSize();

		return (mapPoint.y >= - 3 * this.getMapElementSize().width && mapPoint.y <= mapSize.height + 3 * this.getMapElementSize().height);
	},

	getBoundingBox: function(scale)
	{
		var boundingBox = SquarableMapLayer.prototype.getBoundingBox.call(this);

		if (this._orientation === pm.MapLayer2D.Orientation.Iso)
		{
			boundingBox.x -= Math.sqrt(2)*this._mapElementSize.width/2 * this.getScale();
			boundingBox.y -= Math.sqrt(2)*this._mapElementSize.height/2 * this.getScale();
		}

		return boundingBox;
	},

	handleTouch: function(touch)
	{
		if (!this._clicksEnabled)
			return false;

		var level = this._map.parentLevel;

		var handles = false;

		for(var i = 0; i < level.robots.length; ++i)
		{
			var robot = level.robots[i];

			if (!(robot.sprite instanceof pm.ObjectSprite2D))
				continue;

			if (robot.sprite.containsPoint(touch))
			{
				var currentRobot = level.getCurrentRobot();

				if (currentRobot && robot && currentRobot != robot)
				{
					if (pm.settings.isEditorMode)
						this._activeRobot = robot;

					for (var i = 0; i < this._map.parentLevel.robots.length; ++i)
					{
						var rob = this._map.parentLevel.robots[i];

						if (rob.groupID === robot.groupID)
						{
							rob.sprite.setSpriteColor(this.getRobotIndex(robot.groupID));
							rob.sprite.setActive(true);
						}
						else
						{
							rob.sprite.setActive(false);
						}
					}

					var startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(currentRobot.id);
					var startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

					if(startMarkSprite)
						startMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

					startMarkName = pm.MapLayer2D.START_MARK_NAME_PATTERN.format(robot.id);
					startMarkSprite = this.terrainLayer.getChildByName(startMarkName);

					if(startMarkSprite)
						startMarkSprite.setOpacity(255);

					var posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(currentRobot.id);
					var posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

					if(posMarkSprite)
						posMarkSprite.setOpacity(pm.MapLayer2D.INACTIVE_ROBOT_OPACITY);

					posMarkName = pm.MapLayer2D.POSITION_MARK_NAME_PATTERN.format(robot.id);
					posMarkSprite = this.terrainLayer.getChildByName(posMarkName);

					if(posMarkSprite)
						posMarkSprite.setOpacity(255);
				}

				pm.sendCustomEvent(pm.ROBOT_TOUCH_EVENT_STR, robot);

				handles = true;
				break;
			}
		}

		if (level.isStartPointEditable)
		{
			var currentRobot = level.getCurrentRobot();

			for (var i = 0; i < this._map.width; ++i)
			{
				var pos = cc.p(i, 0);
				var element = this._map.element(pos);

				if (element.containsPoint(touch) && element.getRobotsCount() === 0)
				{
					if (this._map.element(currentRobot.position).startForRobot === currentRobot.id
						&& this._map.element(currentRobot.position).startRobotData.direction === currentRobot.direction)
					{
						this._map.element(currentRobot.position).startForRobot = pm.MapElement.START_FOR_NO_ROBOT;
						delete this._map.element(currentRobot.position).startRobotData.direction;

						this._removeRobotStartPositionMark(currentRobot.id);

						this._map.element(pos).startForRobot = currentRobot.id;
						this._map.element(pos).startRobotData.direction = currentRobot.direction;

						currentRobot.startPosition = pos;
						currentRobot.setPosition(pos, currentRobot.direction);
						currentRobot.sprite.setRealPosition(currentRobot.position);

						this.drawRobotStartPositionMark(currentRobot.id, currentRobot.position);

						handles = true;
						break;
					}
					else
					{
						pm.sendCustomEvent(pm.ANIMATE_RESTART_BUTTON);
					}
				}
			}
		}
		return handles;
	},

	_drawMapElements: function()
	{
		for (var x = 0; x < this._map.width; ++x)
		{
			var p = cc.p(x, 0);
			var terrainSprite = this._map.element(p).generateTerrainSprite(x - this._map.getStartElementPos(), this._previewDraw, this._orientation);

			terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
			this.addTerrainObject(terrainSprite, 0);

			if(this._map.element(p).startForRobot !== pm.MapElement.START_FOR_NO_ROBOT)
				this.drawRobotStartPositionMark(this._map.element(p).startForRobot, p);
		}

		var delta = GrasshopperMapLayer.EXTRA_MAP_ELEMENTS_COUNT;

		for (var x = -delta; x < 0; ++x)
		{
			var p = cc.p(x, 0);

			var terrainSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(0));

			terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
			terrainSprite.getSprite().setOpacity(128);

			this.addTerrainObject(terrainSprite, 0);
		}

		for (var x = this._map.width; x < this._map.width + delta; ++x)
		{
			var p = cc.p(x, 0);

			var terrainSprite = new pm.TerrainSprite(pm.spriteUtils.getMapGrassTileFrame(0));

			terrainSprite.getSprite().setPosition(this.realPosition(p, cc.p(0, 0)));
			terrainSprite.getSprite().setOpacity(128);

			this.addTerrainObject(terrainSprite, 0);
		}
	},

	_drawIsoLayers: function(previewDraw)
	{
		this.terrainLayer = new cc.Layer();
		this.objectLayer = previewDraw ? new cc.Layer() : new pm.TopologicalSortLayer();
		this.depthLayer = new cc.Layer();

		this.container = new cc.Layer();

		this.setCascadeOpacityEnabled(true);
		this.container.setCascadeOpacityEnabled(true);
		this.terrainLayer.setCascadeOpacityEnabled(true);
		this.objectLayer.setCascadeOpacityEnabled(true);
		this.depthLayer.setCascadeOpacityEnabled(true);

		this.container.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.terrainLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.objectLayer.setContentSize(this.getRealWidth(), this.getRealHeight());
		this.depthLayer.setContentSize(this.getRealWidth(), this.getRealHeight());

		this.terrainLayer.setRotation(-45.0);
		this.objectLayer.setRotation(-45.0);
		this.container.setScaleY(0.5);

		this.container.addChild(this.terrainLayer, 0);
		this.container.addChild(this.objectLayer, 1);

		this.addChild(this.depthLayer);
		this.addChild(this.container);

		var rect = this.terrainLayer.getBoundingBox();
		this.setContentSize(rect.width, rect.height / 2);

		var elementRect = cc.rect(0, 0, this.getMapElementSize().width, this.getMapElementSize().height);
		elementRect = cc.rectApplyAffineTransform(elementRect, this.terrainLayer.getNodeToParentTransform());

		this._containerShift = cc.p((this.width - this.container.width) / 2,
			(this.height - this.container.height) / 2 - elementRect.height / 4);

		this.container.setPosition(this._containerShift);
		this.depthLayer.setPosition(this._containerShift.x, this._containerShift.y + elementRect.height / 4 + 1);
	},

	_drawDepthLines: function()
	{
		var depthSprite = pm.moduleUtils.getDepthLineSprites(this._map.parentLevel.getType());

		if(depthSprite)
		{
			if (pm.settings.isEditorMode)
			{
				var depthHorizontal = new cc.Sprite(depthSprite.horizontal);

				var pos = cc.p(-this.getMapElementSize().height * this._map.height / 2 + 1.5,
					-this.getMapElementSize().width - this.getMapElementSize().width * this._map.width / 2 - 1.5);
				pos = cc.pointApplyAffineTransform(pos, pm.MapLayer2D.ISO_TRANSFORM);

				pos.x += this.getMapElementSize().width * this._map.width / 2;
				pos.y += this.getMapElementSize().height * this._map.height / 2 + 1;

				depthHorizontal.setAnchorPoint(cc.p(0, 1));
				depthHorizontal.setPosition(pos);

				this.depthLayer.addChild(depthHorizontal);
			}

			var delta = pm.settings.isEditorMode ? 0 : GrasshopperMapLayer.EXTRA_MAP_ELEMENTS_COUNT;

			for (var j = -delta; j < this._map.width + delta; ++j)
			{
				var depthVertical = new cc.Sprite(depthSprite.vertical);

				var pos = cc.p(this.getMapElementSize().height * this._map.height - this.getMapElementSize().height * this._map.height/2,
					this.getMapElementSize().width * j - this.getMapElementSize().width * this._map.width/2);
				pos = cc.pointApplyAffineTransform(pos, pm.MapLayer2D.ISO_TRANSFORM);

				pos.x += this.getMapElementSize().width * this._map.width/2 + 1;
				pos.y += this.getMapElementSize().height * this._map.height/2;

				depthVertical.setAnchorPoint(cc.p(1, 1));
				depthVertical.setPosition(pos);

				if (j < 0 || j >= this._map.width)
					depthVertical.setOpacity(128);

				this.depthLayer.addChild(depthVertical);
			}
		}
	}
});

GrasshopperMapLayer.EXTRA_MAP_ELEMENTS_COUNT = 100;
