/**
 * Created by Antony Orlovsky on 19.07.19.
 */

/**
 * @class 2D map where robot can paint some kinds({@link GrasshopperMapElementType}) of elements.
 * @implements SquarableMapWithWalls
 */
pm.data.GrasshopperMap = SquarableMap.extend(/** @lends pm.data.GrasshopperMap# */{
	typeName: "GrasshopperMap",
	_startElementPos: 0,

	ctor: function()
	{
		this._super();
	},

	getType: function() { return pm.GrasshopperLevelModule.Type; },

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new GrasshopperMapLayer(this);
			else
				this.mapLayer = new GrasshopperMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	setSize: function(width, height)
	{
		this.mapElements.length = 0;

		this.mapElements.push([]);

		for(var j = 0; j < width; ++j)
			this.mapElements[0].push(new pm.data.GrasshopperMapElement());

		this.width = width;
		this.height = height;
	},

	/**
     * Paints element by element coordinate.
     * @param {Number} position in elements.
     */
	paintElement: function(pos)
	{
		var element = this.element(pos);
		element.paint();
	},

	/**
     * Returns element to broken state by element coordinate.
     * @param {Number} x X-coordinate in elements.
     * @param {Number} y Y-coordinate in elements.
     */
	unpaintElement: function(pos)
	{
		var element = this.element(pos);
		element.unpaint();

	},

	setStartElementPos: function(pos)
	{
		this._startElementPos = pos;
	},

	getStartElementPos: function()
	{
		return this._startElementPos;
	}
});
