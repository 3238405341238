/**
 * Created by Antony Orlovsky on 28.02.18.
 */

/**
 * @class Start page of PiktoMir.
 * @extends ccui.Layout
 */
var StartMenuLayer = ccui.Layout.extend(/** @lends StartMenuLayer# */{

	_loginButton: null,
	_marketButton: null,

	_mainButtonLayer: null,
	_upperButtonLayer: null,
	_lowerButtonLayer: null,

	ctor: function ()
	{
		this._super();

		this.setLayoutType(ccui.Layout.RELATIVE);
		this.setContentSize(pm.settings.getScreenSize());

		this._drawMenuButtons();

		this._drawLayout();

		pm.registerCustomEventListener(pm.UPDATE_SCENE, function()
		{
			cc.director.runScene(new StartMenuScene());
		}, this);
	},

	_drawMenuButtons: function()
	{
		var mistName = "System/Mist.png";

		var worldButton = new pmui.Button(pm.spriteUtils.getIconName("world", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("world", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("world", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		worldButton.setRotation(45);
		worldButton.addClickEventListener(this._onWorld.bind(this));

		var gameButton = new pmui.Button(pm.spriteUtils.getIconName("game", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("game", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("game", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		gameButton.setRotation(45);
		gameButton.addClickEventListener(this._onGame.bind(this));

		var optionsButton = new pmui.Button(pm.spriteUtils.getIconName("options", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("options", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("options", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		optionsButton.setRotation(45);
		optionsButton.addClickEventListener(this._onOptions.bind(this));

		var optionsButtonMist = new ccui.ImageView(mistName);
		optionsButtonMist.setRotation(45);
		optionsButtonMist.setAnchorPoint(cc.p(1, 1));

		var learnButton = new pmui.Button(pm.spriteUtils.getIconName("learn", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("learn", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("learn", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		learnButton.setRotation(45);
		learnButton.addClickEventListener(this._onLearn.bind(this));

		var learnButtonMist = new ccui.ImageView(mistName);
		learnButtonMist.setRotation(45);
		learnButtonMist.setAnchorPoint(cc.p(0, 1));

		this._mainButtonLayer = new ccui.Layout();

		var boundingBox = gameButton.getBoundingBox();
		if (!cc.sys.isNative)
		{
			boundingBox.width *= Math.sqrt(2);
			boundingBox.height *= Math.sqrt(2);
		}

		this._mainButtonLayer.setContentSize(2 * boundingBox.width + StartMenuLayer.SEPARATOR,
			2 * boundingBox.height + StartMenuLayer.SEPARATOR);

		learnButtonMist.setPosition(StartMenuLayer.MIST_SHIFT, this._mainButtonLayer.height / 2 + StartMenuLayer.MIST_SHIFT);
		optionsButtonMist.setPosition(this._mainButtonLayer.width / 2 + StartMenuLayer.MIST_SHIFT, StartMenuLayer.MIST_SHIFT);
		worldButton.setPosition(this._mainButtonLayer.width - boundingBox.width / 2, this._mainButtonLayer.height / 2);
		gameButton.setPosition(this._mainButtonLayer.width / 2, this._mainButtonLayer.height - boundingBox.height / 2);
		learnButton.setPosition(boundingBox.width / 2, this._mainButtonLayer.height / 2);
		optionsButton.setPosition(this._mainButtonLayer.width / 2, boundingBox.height / 2);

		this._mainButtonLayer.addChild(optionsButtonMist, -1);
		this._mainButtonLayer.addChild(learnButtonMist, -1);
		this._mainButtonLayer.addChild(worldButton);
		this._mainButtonLayer.addChild(gameButton);
		this._mainButtonLayer.addChild(optionsButton);
		this._mainButtonLayer.addChild(learnButton);

		var scale = pm.settings.getScreenSize().height / this._mainButtonLayer.height;

		if (scale > 1)
			scale = 1;

		this._mainButtonLayer.setScale(scale);

		var screenBounds = pm.settings.getScreenBounds();
		var rightMargin = screenBounds.safeAreas.right ? screenBounds.right : 0;
		var rightButtonMargin = (StartMenuLayer.BORDER + rightMargin) * scale;

		var buttonLayerAlign = new ccui.RelativeLayoutParameter();
		buttonLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_BOTTOM);
		buttonLayerAlign.setMargin((pm.settings.getScreenSize().width - this._mainButtonLayer.width
			- 1.5 * StartMenuLayer.RIGHT_BAR_WIDTH - StartMenuLayer.SEPARATOR - 2 * rightButtonMargin) / 2, 0, 0,
			(pm.settings.getScreenSize().height - this._mainButtonLayer.height * scale) / 2);
		this._mainButtonLayer.setLayoutParameter(buttonLayerAlign);

		this.addChild(this._mainButtonLayer, 2);
	},

	_onLogin: function()
	{
		var loginLayer = new LoginLayer(this, this._loginSuccess);

		cc.director.getRunningScene().addChild(loginLayer, 100, StartMenuLayer.LOGINLAYER_TAG);
	},

	_loginSuccess: function()
	{
		pm.apiServerUtils.getUserAccountData(
			pm.utils.getUserAccountDataCallback.bind(this, this._drawLayout.bind(this))
		);
	},

	_drawLayout: function()
	{
		if(!this._upperButtonLayer)
		{
			this._upperButtonLayer = new ccui.Layout();
			this._upperButtonLayer.setAnchorPoint(cc.p(1, 1));
		}
		else
		{
			this._upperButtonLayer.removeAllChildren(true);
		}

		if(!this._lowerButtonLayer)
		{
			this._lowerButtonLayer = new ccui.Layout();
			this._lowerButtonLayer.setAnchorPoint(cc.p(1, 0));
		}
		else
		{
			this._lowerButtonLayer.removeAllChildren(true);
		}

		this._drawLowerLayer();

		if(pm.settings.userLoggedIn)
		{
			if(pm.settings.getUserData().accountType === pm.USER_TYPE.USER)
				this._drawUserLayout();
			else
				this._drawTeacherLayout();
		}
		else
		{
			this._drawNotLoggedInLayout();
		}

		var scale = pm.settings.getScreenSize().height / (this._upperButtonLayer.height + this._lowerButtonLayer.height + 3 * StartMenuLayer.BORDER);

		if(scale > 1)
			scale = 1;

		var screenBounds = pm.settings.getScreenBounds();
		var rightMargin = screenBounds.safeAreas.right ? screenBounds.right : 0;
		var rightButtonMargin = (StartMenuLayer.BORDER + rightMargin) * scale;

		var upperButtonLayerAlign = new ccui.RelativeLayoutParameter();
		upperButtonLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		upperButtonLayerAlign.setMargin(0, StartMenuLayer.BORDER * scale, rightButtonMargin, 0);
		this._upperButtonLayer.setLayoutParameter(upperButtonLayerAlign);

		var lowerButtonLayerAlign = new ccui.RelativeLayoutParameter();
		lowerButtonLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
		lowerButtonLayerAlign.setMargin(0, 0, rightButtonMargin, StartMenuLayer.BORDER * scale);
		this._lowerButtonLayer.setLayoutParameter(lowerButtonLayerAlign);

		this._upperButtonLayer.setScale(scale);
		this._lowerButtonLayer.setScale(scale);

		if (!this._upperButtonLayer.getParent())
		{
			this.addChild(this._upperButtonLayer, 2);
			this.addChild(this._lowerButtonLayer, 2);
		}

		this.forceDoLayout();
	},

	_drawLowerLayer: function()
	{
		var versionLabel = new ccui.Text(LocalizedString("AppVersion").format(pm.appUtils.getAppVersion()), pm.settings.fontName, 14);
		versionLabel.setAnchorPoint(cc.p(1, 0));

		var resetButton = new pmui.Button(pm.spriteUtils.getIconName("clearProgress", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("clearProgress", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("clearProgress", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		resetButton.setRotation(45);
		resetButton.addClickEventListener(this._onResetConfirm.bind(this));

		var boundingBox = resetButton.getBoundingBox();
		if (!cc.sys.isNative)
		{
			boundingBox.width *= Math.sqrt(2);
			boundingBox.height *= Math.sqrt(2);
		}

		this._lowerButtonLayer.setContentSize(boundingBox.width, boundingBox.height + versionLabel.height + StartMenuLayer.SEPARATOR);

		versionLabel.setPosition(this._lowerButtonLayer.width, 0);
		resetButton.setPosition(this._lowerButtonLayer.width / 2, versionLabel.height + StartMenuLayer.SEPARATOR + boundingBox.height / 2);

		this._lowerButtonLayer.addChild(versionLabel);
		this._lowerButtonLayer.addChild(resetButton);

		if (pm.appConfig.showMarket)
		{
			this._marketButton = new pmui.Button(pm.spriteUtils.getIconName("shop", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("shop", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("shop", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._marketButton.setRotation(45);
			this._marketButton.addClickEventListener(this._onMarket.bind(this));

			boundingBox = this._marketButton.getBoundingBox();
			if (!cc.sys.isNative)
			{
				boundingBox.width *= Math.sqrt(2);
				boundingBox.height *= Math.sqrt(2);
			}

			this._lowerButtonLayer.setContentSize(this._lowerButtonLayer.width,
				this._lowerButtonLayer.height + boundingBox.height + StartMenuLayer.SEPARATOR);

			this._marketButton.setPosition(this._lowerButtonLayer.width / 2, this._lowerButtonLayer.height - boundingBox.height / 2);
			this._lowerButtonLayer.addChild(this._marketButton);
		}
	},

	_drawUserLayout: function()
	{
		this._loginButton = new pmui.Button(pm.spriteUtils.getIconName("quit", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("quit", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("quit", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._loginButton.setRotation(45);
		this._loginButton.addClickEventListener(this._exitFromAccount.bind(this));

		var boundingBox = this._loginButton.getBoundingBox();
		if (!cc.sys.isNative)
		{
			boundingBox.width *= Math.sqrt(2);
			boundingBox.height *= Math.sqrt(2);
		}

		this._upperButtonLayer.setContentSize(boundingBox.width, boundingBox.height);

		this._loginButton.setPosition(boundingBox.width / 2, boundingBox.height / 2);
		this._upperButtonLayer.addChild(this._loginButton);
	},

	_drawTeacherLayout: function()
	{
		this._loginButton = new pmui.Button(pm.spriteUtils.getIconName("quit", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("quit", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("quit", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._loginButton.setRotation(45);
		this._loginButton.addClickEventListener(this._exitFromAccount.bind(this));

		var toEditorButton = new pmui.Button(pm.spriteUtils.getIconName("editor", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("editor", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("editor", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		toEditorButton.setRotation(45);
		toEditorButton.addClickEventListener(this._toEditor.bind(this));

        var boundingBox = this._loginButton.getBoundingBox();
		if (!cc.sys.isNative)
		{
			boundingBox.width *= Math.sqrt(2);
			boundingBox.height *= Math.sqrt(2);
		}

        if (cc.sys.isNative)
		{
			this._upperButtonLayer.setContentSize(1.5 * boundingBox.width + StartMenuLayer.SEPARATOR,
				2 * boundingBox.height + StartMenuLayer.SEPARATOR);

			var classControl = new pmui.Button(pm.spriteUtils.getIconName("classControl", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("classControl", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("classControl", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			classControl.setRotation(45);
			classControl.addClickEventListener(this._classControl.bind(this));

			classControl.setPosition(boundingBox.width / 2, this._upperButtonLayer.height / 2);
			this._upperButtonLayer.addChild(classControl);
		}
		else
		{
			this._upperButtonLayer.setContentSize(boundingBox.width, 2 * boundingBox.height + StartMenuLayer.SEPARATOR);
		}

		this._loginButton.setPosition(this._upperButtonLayer.width - boundingBox.width / 2,
			this._upperButtonLayer.height - boundingBox.height / 2);
		this._upperButtonLayer.addChild(this._loginButton);

		toEditorButton.setPosition(this._upperButtonLayer.width - boundingBox.width / 2, boundingBox.height / 2);
		this._upperButtonLayer.addChild(toEditorButton);
	},

	_drawNotLoggedInLayout: function()
	{
		this._loginButton = new pmui.Button(pm.spriteUtils.getIconName("enter", pm.NORMAL_STATE),
			pm.spriteUtils.getIconName("enter", pm.SELECTED_STATE),
			pm.spriteUtils.getIconName("enter", pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		this._loginButton.setRotation(45);
		this._loginButton.addClickEventListener(this._onLogin.bind(this));

		var boundingBox = this._loginButton.getBoundingBox();
		if (!cc.sys.isNative)
		{
			boundingBox.width *= Math.sqrt(2);
			boundingBox.height *= Math.sqrt(2);
		}

		this._upperButtonLayer.setContentSize(boundingBox.width, boundingBox.height);

		this._loginButton.setPosition(boundingBox.width / 2, boundingBox.height / 2);
		this._upperButtonLayer.addChild(this._loginButton);
	},

	_exitFromAccount: function()
	{
		pm.settings.userLoggedIn = false;
		pm.settings.clearUserData();

		pm.userCache.remove();
		this._drawLayout();
	},

	_onResetConfirm: function()
	{
		if (!this.getChildByTag(StartMenuLayer.CONFIRM_LAYER_TAG))
		{
			var date = pm.settings.getModifyDate();

			if (typeof(date) === "string")
				date = new Date(date);

			if (!date)
				date = new Date();

			var dateString = date.getDate() + "."+date.getMonth()+"."+date.getFullYear()+", "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();

			var input = new pmui.ConfirmWindow(LocalizedString("ClearProgress"), "Мир: " + world.name + ", последнее изменение: " + dateString);

			input.setCallback(this, this._onReset, function () {});

			var align = new ccui.RelativeLayoutParameter();
			align.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
			input.setLayoutParameter(align);

			this.addChild(input, 100, StartMenuLayer.CONFIRM_LAYER_TAG);
		}
	},

	_onReset: function()
	{
		pm.settings.clearModifyDate();
		pm.userCache.cleanup();
		cc.sys.garbageCollect();
	},

	_toEditor: function()
	{
		pm.settings.isEditorMode = true;

		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new MenuEditorScene());
		cc.director.runScene(trans);
	},

	_classControl: function (object)
	{
		var trans = new cc.TransitionFade( 1.5 * pm.SYSTEM_ANIMATION_DELAY, new ClassControlScene());
		cc.director.runScene(trans);
	},

	_onWorld: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectWorldScene());
		cc.director.runScene(trans);
	},

	_onGame: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY, new SelectGameScene());
		cc.director.runScene(trans);
	},

	_onOptions: function()
	{
		if(cc.director.getRunningScene().getChildByTag(SettingsLayer.TAG))
			return;

		var settingsLayer = new SettingsLayer(this, this._closeOptions, true, false);
		cc.director.getRunningScene().addChild(settingsLayer, 100, SettingsLayer.TAG);
	},

	_onLearn: function()
	{
		var trans = new cc.TransitionFade(1.5 * pm.SYSTEM_ANIMATION_DELAY,
			new ChooseLessonScene(pm.settings.getSelectedWorldID()));
		cc.director.runScene(trans);
	},

	_onMarket: function()
	{
        if (cc.sys.isNative && cc.sys.isMobile && pm.marketHelper.isMarketAvailable())
        {
            var marketLayer = new MarketLayer();
            marketLayer.show();
        }
        else
		{
			var infoMarketLayer = new InfoMarketLayer(this, this._closeMarketInfo);
            cc.director.getRunningScene().addChild(infoMarketLayer, 100, InfoMarketLayer.TAG);
		}
	},

    _closeMarketInfo: function()
    {
        cc.director.getRunningScene().removeChildByTag(InfoMarketLayer.TAG);
    },

	_closeOptions: function()
	{
		cc.director.getRunningScene().removeChildByTag(SettingsLayer.TAG);
	}
});

StartMenuLayer.SEPARATOR = 15;
StartMenuLayer.MENU_INDENTATION = 90;
StartMenuLayer.LOGINLAYER_TAG = 2;
StartMenuLayer.CONFIRM_LAYER_TAG = 4872;
StartMenuLayer.MIST_SHIFT = 30;
StartMenuLayer.BORDER = 15;
StartMenuLayer.RIGHT_BAR_WIDTH = 141.4;

/**
 * @class Scene for {@link StartMenuLayer}
 * @extends cc.Scene
 * @constructor
 */
var StartMenuScene = cc.Scene.extend(/** @lends StartMenuScene# */{
	ctor: function ()
	{
		this._super();

		var layer = new StartMenuLayer();

		this.addChild(layer);

		var backLayer = pm.backgroundUtils.generateBackground();
		this.addChild(backLayer, -1);

		if (!cc.sys.isNative)
		    window.location.hash = "";
	}
});
