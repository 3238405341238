/**
 * Created by Nikita Besshaposhnikov on 30.12.14.
 */

pm.getFileList = pm.getFileList || function()
{};

pm.convertIPAddress = pm.convertIPAddress || function()
{};

pm.setHttpRequestTimeout = pm.setHttpRequestTimeout || function()
{};

pm.setParallaxArrayItemOffset = pm.setParallaxArrayItemOffset || function(parallax, index, offset)
{
	parallax.getParallaxArray()[index].setOffset(offset);
};

pm.setParallaxArrayItemRatio = pm.setParallaxArrayItemRatio || function(parallax, index, ratio)
{
	parallax.getParallaxArray()[index].setRatio(ratio);
};

pm.getParallaxArrayItemChild = pm.getParallaxArrayItemChild || function(parallax, index)
{
	return parallax.getParallaxArray()[index].getChild();
};

pm.scrollViewOnTouchBegan = pm.scrollViewOnTouchBegan || function(scrollView, touch, event)
{
	return scrollView.onTouchBegan(touch, event);
};

pm.scrollViewOnTouchMoved = pm.scrollViewOnTouchMoved || function(scrollView, touch, event)
{
	return scrollView.onTouchMoved(touch, event);
};

pm.scrollViewOnTouchEnded = pm.scrollViewOnTouchEnded || function(scrollView, touch, event)
{
	return scrollView.onTouchEnded(touch, event);
};

pm.scrollViewOnTouchCancelled = pm.scrollViewOnTouchCancelled || function(scrollView, touch, event)
{
	return scrollView.onTouchCancelled(touch, event);
};

pm.playAudio = pm.playAudio || function(file, loop, volume) {};
pm.pauseAudio = pm.pauseAudio || function(audioID) {};
pm.resumeAudio = pm.resumeAudio || function(audioID) {};
pm.stopAudio = pm.stopAudio || function(audioID) {};
pm.setAudioVolume = pm.setAudioVolume || function(audioID, volume) {};
pm.getAudioVolume = pm.getAudioVolume || function(audioID) {};
pm.getAudioState = pm.getAudioState || function(audioID) {};

pm.socialLogin = pm.socialLogin || function(authUrl, callback) {};

pm.createNativeTouch = pm.createNativeTouch || function(id, x, y) {};
pm.setNativeTouchLocation = pm.setNativeTouchLocation || function(x, y) {};
pm.destroyNativeTouch = pm.destroyNativeTouch || function() {};
pm.dispatchEventFromNativeTouch = pm.dispatchEventFromNativeTouch || function(eventCode) {};

pm.btleManager = pm.BTLEManager ? pm.BTLEManager.getInstance() : {
	isBTLEAvailable: function()
	{
		return false;
	},

	setReceiveDataCallback: function(callback) {},
	setConnectCallback: function(callback) {},
	setDisconnectCallback: function(callback) {},
	scan: function() {},
	cleanup: function() {},
	sendData: function(data) {}
};

pm.programRecognizer = pm.ProgramRecognizer ? pm.ProgramRecognizer.getInstance() : {
	setSuccessCallback: function(callback) {},
	setErrorCallback: function(callback) {},
	recognize: function(data, taskID) {},
	setStatsURL: function(url) {}
};

pm.matsRecognizer = pm.MatsRecognizer ? pm.MatsRecognizer.getInstance() : {
	setSuccessCallback: function(callback) {},
	setErrorCallback: function(callback) {},
	recognize: function() {},
	setServerURL: function(url) {}
};


pm.broadcastServer = pm.BroadcastServer ? pm.BroadcastServer.getInstance() : {};
pm.broadcastClient = pm.BroadcastClient ? pm.BroadcastClient.getInstance() : {};

pm.broadcastServer.DATA_PORT = 18842;

pm.BROADCAST_PACKET_PRIORITY ={
	LOW: 1,
	HIGH: 0
};

pm.BROADCAST_PACKET_TYPE = {
	DISCOVER_REQUEST: 0,
	DISCOVER_INFO: 1,
	DATA_REQUEST: 2
};

pm.GameServer = pm.GameServer || cc.Class.extend({});

pm.GameServer.CLIENT_CONNECTED_EVENT = 0;
pm.GameServer.CLIENT_DISCONNECTED_EVENT = 1;

pm.MagnetClient = pm.MagnetClient || cc.Class.extend({});

pm.GAME_SERVER_PORT = 18843;
pm.TEACHER_SERVER_PORT = 18844;

pm.GameClient = pm.GameClient || cc.Class.extend({});

pm.GameClient.CONNECTED_EVENT = 0;
pm.GameClient.DISCONNECTED_EVENT = 1;

//pm.marketHelper = pm.MarketHelper ? pm.MarketHelper.getInstance() : {
pm.marketHelper = {
	setProductInfoCallback: function(callback) {},
	requestProductInfo: function(products) {},
	setPaymentEventCallback: function(callback) {},
	purchaseProduct: function(product) {},
	restorePurchases: function() {},
	cleanup: function() {},
	cleanupTransaction: function() {},
	isMarketAvailable: function() { return false; },
	getDeviceUUID: function() { return "" }
};

pm.marketHelper.EVENT = {
	PURCHASE: 0,
	RESTORE: 1
};

pm.TextFieldWrapper = pm.TextFieldWrapper || ccui.TextField;
