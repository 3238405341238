/**
 * Created by Antony Orlovsky on 09.08.19.
 */

/**
 * @class 2D robot for {@link pm.data.TurtleLevel}.</br>
 * Has a paint element native function.
 * @implements PlayerRobot4
 */
pm.data.TurtleRobot = pm.PlayerRobot2D.extend(/** @lends pm.data.TurtleRobot# */{
	typeName: "TurtleRobot",
	_tailFlag: false,

	startDirection: 0,
	startPosition: cc.p(),

	ctor: function()
	{
		this._addNonEnumerableProps("_tailFlag");

		this._super();

		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.MoveForward] = new pm.NativeFunction(this, this._moveForward, 1);
		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.MoveBackward] = new pm.NativeFunction(this, this._moveBackward, 1);

		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.TurnLeft] = new pm.NativeFunction(this, this._turnLeft, 1);
		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.TurnRight] = new pm.NativeFunction(this, this._turnRight, 1);

		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.TailDown] = new pm.NativeFunction(this, this._tailDown);
		this.nativeFunctionMap[pm.data.TurtleRobot.NativeMethod.TailUp] = new pm.NativeFunction(this, this._tailUp);

		this.nativeFunctions.push(pm.data.TurtleRobot.NativeMethod.MoveForward, pm.data.TurtleRobot.NativeMethod.MoveBackward,
			pm.data.TurtleRobot.NativeMethod.TurnLeft, pm.data.TurtleRobot.NativeMethod.TurnRight,
			pm.data.TurtleRobot.NativeMethod.TailDown, pm.data.TurtleRobot.NativeMethod.TailUp);
	},

	getDirectionCount: function() { return 360; },

	generateRobotSprite: function()
	{
		if(!CORE_BUILD)
		{
			this.sprite = new TurtleRobotSprite();

			return this.sprite;
		}
	},

	_tailDown: function()
	{
		this.setTailFlag(true);
		// this.setStateFlag(pm.RobotState.PlayingAnimation);

		// this.playAnimation(KumirRobotAnimation.Paint, this._endPaint, 0);
	},

	_endTailDown: function()
	{
		// this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_tailUp: function()
	{
		this.setTailFlag(false);
		// this.setStateFlag(pm.RobotState.PlayingAnimation);
	},

	_endTailUp: function()
	{
		// this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	setTailFlag: function(flag)
	{
		this._tailFlag = flag;
	},

	getTailFlag: function()
	{
		return this._tailFlag;
	},

	_moveForward: function(count)
	{
		var target = cc.p(this.position.x + count * Math.sin(cc.degreesToRadians(360 - this.direction)),
			this.position.y + count * Math.cos(cc.degreesToRadians(360 - this.direction)));

		var mapElementSize = null;
		if(!CORE_BUILD)
			mapElementSize = this.parentLevel.activeMap.mapLayer.getMapElementSize();
		else
			mapElementSize = cc.size(64, 64);

		if (Math.round(target.x) < 0 || Math.round(target.y) < mapElementSize.height ||
			Math.round(target.x) > (this.getMap().width - 1) * mapElementSize.width ||
			Math.round(target.y) > this.getMap().height * mapElementSize.height)
			target = null;

		this._moveTo(target, false, this.direction);
	},

	_moveBackward: function(count)
	{
		var target = cc.p(this.position.x - count * Math.sin(cc.degreesToRadians(360 - this.direction)),
			this.position.y - count * Math.cos(cc.degreesToRadians(360 - this.direction)));

		var mapElementSize = null;
		if(!CORE_BUILD)
			mapElementSize = this.parentLevel.activeMap.mapLayer.getMapElementSize();
		else
			mapElementSize = cc.size(64, 64);

		if (Math.round(target.x) < 0 || Math.round(target.y) < mapElementSize.height ||
			Math.round(target.x) > (this.getMap().width - 1) * mapElementSize.width ||
			Math.round(target.y) > this.getMap().height * mapElementSize.height)
			target = null;

		this._moveTo(target, false, this.direction - 360);
	},

	_moveTo: function(target, reverse, initialDirection)
	{
		if(this.isBroken())
		{
			if(reverse)
				this.clearStateFlag(pm.RobotState.Broken);

			this.updateSprite();
			return;
		}

		// var map = this.getMap();

		var move = true;

		if(!target)
		{
			this.setStateFlag(pm.RobotState.Broken);
			return;
		}

		// for(var i in map.element(target)._robotsHere)
		// {
		// 	var r = map.element(target)._robotsHere[i];
		//
		// 	if(!(r instanceof pm.AbstractRobot))
		// 		continue;
		//
		// 	if(!r.canMoveOn())
		// 	{
		// 		if(!r.interactable())
		// 			this.setStateFlag(pm.RobotState.Broken);
		//
		// 		move = false;
		// 		break;
		// 	}
		// }

		if(move)
		{
			if (initialDirection === undefined)
				initialDirection = this.direction;

			var direction = reverse ? (initialDirection + this.getDirectionCount()/2) % this.getDirectionCount() : initialDirection;

			if(!this._canMoveTo(target, direction) && !reverse)
			{
				this.setStateFlag(pm.RobotState.Broken);

				return;
			}

			this._oldPosition = this.position;
			this.position = target;

			if(!this.isBroken())
				this.playAnimation(RobotAnimation2D.Move, this._endMove, this.position);
		}
		else if(!this.isBroken())
		{
			// for(var i in map.element(target)._robotsHere)
			// {
			// 	var r = map.element(target)._robotsHere[i];
			//
			// 	if(!(r instanceof pm.AbstractRobot))
			// 		continue;
			//
			// 	r.interact(this, this._endInteract);
			// }
		}
	},

	_endMove: function()
	{
		if (this._tailFlag)
		{
			this.parentLevel.activeMap.lines.push({
				oldPos: this._oldPosition,
				newPos: this.position
			});
		}

		if (!CORE_BUILD && !this.needToPlayAnimation() && this._tailFlag)
		{
			var oldPos = this.sprite.getRealPosition(this._oldPosition);
			var targetPos = this.sprite.getRealPosition(this.position);

			var length = Math.sqrt((targetPos.x - oldPos.x) * (targetPos.x - oldPos.x) + (targetPos.y - oldPos.y) * (targetPos.y - oldPos.y));
			var rotationAngle = cc.radiansToDegrees(Math.acos((targetPos.x - oldPos.x) / length));

			if (targetPos.y - oldPos.y > 0)
				rotationAngle *= -1;

			var line = new cc.Scale9Sprite("System/Line.png");

			line.setAnchorPoint(cc.p(0, 0.5));
			line.setRotation(rotationAngle);
			line.setPosition(cc.p(oldPos.x - (targetPos.x - oldPos.x) / length,oldPos.y - (targetPos.y - oldPos.y) / length));
			line.setScale((length + 2) / line.width, 1);
			this.parentLevel.activeMap.mapLayer.drawLayer.addChild(line);

			this.updateSprite();
		}

		// var map = this.getMap();
		//
		// for(var i in map.element(this.position)._robotsHere)
		// {
		// 	var r = map.element(this.position)._robotsHere[i];
		//
		// 	if(!(r instanceof pm.AbstractRobot))
		// 		continue;
		//
		// 	r.interact(this, this._endInteract);
		// }
		//
		// this._removeFromOldPosition();

		this._oldPosition = this.position;

		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	_turnLeft: function(angle)
	{
		var newDirection = (this.direction + angle) % this.getDirectionCount();

		this.playAnimation(RobotAnimation2D.Turn, this._endTurn, [{angle: angle, clockwise: false}, newDirection]);

		this.direction = newDirection;
	},

	_turnRight: function(angle)
	{
		var newDirection = (this.direction + this.getDirectionCount() - angle) % this.getDirectionCount();

		this.playAnimation(RobotAnimation2D.Turn, this._endTurn, [{angle: angle, clockwise: true}, newDirection]);

		this.direction = newDirection;
	},

	_endTurn: function()
	{
		if(!CORE_BUILD)
			this.updateSprite();
		this.clearStateFlag(pm.RobotState.PlayingAnimation);
	},

	setPosition: function(point, direction)
	{
		this._oldPosition = point;
		this.position = point;
		this.direction = direction;
	},

	reset: function()
	{
		this.setTailFlag(false);
		PlayerRobot4.prototype.reset.call(this);
	},

	getState: function ()
	{
		// return {
		// 	position: this.position,
		// 	direction: this.direction,
		// 	animation: this.getLastAnimation()
		// };
	},

	setState: function (state)
	{
		// if (state.direction !== this.direction)
		// 	this.direction = state.direction;
		//
		// if (state.position !== this.position || this.isBroken())
		// 	this._moveTo(state.position, true);
		// else if (state.animation === TurtleRobotAnimation.Paint)
		// 	this._unpaint();
	},

	getType: function() { return pm.TurtleLevelModule.RobotType; }
});
pm.data.TurtleRobot.NativeMethod =
{
	MoveForward: "turtle_move_forward",
	MoveBackward: "turtle_move_backward",
	TurnLeft: "turtle_turn_left",
	TurnRight: "turtle_turn_right",
	TailDown: "turtle_tail_down",
	TailUp: "turtle_tail_up"
};
