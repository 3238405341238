/**
 * Created by Kirill Mashchenko on 12.07.19.
 */

/**
 * @class Functions for KumirLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.KumirLevelModule = pm.ModuleInfo.extend(/** @lends pm.KumirLevelModule# */{

	name: "Kumir",

	getType: function()
	{
		return pm.KumirLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.KumirMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = KumirMapElementType.LightBlueGrass;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.KumirLevel();

		level.name = "Новый Робот";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.KumirRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.KumirLevelModule.Type] = new pm.data.KumirTask(level);
		level.taskList.tasks[pm.KumirLevelModule.Type].data[0] = 0;

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.KumirLevelModule.RobotType] = "kumir_robot0.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/kumir_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new KumirRobotSettingsLayer(object);
	},

	getExtraMapSettings: function (target)
	{
		if (pm.appUtils.useExtraMethodsInKumirRobot())
		{
			var separator = cc.size(5, 5);
			var cellSize = cc.size(64, 64);

			var elementMenu = new ccui.Layout();
			elementMenu.setLayoutType(ccui.Layout.RELATIVE);
			elementMenu.setVisible(false);

			var elementLabel = new ccui.Text("", pm.settings.fontName, pm.settings.fontSize);

			var labelAlign = new ccui.RelativeLayoutParameter();
			labelAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			labelAlign.setRelativeName("label");
			elementLabel.setLayoutParameter(labelAlign);

			var elementRadiationLabel = new ccui.Text(LocalizedString("Radiation"), pm.settings.fontName, pm.settings.fontSize);

			var radiationLabelAlign = new ccui.RelativeLayoutParameter();
			radiationLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			radiationLabelAlign.setRelativeName("radiationLabel");
			radiationLabelAlign.setRelativeToWidgetName("label");
			radiationLabelAlign.setMargin(0, 10, 0, 0);
			elementRadiationLabel.setLayoutParameter(radiationLabelAlign);

			var elementRadiationInput = new pmui.TextInput("", 0, pm.settings.fontSize, 40);

			var radiationInputAlign = new ccui.RelativeLayoutParameter();
			radiationInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			radiationInputAlign.setRelativeToWidgetName("radiationLabel");
			radiationInputAlign.setMargin(5, 0, 0, 0);
			elementRadiationInput.setLayoutParameter(radiationInputAlign);

			var elementTemperatureLabel = new ccui.Text(LocalizedString("Temperature"), pm.settings.fontName, pm.settings.fontSize);

			var temperatureLabelAlign = new ccui.RelativeLayoutParameter();
			temperatureLabelAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
			temperatureLabelAlign.setRelativeName("temperatureLabel");
			temperatureLabelAlign.setRelativeToWidgetName("radiationLabel");
			temperatureLabelAlign.setMargin(0, 15, 0, 0);
			elementTemperatureLabel.setLayoutParameter(temperatureLabelAlign);

			var elementTemperatureInput = new pmui.TextInput("", 0, pm.settings.fontSize, 40);

			var temperatureInputAlign = new ccui.RelativeLayoutParameter();
			temperatureInputAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
			temperatureInputAlign.setRelativeToWidgetName("temperatureLabel");
			temperatureInputAlign.setMargin(5, 0, 0, 0);
			elementTemperatureInput.setLayoutParameter(temperatureInputAlign);

			elementMenu.setContentSize(Math.max(elementLabel.width, elementRadiationLabel.width + elementRadiationInput.width + 5,
				elementTemperatureLabel.width + elementTemperatureInput.width + 5), elementLabel.height + Math.max(elementRadiationLabel.height,
				elementRadiationInput.height) + Math.max(elementTemperatureLabel.height, elementTemperatureInput.height) + 25);

			elementMenu.addChild(elementLabel, 0, pm.data.KumirMapElement.LABEL_TAG);
			elementMenu.addChild(elementRadiationLabel);
			elementMenu.addChild(elementRadiationInput, 0, pm.data.KumirMapElement.RADIATION_TAG);
			elementMenu.addChild(elementTemperatureLabel);
			elementMenu.addChild(elementTemperatureInput, 0, pm.data.KumirMapElement.TEMPERATURE_TAG);

			var elementParamsTable = new pmui.SelectTableView(cellSize, separator, 1, 2);

			elementParamsTable.addSelectEventListener(function (selectedMapElement)
			{
				target.mapElementsTable.deselectAll();

				if (selectedMapElement)
				{
					if (selectedMapElement.y === 0)
					{
						pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, pm.data.KumirMapElement.START_ELEMENT_POS);
					}
					if (selectedMapElement.y === 1)
					{
						pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, {
							event: pm.data.KumirMapElement.SELECT_ELEMENT_PARAMS,
							menu: elementMenu
						});
					}
				}
				else
				{
					elementMenu.setVisible(false);
					pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, undefined);
				}
			}.bind(this), this);

			elementParamsTable.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
			elementParamsTable.setFitObjectsSizes(true);

			var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(0), ccui.Widget.PLIST_TEXTURE);
			elementParamsTable.setCell(0, 0, element);

			var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(1), ccui.Widget.PLIST_TEXTURE);
			elementParamsTable.setCell(0, 1, element);

			elementParamsTable.setContentSize(2 * cellSize.width + 3 * separator.width, cellSize.height + 2 * separator.height);

			var tableAlign = new ccui.RelativeLayoutParameter();
			tableAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
			tableAlign.setRelativeName("table");
			elementParamsTable.setLayoutParameter(tableAlign);

			var menuAlign = new ccui.RelativeLayoutParameter();
			menuAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_TOPALIGN);
			menuAlign.setRelativeToWidgetName("table");
			menuAlign.setMargin(10, 0, 0, 0);
			elementMenu.setLayoutParameter(menuAlign);

			var layout = new ccui.Layout();
			layout.setLayoutType(ccui.Layout.RELATIVE);

			layout.setContentSize(elementParamsTable.width + elementMenu.width + 10, Math.max(elementParamsTable.height, elementMenu.height));

			layout.addChild(elementParamsTable);
			layout.addChild(elementMenu);

			return {
				layout: layout,
				callback: function ()
				{
					elementParamsTable.deselectAll();
					elementMenu.setVisible(false);
				}
			}
		}
		else
		{
			var separator = cc.size(5, 5);
			var cellSize = cc.size(64, 64);

			var startElementPos = new pmui.SelectTableView(cellSize, separator, 1, 1);
			startElementPos.addSelectEventListener(function(selectedMapElement)
			{
				target.mapElementsTable.deselectAll();

				if (selectedMapElement)
					pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, pm.data.KumirMapElement.START_ELEMENT_POS);
				else
					pm.sendCustomEvent(pme.MAP_ELEMENT_SELECT_EVENT_STR, undefined);
			}.bind(this), this);

			startElementPos.setSelectFrame(pm.spriteUtils.getInterfaceElementFrame("functionBG"), true);
			startElementPos.setFitObjectsSizes(true);

			var element = new ccui.ImageView(pm.spriteUtils.getMapGrassTileName(1, pm.NORMAL_STATE),
				ccui.Widget.PLIST_TEXTURE);

			startElementPos.setCell(0, 0, element);
			startElementPos.setContentSize(cellSize.width + 2 * separator.width, cellSize.height + 2 * separator.height);

			var layout = new ccui.Layout();
			layout.setLayoutType(ccui.Layout.RELATIVE);

			layout.setContentSize(startElementPos.width, startElementPos.height);

			layout.addChild(startElementPos);

			return {layout: layout};
		}
	},

	getMapElementsType: function()
	{
		return KumirMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Kumir");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/kumir_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/kumir_robot-methods.plist",
			"Conditions/kumir_robot-conditions.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.KumirRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Kumir": "Robot",
					"KumirLevelType": "Robot level",
					"KumirTask": "Robot task",
					"NeedToPaintPattern": "Need to paint: {0}",
					"Radiation": "Radiation",
					"Temperature": "Temperature",
					"KumirLevel": "Kumir",
					"kumir_paint" : "paint",
					"kumir_move_up" : "move up",
					"kumir_move_down" : "move down",
					"kumir_move_left" : "move left",
					"kumir_move_right" : "move right",
					"kumir_gib" : "is cell painted",
					"kumir_ginb" : "isnt cell painted",

					"kumir_wall_up" : "is wall up",
					"kumir_wall_down" : "is wall down",
					"kumir_wall_left" : "is wall left",
					"kumir_wall_right" : "is wall right",

					"kumir_clear_up" : "is up cell empty",
					"kumir_clear_down" : "is down cell empty",
					"kumir_clear_left" : "is left cell empty",
					"kumir_clear_right" : "is right cell empty",

					"kumir_temperature" : "temp",
					"kumir_radiation" : "rad"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Kumir": "Робот",
					"KumirLevelType": "Робот",
					"KumirTask": "Задание покраски",
					"NeedToPaintPattern": "Нужно покрасить: {0}",
					"Radiation": "Радиация",
					"Temperature": "Температура",
					"kumir_gib" : "клетка закрашена",
					"kumir_ginb" : "клетка чистая",
					"KumirLevel": "Робот",
					"kumir_paint" : "закрасить",
					"kumir_move_up" : "вверх",
					"kumir_move_down" : "вниз",
					"kumir_move_left" : "влево",
					"kumir_move_right" : "вправо",
					"kumir_wall_up" : "сверху стена",
					"kumir_wall_down" : "снизу стена",
					"kumir_wall_left" : "слева стена",
					"kumir_wall_right" : "справа стена",

					"kumir_clear_up" : "сверху свободно",
					"kumir_clear_down" : "снизу свободно",
					"kumir_clear_left" : "слева свободно",
					"kumir_clear_right" : "справа свободно",

					"kumir_temperature" : "темп",
					"kumir_radiation" : "рад"
				};
		}
	},

	generateTaskLayer: function (level, task)
	{
		return new KumirTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "KumirTask";
	},

	needToRotateRobot: function()
	{
		return false;
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.KumirTask(level);

		for(var i = 0; i < level.maps.length;++i)
		{
			var paintValue = 0;
			var map = level.maps[i];

			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).originalType === KumirMapElementType.UnpaintedGrass)
						++paintValue;
				}
			}

			task.fillWithInitialData(i, paintValue);
		}

		level.taskList.addTask(pm.KumirLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function() {},

	getChangeableConditions: function(robot, callback, target) {},

	compareConditions: function(a, b) {},

	getLessonMapLayer: function()
	{
		return null;
	}
});

pm.KumirLevelModule.RobotType = "kumir-robot";
pm.KumirLevelModule.Type = "kumir";
pm.KumirLevelModule.ObjectTypes = {};
