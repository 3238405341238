/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * @class Layer for displaying program of robot.
 * @extends ccui.VBox
 * @property {pm.AbstractRobot} robot
 * @property {Number} curMethodCount
 * @constructor
 * @param {GameType} gameType
 * @param {Boolean} useMethodStack Use method stack or not.
 * @param {pm.AbstractRobot} [robot] For which robot construct layer.
 */
var ProgramLayer = ccui.Layout.extend(/** @lends ProgramLayer# */{

	_robot: null,

	_gameType: 0,
	_selectPatternMenu: null,
	_patternContainer: null,

	_programContainer: null,
	_functionScroll: null,
	_methodStackLayer: null,
	_gameLabel: null,
	_worldLabel: null,
	_selectLayout: null,
	_workLayout: null,

	_controlledMode: false,
	_buttonClickCallback: null,
	_buttonClickCallbackTarget: null,

	_programControlLayer: null,

	_colorBarLayer: null,
	_colorProgramLayer: null,
	_middleBorder: null,
	_colorBorder: null,

	ctor: function(gameType, robot)
	{
		this._super();
		this.setLayoutType(ccui.Layout.RELATIVE);

		this._gameType = gameType;

		var screenBounds = pm.settings.getScreenBounds();

		var width = pm.appUtils.getProgramLayerWidth();

		if(screenBounds.safeAreas.right)
			width += screenBounds.right / 2;

		this.setContentSize(width, pm.settings.getScreenSize().height);

		this._createProgramControlLayer(robot);

		this._workLayout = new ccui.Layout();

		this._workLayout.setBackGroundImage("System/PL_Border.png");
		this._workLayout.setBackGroundImageScale9Enabled(true);
		this._workLayout.setCascadeOpacityEnabled(true);
		this._workLayout.setBackGroundImageCapInsets(cc.rect(80, 90, 100, 70));
		this._workLayout.setContentSize(width - ProgramLayer.METHOD_STACK_WIDTH, pm.settings.getScreenSize().height - ProgramLayer.HEADER_HEIGHT);
		this._workLayout.setLayoutType(ccui.Layout.RELATIVE);

		var workLayoutAlign = new ccui.RelativeLayoutParameter();
		workLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		workLayoutAlign.setMargin(0, ProgramLayer.HEADER_HEIGHT, 0, 0);
		this._workLayout.setLayoutParameter(workLayoutAlign);

		this.addChild(this._workLayout, 2);

		pm.registerCustomEventListener(pm.ROBOT_TOUCH_EVENT_STR, function (event)
		{
			if (this._gameType !== GameType.Net && event.getUserData() && event.getUserData().id !== this.robot.id)
				pm.appUtils.getProgramLayerPrototype().setRobot.call(this, event.getUserData());
		}.bind(this), this);

		pm.registerCustomEventListener(pm.ENABLE_CONTROLLED_MODE_STR, function (event)
		{
			var data = event.getUserData();
			if (data !== undefined)
				this._enableControlledMode(data);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.DISABLE_CONTROLLED_MODE_STR, this._disableControlledMode.bind(this), this);

		pm.registerCustomEventListener(pm.COMMANDS_CHANGED,  function ()
		{
			this._drawSelectLayout();
		}.bind(this), this);

		pm.registerCustomEventListener(pme.COOPERATIVE_MODE, function (event)
		{
			var cooperativeMode = event.getUserData();

			if(cooperativeMode)
			{
				this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(this._robot.groupID),
					pm.appUtils.getProgramLayerWidth() - ProgramLayer.METHOD_STACK_WIDTH - 30, 15);
				this._workLayout.addChild(this._colorBorder, -2);
			}
			else
			{
				this._colorBorder.removeFromParent();
				this._colorBorder = null;
			}

			this.updateProgramContainer();
		}.bind(this), this);

		pm.registerCustomEventListener(pm.TURN_TURBO_MODE, function()
		{
			if (this._programControlLayer)
				this._programControlLayer.reloadInterface();

		}.bind(this), this);

		pm.registerCustomEventListener(pme.PATTERNS_COUNT_CHANGED_EVENT_STR, this._updateSelectPatternMenu.bind(this), this);
	},

	_getRobotPatternCount: function(robot)
	{
		return robot.parentLevel.programData[robot.groupID].programDataArray.length;
	},

	/**
     * Sets robot and redraws interface.
     * @param {pm.AbstractRobot} robot
     */
	setRobot: function(robot) {},

	/**
	 * Updates program container and interface.
	 */
	updateProgramContainer: function() {},

	/**
     * Returns robot of layer.
     * @returns {pm.AbstractRobot}
     */
	getRobot: function() { return this._robot; },

	/**
     * Returns if program pattern is editable.
     * @returns {Boolean}
     */
	isProgramPatternEditable: function()
	{
		return this._robot.getProgramData().canEditProgramPattern;
	},

	_clearProgramConfirm: function()
	{
		if (!cc.director.getRunningScene().getChildByTag(StartMenuLayer.CONFIRM_LAYER_TAG))
		{
			var input = new pmui.ConfirmWindow(LocalizedString("ClearProgram"));

			input.setCallback(this, this._clearProgram, function () {});

			input.setPosition((pm.settings.getScreenSize().width - input.width) / 2, (pm.settings.getScreenSize().height - input.height) / 2);

			cc.director.getRunningScene().addChild(input, 100, ProgramLayer.CONFIRM_LAYER_TAG);
		}
	},

	_clearProgram: function()
	{
		if (this._robot.parentLevel.programDataCache[this._robot.groupID])
		{
			this._robot.parentLevel.programDataCache[this._robot.groupID] = null;

			this._updateSelectPatternMenu();
		}
	},

	_createProgramControlLayer: function(robot)
	{
		if (robot === undefined)
			robot = this._robot;

		var useProgramRecognizer = cc.sys.isMobile && pm.settings.getUseProgramRecognizer() && robot.parentLevel.useProgramRecognizer;
		this._programControlLayer = new ProgramControlLayer(robot.parentLevel.maps.length > 1 || robot.parentLevel.checkAllPatterns, this._gameType === GameType.Net, useProgramRecognizer);

		var programMenuAlign = new ccui.RelativeLayoutParameter();
		programMenuAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_RIGHT);
		programMenuAlign.setRelativeName("programMenu");
		programMenuAlign.setMargin(0, 0, 0, 0);

		this._programControlLayer.setLayoutParameter(programMenuAlign);

		this.addChild(this._programControlLayer);
	},

	_createSelectButtonsBar: function(type, values, registerTutorial) {},

	/**
     * Updates interface.
     * @param {?Number} offset Offset fo correct scroll position after changes
     */
	updateView: function(offset) {},

	_recalculateSizes: function() {},

	_dragCallback: function(element, eventType, touch) {},

	_canDragButtons: function()
	{
		return !this._controlledMode;
	},

	/**
     * Enables Controlled mode of program layer.</br>
     * In this mode buttons animates once and called click callback passed to function.
     * @param {pm.ControlledModeData} controlledModeData
     * @private
     */
	_enableControlledMode: function(controlledModeData) {},

	/**
     * Disables Controlled mode of program layer.
     * @private
     */
	_disableControlledMode: function()
	{
		this._controlledMode = false;
		this._buttonClickCallback = null;
		this._buttonClickCallbackTarget = null;

		this._programControlLayer.setButtonsEnabled(true);
	},

	isControlled: function()
	{
		return this._controlledMode;
	},

	_onRecognizeError: function(error)
	{
		if(error !== "")
		{
			var messageBox = new pmui.MessageBox(this, error);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, 1000);
		}
	},

	_onProgramRecognize: function(predictions)
	{
		this._programControlLayer.showRecognizeResultButton(predictions);
	},

	/**
     * Resets layer
     */
	reset: function() {},

	setEnabled: function(flag, steppedWorking) {},

	setProgramControlEnabled: function(flag)
	{
		this._programControlLayer.setButtonsEnabled(flag);
	}
});


/** @expose */
_p.curMethodCount;
cc.defineGetterSetter(_p, "curMethodCount", _p.getCurMethodCount, _p.setCurMethodCount);

ProgramLayer.SELECTDNDNAME = "select_menu_drag_and_drop";
ProgramLayer.MENU_SEPARATOR = 5.0;
ProgramLayer.COMPONENT_SEPARATOR = 5.0;
ProgramLayer.PROGRAM_SEPARATOR = 10.0;
ProgramLayer.METHOD_STACK_WIDTH = 44;
ProgramLayer.HEADER_HEIGHT = 50;
ProgramLayer.BORDER_X = 15.0;
ProgramLayer.BORDER_Y = 5.0;
ProgramLayer.REPEATER_BUTTON_NUM = 6;
ProgramLayer.SCROLL_BAR_WIDTH = 5;
ProgramLayer.BORDER_RADIUS = 20;
ProgramLayer.SECOND_ROW_MARGIN = 10;
ProgramLayer.WORK_LAYOUT_MARGIN = 20;
ProgramLayer.CONDITIONS_MARGIN = 3;
ProgramLayer.CONDITIONS_MARGIN_EDITOR = 2;
ProgramLayer.COLOR_BAR_MARGIN = 14;
ProgramLayer.BAR_COLOR = cc.color(135, 160, 180);
ProgramLayer.PROGRAM_COLOR = cc.color(224, 224, 224);
ProgramLayer.PATTERN_COLOR = cc.color(38, 38, 149);
ProgramLayer.PATTERN_HEIGHT = 30;
ProgramLayer.CONFIRM_LAYER_TAG = 4963;
ProgramLayer.POSITION_BACK_Y = 29;
ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM = 9;
ProgramLayer.EDITOR_BUTTONS_MARGIN_RIGHT = 17.5;
ProgramLayer.POSITION_BACK_MARGIN = 49;
ProgramLayer.BACK_CLEAR_NAME = "backgroundClearButton";
ProgramLayer.BACK_CHANGE_ROWS_NAME = "backgroundAddRowsButton";
ProgramLayer.BACK_BEAUTIFY_NAME = "backgroundBeautifyButton";
ProgramLayer.BACK_CHANGE_SYNTAX_NAME = "backgroundChangeSyntaxButton";