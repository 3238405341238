/**
 * Created by Kirill Mashchenko on 22.07.19.
 */

pm.data.AquariusMap = pm.AbstractMap.extend(/** @lends pm.data.AquariusMap# */{
	typeName: "AquariusMap",

	tankA: null,
	tankB: null,
	tankC: null,

	ctor: function()
	{
		this._super();
	},

	getType: function ()
	{
		return pm.AquariusLevelModule.Type;
	},

	generateMapLayer: function(previewDraw)
	{
		if(!CORE_BUILD)
		{
			if (!pm.settings.isEditorMode)
				this.mapLayer = new AquariusMapLayer(this);
			else
				this.mapLayer = new AquariusMapEditorLayer(this);

			this.mapLayer.drawMap(previewDraw);

			return this.mapLayer;
		}
	},

	load: function()
	{
		this.tankA.currentCapacity = this.tankA.startCapacity;
		this.tankB.currentCapacity = this.tankB.startCapacity;
		this.tankC.currentCapacity = this.tankC.startCapacity;

		this.parentLevel.getCurrentRobot().updateNativeFunctions(this);
	},

	placeRobots: function (flag) {},

	getState: function(){},

	setState: function(){}
});
