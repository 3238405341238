/**
 * Created by danilaeremin on 25.06.15.
 */

/**
 * This callbacks are used on Ok and Cancel buttons.
 * @callback pmui.MessageBox~actionCallback
 */

/**
 * @class Layout for login in editor.
 * @extends pmui.OvalLayout
 * @constructor
 * @param {cc.Node} object Target for callbacks.
 * @param {String} message Message string.
 * @param {pmui.MessageBox~actionCallback?} ok_callback Called on Ok button clicked.
 * @param {pmui.MessageBox~actionCallback?} cancel_callback Called on Cancel button clicked.
 */
pmui.MessageBox = pmui.OvalLayout.extend(/** @lends pmui.MessageBox# */{
	object: null,
	ok_callback: null,
	cancel_callback: null,

	ctor: function(object, message, ok_callback, cancel_callback)
	{
		this._super();

		this.object = object;
		this.ok_callback = ok_callback;
		this.cancel_callback = cancel_callback;

		this.setLayoutType(ccui.Layout.RELATIVE);

		var buttonLayout = new ccui.HBox();

		var buttonLayoutAlign = new ccui.RelativeLayoutParameter();
		buttonLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_BOTTOM_CENTER_HORIZONTAL);
		buttonLayoutAlign.setMargin(0, 0, 0, pmui.OvalLayout.STANDARD_BORDER_RADIUS+5);
		buttonLayout.setLayoutParameter(buttonLayoutAlign);

		this.addChild(buttonLayout);

		var okButton = new pmui.InterfaceButton(this._ok, this, LocalizedString("OkButton"));
		buttonLayout.addChild(okButton);

		var cancelButton = new pmui.InterfaceButton(this._cancel, this, LocalizedString("CancelButton"));
		var cancelMargin = new ccui.LinearLayoutParameter();
		cancelMargin.setMargin(pmui.MessageBox.SEPARATOR, 0, 0, 0);
		cancelButton.setLayoutParameter(cancelMargin);

		if (cancel_callback)
		{
			buttonLayout.addChild(cancelButton);

			buttonLayout.setContentSize(okButton.getContentSize().width + cancelButton.getContentSize().width + pmui.MessageBox.SEPARATOR,
				okButton.getContentSize().height);
		}
		else
		{
			buttonLayout.setContentSize(okButton.getContentSize().width,
				okButton.getContentSize().height);
		}

		var label = new ccui.Text(message, pm.settings.fontName, pm.settings.fontSize);
		label.ignoreContentAdaptWithSize(false);
		label.getVirtualRenderer().setDimensions(pmui.MessageBox.WIDTH, 0);
		label.setTextAreaSize(label.getVirtualRenderer().getContentSize());
		label.setTextHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

		var labelAlign = new ccui.RelativeLayoutParameter();
		labelAlign.setAlign(ccui.RelativeLayoutParameter.CENTER_IN_PARENT);
		label.setLayoutParameter(labelAlign);

		this.setContentSizeWithBorder(Math.max(pmui.MessageBox.WIDTH, label.getContentSize().width),
			Math.max(pmui.MessageBox.HEIGHT, okButton.getContentSize().height + label.getContentSize().height + pmui.OvalLayout.STANDARD_BORDER_RADIUS));

		var textLayout = new ccui.Layout();
		textLayout.setLayoutType(ccui.Layout.RELATIVE);

		textLayout.setContentSize(this.getContentSize().width, this.getContentSize().height - okButton.getContentSize().height);

		var textLayoutAlign = new ccui.RelativeLayoutParameter();
		textLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		textLayoutAlign.setMargin(0, 0, 0, 0);

		textLayout.setLayoutParameter(textLayoutAlign);

		textLayout.addChild(label);

		this.addChild(textLayout);

		var listener = cc.EventListener.create({
			event: cc.EventListener.TOUCH_ONE_BY_ONE,
			swallowTouches: true,
			onTouchBegan: function()
			{
				return true;
			},
			onTouchMoved: function() {},
			onTouchEnded: function() {}
		});

		cc.eventManager.addListener(listener, this);
	},

	_ok: function(sender)
	{
		this.removeFromParent();

		if(this.ok_callback)
			this.ok_callback.call(this.object);
	},

	_cancel: function(sender)
	{
		this.removeFromParent();
		this.cancel_callback.call(this.object);
	}
});

/**
 * @default
 * @const
 * @type {Number}
 */
pmui.MessageBox.WIDTH = 300.0;
/**
 * @default
 * @const
 * @type {Number}
 */
pmui.MessageBox.HEIGHT = 140.0;
/**
 * @default
 * @const
 * @type {Number}
 */
pmui.MessageBox.SEPARATOR = 5.0;
