/**
 * Created by Nikita Besshaposhnikov on 13.11.14.
 */

/**
 * @class Layer for displaying program of robot.
 * @extends ccui.VBox
 * @property {pm.AbstractRobot} robot
 * @property {Number} curMethodCount
 * @constructor
 * @param {GameType} gameType
 * @param {Boolean} useMethodStack Use method stack or not.
 * @param {pm.AbstractRobot} [robot] For which robot construct layer.
 */
var ProgramLayerP = ProgramLayer.extend(/** @lends ProgramLayer# */{

	_clearProgramButton: null,
	_changeRowsButton: null,
	_beautifyProgramButton: null,

	ctor: function(gameType, useMethodStack, robot, level)
	{
		this._super(gameType, robot);

		var screenBounds = pm.settings.getScreenBounds();

		var width = pm.appUtils.getProgramLayerWidth();

		if(screenBounds.safeAreas.right)
			width += screenBounds.right / 2;

		this._middleBorder = new cc.Scale9Sprite("System/PL_Middle.png");
		this._middleBorder.setCapInsets(cc.rect(28, 18, 7, 4));
		this._middleBorder.setContentSize(width - ProgramLayer.METHOD_STACK_WIDTH - 30, 41);
		this._middleBorder.setAnchorPoint(0, 0.5);

		this._workLayout.addChild(this._middleBorder, -1);

		if (level.getRobotCount() >= 2)
		{
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(robot.groupID), width - ProgramLayer.METHOD_STACK_WIDTH - 30, 15);
			this._workLayout.addChild(this._colorBorder, -2);
		}

		this._selectLayout = new ccui.VBox();

		var selectLayoutAlign = new ccui.RelativeLayoutParameter();
		selectLayoutAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		selectLayoutAlign.setRelativeName("selectLayout");
		selectLayoutAlign.setMargin(1.5*ProgramLayer.BORDER_X, 3*ProgramLayer.BORDER_Y, 0, 0);

		this._selectLayout.setLayoutParameter(selectLayoutAlign);

		this._functionScroll = new ccui.ListView();

		var functionsAlign = new ccui.RelativeLayoutParameter();
		functionsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		functionsAlign.setMargin(-3, ProgramLayer.PROGRAM_SEPARATOR + ProgramLayer.COMPONENT_SEPARATOR + 14, 0, 0);
		functionsAlign.setRelativeToWidgetName("selectLayout");

		this._functionScroll.setDirection(ccui.ScrollView.DIR_VERTICAL);
		this._functionScroll.setLayoutParameter(functionsAlign);
		this._functionScroll.setItemsMargin(ProgramLayer.PROGRAM_SEPARATOR);
		this._functionScroll.setScrollBarEnabled(false);

		this._workLayout.addChild(this._selectLayout, 3);
		this._workLayout.addChild(this._functionScroll, 0);

		if(robot)
			this.robot = robot;

		this._updateSelectPatternMenu();

		if(useMethodStack)
		{
			this._methodStackLayer = new MethodStackLayer(this.getContentSize().height - 350, this.robot.parentLevel);

			var msShift = ProgramLayer.METHOD_STACK_WIDTH - this._methodStackLayer.width;
			msShift += MethodStackLayer.INNER_WIDTH;
			msShift -= MethodStackLayer.OUTER_RIGHT_MARGIN;

			var methodStackLayerAlign = new ccui.RelativeLayoutParameter();
			methodStackLayerAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_LEFT_CENTER_VERTICAL);
			methodStackLayerAlign.setMargin(msShift + 5, ProgramLayer.COMPONENT_SEPARATOR, 0, 0);
			this._methodStackLayer.setLayoutParameter(methodStackLayerAlign);

			this.addChild(this._methodStackLayer, -100);
		}

		this._drawEditorButtons();

		pm.registerCustomEventListener(pm.HIGHLIGHT_METHOD_EVENT_STR, function(event)
		{
			var data = event.getUserData();

			if(data && this._robot.id === data.robotIndex)
				this._programContainer.highlightMethod(data.methodPlace);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.HIGHLIGHT_POINT_IN_REP_EVENT, function(event)
		{
			var data = event.getUserData();

			if(data && this._robot.id === data.robotIndex)
				this._programContainer.switchPointsInRepeater(data.repPlace, data.iterNum, data.rep);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.REM_LAST_HIGHLIGHT_EVENT_STR, function(event)
		{
			var data = event.getUserData();

			if(data !== undefined && this._robot.id === data.robotIndex)
				this._programContainer.clearLastHighlight();

		}.bind(this), this);

		pm.registerCustomEventListener(pm.RELOAD_REPEATERS_EVENT_STR, function(event)
		{
			var data = event.getUserData();

			if(data !== undefined && this._robot.id === data.robotIndex)
				this._programContainer.reloadRepeaters();

		}.bind(this), this);

		pm.registerCustomEventListener(pm.HIGHLIGHT_BROKEN_METHOD_EVENT_STR, function(event)
		{
			var data = event.getUserData();

			if(data !== undefined && this._robot.id === data.robotIndex)
				this._programContainer.highlightMethodBroken(data.methodPlace);

		}.bind(this), this);

		pm.registerCustomEventListener(pm.UPDATE_INNER_PROGRAM_LAYER_CONTAINER, function(event)
		{
			this.updateView(event.getUserData());
		}.bind(this), this);

		pm.registerCustomEventListener(pm.RECOGNIZE_PROGRAM, this._recognizeProgram.bind(this), this);

		pm.registerCustomEventListener(pm.UPDATE_CLEAR_PROGRAM_BUTTON, function(event)
		{
			this._updateEditorButtons(event.getUserData());
		}.bind(this), this);
	},

	_updateSelectPatternMenu: function()
	{
		if (this._patternContainer)
		{
			this._workLayout.setContentSize(this._workLayout.width, this._workLayout.height + this._patternContainer.height);

			this._patternContainer.removeFromParent();
			this._patternContainer = null;
		}

		if (this._getRobotPatternCount(this._robot) > 1)
		{
			this._patternContainer = new cc.LayerColor(ProgramLayer.PATTERN_COLOR, this._workLayout.width, ProgramLayer.PATTERN_HEIGHT);

			this._patternContainer.setPosition(this.width - this._patternContainer.width, 0);

			this.addChild(this._patternContainer);

			this._workLayout.setContentSize(this._workLayout.width, this._workLayout.height - this._patternContainer.height);

			this._selectPatternMenu = new SelectPatternMenu(this._robot.parentLevel, this._robot, this, this.updateProgramContainer);
			this._patternContainer.addChild(this._selectPatternMenu, 1);

			this._selectPatternMenu.updateCircles();
		}
		else
		{
			this.updateProgramContainer();
		}
	},

	/**
     * Sets robot and redraws interface.
     * @param {pm.AbstractRobot} robot
     */
	setRobot: function(robot)
	{
		var isControlled = this._methodStackLayer && !this._methodStackLayer.hidden;

		if (isControlled)
			this._methodStackLayer.drawRobotMethodList(robot.groupID);

		this._robot = robot;

		this._robot.parentLevel.setCurrentRobot(this._robot);

		if (this._programContainer)
		{
			this._programContainer.updateProgram(this._robot.getProgramData());
		}
		else
		{
			this._functionScroll.removeAllChildren();
			this._programContainer = new ProgramContainerLayerP(this, false, this._robot.getProgramData());
			this._functionScroll.pushBackCustomItem(this._programContainer);
		}

		if (isControlled)
			pm.robotManager.setControlledMode(true);

		if (this._colorBorder)
		{
			var width = this._colorBorder.getContentSize().width;
			var height = this._colorBorder.getContentSize().height;

			this._colorBorder.removeFromParent();
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(this._robot.groupID), width, height);
			this._workLayout.addChild(this._colorBorder, -2);
		}

		this._updateSelectPatternMenu();

		this._drawSelectLayout();
		this._recalculateSizes();
	},

	/**
	 * Updates program container and interface.
	 */
	updateProgramContainer: function(name)
	{
		if(name === undefined)
			name = false;

		if (this._programContainer)
		{
			this._programContainer.updateProgram(this._robot.getProgramData(), name);
		}
		else
		{
			this._functionScroll.removeAllChildren();
			this._programContainer = new ProgramContainerLayerP(this, false, this._robot.getProgramData());
			this._functionScroll.pushBackCustomItem(this._programContainer);
		}

		if (this._colorBorder)
		{
			var width = this._colorBorder.getContentSize().width;
			var height = this._colorBorder.getContentSize().height;

			this._colorBorder.removeFromParent();
			this._colorBorder = new cc.LayerColor(pm.RobotSprite2D.getRobotColor(this._robot.groupID), width, height);
			this._workLayout.addChild(this._colorBorder, -2);
		}

		this._drawSelectLayout();
		this._recalculateSizes();
	},

	/**
     * Returns robot of layer.
     * @returns {pm.AbstractRobot}
     */
	getRobot: function() { return this._robot; },

	/**
     * Returns if program pattern is editable.
     * @returns {Boolean}
     */
	isProgramPatternEditable: function()
	{
		return this._robot.getProgramData().canEditProgramPattern;
	},

	_drawEditorButtons: function()
	{
		if (pm.settings.getUseClearProgramButton() && !pm.settings.isEditorMode)
		{
			var backClearButton = new cc.Sprite("System/background_extra.png");

			backClearButton.setPosition(ProgramLayerP.POSITION_BACK_X, ProgramLayer.POSITION_BACK_Y);
			backClearButton.setName(ProgramLayer.BACK_CLEAR_NAME);

			this._workLayout.addChild(backClearButton, 4);

			this._clearProgramButton = new pmui.Button(pm.spriteUtils.getIconName("clearProgram", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("clearProgram", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("clearProgram", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._clearProgramButton.addClickEventListener(this._clearProgramConfirm.bind(this));

			var clearProgramButtonAlign = new ccui.RelativeLayoutParameter();
			clearProgramButtonAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			clearProgramButtonAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH - this._clearProgramButton.width, 0, ProgramLayer.EDITOR_BUTTONS_MARGIN_RIGHT, ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM);
			this._clearProgramButton.setLayoutParameter(clearProgramButtonAlign);

			this._workLayout.addChild(this._clearProgramButton, 4);
		}

		if(this.robot.getProgramData().canEditProgramPattern || pm.settings.isEditorMode)
		{
			var backChangeRowsButton = new cc.Sprite("System/background_extra.png");

			var backPositionX = ProgramLayerP.POSITION_BACK_X;
			var backPositionY = ProgramLayer.POSITION_BACK_Y;
			var buttonPositionX = ProgramLayer.EDITOR_BUTTONS_MARGIN_RIGHT;

			if (this._clearProgramButton !== null && !pm.settings.isEditorMode)
			{
				backPositionX -= ProgramLayer.POSITION_BACK_MARGIN;
				buttonPositionX += ProgramLayer.POSITION_BACK_MARGIN;
			}

			backChangeRowsButton.setPosition(backPositionX, backPositionY);
			backChangeRowsButton.setName(ProgramLayer.BACK_CHANGE_ROWS_NAME);

			this._workLayout.addChild(backChangeRowsButton, 4);

			var normal = pm.spriteUtils.getIconName("rowsChangeNew", pm.NORMAL_STATE);
			var selected = pm.spriteUtils.getIconName("rowsChangeNew", pm.SELECTED_STATE);
			var disabled = pm.spriteUtils.getIconName("rowsChangeNew", pm.DISABLED_STATE);

			if (this._programContainer.canAddRemoveRowsState === ProgramContainerLayer.ADD_REMOVE_ROWS_STATE)
			{
					normal = pm.spriteUtils.getIconName("rowsChangeNew", pm.SELECTED_STATE);
					selected = pm.spriteUtils.getIconName("rowsChangeNew", pm.NORMAL_STATE);
					disabled = pm.spriteUtils.getIconName("rowsChangeNew", pm.DISABLED_STATE);
			}

			this._changeRowsButton = new pmui.Button(normal, selected, disabled, ccui.Widget.PLIST_TEXTURE);

			this._changeRowsButton.addClickEventListener(this._changeRows.bind(this));

			var changeRowsAlign = new ccui.RelativeLayoutParameter();
			changeRowsAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			changeRowsAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH, 0, buttonPositionX,  ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM);
			this._changeRowsButton.setLayoutParameter(changeRowsAlign);

			this._workLayout.addChild(this._changeRowsButton, 4);

			var backBeautifyButton = new cc.Sprite("System/background_extra.png");

			backPositionX -= ProgramLayer.POSITION_BACK_MARGIN;
			buttonPositionX += ProgramLayer.POSITION_BACK_MARGIN;

			backBeautifyButton.setPosition(backPositionX, ProgramLayer.POSITION_BACK_Y);
			backBeautifyButton.setName(ProgramLayer.BACK_BEAUTIFY_NAME);
			this._workLayout.addChild(backBeautifyButton, 4);

			this._beautifyProgramButton = new pmui.Button(pm.spriteUtils.getIconName("beautify", pm.NORMAL_STATE),
				pm.spriteUtils.getIconName("beautify", pm.SELECTED_STATE),
				pm.spriteUtils.getIconName("beautify", pm.DISABLED_STATE),
				ccui.Widget.PLIST_TEXTURE);

			this._beautifyProgramButton.addClickEventListener(this._beautifyProgram.bind(this));

			var beautifyProgramAlign = new ccui.RelativeLayoutParameter();
			beautifyProgramAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_RIGHT_BOTTOM);
			beautifyProgramAlign.setMargin(ProgramLayer.METHOD_STACK_WIDTH, 0, buttonPositionX,  ProgramLayer.EDITOR_BUTTONS_MARGIN_BOTTOM);
			this._beautifyProgramButton.setLayoutParameter(beautifyProgramAlign);
			this._beautifyProgramButton.setName("beautify");

			this._workLayout.addChild(this._beautifyProgramButton, 4);
		}
	},

	_changeRows: function()
	{
		this._programContainer.loadAddRemoveRowTexture(this._changeRowsButton);
	},

	_removeBackground: function()
	{
		if (this._workLayout.getChildByName(ProgramLayer.BACK_CLEAR_NAME))
			this._workLayout.getChildByName(ProgramLayer.BACK_CLEAR_NAME).removeFromParent();
		
		if (this._workLayout.getChildByName(ProgramLayer.BACK_CHANGE_ROWS_NAME))
			this._workLayout.getChildByName(ProgramLayer.BACK_CHANGE_ROWS_NAME).removeFromParent();

		if (this._workLayout.getChildByName(ProgramLayer.BACK_BEAUTIFY_NAME))
			this._workLayout.getChildByName(ProgramLayer.BACK_BEAUTIFY_NAME).removeFromParent();
	},

	_updateEditorButtons: function (notToRemoveArrows)
	{
		if (this._clearProgramButton)
		{
			this._clearProgramButton.removeFromParent();
			this._clearProgramButton = null;
		}

		if(this._beautifyProgramButton)
		{
			this._beautifyProgramButton.removeFromParent();
			this._beautifyProgramButton = null;
		}

		if(this._changeRowsButton)
		{
			this._changeRowsButton.removeFromParent();
			this._changeRowsButton = null;
		}

		this._removeBackground();

		if(!notToRemoveArrows)
			pm.sendCustomEvent(pm.REMOVE_ALL_ARROWS);

		this._drawEditorButtons();
	},

	_drawSelectLayout: function()
	{
		this._selectLayout.removeAllChildren();

		var useRepeaters = false;
		var useConditions = false;
		var useCondRepeaters = false;

		var program = this._robot.getProgramData();

		if(!this.isProgramPatternEditable())
		{
			for (var i = 0; i < program.height; ++i)
			{
				for (var j = 0; j < program.width; ++j)
				{
					if (program.symbols[i][j].type === pm.SymbolType.Repeater)
						useRepeaters = true;

					if (program.symbols[i][j].type === pm.SymbolType.CondRepeater || program.symbols[i][j].type === pm.SymbolType.Condition)
						useConditions = true;
				}
			}

			useCondRepeaters = useConditions;
		}
		else
		{
			if (pm.settings.isEditorMode)
			{
				useRepeaters = useConditions = useCondRepeaters = true;
			}
			else
			{
				useRepeaters = this._robot.getProgramData().maxRepeaterCount !== -1;
				useConditions = this._robot.getProgramData().maxConditionCount !== -1;
				useCondRepeaters = useConditions && this._robot.getProgramData().maxCondRepeaterCount !== -1;
			}
		}

		var layoutMargin = new ccui.LinearLayoutParameter();
		layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);

		var layout = null;

		if(useRepeaters)
		{
			layout = this._drawCommonRepeaters();
			var repeatersMargin = new ccui.LinearLayoutParameter();
			repeatersMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);

			layout.setLayoutParameter(repeatersMargin);

			this._selectLayout.addChild(layout);

			layout = this._drawRobotRepeaters();

			if(layout)
			{
				layout.setLayoutParameter(layoutMargin.clone());
				this._selectLayout.addChild(layout);
			}
		}

		layout = this._drawRobotMethods();

		layout.setLayoutParameter(layoutMargin.clone());

		this._selectLayout.addChild(layout);

		var isConditions = false;

		if(useConditions)
		{
			layout = this._drawRobotConditions();

			if(layout)
			{
				layout.setLayoutParameter(layoutMargin.clone());
				this._selectLayout.addChild(layout);

				layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR - ProgramLayer.SECOND_ROW_MARGIN, 0, 0);

				isConditions = true;
			}
		}

		if(useRepeaters)
		{
			layout = this._drawChildRobotRepeaters();
			if(layout)
			{
				layout.setLayoutParameter(layoutMargin.clone());
				this._selectLayout.addChild(layout);
				layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
			}
		}

		layout = this._drawRobotChildMethods();

		if(layout)
		{
			layout.setLayoutParameter(layoutMargin.clone());
			this._selectLayout.addChild(layout);
			layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
		}

		if(useConditions)
		{
			layout = this._drawRobotChildConditions();

			if(layout)
			{
				layout.setLayoutParameter(layoutMargin.clone());
				this._selectLayout.addChild(layout);
				layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
			}
		}

		layout = this._drawFunctionEditButtons();

		if(layout)
		{
			layout.setLayoutParameter(layoutMargin.clone());
			this._selectLayout.addChild(layout);
			layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
		}

		layout = this._createEditButtonsBar(useRepeaters, useConditions, useCondRepeaters);

		if(layout)
		{
			layout.setLayoutParameter(layoutMargin.clone());
			this._selectLayout.addChild(layout);
			layoutMargin.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
		}

		var width = 0;
		var height = 0;

		var children = this._selectLayout.getChildren();

		for(var i = 0; i < this._selectLayout.getChildrenCount(); ++i)
		{
			width = Math.max(width, children[i].getContentSize().width);
			height += children[i].getContentSize().height + ProgramLayer.MENU_SEPARATOR;
		}

		var extraHeight = 0;
		var lessHeight = 0;

		if (isConditions)
		{
			height -= ProgramLayer.SECOND_ROW_MARGIN;
			extraHeight += ProgramLayer.CONDITIONS_MARGIN;
		}
		else
		{
			lessHeight -= ProgramLayer.CONDITIONS_MARGIN;

			if (pm.settings.isEditorMode)
				extraHeight += ProgramLayer.CONDITIONS_MARGIN_EDITOR;
		}

		this._selectLayout.setContentSize(this.getContentSize().width, height + lessHeight);

		var functionsAlign = new ccui.RelativeLayoutParameter();
		functionsAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_BELOW_LEFTALIGN);
		functionsAlign.setMargin(-3, ProgramLayer.PROGRAM_SEPARATOR + ProgramLayer.COMPONENT_SEPARATOR + 14, 0, 0);
		functionsAlign.setRelativeToWidgetName("selectLayout");
		this._functionScroll.setLayoutParameter(functionsAlign);

		if (this._getRobotPatternCount(this._robot) > 1)
			this._selectPatternMenu.setPosition((this._patternContainer.width - this._selectPatternMenu.width) / 2,
				(this._patternContainer.height - this._selectPatternMenu.height) / 2 + 2);

		if (this._colorBarLayer)
			this._colorBarLayer.removeFromParent();

		if (this._colorProgramLayer)
			this._colorProgramLayer.removeFromParent();

		var newHeight = height + extraHeight;
		var newWidth = this._workLayout.width - ProgramLayer.WORK_LAYOUT_MARGIN;

		this._colorBarLayer = new cc.LayerColor(ProgramLayer.BAR_COLOR, newWidth, newHeight + ProgramLayer.COLOR_BAR_MARGIN);
		this._colorBarLayer.setPosition(cc.p(10, this._workLayout.height - this._colorBarLayer.height - 10));
		this._workLayout.addChild(this._colorBarLayer, -3);

		this._colorProgramLayer = new cc.LayerColor(ProgramLayer.PROGRAM_COLOR, newWidth,
			this._workLayout.height - ProgramLayer.WORK_LAYOUT_MARGIN - height - ProgramLayer.COLOR_BAR_MARGIN);
		this._colorProgramLayer.setPosition(cc.p(10, 10));
		this._workLayout.addChild(this._colorProgramLayer, -3);

		this._middleBorder.setContentSize(this._middleBorder.width, 41);
		this._middleBorder.setPosition(cc.p(15, this._colorBarLayer.getPosition().y - 3));

		if (this._colorBorder)
			this._colorBorder.setPosition(cc.p(this._middleBorder.getPosition().x, this._middleBorder.getPosition().y - 12));

		this._workLayout.forceDoLayout();
	},

	_createSelectButtonsBar: function(type, values, registerTutorial)
	{
		if (registerTutorial === undefined)
			registerTutorial = false;

		var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

		layout.setContentSize(Math.max(values.length, 6) * (ProgramContainerLayer.BUTTON_SIZE + ProgramLayer.MENU_SEPARATOR),
			ProgramContainerLayer.BUTTON_SIZE);

		for(var i = 0; i < values.length; ++i)
		{
			var button;

			if(type === FunctionButton.Type.Repeater)
				button = new RepeaterButton(type, values[i]);
			else
				button = pm.appUtils.generateFunctionButton(type, values[i]);

			var buttonMargin = new ccui.LinearLayoutParameter();

			if (i !== 0)
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);

			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);

			if (registerTutorial)
				pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAME_PATTERNS.SELECT.format(values[i]), button, true);
		}

		return layout;
	},

	_createEditButtonsBar: function(useRepeaters, useConditions, useCondRepeaters)
	{
		if (this.isProgramPatternEditable())
		{
			var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

			var button = new EmptyFunctionButton(FunctionButton.Type.Method);
			var buttonMargin = new ccui.LinearLayoutParameter();
			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);

			var countButtons = 1;

			if (useRepeaters)
			{
				var button = new EmptyFunctionButton(FunctionButton.Type.Repeater);
				var buttonMargin = new ccui.LinearLayoutParameter();
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);
				button.setLayoutParameter(buttonMargin);

				layout.addChild(button);

				++countButtons;
			}

			if (useConditions)
			{
				var button = new EmptyFunctionButton(FunctionButton.Type.Condition);
				var buttonMargin = new ccui.LinearLayoutParameter();
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);
				button.setLayoutParameter(buttonMargin);

				layout.addChild(button);

				++countButtons;
			}

			if (useCondRepeaters)
			{
				var button = new EmptyFunctionButton(FunctionButton.Type.CondRepeater);
				var buttonMargin = new ccui.LinearLayoutParameter();
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);
				button.setLayoutParameter(buttonMargin);

				layout.addChild(button);

				++countButtons;
			}

			layout.setContentSize(countButtons * (ProgramContainerLayer.BUTTON_SIZE + ProgramLayer.MENU_SEPARATOR),
				ProgramContainerLayer.BUTTON_SIZE);

			return layout;
		}

		return null;
	},

	_drawRobotMethods: function()
	{
		this._robot.updateMethods();

		var compare = pm.moduleUtils.compareMethods(this._robot.parentLevel.getType());
		this._robot.nativeFunctions.sort(compare.bind(this));

		var exportedFunctions = cc.clone(this._robot.nativeFunctions);

		pm.moduleUtils.addExtraMethods(this._robot.parentLevel.getType(), this._robot, exportedFunctions);

		if (this._robot.parentLevel.robotsCanBeIndicated)
			exportedFunctions.push(pm.CMD_INDICATE);

		if (exportedFunctions.length > 6)
		{
			var firstRowFunctions = exportedFunctions.slice(0, 6);
			var secondRowFunctions = exportedFunctions.slice(6, exportedFunctions.length);

			var firstRow = this._createSelectButtonsBar(FunctionButton.Type.Method, firstRowFunctions, true);
			var secondRow = this._createSelectButtonsBar(FunctionButton.Type.Method, secondRowFunctions, true);

			var layout = new ccui.Layout();

			layout.setLayoutType(ccui.Layout.LINEAR_VERTICAL);
			layout.setContentSize(firstRow.width, firstRow.height + ProgramLayer.MENU_SEPARATOR + secondRow.height);

			var rowAlign = new ccui.LinearLayoutParameter();
			rowAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			rowAlign.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);
			secondRow.setLayoutParameter(rowAlign);

			layout.addChild(firstRow);
			layout.addChild(secondRow);

			return layout;
		}
		else
		{
			return this._createSelectButtonsBar(FunctionButton.Type.Method, exportedFunctions, true);
		}
	},

	_drawRobotChildMethods: function()
	{
		var exportedFunctions = this._robot.getChildFunctions();

		if(exportedFunctions && exportedFunctions.length !== 0)
		{
			var compare = pm.moduleUtils.compareMethods(this._robot.parentLevel.getType());
			exportedFunctions.sort(compare.bind(this));

			return this._createSelectButtonsBar(FunctionButton.Type.Method, exportedFunctions);
		}

		return null;
	},

	_drawCommonRepeaters: function()
	{
		var layout = new FunctionButtonBar(ProgramContainerLayer.DNDNAME, this._dragCallback, this._canDragButtons, this);

		for(var i = 1; i <= ProgramLayer.REPEATER_BUTTON_NUM; ++i)
		{
			var button = new RepeaterButton(i);

			var buttonMargin = new ccui.LinearLayoutParameter();

			if (i !== 1)
				buttonMargin.setMargin(ProgramLayer.MENU_SEPARATOR, 0, 0, 0);

			button.setLayoutParameter(buttonMargin);

			layout.addChild(button);

			pm.tutorialUtils.registerTutorialObject(pm.tutorialUtils.OBJECT_NAME_PATTERNS.SELECT.format(i), button, true);
		}

		var repeaterNum = ProgramLayer.REPEATER_BUTTON_NUM;

		layout.setContentSize(repeaterNum * (ProgramContainerLayer.BUTTON_SIZE + ProgramLayer.MENU_SEPARATOR),
			ProgramContainerLayer.BUTTON_SIZE);

		return layout;
	},

	_drawRobotRepeaters: function()
	{
		var repeaters = this._robot.repeaters;

		if(repeaters && repeaters.length > 0)
			return this._createSelectButtonsBar(FunctionButton.Type.Repeater, repeaters);

		return null;
	},

	_drawChildRobotRepeaters: function()
	{
		var externalRepeaters = this._robot.getChildRepeaters();

		if(externalRepeaters && externalRepeaters.length > 0)
			return this._createSelectButtonsBar(FunctionButton.Type.Repeater, externalRepeaters);

		return null;
	},

	_drawRobotConditions: function()
	{
		if (this._robot.conditions.length === 0)
			return null;

		this._robot.updateConditions();

		var compare = pm.moduleUtils.compareConditions(this._robot.parentLevel.getType());
		this._robot.conditions.sort(compare.bind(this));

		var firstRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, this._robot.conditions, true);

		if (this._robot.conditionOpposites && this._robot.conditionOpposites.length > 0)
		{
			this._robot.conditionOpposites.sort(compare.bind(this));

			var secondRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, this._robot.conditionOpposites, true);

			var layout = new ccui.Layout();

			layout.setLayoutType(ccui.Layout.LINEAR_VERTICAL);
			layout.setContentSize(firstRow.width, firstRow.height + ProgramLayer.MENU_SEPARATOR + secondRow.height);

			var rowAlign = new ccui.LinearLayoutParameter();
			rowAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
			rowAlign.setMargin(ProgramContainerLayer.BUTTON_SIZE / 2 + ProgramLayer.MENU_SEPARATOR /2, -ProgramContainerLayer.BUTTON_SIZE / 2 + ProgramLayer.SECOND_ROW_MARGIN + ProgramLayer.MENU_SEPARATOR, 0, 0);

			secondRow.setLayoutParameter(rowAlign);

			layout.addChild(firstRow);
			layout.addChild(secondRow);

			return layout;
		}

		return firstRow;
	},

	_drawRobotChildConditions: function()
	{
		var childConditions = this._robot.getChildConditions();
		var childConditionOpposites = this._robot.getChildConditionOpposites();

		if(childConditions && childConditions.length > 0)
		{
			var compare = pm.moduleUtils.compareConditions(this._robot.parentLevel.getType());
			childConditions.sort(compare.bind(this));

			var firstRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, childConditions);

			if (childConditionOpposites)
			{
				childConditionOpposites.sort(compare.bind(this));

				var secondRow = this._createSelectButtonsBar(FunctionButton.Type.Condition, childConditionOpposites);

				var layout = new ccui.Layout();

				layout.setLayoutType(ccui.Layout.LINEAR_VERTICAL);
				layout.setContentSize(firstRow.width, firstRow.height + ProgramLayer.MENU_SEPARATOR + secondRow.height);

				var rowAlign = new ccui.LinearLayoutParameter();
				rowAlign.setGravity(ccui.LinearLayoutParameter.LEFT);
				rowAlign.setMargin(0, ProgramLayer.MENU_SEPARATOR, 0, 0);

				secondRow.setLayoutParameter(rowAlign);

				layout.addChild(firstRow);
				layout.addChild(secondRow);

				return layout;
			}

			return firstRow;
		}

		return null;
	},

	_drawFunctionEditButtons: function()
	{
		if (this.isProgramPatternEditable())
		{
			var exportedFunctions = [];

			var functionsCount = this._robot.getProgramData().maxFunctionCount;

			if (functionsCount === 0 || pm.settings.isEditorMode)
				functionsCount = 6;

			--functionsCount;

			if (functionsCount === 0)
				return null;

			var i = 0;
			var c = pm.CMD_A;

			while (i < functionsCount)
			{
				exportedFunctions.push(c);
				c = String.fromCharCode(c.charCodeAt(0) + 1);
				++i;
			}

			return this._createSelectButtonsBar(FunctionButton.Type.Method, exportedFunctions);
		}
		return null;
	},

	/**
     * Updates interface.
     * @param {?Number} offset Offset fo correct scroll position after changes
     */
	updateView: function(offset)
	{
		offset = offset || 0;

		var oldY = this._functionScroll.getInnerContainer().y;

		oldY -= offset;

		this._functionScroll.forceDoLayout();

		var curY = this._functionScroll.getInnerContainer().y;

		var percent = 0;

		if (curY !== 0)
		{
			percent = ((curY - oldY) / curY) * 100;
			percent = Math.min(Math.max(percent, 0), 100);
		}

		this._functionScroll.jumpToPercentVertical(percent);
	},

	_recalculateSizes: function()
	{
		var functionScrollHeight = this._workLayout.getContentSize().height;
		functionScrollHeight -= 2*ProgramLayer.BORDER_RADIUS;
		functionScrollHeight -= this._selectLayout.getContentSize().height;
		functionScrollHeight -= ProgramLayer.COMPONENT_SEPARATOR;

		this._functionScroll.setContentSize(this.getContentSize().width - ProgramLayer.METHOD_STACK_WIDTH - ProgramLayer.BORDER_X, functionScrollHeight - 14);

		this._workLayout.requestDoLayout();
	},

	_dragCallback: function(element, eventType, touch)
	{
		if (eventType === pmui.DragAndDropLayout.Event.CLICKED)
		{
			if(this._controlledMode)
			{
				if (this._robot.isPlayingAnimation())
					return;

				if (element.value === pm.EMPTY_METHOD)
				{
					return;
				}
				else if (element.value >= pm.CMD_A && element.value <= pm.CMD_E)
				{
					var program = this._robot.getProgramData();
					var functionExists = false;

					for (var i = 0; i < program.height; ++i)
					{
						if (program.symbols[i][0].type === pm.SymbolType.Function && program.symbols[i][0].value === element.value)
						{
							functionExists = true;
							break;
						}
					}

					if (!functionExists)
						return;
				}

				if (this._buttonClickCallback)
					this._buttonClickCallback.call(this._buttonClickCallbackTarget, element);

				return;
			}
			element.select();
		}
	},

	_beautifyProgram: function()
	{
		var oldProgram = this._robot.getProgramData();
		var parsedProgram = pm.programUtilsP.parseProgram(oldProgram);
		var program = pm.appUtils.generateProgramData();

		program.canEditProgramPattern = oldProgram.canEditProgramPattern;
		program.maxFunctionCount = oldProgram.maxFunctionCount;
		program.maxMethodCount = oldProgram.maxMethodCount;
		program.maxRepeaterCount = oldProgram.maxRepeaterCount;
		program.maxConditionCount = oldProgram.maxConditionCount;
		program.maxCondRepeaterCount = oldProgram.maxCondRepeaterCount;
		program.maxDepthConstructCount = oldProgram.maxDepthConstructCount;

		var coords = {
			x: 0,
			y: 0,
			lastNotEmptyString: -1
		};

		var maxWidthIndex = 0;

		for (var i = 0; i < oldProgram.height; ++i)
		{
			for (var j = 0; j < oldProgram.width; ++j)
			{
				var symbol = oldProgram.symbols[i][j];

				if (symbol.type !== pm.SymbolType.Empty && j > maxWidthIndex)
				{
					maxWidthIndex = j;
				}
			}
		}

		var newProgram = pm.programUtilsP.beautifyTree(parsedProgram, program, oldProgram, coords, maxWidthIndex);

		this._robot.changeProgramData(newProgram);
		this.updateProgramContainer(true);
	},

	/**
     * Enables Controlled mode of program layer.</br>
     * In this mode buttons animates once and called click callback passed to function.
     * @param {pm.ControlledModeData} controlledModeData
     * @private
     */
	_enableControlledMode: function(controlledModeData)
	{
		this._controlledMode = true;
		this._buttonClickCallback = controlledModeData.addMethodCallback;
		this._buttonClickCallbackTarget = controlledModeData.callbackTarget;

		var child = this._selectLayout.getChildren();

		for(var i = 0 ; i < this._selectLayout.getChildrenCount(); ++i)
		{
			child[i].setEnabled(true);
			child[i].setBright(true);
		}

		this._programControlLayer.setButtonsEnabled(false);
	},

	/**
	 * Start recognizing of program
	 * @private
	 */
	_recognizeProgram: function()
	{
		this._programControlLayer.hideRecognizeResultButton();

		var program = this._robot.getProgramData();

		var sendData = {
            munich_mode: true,
			width: program.width,
			height: program.height,
			signs: []
		};

		for (var i = 0; i < program.height; ++i)
		{
			sendData.signs.push([]);

			for (var j = 0; j < program.width; ++j)
			{
				var symbol = program.symbols[i][j];
				var notEmpty = symbol.type !== pm.SymbolType.Empty;

				sendData.signs[i].push(notEmpty);
			}
		}

		var taskID = "{0}_{1}_{2}".format(world.id, pm.settings.getGame(), pm.settings.getGame());

		pm.programRecognizer.recognize(JSON.stringify(sendData), taskID);
		pm.programRecognizer.setSuccessCallback(this._onProgramRecognize.bind(this));
		pm.programRecognizer.setErrorCallback(this._onRecognizeError.bind(this));
	},

	_onRecognizeError: function(error)
	{
		if(error !== "")
		{
			var messageBox = new pmui.MessageBox(this, error);

			var x = pm.settings.getScreenSize().width / 2 - messageBox.getContentSize().width / 2;
			var y = pm.settings.getScreenSize().height / 2 - messageBox.getContentSize().height / 2;
			messageBox.setPosition(x, y);
			cc.director.getRunningScene().addChild(messageBox, 1000);
		}
	},

	_onProgramRecognize: function(predictions)
	{
		this._programControlLayer.showRecognizeResultButton(predictions);
	},

	/**
     * Resets layer
     */
	reset: function()
	{
		this._programContainer.clearAllHighlights();

		this._programControlLayer.setButtonsEnabled(true);

		this._programContainer.reloadRepeaters();

		this.setEnabled(true);
	},

	setEnabled: function(flag, steppedWorking)
	{
		this._programContainer.enabled = flag;

		var child = this._selectLayout.getChildren();

		for(var i = 0 ; i < this._selectLayout.getChildrenCount(); ++i)
		{
			if (child[i].layoutType == ccui.Layout.LINEAR_VERTICAL)
			{
				var children = child[i].getChildren();
				for(var j = 0 ; j < child[i].getChildrenCount(); ++j)
				{
					children[j].setEnabled(flag);
					children[j].setBright(flag);
				}
			}
			else if (child[i].layoutType == ccui.Layout.LINEAR_HORIZONTAL)
			{
				child[i].setEnabled(flag);
				child[i].setBright(flag);
			}
		}

		if(this._methodStackLayer)
		{
			if (steppedWorking)
				this._methodStackLayer.setEnabled(true);
			else
				this._methodStackLayer.setEnabled(flag);

		}
	}
});
var _p = ProgramLayerP.prototype;

/** @expose */
_p.robot;
cc.defineGetterSetter(_p, "robot", _p.getRobot, _p.setRobot);

ProgramLayerP.POSITION_BACK_X = 262;