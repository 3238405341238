/**
 * Created by Kirill Mashchenko on 03.07.17.
 */

/**
 * @class Functions for RepairLevel module.
 * @interface
 * @extends pm.ModuleInfo
 */

pm.RepairLevelModule = pm.ModuleInfo.extend(/** @lends pm.RepairLevelModule# */{

	name: "Repair",

	getType: function()
	{
		return pm.RepairLevelModule.Type;
	},

	generateEmptyMap: function (level)
	{
		var map = new pm.data.RepairMap();

		map.parentLevel = level;

		var sqrtCount = Math.max(3, Math.floor(Math.sqrt(level.robots.length))+1);

		map.setSize(sqrtCount, sqrtCount);

		for(var x = 0; x < sqrtCount; ++x)
		{
			for(var y = 0; y < sqrtCount; ++y)
				map.element(cc.p(x, y)).originalType = RepairMapElementType.LightBlueGrass;
		}

		map.element(cc.p(1, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(1, 1)).startRobotData.direction = 0;

		var x = 0;
		var y = 0;

		for (var i = 1; i < level.robots.length; ++i)
		{
			map.element(cc.p(x, y)).startForRobot = level.robots[i].id;
			map.element(cc.p(x, y)).startRobotData.direction = 0;

			++x;

			if (x === 1 && y === 1)
				++x;

			if (x >= sqrtCount)
			{
				++y;
				x = 0;
			}
		}

		return map;
	},

	generateEmptyLevel: function ()
	{
		var level = new pm.data.RepairLevel();

		level.name = "Новый Вертун";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.RepairRobot());

		this._fillLevelData(level);

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.RepairLevelModule.Type] = new pm.data.RepairTask(level);
		level.taskList.tasks[pm.RepairLevelModule.Type].data[0] = 0;

		return level;
	},

	getObjectsInfo: function()
	{
		return {};
	},

	getRobotsInfo: function ()
	{
		var ret = {};
		ret[pm.RepairLevelModule.RobotType] = "rep_robot0.png";
		return ret;
	},

	getRobotSpriteSheet: function (robotType)
	{
		return "Robot/repair_robot.plist";
	},

	getSettings: function (robotType, object)
	{
		return new RepairRobotSettingsLayer(object);
	},

	getMapElementsType: function()
	{
		return RepairMapElementType;
	},

	getRobotDisplayedName: function (robotType)
	{
		return LocalizedString("Repair");
	},

	getDisplayedNames: function()
	{
		return LocalizedString(this.name);
	},

	getMapTileset: function (tileset)
	{
		return "MapsPics/repair_level-tileset{0}.plist".format(tileset);
	},

	getResources: function ()
	{
		return [
			"Robot Methods/robot4-methods.plist",
			"Robot Methods/repair_robot-methods.plist",
			"Conditions/robot4-conditions.plist",
			"Conditions/repair_robot-conditions.plist"
		];
	},

	generateRobot: function (robotType)
	{
		return new pm.data.RepairRobot();
	},

	canNotDragOrAddObject: function (map, point)
	{
		return false;
	},

	getLocalization: function(language)
	{
		switch (language)
		{
			case cc.sys.LANGUAGE_ENGLISH:
				return {
					"Repair": "Repair",
					"RepairLevelType": "Repair level",
					"RepairTask": "Repair task",
					"NeedToRepairPattern": "Need to repair: {0}",
					"RepairLevel": "Repair",
					"rep_repair" : "repair",
					"rep_gig": "is cell painted",
					"rep_ging" : "is cell not painted",
					"rep_gilb" : "is cell light blue",
					"rep_ginlb" : "is cell not light blue",
					"rep_gib" : "is cell blue",
					"rep_ginb" : "is cell not blue"
				};
			case cc.sys.LANGUAGE_RUSSIAN:
				return {
					"Repair": "Вертун",
					"RepairLevelType": "Вертун",
					"RepairTask": "Задание починки",
					"NeedToRepairPattern": "Нужно починить: {0}",
					"RepairLevel": "Вертун",
					"rep_repair" : "закрасить",
					"rep_gig": "клетка закрашена",
					"rep_ging" : "клетка чистая",
					"rep_gilb" : "клетка голубая",
					"rep_ginlb" : "клетка не голубая",
					"rep_gib" : "клетка синяя",
					"rep_ginb" : "клетка не синяя"
				};
		}
	},

	getResourcesPlist: function ()
	{
		return "PlayerRobots/RepairLevel.plist";
	},

	generateTaskLayer: function (level, task)
	{
		return new RepairTaskLayer(level, task);
	},

	getTaskLabel: function ()
	{
		return "RepairTask";
	},

	addLevelTask: function (level)
	{
		var task = new pm.data.RepairTask(level);

		for(var i =0; i < level.maps.length;++i)
		{
			var repairValue = 0;
			var map = level.maps[i];

			for (var x = 0; x < map.width; ++x)
			{
				for (var y = 0; y < map.height; ++y)
				{
					if (map.element(cc.p(x, y)).originalType === RepairMapElementType.BrokenGrass)
						++repairValue;
				}
			}

			task.fillWithInitialData(i, repairValue);
		}

		level.taskList.addTask(pm.RepairLevelModule.Type, task);

		return task;
	},

	addExtraMethods: function(robot, functions) {},

	getDepthLineSprites: function()
	{
		return {
			horizontal: pm.spriteUtils.getMapTileFrame("repair-depth_horizontal"),
			vertical: pm.spriteUtils.getMapTileFrame("repair-depth_vertical")
		};
	},

	getChangeableConditions: function(robot, callback, target)
	{
		var conditionsLayout = new ccui.Layout();
		conditionsLayout.setLayoutType(ccui.Layout.RELATIVE);

		var greyCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsGrey, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsGrey, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsGrey, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useGreyCondition)
			greyCondition.setSelectedTexture();
		else
			greyCondition.setNormalTexture();

		greyCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "grey", flag);

			if (robot.useGreyCondition)
				greyCondition.setSelectedTexture();
			else
				greyCondition.setNormalTexture();
		});

		var lightBlueCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsLightBlue, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsLightBlue, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsLightBlue, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useLightBlueCondition)
			lightBlueCondition.setSelectedTexture();
		else
			lightBlueCondition.setNormalTexture();

		lightBlueCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "lightBlue", flag);

			if (robot.useLightBlueCondition)
				lightBlueCondition.setSelectedTexture();
			else
				lightBlueCondition.setNormalTexture();
		});

		var blueCondition = new pmui.SelectionButton(pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsBlue, pm.NORMAL_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsBlue, pm.SELECTED_STATE),
			pm.spriteUtils.getRobotConditionSpriteName(pm.data.RepairRobot.Condition.GrassIsBlue, pm.DISABLED_STATE),
			ccui.Widget.PLIST_TEXTURE);

		if (robot.useBlueCondition)
			blueCondition.setSelectedTexture();
		else
			blueCondition.setNormalTexture();

		blueCondition.addClickEventListener(function (flag)
		{
			callback.call(target, "blue", flag);

			if (robot.useBlueCondition)
				blueCondition.setSelectedTexture();
			else
				blueCondition.setNormalTexture();
		});

		var greyAlign = new ccui.RelativeLayoutParameter();
		greyAlign.setAlign(ccui.RelativeLayoutParameter.PARENT_TOP_LEFT);
		greyAlign.setRelativeName("grey");
		greyCondition.setLayoutParameter(greyAlign);

		var lightBlueAlign = new ccui.RelativeLayoutParameter();
		lightBlueAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		lightBlueAlign.setMargin(5, 0, 0, 0);
		lightBlueAlign.setRelativeName("lightBlue");
		lightBlueAlign.setRelativeToWidgetName("grey");
		lightBlueCondition.setLayoutParameter(lightBlueAlign);

		var blueAlign = new ccui.RelativeLayoutParameter();
		blueAlign.setAlign(ccui.RelativeLayoutParameter.LOCATION_RIGHT_OF_CENTER);
		blueAlign.setMargin(5, 0, 0, 0);
		blueAlign.setRelativeToWidgetName("lightBlue");
		blueCondition.setLayoutParameter(blueAlign);

		conditionsLayout.addChild(greyCondition);
		conditionsLayout.addChild(lightBlueCondition);
		conditionsLayout.addChild(blueCondition);

		conditionsLayout.setContentSize(LevelSettingsLayer.WIDTH, greyCondition.height);

		return conditionsLayout;
	},

	updateChangeableConditions: function(robot, name)
	{
		switch(name)
		{
			case "grey":
				robot.useGreyCondition = !robot.useGreyCondition;
				break;
			case "lightBlue":
				robot.useLightBlueCondition = !robot.useLightBlueCondition;
				break;
			case "blue":
				robot.useBlueCondition = !robot.useBlueCondition;
				break;
		}
	},

	compareConditions: function(a, b)
	{
		if (a.indexOf("4") !== -1 && b.indexOf("4") === -1)
			return -1;
		else if (a.indexOf("4") === -1 && b.indexOf("4") !== -1)
			return 1;

		if (a.lastIndexOf("g") === a.length - 1 && b.lastIndexOf("g") !== b.length - 1)
			return -1;
		else if (a.lastIndexOf("g") !== a.length - 1 && b.lastIndexOf("g") === b.length - 1)
			return 1;

		if (a.indexOf("l") !== -1 && b.indexOf("l") === -1)
			return -1;
		else if (a.indexOf("l") === -1 && b.indexOf("l") !== -1)
			return 1;
	},

	getLessonMapLayer: function()
	{
		var level = new pm.data.RepairLevel();

		level.name = "Repair";
		level.hint = new pm.data.GlobalHint();
		level.hint.text = "";
		level.robots.push(new pm.data.RepairRobot());

		this._fillLevelData(level);

		level.robots[0]._oldPosition = cc.p(0, 1);
		level.robots[0].position = cc.p(0, 1);
		level.robots[0].direction = 1;

		level.taskList = new pm.data.TaskList();
		level.taskList.tasks = new pm.data.MapClass();
		level.taskList.tasks[pm.RepairLevelModule.Type] = new pm.data.RepairTask(level);
		level.taskList.tasks[pm.RepairLevelModule.Type].data[0] = 0;

		var map = new pm.data.RepairMap();

		map.parentLevel = level;
		map.setSize(2, 2);

		map.element(cc.p(0, 0)).originalType = RepairMapElementType.LightBlueGrass;
		map.element(cc.p(1, 0)).originalType = RepairMapElementType.RepairedGrass;
		map.element(cc.p(0, 1)).originalType = RepairMapElementType.BlueGrass;
		map.element(cc.p(1, 1)).originalType = RepairMapElementType.BrokenGrass;

		map.element(cc.p(0, 1)).startForRobot = level.robots[0].id;
		map.element(cc.p(0, 1)).startRobotData.direction = 1;

		level.maps.push(map);
		level.load();

		map.load();
		map.generateMapLayer(false);

		map.mapLayer.placeRobots(true);

		return map.mapLayer;
	}
});

pm.RepairLevelModule.RobotType = "repair-robot";
pm.RepairLevelModule.Type = "repair";
pm.RepairLevelModule.ObjectTypes = {};
